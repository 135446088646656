import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import TypographyCapitalize from 'components/TypographyCapitalize';
import TypographyEmail from 'components/TypographyEmail';
import TypographyPhone from 'components/TypographyPhone';
import { selectCurrentUser } from 'reducers/selectors';
import accountService from 'services/accountService';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2,
  },
  label: {
    fontWeight: 'bold',
  },
});

class Account extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({}).isRequired,
  };

  render() {
    const {
      classes,
      currentUser: { name, company, careerTitle, freeformCompany, freeformCareerTitle, phone, email },
    } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}>Name</Typography>
          <Typography>{name || '-'}</Typography>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}>Company</Typography>
          <Typography>{company === 'OTHER' ? freeformCompany : accountService.getCompanyLabel(company)}</Typography>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}>Rank</Typography>
          <TypographyCapitalize>
            {company === 'OTHER' ? freeformCareerTitle : careerTitle.replace(/[0-9]+_/g, '')}
          </TypographyCapitalize>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label}>Phone</Typography>
          <TypographyPhone disableClick>{phone}</TypographyPhone>
        </FormControl>
        <FormControl>
          <Typography className={classes.label}>Email</Typography>
          <TypographyEmail disableClick>{email}</TypographyEmail>
        </FormControl>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Account);
