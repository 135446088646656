import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FieldTrimmed from 'components/FieldTrimmed';
import FormTextField from 'components/FormTextField';
import { selectLoading, selectCurrentUser } from 'reducers/selectors';
import { reduxForm } from 'redux-form';
import validation from 'services/validationService';
import { DISPATCH_CONNECT_TO_SC } from 'actions/actionTypes';

import scLogo from './SC_LOGO_BLACK_RGB-01.png';

const styles = theme => ({
  wrapper: {
    margin: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 3.5}px`,
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  wrapperLite: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
      maxWidth: 'initial',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  buttonSubmit: {
    marginLeft: theme.spacing.unit,
  },
  listItemWrapper: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing.unit * 3,
  },
  field: {
    // marginLeft: theme.spacing.unit * 2,
    minWidth: 270,
    maxWidth: 350,
  },
  fieldWrapper: {
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
  },
  formWrapper: {
    marginTop: theme.spacing.unit * 2,
  },
  fieldsWrapper: {
    background: '#F6F3F2',
    padding: '20px',
    maxWidth: '420px',
    margin: '0 auto',
  },
  scLogo: {
    width: '80%',
    maxWidth: '200px',
    margin: '0 auto',
    display: 'block',
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  mainDivider: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    marginBottom: theme.spacing.unit * 4,
  },
  forgotText: {
    marginLeft: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    '& a': {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
});

class ConnectSCAccount extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    section: PropTypes.shape({}).isRequired,
    lite: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatchConnectToSC: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lite: false,
  };

  componentDidUpdate() {
    const { section } = this.props;

    if (section.section) {
      this.changeOnboardingStep(section.section);
    }
  }

  changeOnboardingStep = step => {
    cookie.save('onboardingStep', `/onboarding/${step}`, { path: '/' });
  };

  handleSubmit = values => {
    const { dispatchConnectToSC } = this.props;
    dispatchConnectToSC(values);
  };

  renderScoutCellarConnecting() {
    const {
      classes,
      loading,
      pristine,
      submitting,
      handleSubmit,
      section: { nextPathname },
    } = this.props;

    return (
      <form className={classes.formWrapper} onSubmit={handleSubmit(values => this.handleSubmit(values))}>
        <div className={classes.fieldsWrapper}>
          <div className={classes.fieldWrapper}>
            <img src={scLogo} alt="" className={classes.scLogo} />
            <FieldTrimmed
              className={classes.field}
              component={FormTextField}
              name="username"
              label="S&C Username"
              margin="normal"
              fullWidth
              autoFocus
            />
          </div>
          <div className={classes.fieldWrapper}>
            <FieldTrimmed
              className={classes.field}
              component={FormTextField}
              type="password"
              name="password"
              label="Password"
              margin="normal"
              fullWidth
            />
          </div>
          <Typography variant="caption" className={classes.forgotText}>
            <a href="https://team.scoutandcellar.com/Account/ForgotPassword" target="_blank" rel="noopener noreferrer">
              Forgot S&C password?
            </a>
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.buttonSubmit}
            variant="contained"
            color="primary"
            disabled={pristine || submitting || loading}
            type="submit"
          >
            {!nextPathname ? 'Done' : 'Next'}
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const {
      classes,
      section: { title },
      lite,
    } = this.props;

    return (
      <React.Fragment>
        <div
          className={classnames(classes.wrapper, {
            [classes.wrapperLite]: lite,
          })}
        >
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography component="div" variant="body2" gutterBottom>
              {this.renderScoutCellarConnecting()}
            </Typography>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchConnectToSC: data => ({ type: DISPATCH_CONNECT_TO_SC, data }),
  },
);

const withForm = reduxForm({
  form: 'ConnectSCAccount',
  validate: validation.createValidator({
    username: validation.required,
    password: validation.required,
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(ConnectSCAccount);
