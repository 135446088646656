import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit / 2,
  },
  select: {
    cursor: 'unset',
    background: 'none',
    fontSize: 22,
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
  },
  settings: {
    paddingLeft: '10px',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.34)',
    '&:hover': {
      color: '#000000',
    },
  },
  text: {
    paddingLeft: 0,
  },
});

class HomeListSelector extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { classes, onChange } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <MenuItem disableRipple className={classes.select}>
          Today&apos;s IPAs
          <ListItemIcon className={classes.settings} onClick={() => onChange('work/settings')}>
            <SettingsIcon />
          </ListItemIcon>
        </MenuItem>
      </FormControl>
    );
  }
}

export default compose(withStyles(styles))(HomeListSelector);
