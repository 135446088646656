import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { get, omit } from 'lodash';
import moment from 'moment';
import cookie from 'react-cookies';
import capitalize from 'capitalize';

import ChipEditStatus from 'components/ChipEditStatus';
import ChipLogReachOut from 'components/ChipLogReachOut';
import Backdrop from 'components/Backdrop';
import DialogConfirmSkip from 'components/DialogConfirmSkip';
import SocialMediaShortcuts from 'components/SocialMediaShortcuts';
import {
  DISPATCH_SAVE_CONTACT,
  DISPATCH_SAVE_ACTIVITY,
  DISPATCH_SAVE_REMINDER,
  DISPATCH_DELETE_REMINDER,
} from 'actions/actionTypes';
import { selectLoading } from 'reducers/selectors';

const styles = theme => ({
  root: {
    padding: `0 ${theme.spacing.unit}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
  },
  textRoot: {
    paddingLeft: 0,
    paddingRight: theme.spacing.unit,
  },
  textWrapper: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  textNameWrap: {
    minWidth: 180,
    maxWidth: 180,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      minWidth: 160,
      maxWidth: 160,
    },
    '@media (max-width:340px)': {
      // eslint-disable-line no-useless-computed-key
      minWidth: 110,
      maxWidth: 110,
    },
  },
  textName: {
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 140,
    marginRight: theme.spacing.unit * 0.5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
    },
    '@media (max-width:340px)': {
      // eslint-disable-line no-useless-computed-key
      maxWidth: 70,
    },
  },
  textNotes: {
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textDate: {
    minWidth: 100,
    maxWidth: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  secondaryAction: {
    margin: (-1 * theme.spacing.unit) / 2,
    marginRight: theme.spacing.unit / 2,
  },
  chip: {
    flex: 1,
    margin: theme.spacing.unit / 2,
  },
  circle: {
    margin: 12,
  },
  popper: {
    zIndex: '1',
  },
});

class HomeListItem extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      contact: PropTypes.shape({}).isRequired,
    }).isRequired,
    type: PropTypes.oneOf(['contacts', 'reminders', 'activities']).isRequired,
    onClick: PropTypes.func.isRequired,
    dispatchSaveContact: PropTypes.func.isRequired,
    dispatchSaveReminder: PropTypes.func.isRequired,
    dispatchSaveActivity: PropTypes.func.isRequired,
    dispatchDeleteReminder: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: undefined,
    isConfirmDialog: false,
  };

  handleRemoveClick = () => {
    const {
      item: { contact, data },
      dispatchDeleteReminder,
      dispatchSaveContact,
    } = this.props;

    if (get(data, 'type') === 'reminders') {
      dispatchDeleteReminder(contact, data);
    } else {
      dispatchSaveContact({
        ...contact,
        mutedUntil: moment()
          .add(2, 'weeks')
          .toDate(),
      });
    }
  };

  onConfirmDialogOpen = event => {
    event.preventDefault();
    event.stopPropagation();
    if (cookie.load('isContactSkipped')) {
      this.handleRemoveClick();
    } else {
      this.setState({
        isConfirmDialog: true,
      });
    }
  };

  onConfirmDialogClose = () => {
    this.setState({
      isConfirmDialog: false,
    });
  };

  cancelSkipContact = () => {
    cookie.save('isContactSkipped', true, { path: '/' });
    this.onConfirmDialogClose();
  };

  skipContact = () => {
    cookie.save('isContactSkipped', true, { path: '/' });
    this.handleRemoveClick();
  };

  handleReachOut = event => {
    event.stopPropagation();
  };

  handleChangeLogReachOut = value => {
    const {
      dispatchSaveActivity,
      dispatchSaveReminder,
      dispatchSaveContact,
      item: { contact },
    } = this.props;

    const { reminderValue, frequency, contactType, contactStatus } = value;
    dispatchSaveActivity(contact, { ...value, activityType: 'REACH_OUT' });
    if (reminderValue) {
      dispatchSaveReminder(contact, reminderValue);
    }

    const params = {};
    if (frequency !== contact.frequency) {
      params.frequency = frequency;
    }
    if (contactType !== contact.contactType && contactType !== undefined) {
      params.contactType = contactType;
    }
    if (contactStatus !== contact.contactStatus && contactStatus !== undefined) {
      params.contactStatus = contactStatus;
    }
    if (params !== {}) {
      dispatchSaveContact({ ...contact, ...params });
    }
  };

  renderItemText() {
    const {
      classes,
      item: { contact, data },
    } = this.props;

    let dateLabel;
    let dateLabelColor;
    if (data) {
      const date = moment(data.datetime || data.date);
      if (date.isSame(moment(), 'day')) {
        dateLabel = data.type === 'reminders' ? 'today' : 'Today';
      } else if (date.isSame(moment().add(1, 'day'), 'day')) {
        dateLabel = 'in 1 day';
      } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
        dateLabel = '1 day ago';
      } else {
        dateLabel = date.fromNow();
      }
      if (data.type === 'reminders') {
        dateLabel = `Due ${dateLabel}`;
        dateLabelColor = 'secondary';
      }
    } else {
      dateLabel = 'New';
    }

    return (
      <div className={classes.textWrapper}>
        <span className={classes.textNameWrap}>
          <Typography className={classes.textName} noWrap>
            {contact.name}
          </Typography>
          <SocialMediaShortcuts contact={contact} />
        </span>
        <Typography className={classes.textNotes} noWrap>
          {data ? data.notes : ''}
        </Typography>
        <span className={classes.grow} />
        <Typography className={classes.textDate} color={dateLabelColor} noWrap>
          {dateLabel}
        </Typography>
      </div>
    );
  }

  render() {
    const {
      classes,
      loading,
      item: { contact, data },
      type,
      dispatchSaveContact,
      dispatchSaveActivity,
      onClick,
      ...other
    } = this.props;
    const { anchorEl, isConfirmDialog } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <ListItem
          className={classes.root}
          onClick={loading ? undefined : onClick}
          {...omit(other, [
            'dispatchSaveContact',
            'dispatchSaveReminder',
            'dispatchSaveActivity',
            'dispatchDeleteReminder',
          ])}
        >
          <Tooltip title={capitalize(contact.contactStatus)} placement="top" classes={{ popper: classes.popper }}>
            <ChipEditStatus
              className={classes.circle}
              short
              value={contact.contactStatus}
              onChange={contactStatus => dispatchSaveContact({ ...contact, contactStatus })}
            />
          </Tooltip>
          <ListItemText
            classes={{
              root: classes.textRoot,
            }}
            primary={this.renderItemText()}
          />
          <Tooltip title="Log activity" placement="top" classes={{ popper: classes.popper }}>
            <div role="presentation" onClick={this.handleReachOut}>
              <ChipLogReachOut
                contact={contact}
                hasChips
                isBtn
                loading={loading}
                className={classes.chip}
                PopoverProps={{ moveOnResize: true }}
                onChange={v => this.handleChangeLogReachOut(v)}
              />
            </div>
          </Tooltip>
          {type !== 'activities' && (
            <Tooltip title={data && data.type === 'reminders' ? 'Delete reminder' : 'Skip for 2 weeks'} placement="top">
              <div>
                <IconButton aria-label="Close" onClick={this.onConfirmDialogOpen} disabled={loading}>
                  <Close fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </ListItem>
        <Backdrop active={open} />
        <DialogConfirmSkip
          title={`Skip ${contact.firstName} for 2 Weeks?`}
          text="Tapping X indicates you want to skip this contact for now and have them come back in 2 weeks."
          open={isConfirmDialog}
          onSubmit={this.skipContact}
          onCancel={this.cancelSkipContact}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
  }),
  {
    dispatchSaveContact: contact => ({ type: DISPATCH_SAVE_CONTACT, contact }),
    dispatchSaveReminder: (contact, reminder) => ({ type: DISPATCH_SAVE_REMINDER, contact, reminder }),
    dispatchSaveActivity: (contact, activity) => ({ type: DISPATCH_SAVE_ACTIVITY, contact, activity }),
    dispatchDeleteReminder: (contact, reminder) => ({ type: DISPATCH_DELETE_REMINDER, contact, reminder }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(HomeListItem);
