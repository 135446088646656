import { all, call, select, put } from 'redux-saga/effects';

import { ASSIGN_FREQUENCIES, SAVE_ACCOUNT, DISPATCH_LOADING_START, DISPATCH_LOADING_STOP } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import contactsResource from 'resources/contactsResource';
import { selectCurrentUser } from 'reducers/selectors';
import history from 'config/history';
import sagaFetch from './sagaFetch';
import sagaFetchContacts from './sagaFetchContacts';

// bind resource generator
const assignFrequencies = sagaFetch.bind(null, ASSIGN_FREQUENCIES, contactsResource.assignFrequencies);
const saveAccount = sagaFetch.bind(null, SAVE_ACCOUNT, accountResource.saveAccount);

export default function* sagaFinishOnboarding() {
  yield put({ type: DISPATCH_LOADING_START, loadingMessage: 'Finishing onboarding...' });

  // assign frequencies to contacts and set onboarded to true
  const user = yield select(selectCurrentUser);
  yield all([
    call(assignFrequencies, {
      params: {
        assignAllContacts: true,
      },
      skipLoading: true,
    }),
    call(saveAccount, { account: { ...user, onboarded: true }, skipLoading: true }),
  ]);

  // reload contacts
  yield call(sagaFetchContacts, { skipLoading: true });

  // redirect
  yield put({ type: DISPATCH_LOADING_STOP });
  history.push('/home/work');
}
