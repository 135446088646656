import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconOffline = props => (
  <SvgIcon width="500pt" height="500pt" viewBox="0 0 500 500" {...props}>
    <path
      d="M 41.8125 23.40625 L 27.785156 37.433594 L 136.757813 146.40625 C 136.5625 146.40625 136.371094 146.328125 136.136719 146.328125 L 179.347656 189.5 L 179.460938 189.113281 L 367.335938 376.984375 L 366.792969 376.984375 L 386.632813 396.824219 L 387.175781 396.824219 L 456.503906 466.113281 L 470.492188 452.125 L 414.84375 396.476563 C 460.222656 392.796875 496.03125 354.816406 496.03125 308.507813 C 496.03125 263.285156 461.851563 225.886719 417.945313 220.773438 C 418.0625 218.914063 418.0625 217.050781 418.0625 215.230469 C 418.0625 143.308594 359.546875 84.789063 287.621094 84.789063 C 239.21875 84.789063 195.003906 112.109375 172.566406 154.195313 Z M 287.621094 104.632813 C 348.617188 104.632813 398.21875 154.234375 398.21875 215.230469 C 398.21875 219.339844 397.949219 223.71875 397.289063 228.949219 L 395.894531 240.070313 L 408.023438 240.070313 C 445.652344 240.226563 476.191406 270.878906 476.191406 308.507813 C 476.191406 346.292969 445.460938 376.984375 407.714844 376.984375 L 395.390625 376.984375 L 187.289063 168.921875 C 205.273438 130.128906 244.527344 104.632813 287.621094 104.632813 Z M 111.179688 149.46875 C 84.675781 158.03125 65.066406 182.019531 63.089844 210.851563 C 25.617188 224.492188 0 260.496094 0 300.875 C 0 353.769531 43.054688 396.824219 95.949219 396.824219 L 358.578125 396.824219 L 338.734375 376.984375 L 95.949219 376.984375 C 53.980469 376.984375 19.839844 342.84375 19.839844 300.875 C 19.839844 266.847656 42.78125 236.699219 75.644531 227.632813 L 83.203125 225.539063 L 82.773438 215.617188 C 82.773438 189.695313 102.773438 168.613281 128.078125 166.363281 Z "
      fill="#808080"
    />
  </SvgIcon>
);

export default IconOffline;
