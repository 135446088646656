import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { find } from 'lodash';

import ImportContacts from 'containers/ImportContacts';
import ConnectSCAccount from 'containers/ConnectSCAccount';
import OnboardingWelcome from 'containers/OnboardingWelcome';
import { selectLoading, selectCurrentUser } from 'reducers/selectors';
import contactsService from 'services/contactsService';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  dropzone: {
    outline: 'none',
  },
  root: {
    background: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: 112,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  loading: {
    opacity: 0.3,
  },
});

class Onboarding extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        section: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
      onboarded: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const {
      loading,
      classes,
      currentUser: { company, onboarded },
      match: {
        params: { section },
      },
    } = this.props;
    const sections = contactsService.getOnboardingSections(company);
    const currentSection = find(sections, { section });

    // If route is invalid (i.e., it does not match any onboarding section, like /onboarding/blah) OR if user is already onboarded, redirect to Home.
    if (onboarded || (!currentSection && section !== 'welcome')) {
      return <Redirect to="/home/work" />;
    }

    // If route is /onboarding/welcome, display the Welcome screen.
    if (section === 'welcome') {
      return (
        <div
          className={classnames(classes.root, {
            [classes.loading]: loading,
          })}
        >
          <div className={classes.toolbar} />
          <OnboardingWelcome />
        </div>
      );
    }

    // If route is /onboarding/connect-sc, display the ConnectSCAccount screen.
    if (section === 'connect-sc') {
      return (
        <div
          className={classnames(classes.root, {
            [classes.loading]: loading,
          })}
        >
          <div className={classes.toolbar} />
          <ConnectSCAccount section={currentSection} />
        </div>
      );
    }

    // Otherwise, display appropriate onboarding/import screen.
    return (
      <div
        className={classnames(classes.root, {
          [classes.loading]: loading,
        })}
      >
        <div className={classes.toolbar} />
        <ImportContacts section={currentSection} />
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Onboarding);
