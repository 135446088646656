import { call } from 'redux-saga/effects';

import { SAVE_ACCOUNT } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const saveAccount = sagaFetch.bind(null, SAVE_ACCOUNT, accountResource.saveAccount);

export default function* sagaSaveAccount({ account, callbackSuccess }) {
  const res = yield call(saveAccount, { account });

  if (res.error) {
    return;
  }
  if (callbackSuccess) {
    callbackSuccess();
  }
}
