// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import StripeInput from 'components/StripeInput';

class StripeElementWrapper extends PureComponent {
  static propTypes = {
    component: PropTypes.func.isRequired,
    InputLabelProps: PropTypes.shape({}),
    InputProps: PropTypes.shape({}),
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
  };

  static defaultProps = {
    InputLabelProps: undefined,
    InputProps: undefined,
    placeholder: undefined,
    helperText: undefined,
  };

  state = {
    focused: false,
    empty: true,
    error: false,
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleChange = value => {
    this.setState({
      error: value.error,
      empty: value.empty,
    });
  };

  render() {
    const { component, InputLabelProps, InputProps, placeholder, helperText, ...other } = this.props;
    const { focused, empty, error } = this.state;
    return (
      <TextField
        InputLabelProps={{
          focused,
          shrink: !!placeholder || focused || !empty,
          ...InputLabelProps,
        }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: { component },
          placeholder: placeholder || '',
          ...InputProps,
        }}
        fullWidth
        helperText={error ? error.message : helperText}
        error={!!error}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        {...other}
      />
    );
  }
}

export default StripeElementWrapper;
