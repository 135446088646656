import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isPristine, isInvalid } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { find } from 'lodash';

import ImportContacts from 'containers/ImportContacts';
import contactsService from 'services/contactsService';
import { selectLoading, selectCurrentUser } from 'reducers/selectors';
import { DISPATCH_IMPORT_CONTACTS } from 'actions/actionTypes';

const styles = () => ({
  tip: {
    color: '#888888',
  },
});

class DialogImportContacts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    section: PropTypes.string,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchImportContacts: PropTypes.func.isRequired,
    formValues: PropTypes.shape({}),
    pristineForm: PropTypes.bool.isRequired,
    invalidForm: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    section: undefined,
    formValues: {},
  };

  state = {
    file: undefined,
  };

  handleFileChanged = file => {
    this.setState({ file });
  };

  handleInstagramImportTypeChanged = instagramImportType => {
    this.setState({ instagramImportType });
  };

  handleImportSubmit = ({ spreadsheetType, titleLite }) => {
    // eslint-disable-next-line no-console
    console.log(`DialogImportContacts.handleImportSubmit spreadsheetType: ${spreadsheetType}`);
    const { dispatchImportContacts, onClose } = this.props;
    const { file, instagramImportType } = this.state;
    dispatchImportContacts(file, spreadsheetType, titleLite, onClose, undefined, instagramImportType);
  };

  handleStandardImportSubmit = ({ spreadsheetType, titleLite }) => {
    const { dispatchImportContacts, onClose, formValues } = this.props;
    const { file } = this.state;
    const spreadsheetHeaders = formValues;

    // eslint-disable-next-line no-console
    console.log(
      `DialogImportContacts.handleStandardImportSubmit spreadsheetType: ${spreadsheetType}, spreadsheetHeaders: ${JSON.stringify(
        spreadsheetHeaders,
      )}`,
    );

    // Call dispatchImportContacts, passing all selected form values (spreadsheet column headers).
    dispatchImportContacts(file, spreadsheetType, titleLite, onClose, spreadsheetHeaders);
  };

  render() {
    const {
      classes,
      loading,
      section,
      currentUser: { company },
      fullScreen,
      open,
      onClose,
      invalidForm,
      pristineForm,
    } = this.props;
    const { file } = this.state;
    const sections = contactsService.getImportSections(company);
    const currentSection = find(sections, { section }) || { titleLite: '' };
    const isStandardImport =
      currentSection.spreadsheetType === 'STANDARD_CUSTOMERS' ||
      currentSection.spreadsheetType === 'STANDARD_CONSULTANTS';

    return (
      <Dialog open={open} onClose={onClose} fullWidth fullScreen={fullScreen}>
        <DialogTitle id="alert-dialog-title">{currentSection.titleLite}</DialogTitle>
        <DialogContent>
          <Typography className={classes.tip} variant="caption">
            Tip: Dough will just add new contacts. It won{"'"}t create duplicates or bring back deleted ones, and your
            work will remain intact.
          </Typography>
          <ImportContacts
            section={currentSection}
            lite
            onFileChanged={this.handleFileChanged}
            onInstagramImportTypeChanged={this.handleInstagramImportTypeChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          {isStandardImport ? (
            <Button
              variant="contained"
              color="primary"
              disabled={!file || loading || pristineForm || invalidForm}
              type="submit"
              onClick={() => this.handleStandardImportSubmit(currentSection)}
            >
              Import
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={!file || loading}
              onClick={() => this.handleImportSubmit(currentSection)}
            >
              Import
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const formValuesSelector = state => getFormValues('SpreadsheetMatching')(state);
const invalidFormSelector = state => isInvalid('SpreadsheetMatching')(state);
const pristineFormSelector = state => isPristine('SpreadsheetMatching')(state);
const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    currentUser: selectCurrentUser,
    invalidForm: invalidFormSelector,
    pristineForm: pristineFormSelector,
    formValues: formValuesSelector,
  }),
  {
    dispatchImportContacts: (file, spreadsheetType, label, callback, spreadsheetHeaders, instagramImportType) => ({
      type: DISPATCH_IMPORT_CONTACTS,
      file,
      spreadsheetType,
      label,
      callback,
      spreadsheetHeaders,
      instagramImportType,
    }),
  },
);

export default compose(
  withConnect,
  withMobileDialog(),
  withStyles(styles),
)(DialogImportContacts);
