import { call } from 'redux-saga/effects';

import { FETCH_ACTIVITY_STATS } from 'actions/actionTypes';
import activitiesResource from 'resources/activitiesResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchActivityStats = sagaFetch.bind(null, FETCH_ACTIVITY_STATS, activitiesResource.fetchActivityStats);

export default function* sagaFetchActivityStats() {
  yield call(fetchActivityStats);
}
