import axios from 'axios';
import last from 'lodash/last';

import api from 'services/api';

const url = '/api/v1/s3';

async function fetchS3Signature({ file }) {
  const res = await api.get(`${url}/sign`, {
    params: {
      filename: file.name,
    },
  });
  const result = last(res.normalized.s3Key.split('/'));
  return {
    ...res,
    normalized: {
      entities: {
        S3: {
          [result]: {
            ...res.normalized,
          },
        },
      },
      result,
    },
  };
}

function uploadFile({ s3SignedUrl, file }) {
  return axios.put(s3SignedUrl, file);
}

export default {
  fetchS3Signature,
  uploadFile,
};
