import { schema } from 'normalizr';
import moment from 'moment';

import api from 'services/api';
import resource from './resource';

const url = '/api/v1/activities';

// Interceptors
function requestInterceptor(data) {
  return {
    ...data,
    date: undefined,
  };
}

function processStrategy(data) {
  const res = resource.processStrategy(data);
  return {
    ...res,
    date: moment(res.datetime),
    type: res.type || 'activities',
  };
}

// Schemas
const activitySchema = new schema.Entity('activities', {}, { processStrategy });
const activitiesSchema = [activitySchema];

// Methods
function fetchRecentActivity() {
  return api.get(`${url}/all`, {
    params: {
      start_date: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    schema: activitiesSchema,
  });
}

function fetchActivityStats() {
  return api.get(`${url}/stats_v2`, {
    params: {
      date: moment().format('YYYY-MM-DD'),
    },
  });
}

function fetchAllActivityStats() {
  return api.get(`${url}/all_stats`);
}

export default {
  requestInterceptor,
  processStrategy,
  activitySchema,
  activitiesSchema,
  fetchRecentActivity,
  fetchActivityStats,
  fetchAllActivityStats,
};
