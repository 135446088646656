import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import DialogConfirmDelete from 'components/DialogConfirmDelete';

import { DISPATCH_DELETE_CONTACTS } from 'actions/actionTypes';

const styles = () => ({
  iconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  textButton: {
    margin: '0 14px 0 0',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    fontFamily: 'MuseoSans',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      color: '#00bcd4',
    },
  },
  deleteIcon: {
    color: '#000',
  },
});

class CustomToolbarSelect extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    selectedRows: PropTypes.shape([]).isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    dispatchDeleteContact: PropTypes.func,
    openQuickLog: PropTypes.func,
  };

  static defaultProps = {
    dispatchDeleteContact: undefined,
    openQuickLog: undefined,
  };

  state = {
    openDialogDelete: false,
  };

  openDeleteModal = () => {
    this.setState({ openDialogDelete: true });
  };

  handleDelete = () => {
    const { selectedRows, data, dispatchDeleteContact } = this.props;
    this.setState(
      {
        openDialogDelete: false,
      },
      () => {
        const ids = [];
        selectedRows.data.map(item => ids.push(JSON.parse(data[item.dataIndex][0]).id));
        dispatchDeleteContact(ids);
      },
    );
  };

  openQuickLog = () => {
    const { openQuickLog } = this.props;
    openQuickLog();
  };

  render() {
    const { classes } = this.props;
    const { openDialogDelete } = this.state;

    return (
      <React.Fragment>
        <div className="custom-toolbar-select">
          <Tooltip title="Batch Action">
            <div role="presentation" className={classes.textButton} onClick={this.openQuickLog}>
              Batch Action
            </div>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton className={classes.iconButton} onClick={this.openDeleteModal}>
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        </div>

        <DialogConfirmDelete
          title="Delete Contacts"
          text="Are you sure you want to delete these contacts?"
          open={openDialogDelete}
          onClose={() => this.setState({ openDialogDelete: false })}
          onSubmit={this.handleDelete}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  null,
  {
    dispatchDeleteContact: ids => ({
      type: DISPATCH_DELETE_CONTACTS,
      ids,
    }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(CustomToolbarSelect);
