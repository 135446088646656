import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import DialogEditPayment from 'containers/DialogEditPayment';
import { selectCurrentUser } from 'reducers/selectors';

const styles = theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing.unit * 4,
  },
});

class Payment extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    dialogOpen: false,
  };

  render() {
    const {
      classes,
      currentUser: {
        paymentInfo: { cardholderName, brand, lastDigits, expiryYear, expiryMonth },
      },
    } = this.props;
    const { dialogOpen } = this.state;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Typography className={classes.title}>Payment Method</Typography>
          <Typography>{cardholderName}</Typography>
          <Typography>
            {brand} •••• •••• •••• {lastDigits}
          </Typography>
          <Typography>Expires {moment(`${expiryYear}-${expiryMonth}-01`).format('MM/YY')}</Typography>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => this.setState({ dialogOpen: true })}
          >
            Update Payment
          </Button>
        </div>
        <DialogEditPayment
          open={dialogOpen}
          lastDigits={lastDigits}
          onClose={() => this.setState({ dialogOpen: false })}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Payment);
