import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { isEmpty } from 'lodash';

import AccountContainer from 'containers/Account';
import Plan from 'containers/Plan';
import Payment from 'containers/Payment';
import { selectCurrentUser } from 'reducers/selectors';
import accountService from 'services/accountService';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    margin: `${theme.spacing.unit * 2}px 12%`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    },
  },
  paper: {
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
  },
  divider: {
    margin: `${theme.spacing.unit * 4}px ${-1 * theme.spacing.unit * 4}px`,
  },
});

class Account extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      paymentInfo: PropTypes.shape({}).isRequired,
    }).isRequired,
  };

  render() {
    const {
      classes,
      currentUser: { paymentInfo, planKey },
    } = this.props;
    const plan = accountService.getPlan(planKey);
    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" paragraph>
                Your Account
              </Typography>
              <AccountContainer />
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" paragraph>
                Your Subscription
              </Typography>
              <Plan data={plan} />
              {!isEmpty(paymentInfo) && (
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Payment />
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Account);
