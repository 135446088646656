import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

class Anchor extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { classes, children, ...other } = this.props;
    return (
      <span className={classes.root} {...other}>
        {children}
      </span>
    );
  }
}

export default compose(withStyles(styles))(Anchor);
