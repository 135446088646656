import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'screens/Login';
import Signup from 'screens/Signup';
import Header from 'containers/Header';
import storageService from 'services/storageService';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  root: {
    background: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
  wrapper: {
    width: 350,
  },
});

class Auth extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
  };

  render() {
    const { classes } = this.props;
    if (storageService.getAuth()) {
      return <Redirect to="/home/work" />;
    }
    return (
      <div className={classes.root}>
        <Header />
        <div className={classes.wrapper}>
          <div className={classes.toolbar} />
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default compose(withStyles(styles))(Auth);
