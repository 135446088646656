import { find, filter, isNaN, map } from 'lodash';
import pluralize from 'pluralize';

const standardCustomerLabel = 'Customer';
const customerLabels = {
  PLEXUS: standardCustomerLabel,
  MONAT: standardCustomerLabel,
  DOTERRA: standardCustomerLabel,
  ARBONNE: standardCustomerLabel,
  BEACHBODY: standardCustomerLabel,
  BEAUTYCOUNTER: standardCustomerLabel,
  HEMPWORX: standardCustomerLabel,
  ISAGENIX: standardCustomerLabel,
  IT_WORKS: standardCustomerLabel,
  JEUNESSE: standardCustomerLabel,
  LE_VEL: standardCustomerLabel,
  LIMELIFE: standardCustomerLabel,
  MELALEUCA: standardCustomerLabel,
  MODERE: standardCustomerLabel,
  OPTAVIA: 'Client',
  OTHER: standardCustomerLabel,
  PRUVIT: standardCustomerLabel,
  RODAN_FIELDS: standardCustomerLabel,
  SCENTSY: standardCustomerLabel,
  SCOUT_CELLAR: standardCustomerLabel,
  SENEGENCE: standardCustomerLabel,
  SHAKLEE: standardCustomerLabel,
  YOUNG_LIVING: 'Member',
  CREW: 'Member',
  YOUNIQUE: standardCustomerLabel,
};

const standardConsultantLabel = 'Consultant';
const consultantLabels = {
  PLEXUS: 'Ambassador',
  MONAT: 'Market Partner',
  DOTERRA: 'Wellness Advocate',
  ARBONNE: standardConsultantLabel,
  BEACHBODY: 'Coach',
  BEAUTYCOUNTER: standardConsultantLabel,
  HEMPWORX: standardConsultantLabel,
  ISAGENIX: standardConsultantLabel,
  IT_WORKS: standardConsultantLabel,
  JEUNESSE: standardConsultantLabel,
  LE_VEL: standardConsultantLabel,
  LIMELIFE: standardConsultantLabel,
  MELALEUCA: standardConsultantLabel,
  MODERE: standardConsultantLabel,
  OPTAVIA: 'Coach',
  OTHER: standardConsultantLabel,
  PRUVIT: standardConsultantLabel,
  RODAN_FIELDS: standardConsultantLabel,
  SCENTSY: standardConsultantLabel,
  SCOUT_CELLAR: standardConsultantLabel,
  SENEGENCE: standardConsultantLabel,
  SHAKLEE: standardConsultantLabel,
  YOUNG_LIVING: 'Business Builder',
  CREW: 'Business Builder',
  YOUNIQUE: standardConsultantLabel,
};

const standardSuggestedNotes = {
  startedConv: 'Started conversation',
  talkedAbout: 'Talked about relevant subject',
  invited: 'Invited',
  didAssessment: 'Did needs assessment',
  signedAsCustomer: 'Signed up as customer',
  signedAsConsultant: 'Signed up as consultant',
};

const suggestedNotes = {
  PLEXUS: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about health',
    signedAsConsultant: 'Signed up as ambassador',
  },
  MONAT: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about hair and skin',
    signedAsConsultant: 'Signed up as market partner',
  },
  DOTERRA: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about wellness',
    signedAsConsultant: 'Signed up as wellness advocate',
  },
  ARBONNE: standardSuggestedNotes,
  BEACHBODY: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about fitness',
    signedAsConsultant: 'Signed up as coach',
  },
  BEAUTYCOUNTER: standardSuggestedNotes,
  HEMPWORX: standardSuggestedNotes,
  ISAGENIX: standardSuggestedNotes,
  IT_WORKS: standardSuggestedNotes,
  JEUNESSE: standardSuggestedNotes,
  LE_VEL: standardSuggestedNotes,
  LIMELIFE: standardSuggestedNotes,
  MELALEUCA: standardSuggestedNotes,
  MODERE: standardSuggestedNotes,
  OPTAVIA: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about health',
    didAssessment: 'Did Health Assessment',
    signedAsCustomer: 'Signed up as client',
    signedAsConsultant: 'Signed up as coach',
  },
  OTHER: standardSuggestedNotes,
  PRUVIT: standardSuggestedNotes,
  RODAN_FIELDS: standardSuggestedNotes,
  SCENTSY: standardSuggestedNotes,
  SCOUT_CELLAR: {
    ...standardSuggestedNotes,
    talkedAbout: 'Talked about clean-crafted',
    didAssessment: 'Accepted invite',
  },
  SENEGENCE: standardSuggestedNotes,
  SHAKLEE: standardSuggestedNotes,
  YOUNIQUE: standardSuggestedNotes,
};

const youngLivingQuickNotes = {
  PROSPECT: [
    {
      key: 0,
      notes: 'Started conversation',
      contactStatus: 'COLD',
    },
    {
      key: 1,
      notes: 'Talked about relevant subject',
      contactStatus: 'WARM',
      frequency: 3,
    },
    {
      key: 2,
      notes: 'Invited to look at YL, wellness quiz',
      contactStatus: 'WARM',
      frequency: 3,
    },
    {
      key: 3,
      notes: 'Accepted invite',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 4,
      notes: 'Sent enrollment link',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 5,
      notes: 'Enrolled as member',
      contactStatus: 'HOT',
      contactType: 'CUSTOMER',
      frequency: 7,
    },
  ],
  CUSTOMER: [
    {
      key: 0,
      notes: 'Created 90-day wellness plan',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 1,
      notes: 'Joined ER',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 2,
      notes: 'Followed up on wellness plan',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 3,
      notes: 'Encouraged to share',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 4,
      notes: 'Got their enrollment link',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 5,
      notes: 'Got Dough app',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 6,
      notes: 'Enrolled first member',
      contactStatus: 'HOT',
      contactType: 'CONSULTANT',
      frequency: 2,
    },
  ],
  CONSULTANT: [
    {
      key: 0,
      notes: 'Created business plan',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 1,
      notes: 'Revisited plan',
      contactStatus: 'HOT',
      frequency: 7,
    },
  ],
};
const crewQuickNotes = {
  PROSPECT: [
    {
      key: 0,
      notes: 'Started conversation',
      contactStatus: 'COLD',
    },
    {
      key: 1,
      notes: 'Talked about relevant subject',
      contactStatus: 'WARM',
      frequency: 3,
    },
    {
      key: 2,
      notes: 'Invited to look at YL / sent video / came to intro class',
      contactStatus: 'WARM',
      frequency: 3,
    },
    {
      key: 3,
      notes: 'Sent wellness quiz (in Scripts)',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 4,
      notes: 'Sent enrollment link',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 5,
      notes: 'Enrolled as member',
      contactStatus: 'HOT',
      contactType: 'CUSTOMER',
      frequency: 7,
    },
  ],
  CUSTOMER: [
    {
      key: 0,
      notes: 'Created 90-day wellness plan',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 1,
      notes: 'Joined ER',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 2,
      notes: 'Followed up on wellness plan',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 3,
      notes: 'Encouraged to share',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 4,
      notes: 'Got their enrollment link',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 5,
      notes: 'Got Dough app',
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 6,
      notes: 'Enrolled first member',
      contactStatus: 'HOT',
      contactType: 'CONSULTANT',
      frequency: 2,
    },
  ],
  CONSULTANT: [
    {
      key: 0,
      notes: 'Scheduled first meeting to discuss business goals',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 1,
      notes: 'Helped them with calendar / scheduled work time',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 2,
      notes: 'Followed up to execute calendar / schedule',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 3,
      notes: 'Hit Senior Star',
      contactStatus: 'HOT',
      frequency: 7,
    },
    {
      key: 4,
      notes: 'Built their first member to Star',
      contactStatus: 'HOT',
      frequency: 7,
    },
  ],
};

function parseNumber(value) {
  return isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
}

function getCustomerLabel(company) {
  return customerLabels[company];
}

function getCustomersLabel(company) {
  return pluralize(customerLabels[company]);
}

function getConsultantLabel(company) {
  return consultantLabels[company];
}

function getConsultantsLabel(company) {
  return pluralize(consultantLabels[company]);
}

function getQuickNotes(company, contactType) {
  if (company === 'YOUNG_LIVING') {
    return youngLivingQuickNotes[contactType];
  }
  if (company === 'CREW') {
    return crewQuickNotes[contactType];
  }

  // For all other companies, return the standard/default set of notes (not customized by contact type).
  const suggestedNoteStrings = suggestedNotes[company];
  return [
    {
      key: 0,
      notes: suggestedNoteStrings.startedConv,
      contactStatus: 'COLD',
    },
    {
      key: 1,
      notes: suggestedNoteStrings.talkedAbout,
      contactStatus: 'WARM',
      frequency: 3,
    },
    {
      key: 2,
      notes: suggestedNoteStrings.invited,
      contactStatus: 'WARM',
      frequency: 2,
    },
    {
      key: 3,
      notes: suggestedNoteStrings.didAssessment,
      contactStatus: 'HOT',
      frequency: 2,
    },
    {
      key: 4,
      notes: suggestedNoteStrings.signedAsCustomer,
      contactStatus: 'HOT',
      contactType: 'CUSTOMER',
      frequency: 7,
    },
    {
      key: 5,
      notes: suggestedNoteStrings.signedAsConsultant,
      contactStatus: 'HOT',
      contactType: 'CONSULTANT',
      frequency: 7,
    },
    {
      key: 6,
      notes: 'Engaged / RB',
    },
    {
      key: 7,
      notes: 'Followed up',
    },
  ];
}

function getFunnelInsightsColumns(company) {
  const prospectNotes = getQuickNotes(company, 'PROSPECT');
  const customerNotes = getQuickNotes(company, 'CUSTOMER');
  const prospectBecameWarmNotes = filter(prospectNotes, note => note.contactStatus === 'WARM' && !note.contactType);
  const prospectBecameHotNotes = filter(prospectNotes, note => note.contactStatus === 'HOT' && !note.contactType);

  return {
    StartedConversation: {
      title: find(prospectNotes, { key: 0 }).notes,
    },
    BecameWarm: {
      title: map(prospectBecameWarmNotes, e => e.notes).join(' / '),
      average: 25,
    },
    BecameHot: {
      title: map(prospectBecameHotNotes, e => e.notes).join(' / '),
      average: 50,
    },
    BecameCustomer: {
      title: find(prospectNotes, { contactType: 'CUSTOMER' }).notes,
      average: 25,
    },
    BecameConsultant: {
      title: (find(prospectNotes, { contactType: 'CONSULTANT' }) || find(customerNotes, { contactType: 'CONSULTANT' }))
        .notes,
      average: 12.5,
    },
  };
}

export default {
  parseNumber,
  getCustomerLabel,
  getCustomersLabel,
  getConsultantLabel,
  getConsultantsLabel,
  getQuickNotes,
  getFunnelInsightsColumns,
};
