import { merge } from 'lodash';

import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_WORK_LIST,
  DISPATCH_INCREASE_WORK_LIST_FACTOR,
  SAVE_CONTACT,
  DELETE_CONTACT,
  SAVE_REMINDER,
  DELETE_REMINDER,
  SAVE_ACTIVITY,
  DELETE_ACTIVITY,
} from 'actions/actionTypes';

const initialState = {
  factor: 1,
  success: false,
  coldProspects: {},
  warmProspects: {},
  hotProspects: {},
  customers: {},
  consultants: {},
};

export default function createWorkListReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      case FETCH_WORK_LIST.SUCCESS:
        // Cannot use merge() because it does not work for arrays: https://stackoverflow.com/a/33247597
        return {
          ...state,
          ...action.normalized.result,
          success: true,
        };
      case DISPATCH_INCREASE_WORK_LIST_FACTOR:
        return merge({}, state, {
          factor: state.factor + 1,
        });
      // handle updates
      case SAVE_CONTACT.SUCCESS:
      case DELETE_CONTACT.SUCCESS:
      case SAVE_REMINDER.SUCCESS:
      case DELETE_REMINDER.SUCCESS:
      case SAVE_ACTIVITY.SUCCESS:
      case DELETE_ACTIVITY.SUCCESS:
        return merge({}, state, {
          success: false,
        });
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
