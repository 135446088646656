import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconFacebook = props => (
  <SvgIcon width="528" height="528" viewBox="0 0 528 528" {...props}>
    <path
      fill="#3F51B5"
      d="M 462 407 C 462 437.378906 437.378906 462 407 462 L 121 462 C 90.621094 462 66 437.378906 66 407 L 66 121 C 66 90.621094 90.621094 66 121 66 L 407 66 C 437.378906 66 462 90.621094 462 121 Z"
    />
    <path
      fill="#FFFFFF"
      d="M 378.039063 275 L 341 275 L 341 418 L 286 418 L 286 275 L 253 275 L 253 231 L 286 231 L 286 204.488281 C 286.042969 165.902344 302.070313 143 347.53125 143 L 385 143 L 385 187 L 359.863281 187 C 342.160156 187 341 193.617188 341 205.949219 L 341 231 L 385 231 Z"
    />
  </SvgIcon>
);

export default IconFacebook;
