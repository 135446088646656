import { call } from 'redux-saga/effects';

import { SAVE_PAYMENT, SAVE_PLAN } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const savePayment = sagaFetch.bind(null, SAVE_PAYMENT, accountResource.savePayment);
const savePlan = sagaFetch.bind(null, SAVE_PLAN, accountResource.savePlan);

export default function* sagaSavePlan({ cardToken, planKey, callback }) {
  let res = yield call(savePayment, { cardToken });
  if (!res.error) {
    res = yield call(savePlan, { planKey });
    if (!res.error && callback) {
      callback();
    }
  }
}
