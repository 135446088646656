import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { map } from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  tile: {
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

class DialogVideoCatalog extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    videos: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { classes, width, videos, onClose, onChange, ...other } = this.props;
    let cols = 3;
    if (/sm|md/.test(width)) {
      cols = 2;
    } else if (/xs/.test(width)) {
      cols = 1;
    }
    return (
      <Dialog fullScreen onClose={onClose} {...other}>
        <DialogTitle>Video Sessions</DialogTitle>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className={classes.root}>
            <GridList cols={cols} spacing={24} cellHeight={240}>
              {map(videos, video => (
                <GridListTile key={video.key} className={classes.tile} onClick={() => onChange(video)}>
                  <img src={video.img} alt={video.title} />
                  <GridListTileBar
                    className={classes.title}
                    title={video.title}
                    actionIcon={
                      video.completed && (
                        <IconButton>
                          <CheckIcon />
                        </IconButton>
                      )
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(styles),
  withWidth(),
)(DialogVideoCatalog);
