import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';

import Chip from 'components/Chip';
import Menu from 'components/Menu';
import { selectCurrentUser } from 'reducers/selectors';
import { Icon } from '@material-ui/core';
import find from 'lodash/find';

import interestCold from './interest_cold.svg';
import interestWarm from './interest_warm.svg';
import interestHot from './interest_hot.svg';
import interestEdit from './interest_edit.svg';

const styles = () => ({
  icon: {
    padding: '1px',
    marginLeft: '4px',
    width: '90%',
    height: 'auto',
  },
  iconWrap: {
    marginRight: '-4px',
  },
});

const contactStatuses = [
  { key: 'subheader', label: 'Interest Level', subheader: true },
  { key: 'COLD', label: 'Cold', icon: interestCold, textColor: '#1565C0' },
  { key: 'WARM', label: 'Warm', icon: interestWarm, textColor: '#FF9000' },
  { key: 'HOT', label: 'Hot', icon: interestHot, textColor: '#F13A00' },
];

class ChipEditStatus extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    current_user: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    short: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    short: false,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value,
    anchorEl: null,
    isChanged: false,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.changeState();
    }
  }

  changeState = () => {
    const { value } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.value !== value) {
      this.setState({
        isChanged: true,
      });
    } else {
      this.setState({
        isChanged: false,
      });
    }
  };

  handleClick = event => {
    const { short } = this.props;
    if (short) event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = item => {
    const { onChange } = this.props;
    this.handleClose();
    onChange(item ? item.key : null);
  };

  render() {
    const { classes, value, short, dispatch, ...other } = this.props;
    const { anchorEl, isChanged } = this.state;
    const item = find(contactStatuses, { key: value || null });
    const icon = item ? item.icon : interestEdit;
    const label = item ? item.label : 'Edit Interest';
    return (
      <React.Fragment>
        {short ? (
          <Icon label={label} onClick={this.handleClick} selected={!!item} {...other}>
            <img src={icon} className={classes.icon} alt="" />
          </Icon>
        ) : (
          <Chip
            icon={
              <Icon className={classes.iconWrap}>
                <img src={icon} className={classes.icon} alt="" />
              </Icon>
            }
            label={label}
            onClick={this.handleClick}
            selected={isChanged}
            {...other}
          />
        )}
        <Menu
          icons
          anchorEl={anchorEl}
          items={contactStatuses}
          itemSelected={item}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onClick={e => {
            if (short) e.stopPropagation();
          }}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    current_user: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(ChipEditStatus);
