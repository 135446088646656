import { schema } from 'normalizr';
import moment from 'moment';

import api from 'services/api';
import resource from './resource';

const url = '/api/v1/reminders';

// Interceptors
function requestInterceptor(data) {
  return {
    ...data,
    date: undefined,
  };
}

function processStrategy(data) {
  const res = resource.processStrategy(data);
  return {
    ...res,
    date: moment(res.datetime),
    type: res.type || 'reminders',
  };
}

// Schemas
const reminderSchema = new schema.Entity('reminders', {}, { processStrategy });
const remindersSchema = [reminderSchema];

// Methods
function fetchActiveReminders() {
  return api.get(`${url}/active`, {
    schema: remindersSchema,
  });
}

export default {
  requestInterceptor,
  processStrategy,
  reminderSchema,
  remindersSchema,
  fetchActiveReminders,
};
