import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector, change, Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { map } from 'lodash';

import FieldTrimmed from 'components/FieldTrimmed';
import FormTextField from 'components/FormTextField';
import TypographyCapitalize from 'components/TypographyCapitalize';
import validation from 'services/validationService';
import { DISPATCH_SIGN_UP } from 'actions/actionTypes';
import { selectLoading } from 'reducers/selectors';
import accountService from 'services/accountService';

const styles = theme => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit * 4,
    width: 160,
  },
  menuItem: {
    fontSize: 16,
  },
});

class Signup extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
    selectedCompany: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatchSignUp: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    selectedCompany: '',
  };

  componentDidMount() {
    // If user tries to go to Signup page, redirect to Dough 3.
    window.location.replace('https://www.doughapp.com');
  }

  componentDidUpdate(prevProps) {
    const { selectedCompany, dispatch } = this.props;
    if (selectedCompany !== prevProps.selectedCompany) {
      // When selected company is changed, reset selected careerTitle, freeformCompany, freeformCareerTitle values.
      dispatch(change('Signup', 'careerTitle', null));
      dispatch(change('Signup', 'freeformCompany', null));
      dispatch(change('Signup', 'freeformCareerTitle', null));
    }
  }

  render() {
    const { classes, handleSubmit, dispatchSignUp, pristine, submitting, loading, selectedCompany } = this.props;

    const companies = accountService.getCompanies();
    const careerTitles = accountService.getCareerTitles(selectedCompany);

    return (
      <form onSubmit={handleSubmit(values => dispatchSignUp(values))}>
        <Typography variant="h5" gutterBottom>
          Start your free trial
        </Typography>
        <FieldTrimmed
          component={FormTextField}
          name="firstName"
          label="First name"
          margin="normal"
          fullWidth
          autoFocus
        />
        <FieldTrimmed component={FormTextField} name="lastName" label="Last name" margin="normal" fullWidth />
        <Field component={FormTextField} name="company" label="Company" margin="normal" fullWidth select>
          {map(companies, value => (
            <MenuItem key={value} value={value}>
              <Typography className={classes.menuItem}>{accountService.getCompanyLabel(value)}</Typography>
            </MenuItem>
          ))}
        </Field>
        {/* Display careerTitle field only if selected company is NOT 'OTHER'. */}
        {selectedCompany && selectedCompany !== 'OTHER' && (
          <Field component={FormTextField} name="careerTitle" label="Rank" margin="normal" fullWidth select>
            {map(careerTitles, value => (
              <MenuItem key={value} value={value}>
                <TypographyCapitalize className={classes.menuItem}>
                  {value.replace(/^[0-9]{2}_/g, '')}
                </TypographyCapitalize>
              </MenuItem>
            ))}
          </Field>
        )}
        {/* Display freeformCompany and freeformCareerTitle fields only if selected company is 'OTHER'. */}
        {selectedCompany && selectedCompany === 'OTHER' && (
          <React.Fragment>
            <Field component={FormTextField} name="freeformCompany" label="Which company?" margin="normal" fullWidth />
            <Field component={FormTextField} name="freeformCareerTitle" label="Rank" margin="normal" fullWidth />
          </React.Fragment>
        )}
        <FieldTrimmed component={FormTextField} name="phone" label="Phone" margin="normal" fullWidth />
        <FieldTrimmed component={FormTextField} name="email" label="Email" margin="normal" fullWidth />
        <FieldTrimmed
          component={FormTextField}
          name="emailConfirmation"
          label="Confirm email"
          margin="normal"
          fullWidth
        />
        <FieldTrimmed
          component={FormTextField}
          type="password"
          name="password"
          label="Password (at least 8 characters)"
          margin="normal"
          fullWidth
        />
        <FieldTrimmed
          component={FormTextField}
          type="password"
          name="passwordConfirmation"
          label="Confirm password"
          margin="normal"
          fullWidth
        />
        {/* Display accessCode field for all companies except Plexus, Monat, S&C. */}
        {selectedCompany !== 'PLEXUS' && selectedCompany !== 'MONAT' && selectedCompany !== 'SCOUT_CELLAR' && (
          <FieldTrimmed component={FormTextField} name="accessCode" label="Access code" margin="normal" fullWidth />
        )}
        {/** Display promoCode field for S&C only. */}
        {selectedCompany === 'SCOUT_CELLAR' && (
          <FieldTrimmed
            component={FormTextField}
            name="accessCode"
            label="Promo code (optional)"
            margin="normal"
            fullWidth
          />
        )}
        <div className={classes.actions}>
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={pristine || submitting || loading}
          >
            Create Account
          </Button>
          <Typography variant="caption" align="center">
            {'By clicking "Create Account", you accept our '}
            <a href="https://www.doughcrm.com/terms.html" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
            {' and '}
            <a href="https://www.doughcrm.com/privacy.html" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            {'.'}
          </Typography>
        </div>
      </form>
    );
  }
}

const makeValueSelector = valueName => state => formValueSelector('Signup')(state, valueName);
const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    selectedCompany: makeValueSelector('company'),
  }),
  {
    dispatchSignUp: data => ({ type: DISPATCH_SIGN_UP, data }),
  },
);

const withForm = reduxForm({
  form: 'Signup',
  validate: validation.createValidator({
    firstName: validation.required,
    lastName: validation.required,
    company: validation.required,
    careerTitle: validation.required,
    freeformCompany: validation.required,
    freeformCareerTitle: validation.required,
    phone: validation.required,
    email: [validation.required, validation.email],
    emailConfirmation: [validation.required, validation.emailConfirmation],
    password: [validation.required, validation.password],
    passwordConfirmation: [validation.required, validation.passwordConfirmation],
    accessCode: validation.accessCode,
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(Signup);
