import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconStar from 'assets/IconStar';

const styles = () => ({
  sparkles: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-4px 0 0 -4px',
  },
  sparkle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '8px',
    height: '8px',
    opacity: '0',
    background: '#FFD700',
  },
  sparkle1: {
    '&.pressed': {
      animation: 'animate1 .55s ease-in',
    },
  },
  sparkle2: {
    '&.pressed': {
      animation: 'animate2 .55s .1s ease-in',
    },
  },
  sparkle3: {
    '&.pressed': {
      animation: 'animate3 .55s ease-in',
    },
  },
  sparkle4: {
    '&.pressed': {
      animation: 'animate4 .55s .1s ease-in',
    },
  },
  sparkle5: {
    '&.pressed': {
      animation: 'animate5 .55s ease-in',
    },
  },
  sparkle6: {
    '&.pressed': {
      animation: 'animate6 .55s .1s ease-in',
    },
  },

  '@keyframes animate1': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(-30deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(-30deg) translateY(-500%) translateX(-5px) scale(1)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(-30deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
  '@keyframes animate2': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(30deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(30deg) translateY(-500%) translateX(-5px) scale(0.7)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(30deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
  '@keyframes animate3': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(60deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(60deg) translateY(-500%) translateX(-5px) scale(1)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(60deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
  '@keyframes animate4': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(270deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(270deg) translateY(-500%) translateX(-5px) scale(0.7)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(270deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
  '@keyframes animate5': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(310deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(310deg) translateY(-500%) translateX(-5px) scale(1)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(310deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
  '@keyframes animate6': {
    '0%': {
      opacity: '0',
      background: '#FFD700',
      transform: 'rotate(80deg) translateY(-250%) translateX(-5px) scale(0)',
    },
    '50%': {
      opacity: '1',
      background: '#fad201',
      transform: 'rotate(80deg) translateY(-500%) translateX(-5px) scale(0.7)',
    },
    '100%': {
      opacity: '0',
      background: '#ffdc33',
      transform: 'rotate(80deg) translateY(-750%) translateX(-5px) scale(0)',
    },
  },
});

class ChipStar extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    isAnimating: PropTypes.bool,
  };

  static defaultProps = {
    isAnimating: false,
  };

  render() {
    const { classes, isAnimating } = this.props;
    return (
      <span className={classes.sparkles}>
        <IconStar className={`${classes.sparkle} ${classes.sparkle1} ${isAnimating ? 'pressed' : ''}`} />
        <IconStar className={`${classes.sparkle} ${classes.sparkle2} ${isAnimating ? 'pressed' : ''}`} />
        <IconStar className={`${classes.sparkle} ${classes.sparkle3} ${isAnimating ? 'pressed' : ''}`} />
        <IconStar className={`${classes.sparkle} ${classes.sparkle4} ${isAnimating ? 'pressed' : ''}`} />
        <IconStar className={`${classes.sparkle} ${classes.sparkle5} ${isAnimating ? 'pressed' : ''}`} />
        <IconStar className={`${classes.sparkle} ${classes.sparkle6} ${isAnimating ? 'pressed' : ''}`} />
      </span>
    );
  }
}

export default withStyles(styles)(ChipStar);
