import { all, call } from 'redux-saga/effects';
import { map, forEach, filter, concat, isUndefined } from 'lodash';

import contactsResource from 'resources/contactsResource';
import moment from 'moment';
import sagaSaveContact from './sagaSaveContact';
import sagaSaveReminder from './sagaSaveReminder';
import sagaSaveActivity from './sagaSaveActivity';
import sagaRefresh from './sagaRefresh';

export default function* sagaPostQuickLog(action) {
  const {
    contacts,
    chips: { popToday, frequency, contactType, contactStatus, reminder, reachOut, isFrequencyAutoAssigned },
  } = action;

  // create contacts
  let newContacts = filter(contacts, contact => !contact.id);
  const newContactsResponses = yield all(
    map(newContacts, contact =>
      call(sagaSaveContact, {
        contact: {
          ...contact,
          frequency,
          contactType,
          contactStatus,
        },
        skipRefresh: true,
      })),
  );
  newContacts = map(newContactsResponses, res => contactsResource.processStrategy(res.data));

  // promises to save existing contacts
  const promises = [];
  const existingContacts = filter(contacts, 'id');
  forEach(existingContacts, contact => {
    promises.push(
      call(sagaSaveContact, {
        contact: {
          ...contact,
          frequency: frequency !== undefined ? frequency : contact.frequency,
          contactType: contactType || contact.contactType,
          contactStatus: contactStatus || contact.contactStatus,
          isFrequencyAutoAssigned: isUndefined(isFrequencyAutoAssigned)
            ? contact.isFrequencyAutoAssigned
            : isFrequencyAutoAssigned,
        },
        skipRefresh: true,
      }),
    );
  });

  // promises to add reminders/activities to all contacts
  const allContacts = concat(newContacts, existingContacts);
  forEach(allContacts, contact => {
    // post reminder
    if (reminder) {
      promises.push(
        call(sagaSaveReminder, {
          contact,
          reminder,
          skipRefresh: true,
        }),
      );
    }
    // post today reminder
    if (popToday) {
      promises.push(
        call(sagaSaveReminder, {
          contact,
          reminder: {
            date: moment(),
            notes: 'Popped to today',
          },
          skipRefresh: true,
        }),
      );
    }
    // post activity REACH_OUT
    if (reachOut) {
      promises.push(
        call(sagaSaveActivity, {
          contact,
          activity: {
            ...reachOut,
            activityType: 'REACH_OUT',
          },
          skipRefresh: true,
        }),
      );
    }
  });

  // execute promises
  yield all(promises);

  // refresh
  yield call(sagaRefresh);
}
