import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';

import reducers from 'reducers';
import sagas from 'sagas';

// add middlewares
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(reducers, composeWithDevTools(...enhancers));

// run sagas
sagaMiddleware.run(sagas);

export default store;
