import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import EmptyState from 'components/EmptyState';
import Notes from 'components/Notes';

class ContactNotes extends Component {
  static propTypes = {
    contact: PropTypes.shape({}).isRequired,
  };

  state = { ignoreEmptyState: false };

  render() {
    const { contact, ...other } = this.props;
    const { ignoreEmptyState } = this.state;
    if (!ignoreEmptyState && isEmpty(contact.notes)) {
      return (
        <EmptyState style={{ cursor: 'pointer' }} onClick={() => this.setState({ ignoreEmptyState: true })}>
          {`Click here to add notes for ${contact.name}.`}
          <br />
          Your notes will save automatically!
        </EmptyState>
      );
    }

    return <Notes value={contact.notes || ''} autoFocus {...other} />;
  }
}

export default ContactNotes;
