import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import {
  StripeProvider,
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import MenuItem from '@material-ui/core/MenuItem';
import { map } from 'lodash';

import FormTextField from 'components/FormTextField';
import StripeElementWrapper from 'components/StripeElementWrapper';
import configService from 'services/configService';
import validation from 'services/validationService';

const STATE_VALUES = [
  { key: 'AL', value: 'Alabama' },
  { key: 'AK', value: 'Alaska' },
  { key: 'AZ', value: 'Arizona' },
  { key: 'AR', value: 'Arkansas' },
  { key: 'AA', value: 'Armed Forces (AA)' },
  { key: 'AE', value: 'Armed Forces (AE)' },
  { key: 'AP', value: 'Armed Forces (AP)' },
  { key: 'CA', value: 'California' },
  { key: 'CO', value: 'Colorado' },
  { key: 'CT', value: 'Connecticut' },
  { key: 'DE', value: 'Delaware' },
  { key: 'FL', value: 'Florida' },
  { key: 'GA', value: 'Georgia' },
  { key: 'HI', value: 'Hawaii' },
  { key: 'ID', value: 'Idaho' },
  { key: 'IL', value: 'Illinois' },
  { key: 'IN', value: 'Indiana' },
  { key: 'IA', value: 'Iowa' },
  { key: 'KS', value: 'Kansas' },
  { key: 'KY', value: 'Kentucky' },
  { key: 'LA', value: 'Louisiana' },
  { key: 'ME', value: 'Maine' },
  { key: 'MD', value: 'Maryland' },
  { key: 'MA', value: 'Massachusetts' },
  { key: 'MI', value: 'Michigan' },
  { key: 'MN', value: 'Minnesota' },
  { key: 'MS', value: 'Mississippi' },
  { key: 'MO', value: 'Missouri' },
  { key: 'MT', value: 'Montana' },
  { key: 'NE', value: 'Nebraska' },
  { key: 'NV', value: 'Nevada' },
  { key: 'NH', value: 'New Hampshire' },
  { key: 'NJ', value: 'New Jersey' },
  { key: 'NM', value: 'New Mexico' },
  { key: 'NY', value: 'New York' },
  { key: 'NC', value: 'North Carolina' },
  { key: 'ND', value: 'North Dakota' },
  { key: 'OH', value: 'Ohio' },
  { key: 'OK', value: 'Oklahoma' },
  { key: 'OR', value: 'Oregon' },
  { key: 'PA', value: 'Pennsylvania' },
  { key: 'RI', value: 'Rhode Island' },
  { key: 'SC', value: 'South Carolina' },
  { key: 'SD', value: 'South Dakota' },
  { key: 'TN', value: 'Tennessee' },
  { key: 'TX', value: 'Texas' },
  { key: 'UT', value: 'Utah' },
  { key: 'VT', value: 'Vermont' },
  { key: 'VA', value: 'Virginia' },
  { key: 'WA', value: 'Washington' },
  { key: 'WV', value: 'West Virginia' },
  { key: 'WI', value: 'Wisconsin' },
  { key: 'WY', value: 'Wyoming' },
  { key: 'AB', value: 'Alberta' },
  { key: 'BC', value: 'British Columbia' },
  { key: 'MB', value: 'Manitoba' },
  { key: 'NB', value: 'New Brunswick' },
  { key: 'NL', value: 'Newfoundland and Labrador' },
  { key: 'NS', value: 'Nova Scotia' },
  { key: 'ON', value: 'Ontario' },
  { key: 'PE', value: 'Prince Edward Island' },
  { key: 'QC', value: 'Quebec' },
  { key: 'SK', value: 'Saskatchewan' },
  { key: 'NT', value: 'Northwest Territories' },
  { key: 'NU', value: 'Nunavut' },
  { key: 'YT', value: 'Yukon' },
  { key: 'None', value: 'None' },
];

const COUNTRY_VALUES = [
  { key: 'US', value: 'United States' },
  { key: 'CA', value: 'Canada' },
  { key: 'AU', value: 'Australia' },
  { key: 'NZ', value: 'New Zealand' },
  { key: 'AF', value: 'Afghanistan' },
  { key: 'AL', value: 'Albania' },
  { key: 'DZ', value: 'Algeria' },
  { key: 'AD', value: 'Andorra' },
  { key: 'AO', value: 'Angola' },
  { key: 'AQ', value: 'Antarctica' },
  { key: 'AG', value: 'Antigua and Barbuda' },
  { key: 'AR', value: 'Argentina' },
  { key: 'AM', value: 'Armenia' },
  { key: 'AT', value: 'Austria' },
  { key: 'AZ', value: 'Azerbaijan' },
  { key: 'BH', value: 'Bahrain' },
  { key: 'BS', value: 'Bahamas' },
  { key: 'BD', value: 'Bangladesh' },
  { key: 'BB', value: 'Barbados' },
  { key: 'BY', value: 'Belarus' },
  { key: 'BE', value: 'Belgium' },
  { key: 'BZ', value: 'Belize' },
  { key: 'BJ', value: 'Benin' },
  { key: 'BM', value: 'Bermuda' },
  { key: 'BT', value: 'Bhutan' },
  { key: 'BO', value: 'Bolivia, Plurinational State of' },
  { key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba' },
  { key: 'BA', value: 'Bosnia and Herzegovina' },
  { key: 'BW', value: 'Botswana' },
  { key: 'BV', value: 'Bouvet Island' },
  { key: 'BR', value: 'Brazil' },
  { key: 'IO', value: 'British Indian Ocean Territory' },
  { key: 'BN', value: 'Brunei Darussalam' },
  { key: 'BG', value: 'Bulgaria' },
  { key: 'BF', value: 'Burkina Faso' },
  { key: 'BI', value: 'Burundi' },
  { key: 'KH', value: 'Cambodia' },
  { key: 'CM', value: 'Cameroon' },
  { key: 'CV', value: 'Cape Verde' },
  { key: 'KY', value: 'Cayman Islands' },
  { key: 'CF', value: 'Central African Republic' },
  { key: 'TD', value: 'Chad' },
  { key: 'CL', value: 'Chile' },
  { key: 'CN', value: 'China' },
  { key: 'CX', value: 'Christmas Island' },
  { key: 'CC', value: 'Cocos (Keeling) Islands' },
  { key: 'CO', value: 'Colombia' },
  { key: 'KM', value: 'Comoros' },
  { key: 'CG', value: 'Congo' },
  { key: 'CD', value: 'Congo, the Democratic Republic of the' },
  { key: 'CK', value: 'Cook Islands' },
  { key: 'CR', value: 'Costa Rica' },
  { key: 'CI', value: "Côte d'Ivoire" },
  { key: 'HR', value: 'Croatia' },
  { key: 'CU', value: 'Cuba' },
  { key: 'CW', value: 'Curaçao' },
  { key: 'CY', value: 'Cyprus' },
  { key: 'CZ', value: 'Czech Republic' },
  { key: 'DK', value: 'Denmark' },
  { key: 'DJ', value: 'Djibouti' },
  { key: 'DM', value: 'Dominica' },
  { key: 'DO', value: 'Dominican Republic' },
  { key: 'EC', value: 'Ecuador' },
  { key: 'EG', value: 'Egypt' },
  { key: 'SV', value: 'El Salvador' },
  { key: 'GQ', value: 'Equatorial Guinea' },
  { key: 'ER', value: 'Eritrea' },
  { key: 'EE', value: 'Estonia' },
  { key: 'ET', value: 'Ethiopia' },
  { key: 'FK', value: 'Falkland Islands (Malvinas)' },
  { key: 'FO', value: 'Faroe Islands' },
  { key: 'FJ', value: 'Fiji' },
  { key: 'FI', value: 'Finland' },
  { key: 'FR', value: 'France' },
  { key: 'GF', value: 'French Guiana' },
  { key: 'PF', value: 'French Polynesia' },
  { key: 'TF', value: 'French Southern Territories' },
  { key: 'GA', value: 'Gabon' },
  { key: 'GM', value: 'Gambia' },
  { key: 'GE', value: 'Georgia' },
  { key: 'DE', value: 'Germany' },
  { key: 'GH', value: 'Ghana' },
  { key: 'GI', value: 'Gibraltar' },
  { key: 'GR', value: 'Greece' },
  { key: 'GL', value: 'Greenland' },
  { key: 'GD', value: 'Grenada' },
  { key: 'GP', value: 'Guadeloupe' },
  { key: 'GU', value: 'Guam' },
  { key: 'GT', value: 'Guatemala' },
  { key: 'GG', value: 'Guernsey' },
  { key: 'GN', value: 'Guinea' },
  { key: 'GW', value: 'Guinea-Bissau' },
  { key: 'GY', value: 'Guyana' },
  { key: 'HT', value: 'Haiti' },
  { key: 'HM', value: 'Heard Island and McDonald Islands' },
  { key: 'VA', value: 'Holy See (Vatican City State)' },
  { key: 'HN', value: 'Honduras' },
  { key: 'HK', value: 'Hong Kong' },
  { key: 'HU', value: 'Hungary' },
  { key: 'IS', value: 'Iceland' },
  { key: 'IN', value: 'India' },
  { key: 'ID', value: 'Indonesia' },
  { key: 'IR', value: 'Iran, Islamic Republic of' },
  { key: 'IQ', value: 'Iraq' },
  { key: 'IE', value: 'Ireland' },
  { key: 'IM', value: 'Isle of Man' },
  { key: 'IL', value: 'Israel' },
  { key: 'IT', value: 'Italy' },
  { key: 'JM', value: 'Jamaica' },
  { key: 'JP', value: 'Japan' },
  { key: 'JE', value: 'Jersey' },
  { key: 'JO', value: 'Jordan' },
  { key: 'KZ', value: 'Kazakhstan' },
  { key: 'KE', value: 'Kenya' },
  { key: 'KI', value: 'Kiribati' },
  { key: 'KP', value: "Korea, Democratic People's Republic of" },
  { key: 'KR', value: 'Korea, Republic of' },
  { key: 'KW', value: 'Kuwait' },
  { key: 'KG', value: 'Kyrgyzstan' },
  { key: 'LA', value: "Lao People's Democratic Republic" },
  { key: 'LV', value: 'Latvia' },
  { key: 'LB', value: 'Lebanon' },
  { key: 'LS', value: 'Lesotho' },
  { key: 'LR', value: 'Liberia' },
  { key: 'LY', value: 'Libya' },
  { key: 'LI', value: 'Liechtenstein' },
  { key: 'LT', value: 'Lithuania' },
  { key: 'LU', value: 'Luxembourg' },
  { key: 'MO', value: 'Macao' },
  { key: 'MK', value: 'Macedonia, the Former Yugoslav Republic of' },
  { key: 'MG', value: 'Madagascar' },
  { key: 'MW', value: 'Malawi' },
  { key: 'MY', value: 'Malaysia' },
  { key: 'MV', value: 'Maldives' },
  { key: 'ML', value: 'Mali' },
  { key: 'MT', value: 'Malta' },
  { key: 'MH', value: 'Marshall Islands' },
  { key: 'MQ', value: 'Martinique' },
  { key: 'MR', value: 'Mauritania' },
  { key: 'MU', value: 'Mauritius' },
  { key: 'YT', value: 'Mayotte' },
  { key: 'MX', value: 'Mexico' },
  { key: 'FM', value: 'Micronesia, Federated States of' },
  { key: 'MD', value: 'Moldova, Republic of' },
  { key: 'MC', value: 'Monaco' },
  { key: 'MN', value: 'Mongolia' },
  { key: 'ME', value: 'Montenegro' },
  { key: 'MS', value: 'Montserrat' },
  { key: 'MA', value: 'Morocco' },
  { key: 'MZ', value: 'Mozambique' },
  { key: 'MM', value: 'Myanmar' },
  { key: 'NA', value: 'Namibia' },
  { key: 'NR', value: 'Nauru' },
  { key: 'NP', value: 'Nepal' },
  { key: 'NL', value: 'Netherlands' },
  { key: 'NC', value: 'New Caledonia' },
  { key: 'NI', value: 'Nicaragua' },
  { key: 'NE', value: 'Niger' },
  { key: 'NG', value: 'Nigeria' },
  { key: 'NU', value: 'Niue' },
  { key: 'NF', value: 'Norfolk Island' },
  { key: 'MP', value: 'Northern Mariana Islands' },
  { key: 'NO', value: 'Norway' },
  { key: 'OM', value: 'Oman' },
  { key: 'PK', value: 'Pakistan' },
  { key: 'PW', value: 'Palau' },
  { key: 'PS', value: 'Palestine, State of' },
  { key: 'PA', value: 'Panama' },
  { key: 'PG', value: 'Papua New Guinea' },
  { key: 'PY', value: 'Paraguay' },
  { key: 'PE', value: 'Peru' },
  { key: 'PH', value: 'Philippines' },
  { key: 'PN', value: 'Pitcairn' },
  { key: 'PL', value: 'Poland' },
  { key: 'PT', value: 'Portugal' },
  { key: 'PR', value: 'Puerto Rico' },
  { key: 'QA', value: 'Qatar' },
  { key: 'RE', value: 'Réunion' },
  { key: 'RO', value: 'Romania' },
  { key: 'RU', value: 'Russian Federation' },
  { key: 'RW', value: 'Rwanda' },
  { key: 'BL', value: 'Saint Barthélemy' },
  { key: 'SH', value: 'Saint Helena, Ascension and Tristan da Cunha' },
  { key: 'KN', value: 'Saint Kitts and Nevis' },
  { key: 'LC', value: 'Saint Lucia' },
  { key: 'MF', value: 'Saint Martin (French part)' },
  { key: 'PM', value: 'Saint Pierre and Miquelon' },
  { key: 'VC', value: 'Saint Vincent and the Grenadines' },
  { key: 'WS', value: 'Samoa' },
  { key: 'SM', value: 'San Marino' },
  { key: 'ST', value: 'Sao Tome and Principe' },
  { key: 'SA', value: 'Saudi Arabia' },
  { key: 'SN', value: 'Senegal' },
  { key: 'RS', value: 'Serbia' },
  { key: 'SC', value: 'Seychelles' },
  { key: 'SL', value: 'Sierra Leone' },
  { key: 'SG', value: 'Singapore' },
  { key: 'SX', value: 'Sint Maarten (Dutch part)' },
  { key: 'SK', value: 'Slovakia' },
  { key: 'SI', value: 'Slovenia' },
  { key: 'SB', value: 'Solomon Islands' },
  { key: 'SO', value: 'Somalia' },
  { key: 'ZA', value: 'South Africa' },
  { key: 'GS', value: 'South Georgia and the South Sandwich Islands' },
  { key: 'SS', value: 'South Sudan' },
  { key: 'ES', value: 'Spain' },
  { key: 'LK', value: 'Sri Lanka' },
  { key: 'SD', value: 'Sudan' },
  { key: 'SR', value: 'Suriname' },
  { key: 'SJ', value: 'Svalbard and Jan Mayen' },
  { key: 'SZ', value: 'Swaziland' },
  { key: 'SE', value: 'Sweden' },
  { key: 'CH', value: 'Switzerland' },
  { key: 'SY', value: 'Syrian Arab Republic' },
  { key: 'TW', value: 'Taiwan, Province of China' },
  { key: 'TJ', value: 'Tajikistan' },
  { key: 'TZ', value: 'Tanzania, United Republic of' },
  { key: 'TH', value: 'Thailand' },
  { key: 'TL', value: 'Timor-Leste' },
  { key: 'TG', value: 'Togo' },
  { key: 'TK', value: 'Tokelau' },
  { key: 'TO', value: 'Tonga' },
  { key: 'TT', value: 'Trinidad and Tobago' },
  { key: 'TN', value: 'Tunisia' },
  { key: 'TR', value: 'Turkey' },
  { key: 'TM', value: 'Turkmenistan' },
  { key: 'TC', value: 'Turks and Caicos Islands' },
  { key: 'TV', value: 'Tuvalu' },
  { key: 'UG', value: 'Uganda' },
  { key: 'UA', value: 'Ukraine' },
  { key: 'AE', value: 'United Arab Emirates' },
  { key: 'GB', value: 'United Kingdom' },
  { key: 'UM', value: 'United States Minor Outlying Islands' },
  { key: 'UY', value: 'Uruguay' },
  { key: 'UZ', value: 'Uzbekistan' },
  { key: 'VU', value: 'Vanuatu' },
  { key: 'VE', value: 'Venezuela, Bolivarian Republic of' },
  { key: 'VN', value: 'Vietnam' },
  { key: 'VG', value: 'Virgin Islands, British' },
  { key: 'VI', value: 'Virgin Islands, U.S.' },
  { key: 'WF', value: 'Wallis and Futuna' },
  { key: 'EH', value: 'Western Sahara' },
  { key: 'YE', value: 'Yemen' },
  { key: 'ZM', value: 'Zambia' },
  { key: 'ZW', value: 'Zimbabwe' },
];

class _Form extends Component {
  static propTypes = {
    stripe: PropTypes.shape({}).isRequired,
    formRef: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formRef: undefined,
  };

  handleFormSubmit = values => {
    const { stripe, onSubmit } = this.props;
    stripe.createToken(values).then(({ token }) => {
      if (token) {
        onSubmit(token);
      }
    });
  };

  render() {
    const { formRef, handleSubmit } = this.props;
    return (
      <form ref={formRef} onSubmit={handleSubmit(values => this.handleFormSubmit(values))}>
        <Field
          component={FormTextField}
          name="name"
          label="Cardholder Name"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <StripeElementWrapper
          label="Credit Card Number"
          variant="outlined"
          margin="normal"
          component={CardNumberElement}
        />
        <StripeElementWrapper
          label="Expiration Date (MM/YY)"
          variant="outlined"
          margin="normal"
          component={CardExpiryElement}
        />
        <StripeElementWrapper label="CVC" variant="outlined" margin="normal" component={CardCVCElement} />
        <Field
          component={FormTextField}
          name="address_state"
          label="State/Province"
          variant="outlined"
          margin="normal"
          fullWidth
          select
        >
          {map(STATE_VALUES, entry => (
            <MenuItem key={entry.key} value={entry.key}>
              {entry.value}
            </MenuItem>
          ))}
        </Field>
        <Field
          component={FormTextField}
          name="address_zip"
          label="Zip Code"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <Field
          component={FormTextField}
          name="address_country"
          label="Country"
          variant="outlined"
          margin="normal"
          fullWidth
          select
        >
          {map(COUNTRY_VALUES, entry => (
            <MenuItem key={entry.key} value={entry.key}>
              {entry.value}
            </MenuItem>
          ))}
        </Field>
      </form>
    );
  }
}

const withForm = reduxForm({
  form: 'FormStripe',
  validate: validation.createValidator({
    name: validation.required,
    address_state: validation.required,
    address_zip: validation.required,
    address_country: validation.required,
  }),
  touchOnBlur: false,
  // Force Stripe validation
  onSubmitFail: (errors, dispatch, submitError, { stripe }) => stripe.createToken(),
});

const Form = compose(
  injectStripe,
  withForm,
)(_Form);

const apiKey = configService.getStripeApiKey();

// eslint-disable-next-line react/no-multi-comp
class FormStripe extends Component {
  render() {
    return (
      <StripeProvider apiKey={apiKey}>
        <Elements>
          <Form {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default FormStripe;
