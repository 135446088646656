import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, Field } from 'redux-form';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { map } from 'lodash';

import stringsService from 'services/stringsService';
import FormTextField from 'components/FormTextField';
import { DISPATCH_SAVE_ACCOUNT, DISPATCH_FETCH_WORK_LIST } from 'actions/actionTypes';
import { selectCurrentUser } from 'reducers/selectors';

const options = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
const filterOptions = [
  { key: 'ALL', value: 'Show All' },
  { key: 'EXCLUDE_INSTAGRAM', value: 'Exclude Instagram' },
  { key: 'ONLY_INSTAGRAM', value: 'Only Instagram' },
];
const sortingOptions = [{ key: 'DESC', value: 'Newest to Oldest' }, { key: 'ASC', value: 'Oldest to Newest' }];
const activityPriorityOptions = [
  { key: 'HAS_ACTIVITY', value: 'Logged Activity' },
  { key: 'HAS_NO_ACTIVITY', value: 'No Activity (New in Dough)' },
];

const styles = theme => ({
  input: {
    textAlign: 'right',
  },
  inputAdornment: {
    width: '100%',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  coldText: {
    display: 'contents',
    color: '#1565C0',
  },
  warmText: {
    display: 'contents',
    color: '#FF9000',
  },
  hotText: {
    display: 'contents',
    color: '#F13A00',
  },
  title: {
    paddingTop: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing.unit * 1.5,
    },
  },
});

class DialogSettingsIPAs extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchSaveAccount: PropTypes.func.isRequired,
    dispatchFetchWorkList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { initialize, currentUser } = this.props;
    initialize(currentUser);
  }

  componentDidUpdate(prevProps) {
    const { open, initialize, currentUser } = this.props;
    if (open && !prevProps.open) {
      initialize(currentUser);
    }
  }

  onSaveAccountSuccess = () => {
    // Force fetching the work list in case work list settings were changed.
    const { onClose, dispatchFetchWorkList } = this.props;
    onClose();
    dispatchFetchWorkList();
  };

  render() {
    const {
      classes,
      fullScreen,
      dispatchSaveAccount,
      handleSubmit,
      pristine,
      submitting,
      open,
      onClose,
      currentUser,
    } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle className={classes.title}>IPA Settings</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(values => dispatchSaveAccount(values, this.onSaveAccountSuccess))}>
            <Field
              component={FormTextField}
              name="workListSettings.coldProspects"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <span className={classes.coldText}>Cold</span> Prospects
                  </InputAdornment>
                ),
              }}
            >
              {map(options, option => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListSettings.warmProspects"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <span className={classes.warmText}>Warm</span> Prospects
                  </InputAdornment>
                ),
              }}
            >
              {map(options, option => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListSettings.hotProspects"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <span className={classes.hotText}>Hot</span> Prospects
                  </InputAdornment>
                ),
              }}
            >
              {map(options, option => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListSettings.customers"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    {`All ${stringsService.getCustomersLabel(currentUser.company)}`}
                  </InputAdornment>
                ),
              }}
            >
              {map(options, option => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListSettings.consultants"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    {`All ${stringsService.getConsultantsLabel(currentUser.company)}`}
                  </InputAdornment>
                ),
              }}
            >
              {map(options, option => (
                <MenuItem key={option} value={option}>
                  {`${option}`}
                </MenuItem>
              ))}
            </Field>
            <Divider />
            <Field
              component={FormTextField}
              name="workListActivityPriority"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    Prioritize Contacts With
                  </InputAdornment>
                ),
              }}
            >
              {map(activityPriorityOptions, option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListNoActivitySorting"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    Sort New Contacts by
                  </InputAdornment>
                ),
              }}
            >
              {map(sortingOptions, option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={FormTextField}
              name="workListProspectFilter"
              margin="normal"
              fullWidth
              select
              InputProps={{
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    Filter <span className={classes.coldText}>Cold</span> Prospects
                  </InputAdornment>
                ),
              }}
            >
              {map(filterOptions, option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Field>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
            onClick={handleSubmit(values => dispatchSaveAccount(values, this.onSaveAccountSuccess))}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
  {
    dispatchSaveAccount: (account, callbackSuccess) => ({ type: DISPATCH_SAVE_ACCOUNT, account, callbackSuccess }),
    dispatchFetchWorkList: () => ({ type: DISPATCH_FETCH_WORK_LIST }),
  },
);

const withForm = reduxForm({
  form: 'DialogSettingsIPAs',
});

export default compose(
  withConnect,
  withForm,
  withMobileDialog(),
  withStyles(styles),
)(DialogSettingsIPAs);
