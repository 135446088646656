import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MuiChip from '@material-ui/core/Chip';

const styles = theme => ({
  chip: {
    cursor: 'pointer',
    '&:active': {
      boxShadow: theme.shadows[1],
    },
  },
  selected: {
    backgroundColor: `${fade(theme.palette.primary.main, 0.2)} !important`,
  },
  label: {
    width: 117,
    display: 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class Chip extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    icon: PropTypes.node.isRequired,
    skipIcon: PropTypes.bool,
    skipSelected: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    skipIcon: false,
    skipSelected: false,
  };

  render() {
    const { classes, className, selected, skipSelected, skipIcon, icon, ...other } = this.props;

    return (
      <MuiChip
        classes={{
          label: classes.label,
        }}
        className={classnames(className, classes.chip, { [classes.selected]: !skipSelected && selected })}
        clickable={false}
        icon={skipIcon ? null : icon}
        {...other}
      />
    );
  }
}

export default withStyles(styles)(Chip);
