import { call } from 'redux-saga/effects';

import { FETCH_CONTACT_CARD } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchContactCard = sagaFetch.bind(null, FETCH_CONTACT_CARD, contactsResource.fetchContactCard);

export default function* sagaFetchContactCardValues({ contact }) {
  yield call(fetchContactCard, { contact });
}
