import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  backdrop: {
    background: '#000',
    bottom: '100%',
    left: 0,
    opacity: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transition: 'opacity .3s, bottom 0s .3s',
    zIndex: 1200,
  },
  backdropActive: {
    bottom: '0%',
    opacity: 0.6,
    transition: 'opacity .3s',
  },
});

class Backdrop extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    active: PropTypes.bool.isRequired,
    handleFocusOut: PropTypes.func,
  };

  static defaultProps = {
    handleFocusOut: undefined,
  };

  render() {
    const { classes, active, handleFocusOut } = this.props;
    return (
      <div
        role="presentation"
        onClick={handleFocusOut}
        className={classnames(classes.backdrop, {
          [classes.backdropActive]: active,
        })}
      />
    );
  }
}

export default compose(withStyles(styles))(Backdrop);
