import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import {
  DISPATCH_SAVE_CONTACT,
  DISPATCH_DELETE_CONTACTS,
  DISPATCH_SAVE_REMINDER,
  DISPATCH_SAVE_ACTIVITY,
} from 'actions/actionTypes';
import ContactCardValues from 'containers/ContactCardValues';
import ContactNotes from 'containers/ContactNotes';
import ContactInfo from 'containers/ContactInfo';
import ChipEditType from 'components/ChipEditType';
import ChipEditFrequency from 'components/ChipEditFrequency';
import ChipSetReminder from 'components/ChipSetReminder';
import ChipLogReachOut from 'components/ChipLogReachOut';
import DialogConfirmDelete from 'components/DialogConfirmDelete';
import ChipEditStatus from 'components/ChipEditStatus';
import SocialMediaShortcuts from 'components/SocialMediaShortcuts';

const styles = theme => ({
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
      maxHeight: '80vh',
      minWidth: 600,
    },
  },
  flex: {
    display: 'flex',
  },
  primaryBackground: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
  },
  noPadding: {
    padding: 0,
  },
  headerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    backgroundColor: fade(theme.palette.primary.main, 0.2),
  },
  cardHeaderRoot: {
    paddingTop: 0,
    paddingLeft: theme.spacing.unit,
  },
  cardHeaderAvatar: {
    marginTop: -25,
    marginRight: 0,
  },
  cardHeaderTitle: {
    fontWeight: 600,
    fontSize: 17,
  },
  chipsLite: {
    marginLeft: -12,
  },
  chipLite: {
    backgroundColor: 'transparent !important',
    '& span': {
      width: 'initial',
    },
  },
  actions: {
    justifyContent: 'center',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing.unit,
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
  contactName: {
    marginRight: theme.spacing.unit * 0.5,
  },
  circle: {
    cursor: 'pointer',
    margin: 12,
  },
  socialMedia: {
    position: 'relative',
    bottom: 2.7,
  },
});

class DialogContact extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    section: PropTypes.string,
    contact: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    onSectionChange: PropTypes.func.isRequired,
    dispatchSaveContact: PropTypes.func.isRequired,
    dispatchDeleteContact: PropTypes.func.isRequired,
    dispatchSaveReminder: PropTypes.func.isRequired,
    dispatchSaveActivity: PropTypes.func.isRequired,
  };

  static defaultProps = {
    section: 'activities',
    contact: {},
  };

  state = {
    openDialogDelete: false,
  };

  handleDelete = () => {
    this.setState({ openDialogDelete: true });
  };

  handleChangeLogReachOut = value => {
    const { dispatchSaveActivity, dispatchSaveReminder, dispatchSaveContact, contact } = this.props;

    const { reminderValue, frequency, contactType, contactStatus } = value;
    dispatchSaveActivity(contact, { ...value, activityType: 'REACH_OUT' });
    if (reminderValue) {
      dispatchSaveReminder(contact, reminderValue);
    }

    const params = {};
    if (frequency !== contact.frequency) {
      params.frequency = frequency;
    }
    if (contactType !== contact.contactType && contactType !== undefined) {
      params.contactType = contactType;
    }
    if (contactStatus !== contact.contactStatus && contactStatus !== undefined) {
      params.contactStatus = contactStatus;
    }
    if (params !== {}) {
      dispatchSaveContact({ ...contact, ...params });
    }
  };

  render() {
    const {
      classes,
      fullScreen,
      contact,
      onClose,
      onSectionChange,
      dispatchSaveContact,
      dispatchDeleteContact,
      dispatchSaveReminder,
      dispatchSaveActivity,
      section,
      ...other
    } = this.props;
    const { openDialogDelete } = this.state;

    return (
      <React.Fragment>
        <Dialog classes={{ paper: classes.dialogPaper }} onClose={onClose} fullScreen={fullScreen} {...other}>
          <DialogTitle className={classes.noPadding}>
            <div className={classes.headerActions}>
              <IconButton onClick={() => onSectionChange('edit')}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={this.handleDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <CardHeader
              classes={{
                root: classnames(classes.cardHeaderRoot, classes.primaryBackground),
                avatar: classes.cardHeaderAvatar,
                title: classes.cardHeaderTitle,
              }}
              avatar={
                <ChipEditStatus
                  short
                  className={classes.circle}
                  value={contact.contactStatus}
                  onChange={contactStatus => dispatchSaveContact({ ...contact, contactStatus })}
                />
              }
              title={
                <div>
                  <span className={classes.contactName}>{contact.name}</span>
                  <SocialMediaShortcuts className={classes.socialMedia} contact={contact} />
                </div>
              }
              subheader={
                <div className={classes.chipsLite}>
                  <ChipEditType
                    className={classes.chipLite}
                    value={contact.contactType}
                    onChange={contactType => dispatchSaveContact({ ...contact, contactType })}
                    skipIcon
                  />
                  <span>·</span>
                  <ChipEditFrequency
                    className={classes.chipLite}
                    value={contact.frequency}
                    onChange={frequency =>
                      dispatchSaveContact({ ...contact, frequency, isFrequencyAutoAssigned: false })
                    }
                  />
                </div>
              }
            />
            <Tabs
              className={classes.primaryBackground}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              value={/activities|notes|info/.test(section) ? section : false}
              onChange={(event, tabValue) => onSectionChange(tabValue)}
            >
              <Tab value="activities" label="Activity" />
              <Tab value="notes" label="Notes" />
              <Tab value="info" label="Info" />
            </Tabs>
          </DialogTitle>
          <Divider />
          <DialogContent
            classes={{
              root: classnames({
                [classes.noPadding]: section !== 'info',
                [classes.flex]: section === 'notes',
              }),
            }}
          >
            {section === 'activities' && <ContactCardValues id={contact.id} contact={contact} />}
            {section === 'notes' && (
              <ContactNotes contact={contact} onChange={notes => dispatchSaveContact({ ...contact, notes }, true)} />
            )}
            {section === 'info' && <ContactInfo contact={contact} />}
          </DialogContent>
          <Divider />
          {section === 'activities' && (
            <DialogActions className={classes.actions}>
              <ChipSetReminder className={classes.chip} onChange={v => v && dispatchSaveReminder(contact, v)} />
              <ChipLogReachOut
                contact={contact}
                hasChips
                className={classes.chip}
                PopoverProps={{ moveOnResize: true }}
                onChange={v => this.handleChangeLogReachOut(v)}
              />
            </DialogActions>
          )}
        </Dialog>
        <DialogConfirmDelete
          title="Delete Contact"
          text="Are you sure you want to delete this contact?"
          open={openDialogDelete}
          onClose={() => this.setState({ openDialogDelete: false })}
          onSubmit={() => {
            this.setState({ openDialogDelete: false });
            dispatchDeleteContact(contact, onClose);
          }}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  null,
  {
    dispatchSaveContact: (contact, skipRefresh) => ({
      type: DISPATCH_SAVE_CONTACT,
      contact,
      skipRefresh,
    }),
    dispatchDeleteContact: (contact, callback) => ({
      type: DISPATCH_DELETE_CONTACTS,
      ids: [contact.id],
      callback,
    }),
    dispatchSaveReminder: (contact, reminder) => ({ type: DISPATCH_SAVE_REMINDER, contact, reminder }),
    dispatchSaveActivity: (contact, activity) => ({ type: DISPATCH_SAVE_ACTIVITY, contact, activity }),
  },
);

export default compose(
  withConnect,
  withMobileDialog(),
  withStyles(styles),
)(DialogContact);
