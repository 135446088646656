import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Paper from '@material-ui/core/Paper';

import Notes from 'components/Notes';
import { DISPATCH_SAVE_ACCOUNT } from 'actions/actionTypes';
import { selectCurrentUser } from 'reducers/selectors';

class HomeNotes extends Component {
  static propTypes = {
    user: PropTypes.shape({}).isRequired,
    dispatchSaveAccount: PropTypes.func.isRequired,
  };

  render() {
    const { user, dispatchSaveAccount } = this.props;
    return (
      <Paper>
        <Notes
          value={user.notes}
          placeholder={'Notes go here!\n\nThink goals, templates, and social media ideas.'}
          rows={17}
          onChange={notes => dispatchSaveAccount({ ...user, notes })}
        />
      </Paper>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    user: selectCurrentUser,
  }),
  {
    dispatchSaveAccount: account => ({ type: DISPATCH_SAVE_ACCOUNT, account }),
  },
);

export default compose(withConnect)(HomeNotes);
