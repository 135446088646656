import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import stringsService from 'services/stringsService';
import { selectStatsActivity, selectCurrentUser } from 'reducers/selectors';

import Sparkles from 'components/Sparkles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 60,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 250,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});

class FooterSummary extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    stats: PropTypes.shape({
      COLD_PROSPECT: PropTypes.number,
      WARM_PROSPECT: PropTypes.number,
      HOT_PROSPECT: PropTypes.number,
      CUSTOMER: PropTypes.number,
      CONSULTANT: PropTypes.number,
    }).isRequired,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    isAnimatingProspect: false,
    isAnimatingCustomer: false,
    isAnimatingConsultant: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.stats !== props.stats) {
      if (state.stats) {
        if (
          state.stats.COLD_PROSPECT !== props.stats.COLD_PROSPECT ||
          state.stats.WARM_PROSPECT !== props.stats.WARM_PROSPECT ||
          state.stats.HOT_PROSPECT !== props.stats.HOT_PROSPECT
        ) {
          return {
            stats: props.stats,
            isAnimatingProspect: true,
          };
        }
        if (state.stats.CUSTOMER !== props.stats.CUSTOMER) {
          return {
            stats: props.stats,
            isAnimatingCustomer: true,
          };
        }
        if (state.stats.CONSULTANT !== props.stats.CONSULTANT) {
          return {
            stats: props.stats,
            isAnimatingConsultant: true,
          };
        }
      }
      return {
        stats: props.stats,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { stats } = this.props;
    if (stats !== prevProps.stats) {
      setTimeout(() => {
        this.resetSparkles();
      }, 750);
    }
  }

  resetSparkles = () => {
    this.setState({
      isAnimatingProspect: false,
      isAnimatingCustomer: false,
      isAnimatingConsultant: false,
    });
  };

  render() {
    const {
      classes,
      stats,
      currentUser: { company },
    } = this.props;
    const { isAnimatingProspect, isAnimatingCustomer, isAnimatingConsultant } = this.state;

    return (
      <div className={classes.root}>
        <Typography className={classes.title}>Completed Today</Typography>
        <div className={classes.row}>
          <div className={classes.column}>
            <Typography variant="h3">
              {stats.COLD_PROSPECT + stats.WARM_PROSPECT + stats.HOT_PROSPECT}
              <Sparkles isAnimating={isAnimatingProspect} />
            </Typography>
            <Typography variant="caption">prospects</Typography>
          </div>
          <div className={classes.column}>
            <Typography variant="h3">
              {stats.CUSTOMER}
              <Sparkles isAnimating={isAnimatingCustomer} />
            </Typography>
            <Typography variant="caption">{`${stringsService.getCustomersLabel(company)}`.toLowerCase()}</Typography>
          </div>
          <div className={classes.column}>
            <Typography variant="h3">
              {stats.CONSULTANT}
              <Sparkles isAnimating={isAnimatingConsultant} />
            </Typography>
            <Typography variant="caption">{`${stringsService.getConsultantsLabel(company)}`.toLowerCase()}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    stats: selectStatsActivity,
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(FooterSummary);
