import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import map from 'lodash/map';
import { Icon } from '@material-ui/core';

const styles = theme => ({
  subheader: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    pointerEvents: 'none',
  },
  circle: {
    marginBottom: 2,
    marginRight: 5,
  },
});

class Menu extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    anchorEl: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    itemSelected: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    icons: PropTypes.bool,
  };

  static defaultProps = {
    anchorEl: undefined,
    itemSelected: undefined,
    icons: false,
  };

  render() {
    const { icons, classes, anchorEl, items, itemSelected, onChange, ...other } = this.props;

    return (
      <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} {...other}>
        {map(items, item => (
          <MenuItem
            className={classnames({
              [classes.subheader]: item.subheader,
            })}
            key={item.key}
            onClick={() => onChange(item)}
            selected={item === itemSelected}
          >
            {icons &&
              (item.subheader ? null : (
                <Icon className={classes.circle}>
                  <img src={item.icon} alt="" />
                </Icon>
              ))}

            {item.textColor ? <span style={{ color: item.textColor }}>{item.label}</span> : item.label}
          </MenuItem>
        ))}
      </MuiMenu>
    );
  }
}

export default withStyles(styles)(Menu);
