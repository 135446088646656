import { schema } from 'normalizr';

import api from 'services/api';
import resource from './resource';

const url = '/api/v1/spreadsheet_upload_attempts';

// Interceptors
function processStrategy(data) {
  return resource.processStrategy(data);
}

// Schemas
const attepmtSchema = new schema.Entity('uploadAttempts', {}, { processStrategy });

// Methods
function fetchAttempt({ id }) {
  return api.get(`${url}/${id}`, {
    schema: attepmtSchema,
  });
}

export default {
  attepmtSchema,
  fetchAttempt,
};
