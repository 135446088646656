import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconAdd from 'assets/IconAdd';
import { map } from 'lodash';
import moment from 'moment';

import { DISPATCH_FETCH_CONTACT_IMPORT_STATS } from 'actions/actionTypes';
import { selectStatsImport, selectCurrentUser } from 'reducers/selectors';
import contactsService from 'services/contactsService';
import DialogImportContacts from './DialogImportContacts';

const styles = theme => ({
  paper: {
    minWidth: 340,
    marginTop: '35px',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topBorder: {
    borderTop: '1px solid rgba(0, 0, 0, 0.54)',
  },
  itemSecondaryText: {
    color: 'gray',
  },
  danger: {
    color: theme.palette.error.main,
  },
  success: {
    color: green[500],
  },
  menuTitle: {
    padding: '10px 15px',
    outline: '0',
    fontWeight: '500',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  addContact: {
    position: 'absolute',
    zIndex: '100',
    // right: '264px',
    right: '216px',
    top: '8px',
    cursor: 'pointer',
    padding: '12px',
    minWidth: '1px',
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: '#00bcd4',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
      right: '50%',
      top: '1px',
      marginRight: '-100px',
    },
  },
  redPoint: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '7px',
    height: '7px',
    borderRadius: '100%',
    backgroundColor: theme.palette.error.main,
  },
});

class ButtonImportContacts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    stats: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    dispatchFetchContactImportStats: PropTypes.func.isRequired,
    openQuickLog: PropTypes.func,
  };

  static defaultProps = {
    openQuickLog: undefined,
  };

  state = {
    anchorEl: null,
    section: null,
  };

  componentDidMount() {
    const { dispatchFetchContactImportStats } = this.props;
    dispatchFetchContactImportStats();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleItemClick = section => {
    this.setState({
      anchorEl: null,
      section,
    });
  };

  handleDialogClose = () => {
    this.setState({ section: null });
  };

  openQuickLog = () => {
    const { openQuickLog } = this.props;
    this.handleClose();
    openQuickLog(undefined);
  };

  render() {
    const {
      classes,
      stats,
      currentUser: { company },
    } = this.props;
    const { anchorEl, section } = this.state;

    const items = contactsService.getImportSections(company);

    let isOldImport;
    map(items, ({ spreadsheetType }) => {
      const { lastSuccessfulImportAt } = stats[spreadsheetType] || {};
      if (
        moment()
          .subtract(1, 'months')
          .isAfter(lastSuccessfulImportAt)
      ) {
        isOldImport = true;
      }
    });

    return (
      <React.Fragment>
        <Button className={classes.addContact} onClick={this.handleClick}>
          {isOldImport ? <div className={classes.redPoint} /> : null}
          <Tooltip title="Add/Update Contacts">
            <IconAdd className={classes.addContactIcon} />
          </Tooltip>
        </Button>
        <Menu
          id="import-contacts-menu"
          classes={{
            paper: classes.paper,
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <Typography className={classes.menuTitle}>Import or update:</Typography>
          {map(items, ({ section: key, label, titleLite, spreadsheetType }) => {
            const { lastSuccessfulImportAt } = stats[spreadsheetType] || {};
            const isOld = moment()
              .subtract(1, 'months')
              .isAfter(lastSuccessfulImportAt);
            return (
              <MenuItem key={key} className={classes.item} onClick={() => this.handleItemClick(key)}>
                <span>{label || titleLite}</span>
                {lastSuccessfulImportAt && (
                  <span
                    className={classnames(classes.itemSecondaryText, {
                      [classes.danger]: isOld,
                      [classes.success]: !isOld,
                    })}
                  >
                    {moment(lastSuccessfulImportAt).fromNow()}
                  </span>
                )}
              </MenuItem>
            );
          })}
          <MenuItem key="manual" className={classnames(classes.item, classes.topBorder)} onClick={this.openQuickLog}>
            Add contacts manually...
          </MenuItem>
        </Menu>
        <DialogImportContacts section={section} open={!!section} onClose={this.handleDialogClose} />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    stats: selectStatsImport,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchContactImportStats: () => ({ type: DISPATCH_FETCH_CONTACT_IMPORT_STATS }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(ButtonImportContacts);
