import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TypographyLiquid from 'components/TypographyLiquid';
import DialogPlanPayment from 'containers/DialogPlanPayment';

const styles = theme => ({
  root: {
    textAlign: 'center',
    boxShadow: `0 0 0 1px ${theme.palette.divider}`,
  },
  highlighted: {
    boxShadow: '0 0 0 2px #4cae51',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  header: {
    padding: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  headerHighlighted: {
    backgroundColor: '#4cae51',
    color: theme.palette.common.white,
    boxShadow: '0 0 0 2px #4cae51',
    padding: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    marginTop: theme.spacing.unit,
    fontWeight: 'bold',
  },
  price: {
    fontWeight: 'bold',
    color: '#f44336',
  },
  priceCaption: {
    color: '#f44336',
  },
  undiscountedPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  actions: {
    justifyContent: 'center',
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2.5}px ${theme.spacing.unit * 3}px`,
  },
});

class PlanCard extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
  };

  state = {
    dialogOpen: false,
  };

  render() {
    const { classes, data } = this.props;
    const { name, description, undiscountedPrice, monthlyDisplayPrice, price, months, mostPopular } = data;
    const { dialogOpen } = this.state;
    return (
      <React.Fragment>
        <div>
          {mostPopular ? (
            <Typography className={classes.headerHighlighted} variant="caption" align="center">
              MOST POPULAR
            </Typography>
          ) : (
            <Typography className={classes.header} variant="caption">
              &nbsp;
            </Typography>
          )}
          <Card
            className={classnames(classes.root, {
              [classes.highlighted]: mostPopular,
            })}
          >
            <CardContent>
              <Typography className={classes.title} paragraph>
                {name}
              </Typography>
              <Typography className={classes.price} variant="h5">
                ${monthlyDisplayPrice || Math.round((price / months) * 100) / 100}
                <Typography className={classes.priceCaption} variant="caption" inline>
                  /mo
                </Typography>
              </Typography>
              <Typography className={classes.undiscountedPrice} paragraph>
                ${undiscountedPrice}/mo
              </Typography>
              <TypographyLiquid variant="caption" values={{ price }}>
                {description}
              </TypographyLiquid>
              {months > 1 ? (
                <TypographyLiquid variant="caption" values={{ price: `$${price}` }}>
                  {'({{ price }} total)'}
                </TypographyLiquid>
              ) : (
                <Typography variant="caption">&nbsp;</Typography>
              )}
            </CardContent>
            <CardActions className={classes.actions}>
              <Button variant="contained" color="primary" fullWidth onClick={() => this.setState({ dialogOpen: true })}>
                Choose Plan
              </Button>
            </CardActions>
          </Card>
        </div>
        <DialogPlanPayment open={dialogOpen} data={data} onClose={() => this.setState({ dialogOpen: false })} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PlanCard);
