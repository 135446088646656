import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    flex: 1,
    padding: theme.spacing.unit * 3,
    backgroundColor: '#fefac7',
  },
});

class Notes extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    placeholder: '',
  };

  // eslint-disable-next-line react/destructuring-assignment
  propsValue = this.props.value || '';

  state = {
    newValue: this.propsValue,
    lastValueSaved: this.propsValue,
  };

  timer = null;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleChange = event => {
    const {
      target: { value },
    } = event;
    this.setState({ newValue: value });
    this.timer = setTimeout(this.handleChangeSubmit, 5000);
  };

  handleChangeSubmit = () => {
    const { onChange } = this.props;
    const { newValue, lastValueSaved } = this.state;
    clearTimeout(this.timer);
    if (newValue !== lastValueSaved) {
      this.setState({
        lastValueSaved: newValue,
      });
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  render() {
    const { classes, value, onChange, placeholder, ...other } = this.props;
    const { newValue } = this.state;

    return (
      <div className={classes.root}>
        <TextField
          placeholder={placeholder}
          value={newValue}
          multiline
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={this.handleChange}
          {...other}
          onBlur={this.handleChangeSubmit}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Notes);
