import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const createThemeDark = theme =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: 'MuseoSans',
    },
    palette: {
      primary: theme.palette.primary,
      secondary: theme.palette.secondary,
      type: 'dark',
      background: {
        paper: fade(theme.palette.common.black, 0.85),
      },
    },
    overrides: theme.overrides,
  });

export default createThemeDark;
