import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.primary.dark,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 25,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
  },
  maxWidth: {
    maxWidth: '600px',
  },
});

function renderMessage(message, classes) {
  if (typeof message === 'object') {
    // Display custom message for invalid access code in Signup form.
    if (message.length === 1 && message[0] === 'Access code is invalid') {
      return (
        <div>
          {"We're in closed beta! Email "}
          <a href="mailto:support@doughcrm.com">support@doughcrm.com</a>
          {' for a valid access code'}
        </div>
      );
    }

    // Otherwise, simply display each error message in the array.
    return (
      <div className={classes.messages}>
        {message.map(item => (
          <div key={item}>{item}</div>
        ))}
      </div>
    );
  }

  return message;
}

function SnackbarContentWrapper(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      key={message}
      className={classnames(classes[variant], className, classes.maxWidth)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classnames(classes.icon, classes.iconVariant)} />
          {renderMessage(message, classes)}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  classes: PropTypes.shape({
    message: PropTypes.string,
    icon: PropTypes.string,
    iconVariant: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

SnackbarContentWrapper.defaultProps = {
  className: null,
  message: null,
  onClose: () => {},
};

export default withStyles(styles)(SnackbarContentWrapper);
