import axios from 'axios';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { pick, get } from 'lodash';

import configService from 'services/configService';
import storageService from 'services/storageService';
import history from 'config/history';

// Create a new instance of axios
// https://github.com/axios/axios#creating-an-instance
const axiosClient = axios.create({
  baseURL: configService.getApiBasePath(),
});

// Add a request interceptor
axiosClient.interceptors.request.use(config => {
  const { url, data, params } = config;
  const query = queryString.stringify(storageService.getAuth());
  return {
    ...config,
    // add authorization
    url: query ? `${url}${url.indexOf('?') >= 0 ? '&' : '?'}${query}` : url,
    // decamelize keys
    data: data
      ? decamelizeKeys(data, (key, convert, options) =>
          convert(
            // line1 -> line_1
            key.replace(/[0-9]+$/, '_$&'),
            options,
          ))
      : data,
    params: params ? decamelizeKeys(params) : params,
  };
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  response => {
    const {
      headers,
      data: resData,
      config: { schema },
    } = response;

    // Store auth
    storageService.setAuth(pick(headers, ['access-token', 'client', 'uid']));

    // camelizeKeys, preventing conversion of keys containing only uppercase letters or numbers
    // https://github.com/domchristie/humps#converting-object-keys
    const data = camelizeKeys(resData ? resData.data || resData : {}, (key, convert) =>
      (/^[A-Z0-9_]+$/.test(key) ? key : convert(key)));

    return {
      ...response,
      data,
      // Normalizes the result JSON according to schema.
      // This makes every API response have the same shape, regardless of how nested it was.
      normalized: schema ? normalize(data, schema) : data,
    };
  },
  error =>
    Promise.reject(error).then(() => {
      if (get(error, 'response.status') === 401) {
        storageService.removeAuth();
        history.push('/login');
      }
    }),
);

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export default axiosClient;
