import configService from 'services/configService';

const PREFIX = `@${configService.getAppPrefix()}`;
const SUFFIX_REQUEST = '_REQUEST';
const SUFFIX_SUCCESS = '_SUCCESS';
const SUFFIX_ERROR = '_ERROR';

export const regexAction = new RegExp(`^${PREFIX}.+(${SUFFIX_REQUEST}|${SUFFIX_SUCCESS}|${SUFFIX_ERROR})$`, 'i');
export const regexRequest = new RegExp(`^${PREFIX}.+${SUFFIX_REQUEST}$`, 'i');
export const regexSuccess = new RegExp(`^${PREFIX}.+${SUFFIX_SUCCESS}$`, 'i');
export const regexError = new RegExp(`^${PREFIX}.+${SUFFIX_ERROR}$`, 'i');

function createActionType(base) {
  return `${PREFIX}/${base}`;
}

function createActionTypes(base) {
  return {
    REQUEST: createActionType(`${base}${SUFFIX_REQUEST}`),
    SUCCESS: createActionType(`${base}${SUFFIX_SUCCESS}`),
    ERROR: createActionType(`${base}${SUFFIX_ERROR}`),
  };
}

export const SIGN_UP = createActionTypes('SIGN_UP');
export const SIGN_IN = createActionTypes('SIGN_IN');
export const SIGN_OUT = createActionTypes('SIGN_OUT');
export const RESET_PASSWORD = createActionTypes('RESET_PASSWORD');
export const VALIDATE_RESET_PASSWORD_TOKEN = createActionTypes('VALIDATE_RESET_PASSWORD_TOKEN');
export const SAVE_PASSWORD = createActionTypes('SAVE_PASSWORD');
export const FETCH_S3_SIGNATURE = createActionTypes('FETCH_S3_SIGNATURE');
export const UPLOAD_FILE = createActionTypes('UPLOAD_FILE');
export const IMPORT_CONTACTS = createActionTypes('IMPORT_CONTACTS');
export const FETCH_ATTEMPT = createActionTypes('FETCH_ATTEMPT');
export const FETCH_ACCOUNT = createActionTypes('FETCH_ACCOUNT');
export const FETCH_ACTIVITY_STATS = createActionTypes('FETCH_ACTIVITY_STATS');
export const FETCH_ALL_ACTIVITY_STATS = createActionTypes('FETCH_ALL_ACTIVITY_STATS');
export const FETCH_CONTACTS = createActionTypes('FETCH_CONTACTS');
export const FETCH_CONTACT = createActionTypes('FETCH_CONTACT');
export const FETCH_CONTACT_CARD = createActionTypes('FETCH_CONTACT_CARD');
export const FETCH_CONTACT_IMPORT_STATS = createActionTypes('FETCH_CONTACT_IMPORT_STATS');
export const FETCH_WORK_LIST = createActionTypes('FETCH_WORK_LIST');
export const FETCH_ACTIVE_REMINDERS = createActionTypes('FETCH_ACTIVE_REMINDERS');
export const FETCH_RECENT_ACTIVITY = createActionTypes('FETCH_RECENT_ACTIVITY');
export const FETCH_SCRIPTS = createActionTypes('FETCH_SCRIPTS');
export const SAVE_ACCOUNT = createActionTypes('SAVE_ACCOUNT');
export const SAVE_PAYMENT = createActionTypes('SAVE_PAYMENT');
export const SAVE_PLAN = createActionTypes('SAVE_PLAN');
export const SAVE_CONTACT = createActionTypes('SAVE_CONTACT');
export const SAVE_REMINDER = createActionTypes('SAVE_REMINDER');
export const SAVE_ACTIVITY = createActionTypes('SAVE_ACTIVITY');
export const ASSIGN_FREQUENCIES = createActionTypes('ASSIGN_FREQUENCIES');
export const DELETE_CONTACT = createActionTypes('DELETE_CONTACT');
export const DELETE_REMINDER = createActionTypes('DELETE_REMINDER');
export const DELETE_ACTIVITY = createActionTypes('DELETE_ACTIVITY');
export const CONNECT_TO_SC = createActionTypes('CONNECT_TO_SC');
export const SYNC_WITH_SC = createActionTypes('SYNC_WITH_SC');

// sync actions
export const DISPATCH_LOADING_START = createActionType('DISPATCH_LOADING_START');
export const DISPATCH_LOADING_STOP = createActionType('DISPATCH_LOADING_STOP');
export const DISPATCH_APP_READY = createActionType('DISPATCH_APP_READY');
export const DISPATCH_ERROR = createActionType('DISPATCH_ERROR');
export const DISPATCH_SIGN_UP = createActionType('DISPATCH_SIGN_UP');
export const DISPATCH_SIGN_IN = createActionType('DISPATCH_SIGN_IN');
export const DISPATCH_SIGN_OUT = createActionType('DISPATCH_SIGN_OUT');
export const DISPATCH_RESET_PASSWORD = createActionType('DISPATCH_RESET_PASSWORD');
export const DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN = createActionType('DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN');
export const DISPATCH_SET_NEW_PASSWORD = createActionType('DISPATCH_SET_NEW_PASSWORD');
export const DISPATCH_FINISH_ONBOARDING = createActionType('DISPATCH_FINISH_ONBOARDING');
export const DISPATCH_IMPORT_CONTACTS = createActionType('DISPATCH_IMPORT_CONTACTS');
export const DISPATCH_FETCH_INITIAL_DATA = createActionType('DISPATCH_FETCH_INITIAL_DATA');
export const DISPATCH_FETCH_ACCOUNT = createActionType('DISPATCH_FETCH_ACCOUNT');
export const DISPATCH_FETCH_CONTACTS = createActionType('DISPATCH_FETCH_CONTACTS');
export const DISPATCH_FETCH_CONTACT_IMPORT_STATS = createActionType('DISPATCH_FETCH_CONTACT_IMPORT_STATS');
export const DISPATCH_FETCH_ACTIVITY_STATS = createActionType('DISPATCH_FETCH_ACTIVITY_STATS');
export const DISPATCH_FETCH_ALL_ACTIVITY_STATS = createActionType('DISPATCH_FETCH_ALL_ACTIVITY_STATS');
export const DISPATCH_FETCH_CONTACT_CARD = createActionType('DISPATCH_FETCH_CONTACT_CARD');
export const DISPATCH_FETCH_WORK_LIST = createActionType('DISPATCH_FETCH_WORK_LIST');
export const DISPATCH_FETCH_SCRIPTS = createActionType('DISPATCH_FETCH_SCRIPTS');
export const DISPATCH_INCREASE_WORK_LIST_FACTOR = createActionType('DISPATCH_INCREASE_WORK_LIST_FACTOR');
export const DISPATCH_FETCH_ACTIVE_REMINDERS = createActionType('DISPATCH_FETCH_ACTIVE_REMINDERS');
export const DISPATCH_FETCH_RECENT_ACTIVITY = createActionType('DISPATCH_FETCH_RECENT_ACTIVITY');
export const DISPATCH_SAVE_ACCOUNT = createActionType('DISPATCH_SAVE_ACCOUNT');
export const DISPATCH_SAVE_PAYMENT = createActionType('DISPATCH_SAVE_PAYMENT');
export const DISPATCH_SAVE_PLAN = createActionType('DISPATCH_SAVE_PLAN');
export const DISPATCH_SAVE_CONTACT = createActionType('DISPATCH_SAVE_CONTACT');
export const DISPATCH_SAVE_REMINDER = createActionType('DISPATCH_SAVE_REMINDER');
export const DISPATCH_SAVE_ACTIVITY = createActionType('DISPATCH_SAVE_ACTIVITY');
export const DISPATCH_DELETE_CONTACTS = createActionType('DISPATCH_DELETE_CONTACTS');
export const DISPATCH_DELETE_REMINDER = createActionType('DISPATCH_DELETE_REMINDER');
export const DISPATCH_DELETE_ACTIVITY = createActionType('DISPATCH_DELETE_ACTIVITY');
export const DISPATCH_POST_QUICK_LOG = createActionType('DISPATCH_POST_QUICK_LOG');
export const DISPATCH_CONNECT_TO_SC = createActionType('DISPATCH_CONNECT_TO_SC');
export const DISPATCH_SYNC_WITH_SC = createActionType('DISPATCH_SYNC_WITH_SC');
