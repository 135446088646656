// TODO: Update Standard Version script categories (currently, we are temporarily using the same Plexus scripts).
const standardFilters = [
  {
    key: 'all',
    label: 'All Scripts',
    filterBy: {},
  },
  {
    key: '01_RELATIONSHIP_BUILDING',
    label: 'Relationship-Building',
    filterBy: { category: '01_RELATIONSHIP_BUILDING' },
  },
  {
    key: '02_FOLLOWING_UP',
    label: 'Following Up',
    filterBy: { category: '02_FOLLOWING_UP' },
  },
  {
    key: '03_CUSTOMER_CONSULTANT',
    label: 'Customers/Consultants',
    filterBy: { category: '03_CUSTOMER_CONSULTANT' },
  },
];
const filters = {
  PLEXUS: [
    {
      key: 'all',
      label: 'All Scripts',
      filterBy: {},
    },
    {
      key: '01_RELATIONSHIP_BUILDING',
      label: 'Relationship-Building',
      filterBy: { category: '01_RELATIONSHIP_BUILDING' },
    },
    {
      key: '02_SHARING_PLEXUS',
      label: 'Sharing Plexus',
      filterBy: { category: '02_SHARING_PLEXUS' },
    },
    {
      key: '03_FOLLOWING_UP',
      label: 'Following Up',
      filterBy: { category: '03_FOLLOWING_UP' },
    },
    {
      key: '04_NEW_CUSTOMER_AMBASSADOR',
      label: 'New Customers/Ambs',
      filterBy: { category: '04_NEW_CUSTOMER_AMBASSADOR' },
    },
    {
      key: '05_CURRENT_CUSTOMER_AMBASSADOR',
      label: 'Current Customers/Ambs',
      filterBy: { category: '05_CURRENT_CUSTOMER_AMBASSADOR' },
    },
  ],
  MONAT: [
    {
      key: 'all',
      label: 'All Scripts',
      filterBy: {},
    },
    {
      key: '01_RELATIONSHIP_BUILDING',
      label: 'Relationship-Building',
      filterBy: { category: '01_RELATIONSHIP_BUILDING' },
    },
    {
      key: '02_SHARING_MONAT',
      label: 'Sharing Monat',
      filterBy: { category: '02_SHARING_MONAT' },
    },
    {
      key: '03_PRODUCT_FUNNEL',
      label: 'Product Funnel',
      filterBy: { category: '03_PRODUCT_FUNNEL' },
    },
    {
      key: '04_OPPORTUNITY_FUNNEL',
      label: 'Opportunity Funnel',
      filterBy: { category: '04_OPPORTUNITY_FUNNEL' },
    },
    {
      key: '05_FOLLOWING_UP',
      label: 'Following Up',
      filterBy: { category: '05_FOLLOWING_UP' },
    },
    {
      key: '06_OBJECTIONS',
      label: 'Objections',
      filterBy: { category: '06_OBJECTIONS' },
    },
  ],
  DOTERRA: standardFilters,
  ARBONNE: standardFilters,
  BEACHBODY: standardFilters,
  BEAUTYCOUNTER: standardFilters,
  HEMPWORX: standardFilters,
  ISAGENIX: standardFilters,
  IT_WORKS: standardFilters,
  JEUNESSE: standardFilters,
  LE_VEL: standardFilters,
  LIMELIFE: standardFilters,
  MELALEUCA: standardFilters,
  MODERE: standardFilters,
  OPTAVIA: standardFilters,
  OTHER: standardFilters,
  PRUVIT: standardFilters,
  RODAN_FIELDS: standardFilters,
  SCENTSY: standardFilters,
  SCOUT_CELLAR: [
    {
      key: 'all',
      label: 'All Scripts',
      filterBy: {},
    },
    {
      key: '01_SCOUT_CELLAR',
      label: 'Scout & Cellar',
      filterBy: { category: '01_SCOUT_CELLAR' },
    },
    {
      key: '02_RELATIONSHIP_BUILDING',
      label: 'Relationship-Building',
      filterBy: { category: '02_RELATIONSHIP_BUILDING' },
    },
    {
      key: '03_FOLLOWING_UP',
      label: 'Following Up',
      filterBy: { category: '03_FOLLOWING_UP' },
    },
    {
      key: '04_CUSTOMER_CONSULTANT',
      label: 'Customers/Consultants',
      filterBy: { category: '04_CUSTOMER_CONSULTANT' },
    },
  ],
  SENEGENCE: standardFilters,
  SHAKLEE: standardFilters,
  YOUNG_LIVING: standardFilters,
  CREW: [
    {
      key: 'all',
      label: 'All Scripts',
      filterBy: {},
    },
    {
      key: '01_RELATIONSHIP_BUILDING',
      label: 'Relationship-Building',
      filterBy: { category: '01_RELATIONSHIP_BUILDING' },
    },
    {
      key: '02_FOLLOWING_UP',
      label: 'Following Up',
      filterBy: { category: '02_FOLLOWING_UP' },
    },
    {
      key: '03_CUSTOMER_CONSULTANT',
      label: 'Customers/Consultants',
      filterBy: { category: '03_CUSTOMER_CONSULTANT' },
    },
  ],
  YOUNIQUE: standardFilters,
};

function getFilters(company) {
  return filters[company];
}

export default {
  getFilters,
};
