import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from 'material-ui-pickers/DatePicker/components/Calendar';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

const styles = theme => ({
  header: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconClose: {
    position: 'absolute',
    right: 0,
  },
  notesWrapper: {
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
  },
  actions: {
    padding: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  action: {
    marginLeft: theme.spacing.unit / 2,
  },
});

class PopoverReminder extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      date: PropTypes.shape({}).isRequired,
      notes: PropTypes.string,
    }),
    title: PropTypes.string,
    secondaryAction: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
    title: 'Set Reminder',
    secondaryAction: undefined,
  };

  state = {
    date: moment(),
    notes: '',
  };

  componentDidUpdate(prevProps) {
    const { value, open } = this.props;
    if (value && !isEqual(value, prevProps.value)) {
      this.handleNewState(value);
    } else if (open && !prevProps.open && !value) {
      this.handleNewState({
        date: moment(),
        notes: '',
      });
    }
  }

  handleNewState = state => {
    this.setState({
      ...state,
    });
  };

  handleDateChange = date => {
    this.setState({ date });
  };

  handleTextChange = event => {
    this.setState({ notes: event.target.value });
  };

  render() {
    const { classes, title, secondaryAction, onClose, onSubmit, ...other } = this.props;
    const { date, notes } = this.state;
    return (
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
        {...other}
      >
        <div className={classes.header}>
          <Typography>{title}</Typography>
          <IconButton className={classes.iconClose} key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Calendar date={date} allowKeyboardControl={false} onChange={this.handleDateChange} minDate={moment()} />
        <Divider />
        <div className={classes.notesWrapper}>
          <TextField
            label="What will you need to do?"
            value={notes}
            onChange={this.handleTextChange}
            rowsMax="4"
            multiline
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
          />
        </div>
        <Divider />
        <div className={classes.actions}>
          {secondaryAction}
          <Button
            className={classes.action}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSubmit(this.state)}
          >
            Save
          </Button>
        </div>
      </Popover>
    );
  }
}

export default withStyles(styles)(PopoverReminder);
