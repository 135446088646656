import merge from 'lodash/merge';

import {
  SIGN_IN,
  SIGN_OUT,
  DISPATCH_APP_READY,
  DISPATCH_LOADING_START,
  DISPATCH_LOADING_STOP,
} from 'actions/actionTypes';

const initialState = {
  ready: false,
  loading: false,
  loadingMessage: 'Loading...',
};

export default function createAppStatusReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      case DISPATCH_APP_READY:
        return merge({}, state, {
          ready: true,
        });
      case DISPATCH_LOADING_START:
        return merge({}, state, {
          loading: true,
          loadingMessage: action.loadingMessage,
        });
      case DISPATCH_LOADING_STOP:
        return merge({}, state, {
          loading: false,
        });
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
