import { call, select, put } from 'redux-saga/effects';

import {
  FETCH_S3_SIGNATURE,
  UPLOAD_FILE,
  IMPORT_CONTACTS,
  ASSIGN_FREQUENCIES,
  DISPATCH_LOADING_START,
  DISPATCH_LOADING_STOP,
} from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import { selectS3Signature } from 'reducers/selectors';
import filesResource from 'resources/filesResource';
import history from 'config/history';
import sagaFetch from './sagaFetch';
import sagaFetchAttempt from './sagaFetchAttempt';
import sagaFinishOnboarding from './sagaFinishOnboarding';
import sagaFetchContacts from './sagaFetchContacts';

// bind resource generator
const fetchS3Signature = sagaFetch.bind(null, FETCH_S3_SIGNATURE, filesResource.fetchS3Signature);
const uploadFile = sagaFetch.bind(null, UPLOAD_FILE, filesResource.uploadFile);
const importContacts = sagaFetch.bind(null, IMPORT_CONTACTS, contactsResource.importContacts);
const assignFrequencies = sagaFetch.bind(null, ASSIGN_FREQUENCIES, contactsResource.assignFrequencies);

export default function* sagaImportContacts({
  file,
  spreadsheetType,
  label,
  pathSuccess,
  callback,
  spreadsheetHeaders,
  instagramImportType,
}) {
  yield put({ type: DISPATCH_LOADING_START, loadingMessage: `Importing ${label}...` });

  // get signed URL for S3
  let res = yield call(fetchS3Signature, { file, skipLoading: true });
  if (res.error) {
    return;
  }
  // upload file to S3
  const { s3Key, s3SignedUrl } = yield select(selectS3Signature);
  res = yield call(uploadFile, { s3SignedUrl, file, skipLoading: true });
  if (res.error) {
    return;
  }

  // import contacts
  const importContactsRequest = { s3Key, spreadsheetType, skipLoading: true };
  const isStandardImport = spreadsheetType === 'STANDARD_CUSTOMERS' || spreadsheetType === 'STANDARD_CONSULTANTS';
  if (isStandardImport) {
    // If importing a Standard spreadsheet, add spreadsheet column headers to request.
    importContactsRequest.columnHeaders = spreadsheetHeaders;
  }
  if (spreadsheetType === 'INSTAGRAM_FOLLOWERS') {
    importContactsRequest.instagramImportType = instagramImportType;
  }
  // eslint-disable-next-line no-console
  console.log(`sagaImportContacts importContactsRequest: ${JSON.stringify(importContactsRequest)}`);
  res = yield call(importContacts, importContactsRequest);
  if (res.error) {
    return;
  }
  // poll the server to determine whether the upload/import is done
  res = yield call(sagaFetchAttempt, { id: res.data.id, label });
  if (res.error) {
    return;
  }
  // continue
  if (pathSuccess) {
    history.push(pathSuccess);
    yield put({ type: DISPATCH_LOADING_STOP });
  } else if (callback) {
    // skip modifying any contact's frequencies that the user manually changed
    yield call(assignFrequencies, {
      params: {
        assignAllContacts: false,
      },
    });
    yield call(sagaFetchContacts, { skipLoading: true });
    callback();
  } else {
    // finish onboarding
    yield call(sagaFinishOnboarding);
  }
}
