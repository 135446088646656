import { call } from 'redux-saga/effects';

import { SAVE_PAYMENT } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const savePayment = sagaFetch.bind(null, SAVE_PAYMENT, accountResource.savePayment);

export default function* sagaSavePayment({ cardToken, callback }) {
  const res = yield call(savePayment, { cardToken });
  if (!res.error && callback) {
    callback();
  }
}
