import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: 390,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
});

class TypographyWebsite extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    children: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { classes, children, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }
    const url = /^(http|https):\/\//i.test(children) ? children : `http://${children}`;
    return (
      <Typography className={classes.root} {...other}>
        {
          <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        }
      </Typography>
    );
  }
}

export default withStyles(styles)(TypographyWebsite);
