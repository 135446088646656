import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { map } from 'lodash';
import moment from 'moment';

import PlanCard from 'containers/PlanCard';
import accountService from 'services/accountService';
import { selectCurrentUser } from 'reducers/selectors';
import { DISPATCH_FETCH_ACCOUNT } from 'actions/actionTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing.unit,
    },
  },
  paper: {
    flex: 1,
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px ${theme.spacing.unit * 4.5}px`,
    maxWidth: theme.spacing.unit * 110,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
    },
  },
  plans: {
    padding: theme.spacing.unit * 5,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
    },
  },
});

const plans = accountService.getPlansAvailable();

class Paywall extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      paymentInfo: PropTypes.shape({}).isRequired,
    }).isRequired,
    dispatchFetchAccount: PropTypes.func.isRequired,
  };

  state = {
    renderComponent: false,
  };

  componentDidMount() {
    const { dispatchFetchAccount } = this.props;
    // Fetch subscription data again, in case user was using app when their subscription expired.
    dispatchFetchAccount();

    // Wait 2 seconds before rendering Paywall.
    setTimeout(() => {
      this.setState({ renderComponent: true });
    }, 2000);
  }

  render() {
    const {
      classes,
      currentUser: { subscriptionExpiresAt, planKey },
    } = this.props;
    const { renderComponent } = this.state;
    const isValidSubscription = subscriptionExpiresAt && moment(subscriptionExpiresAt).isAfter();
    if (!renderComponent) {
      return <React.Fragment />;
    }

    if (isValidSubscription) {
      return <Redirect to="/home/work" />;
    }
    const subscriptionType = planKey === 'PLAN_TRIAL' ? 'trial' : 'subscription';
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h6" paragraph align="center">
            Your {subscriptionType} has ended. Lock in a launch special now!
          </Typography>
          <Grid className={classes.plans} container justify="center" spacing={24}>
            {map(plans, plan => (
              <Grid key={plan.planKey} item sm={4} xs={12}>
                <PlanCard data={plan} />
              </Grid>
            ))}
          </Grid>
          <Typography variant="caption" align="center">
            Your subscription will auto-renew, and you may change or cancel your subscription anytime for the next
            billing cycle.
          </Typography>
          <Typography variant="caption" align="center" paragraph>
            If you cancel, you&apos;ll have access until the period ends. No refunds will be given for any portion of a
            prepaid period.
          </Typography>
          <Typography variant="caption" align="center">
            All prices are in USD and do not include taxes. Please contact{' '}
            <a href="mailto:support@doughcrm.com?subject=Subscription help" rel="noopener noreferrer">
              support@doughcrm.com
            </a>{' '}
            with any questions.
          </Typography>
        </Paper>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchAccount: () => ({ type: DISPATCH_FETCH_ACCOUNT }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(Paywall);
