import { merge } from 'lodash';

import {
  FETCH_ACTIVITY_STATS,
  FETCH_ALL_ACTIVITY_STATS,
  FETCH_CONTACT_IMPORT_STATS,
  SIGN_IN,
  SIGN_OUT,
} from 'actions/actionTypes';

const initialState = {
  activity: {
    COLD_PROSPECT: 0,
    WARM_PROSPECT: 0,
    HOT_PROSPECT: 0,
    CUSTOMER: 0,
    CONSULTANT: 0,
  },
  import: {
    FACEBOOK_FRIENDS: {},
    PLEXUS_CLIENTS: {},
    PLEXUS_AMBASSADORS: {},
  },
  allStats: {},
};

export default function createStatsReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ACTIVITY_STATS.SUCCESS:
        return merge({}, state, { activity: action.normalized });
      case FETCH_ALL_ACTIVITY_STATS.SUCCESS:
        return merge({}, state, { allStats: action.normalized });
      case FETCH_CONTACT_IMPORT_STATS.SUCCESS:
        return merge({}, state, { import: action.normalized });
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
