import { call } from 'redux-saga/effects';
import { pick } from 'lodash';

import { SAVE_PASSWORD } from 'actions/actionTypes';
import storageService from 'services/storageService';
import history from 'config/history';
import passwordsResource from 'resources/passwordsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const setNewPassword = sagaFetch.bind(null, SAVE_PASSWORD, passwordsResource.setNewPassword);

export default function* sagaSetNewPassword({ params: { accessToken, ...other } }) {
  // WORKAROUND using `access-token` instead of `access_token|accessToken`
  const params = {
    ...other,
    'access-token': accessToken,
  };
  const res = yield call(setNewPassword, { params });
  if (!res.error) {
    storageService.setAuth(pick(params, ['access-token', 'client', 'uid']));
    history.push('/home/work');
  }
}
