import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconReminder = props => (
  <SvgIcon width="528" height="528" viewBox="0 0 528 528" {...props}>
    <path
      d="M423.5 490.617l-44-44 23.117-23.117 44 44c6.574 6.617 6.574 16.5 0 23.117-5.5 5.5-16.5 5.5-23.117 0zM104.5 490.617l44-44-23.117-23.117-44 44c-6.574 6.617-6.574 16.5 0 23.117 5.5 5.5 16.5 5.5 23.117 0z"
      fill="#37474f"
    />
    <path
      d="M484 264c0 121.516-98.484 220-220 220S44 385.516 44 264 142.484 44 264 44s220 98.484 220 220z"
      fill="#c62828"
    />
    <path
      d="M440 264c0 97.195-78.805 176-176 176S88 361.195 88 264 166.805 88 264 88s176 78.805 176 176z"
      fill="#eee"
    />
    <path d="M166.074 368.242l-6.23-6.23 101.105-101.106 6.23 6.188z" fill="#e53935" />
    <path d="M253 121h22v143h-22z" />
    <path d="M344.094 326.176l-17.875 17.918-71.586-71.586 17.918-17.875z" />
    <path d="M286 264c0 12.16-9.84 22-22 22s-22-9.84-22-22 9.84-22 22-22 22 9.84 22 22z" />
    <path d="M275 264c0 6.059-4.941 11-11 11s-11-4.941-11-11 4.941-11 11-11 11 4.941 11 11z" fill="#c62828" />
    <path
      d="M242 11h44v33h-44zM488.383 178.191c27.5-38.5 23.117-92.382-11-126.5-34.074-34.074-88-38.5-126.5-11zM39.617 178.191c-27.5-38.5-23.117-92.382 11-126.5 34.074-34.074 88-38.5 126.5-11z"
      fill="#37474f"
    />
  </SvgIcon>
);

export default IconReminder;
