import axios from 'axios';

// Methods
const connectSCAccount = async data => {
  const response = await axios({
    url: 'https://tower-api.scoutandcellar.com/api/consultant/CustomLogin',
    data: {
      username: data.data.username,
      password: data.data.password,
      rememberMe: false,
      PersonTypeID: 1,
    },
    method: 'post',
  });
  return { ...response };
};

export default {
  connectSCAccount,
};
