function getAppPrefix() {
  return process.env.REACT_APP_PREFIX;
}

function getApiBasePath() {
  return process.env.REACT_APP_API_BASE_PATH;
}

function getStripeApiKey() {
  return process.env.REACT_APP_STRIPE_API_KEY;
}

function getFacebookPixelId() {
  return process.env.REACT_APP_FACEBOOK_PIXEL_ID;
}

export default {
  getAppPrefix,
  getApiBasePath,
  getStripeApiKey,
  getFacebookPixelId,
};
