import { get } from 'lodash';

function getApiError({ error, type }) {
  if (!error || type.indexOf('@@') >= 0 || get(error, 'config.skipErrorMessage')) {
    return null;
  }
  return error;
}

function getMessage(action) {
  return get(action, 'data.message');
}

function skipSnackbar(action) {
  return get(action, 'config.skipSnackbar');
}

export default {
  getApiError,
  getMessage,
  skipSnackbar,
};
