import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReactPlayer from 'react-player';
import { map, includes, isEqual, find, findLast, last, reverse, filter } from 'lodash';
import moment from 'moment';

import storageService, { KEY_TOUR_DONE } from 'services/storageService';
import routerService from 'services/routerService';
import DialogVideoCatalog from 'containers/DialogVideoCatalog';
import { DISPATCH_SAVE_ACCOUNT } from 'actions/actionTypes';
import { selectCurrentUser } from 'reducers/selectors';
import videosService from 'services/videosService';
import FooterPlayerControls from './FooterPlayerControls';

const videoRatio = 16 / 9;
const videoHeight = 96;

const styles = theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      textAlign: 'right',
      borderRight: `1px solid ${fade(theme.palette.common.white, 0.4)}`,
      height: theme.spacing.unit * 12,
    },
  },
  rootLite: {
    display: 'flex',
    justifyContent: 'center',
  },
  playerWrapperUnstarted: {
    height: videoHeight,
    width: videoHeight * videoRatio,
    '& *': {
      pointerEvents: 'none',
    },
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: -9999,
    },
  },
  playerWrapperStarted: {
    position: 'fixed',
    height: videoHeight,
    width: videoHeight * videoRatio,
    right: 0,
    bottom: 112,
    [theme.breakpoints.up('lg')]: {
      height: videoHeight * 2,
      width: videoHeight * videoRatio * 2,
      right: theme.spacing.unit,
      bottom: theme.spacing.unit,
    },
  },
  expandButton: {
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
    },
  },
});

class FooterPlayer extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      completedVideos: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      email: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
    }).isRequired,
    dispatchSaveAccount: PropTypes.func.isRequired,
  };

  timer = null;

  constructor(props) {
    super(props);

    const {
      // location,
      user: { completedVideos },
    } = props;
    // TODO: Confirm if we should enable auto-play anywhere. If not, remove the related logic.
    // const autoPlay = routerService.isOnboarding(location) || !storageService.get(KEY_TOUR_DONE);
    this.state = {
      videos: this.getVideos(completedVideos),
      video: null,
      started: false,
      playing: false,
      played: 0,
      duration: 0,
      autoPlay: false,
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.handleLoadNewVideo();
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      user: { completedVideos },
    } = this.props;

    // refresh videos completed
    if (!isEqual(completedVideos, prevProps.user.completedVideos)) {
      this.handleVideosUpdate();
    }
    // reload on route change
    const isOnboarding = routerService.isOnboarding(location);
    if (
      (!isOnboarding && routerService.isOnboarding(prevProps.location)) ||
      (isOnboarding && !isEqual(location, prevProps.location))
    ) {
      this.reload(true);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getVideos = () => {
    const {
      user: { completedVideos, company },
    } = this.props;
    return map(videosService.getVideos(company), v => ({
      ...v,
      completed: includes(completedVideos, v.key),
    }));
  };

  handleVideosUpdate = () => {
    this.setState({
      videos: this.getVideos(),
    });
  };

  getCurrentVideo = () => {
    const { location, user } = this.props;
    const { videos } = this.state;

    // Video progression logic:
    // If user is on any Onboarding screen, display the correct video for that section.
    // Otherwise, (i.e., user is NOT on Onboarding screen),
    // - If the user is new (< 10 days since account created), display the first/oldest unwatched introductory video.
    //   (This is to show the introductory videos DAY1_VIDEO, DAY2_15MIN_IPAS, DAY3_15MIN_FOLLOWUPS for new users.)
    //   - If the new user has already watched all intro videos, show them the latest unwatched non-intro video.
    // - If the user is old, display the last/latest unwatched non-introductory video.
    // - If the user has watched all videos, display the last/latest video.
    const onboardingSection = routerService.getOnboardingSection(location);
    if (onboardingSection) {
      const videoForOnboardingSection = find(videos, { onboardingSection });
      const prospectOnboardingVideo = find(videos, { onboardingSection: 'prospects' });
      return videoForOnboardingSection || prospectOnboardingVideo;
    }

    const dateTenDaysAgo = moment().subtract(10, 'days');
    const userIsNew = moment(user.createdAt).isAfter(dateTenDaysAgo);
    const newestVideo = last(videos);
    const newestUnwatchedNonIntroductoryVideo = findLast(
      videos,
      v => !v.onboardingSection && !v.isIntroductoryVideo && !v.completed,
    );
    if (userIsNew) {
      const oldestUnwatchedIntroductoryVideo = find(
        videos,
        v => !v.onboardingSection && v.isIntroductoryVideo && !v.completed,
      );
      return oldestUnwatchedIntroductoryVideo || newestUnwatchedNonIntroductoryVideo || newestVideo;
    }

    return newestUnwatchedNonIntroductoryVideo || newestVideo;
  };

  handleLoadNewVideo = () => {
    this.load(this.getCurrentVideo());
  };

  handleVideoChange = video => {
    this.setState({
      dialogOpen: false,
      autoPlay: true,
    });
    this.load({});
    this.timer = setTimeout(() => {
      this.load(video);
    });
  };

  load = video => {
    this.setState({
      video,
      played: 0,
    });
  };

  reload = autoPlay => {
    this.setState({
      started: false,
      playing: false,
      played: 0,
      autoPlay,
    });
    this.player.seekTo(0);

    // reload video in order to auto-play the next one or show default thumbnail
    this.load({});
    this.timer = setTimeout(() => {
      this.load(this.getCurrentVideo());
    });
  };

  playPause = () => {
    const { location } = this.props;
    if (!routerService.isOnboarding(location)) {
      storageService.set(KEY_TOUR_DONE, true);
    }
    this.setState(prevState => ({
      started: true,
      autoPlay: false,
      playing: !prevState.playing,
    }));
  };

  handleReady = () => {
    const { autoPlay } = this.state;
    if (autoPlay) {
      this.playPause();
    }
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleDuration = duration => {
    this.setState({ duration });
  };

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    const { seeking } = this.state;
    if (!seeking) {
      this.setState(state);
    }

    // mark video as done when user completes watching 80% (unless in onboarding)
    const { dispatchSaveAccount, user } = this.props;
    const {
      video: { key },
    } = this.state;
    if (state.played > 0.8 && !includes(user.completedVideos, key)) {
      const completedVideos = [...user.completedVideos];
      completedVideos.push(key);
      dispatchSaveAccount({
        user,
        completedVideos,
      });
    }
  };

  handleEnded = () => {
    this.setState({ started: false, playing: false, played: 0 });
    this.handleLoadNewVideo();
  };

  handleError = error => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  render() {
    const {
      classes,
      location,
      user: { email },
    } = this.props;
    const { videos, video, started, playing, played, duration, dialogOpen } = this.state;
    const isOnboarding = routerService.isOnboarding(location);
    const url = video ? video.url : null;
    const config = { wistia: { options: { email } } };
    const videosForVideoCatalog = filter(reverse([...videos]), v => !v.onboardingSection); // Exclude onboarding videos from Video Catalog.
    return (
      <React.Fragment>
        <div
          className={classnames({
            [classes.root]: !isOnboarding,
            [classes.rootLite]: isOnboarding,
          })}
        >
          {!isOnboarding && (
            <IconButton className={classes.expandButton} onClick={() => this.setState({ dialogOpen: true })}>
              <ExpandLessIcon />
            </IconButton>
          )}
          <div
            className={classnames({
              [classes.playerWrapperUnstarted]: !started,
              [classes.playerWrapperStarted]: started,
            })}
          >
            <ReactPlayer
              ref={player => {
                this.player = player;
              }}
              config={config}
              width="100%"
              height="100%"
              url={url}
              playing={playing}
              onReady={this.handleReady}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              onEnded={this.handleEnded}
              onError={this.handleError}
            />
          </div>
          <FooterPlayerControls
            isOnboarding={isOnboarding}
            video={video}
            started={started}
            playing={playing}
            played={played}
            duration={duration}
            onPlayPause={this.playPause}
            onExit={() => this.reload()}
          />
        </div>
        <DialogVideoCatalog
          videos={videosForVideoCatalog}
          open={dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          onChange={this.handleVideoChange}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    user: selectCurrentUser,
  }),
  {
    dispatchSaveAccount: account => ({ type: DISPATCH_SAVE_ACCOUNT, account }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(FooterPlayer);
