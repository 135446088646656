import { call } from 'redux-saga/effects';

import { SAVE_ACTIVITY } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const saveActivity = sagaFetch.bind(null, SAVE_ACTIVITY, contactsResource.saveActivity);

export default function* sagaSaveActivity(action) {
  const { contact, activity, skipRefresh } = action;
  yield call(saveActivity, {
    contact,
    activity: {
      ...activity,
      datetime: activity.date.toDate(),
    },
  });
  if (!skipRefresh) {
    yield call(sagaRefresh, contact);
  }
}
