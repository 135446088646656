import { call } from 'redux-saga/effects';

import { FETCH_ACCOUNT } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchAccount = sagaFetch.bind(null, FETCH_ACCOUNT, accountResource.fetchAccount);

export default function* sagaFetchAccount() {
  yield call(fetchAccount);
}
