import { call } from 'redux-saga/effects';
import ReactPixel from 'react-facebook-pixel';

import { SIGN_UP } from 'actions/actionTypes';
import authResource from 'resources/authResource';
import history from 'config/history';
import configService from 'services/configService';
import sagaFetch from './sagaFetch';

// bind resource generator
const signUp = sagaFetch.bind(null, SIGN_UP, authResource.signUp);

const pixelId = configService.getFacebookPixelId();

export default function* sagaSignUp({ data }) {
  const res = yield call(signUp, { data });
  if (!res.error) {
    // In production, initialize Facebook Pixel and track signup event.
    // https://github.com/zsajjad/react-facebook-pixel
    if (process.env.NODE_ENV === 'production') {
      const { email, firstName, lastName, phone } = res.data;
      const phoneDigits = phone.replace(/\D/g, '');
      const phoneWithCountryCode = phoneDigits.length === 10 ? `1${phoneDigits}` : phoneDigits; // Assume country code is 1
      const advancedMatchingInfo = {
        em: email.toLowerCase(),
        fn: firstName.toLowerCase(),
        ln: lastName.toLowerCase(),
        ph: phoneWithCountryCode,
      };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(pixelId, advancedMatchingInfo, options);
      ReactPixel.track('CompleteRegistration');
    }

    history.push('/');
  }
}
