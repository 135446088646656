import { filter, map } from 'lodash';
import moment from 'moment';

import stringsService from 'services/stringsService';

const facebookSection = {
  section: 'prospects',
  isOnboardingSection: true,
  isImportSection: true,
  label: 'Facebook',
  title: 'Step 1: Import Facebook Friends',
  titleLite: 'Facebook Prospects',
  accept: 'application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream,application/json',
  spreadsheetType: 'FACEBOOK_FRIENDS',
  nextPathname: '/onboarding/customers',
  desktopVideoUrl: 'https://doughcrm.wistia.com/medias/iz1sriind1',
  mobileVideoUrl: 'https://doughcrm.wistia.com/medias/ze5s5tij3t',
};
const instagramSection = {
  section: 'instagram',
  isOnboardingSection: false,
  isImportSection: true,
  label: 'Instagram',
  titleLite: 'Instagram Prospects',
  accept: 'application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream,application/json',
  spreadsheetType: 'INSTAGRAM_FOLLOWERS',
};
const linkedinSection = {
  section: 'linkedin',
  isOnboardingSection: false,
  isImportSection: true,
  label: 'LinkedIn',
  titleLite: 'LinkedIn Prospects',
  accept: 'application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream,.csv',
  spreadsheetType: 'LINKEDIN_CONNECTIONS',
};

// "Import" button should display menu items in this order: Facebook, Instagram, LinkedIn, company-specific spreadsheets
const commonImportSectionsForAllCompanies = [facebookSection, instagramSection, linkedinSection];
const standardImportSections = [
  ...commonImportSectionsForAllCompanies,
  {
    section: 'customers',
    isOnboardingSection: true,
    isImportSection: true,
    title: 'Step 2: Import Customers',
    titleLite: 'Customers',
    accept: '.csv',
    spreadsheetType: 'STANDARD_CUSTOMERS',
    nextPathname: '/onboarding/consultants',
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/5h9e3wgxbk',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/1ndim1hjwm',
  },
  {
    section: 'consultants',
    isOnboardingSection: true,
    isImportSection: true,
    title: 'Step 3: Import Consultants',
    titleLite: 'Consultants',
    accept: '.csv',
    spreadsheetType: 'STANDARD_CONSULTANTS',
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/wzsrwxvonx',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/tqqjuspmun',
  },
];
const importSections = {
  PLEXUS: [
    ...commonImportSectionsForAllCompanies,
    {
      section: 'customers',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 2: Import Customers',
      titleLite: 'Customers',
      accept: '.csv',
      spreadsheetType: 'PLEXUS_CLIENTS',
      nextPathname: '/onboarding/consultants',
      // desktopVideoUrl: 'https://doughcrm.wistia.com/medias/vx0ocn9y0g', // old video
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/ixb5hl05u5',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/smoaaxv904',
    },
    {
      section: 'consultants',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 3: Import Ambassadors',
      titleLite: 'Ambassadors',
      accept: '.csv',
      spreadsheetType: 'PLEXUS_AMBASSADORS',
      // desktopVideoUrl: 'https://doughcrm.wistia.com/medias/7vgcs48tmw', // old video
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/udb4bn5l0r',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/sh3ekbtph2',
    },
  ],
  MONAT: [
    ...commonImportSectionsForAllCompanies,
    {
      section: 'customers',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 2: Import Customers',
      titleLite: 'Customers',
      accept: '.csv',
      spreadsheetType: 'MONAT_CUSTOMERS',
      nextPathname: '/onboarding/consultants',
      // desktopVideoUrl: 'https://doughcrm.wistia.com/medias/v6eexz8e7c', // old video
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/27107mmsbf',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/cqnvai8efb',
    },
    {
      section: 'consultants',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 3: Import Market Partners',
      titleLite: 'Market Partners',
      accept: '.csv',
      spreadsheetType: 'MONAT_MARKET_PARTNERS',
      // desktopVideoUrl: 'https://doughcrm.wistia.com/medias/m17qbis8sx', // old video
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/dvgqx8exu8',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/6cd2js3shk',
    },
  ],
  DOTERRA: [
    ...commonImportSectionsForAllCompanies,
    {
      section: 'customers',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 2: Import Customers',
      titleLite: 'Customers',
      accept: '.csv',
      spreadsheetType: 'DOTERRA_CUSTOMERS',
      nextPathname: '/onboarding/consultants',
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/bjpspls6jy',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/hv8mn4gryb',
    },
    {
      section: 'consultants',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 3: Import Wellness Advocates',
      titleLite: 'Wellness Advocates',
      accept: '.csv',
      spreadsheetType: 'DOTERRA_WELLNESS_ADVOCATES',
      desktopVideoUrl: 'https://doughcrm.wistia.com/medias/4cy75oq6f9',
      mobileVideoUrl: 'https://doughcrm.wistia.com/medias/zoyt6vcfh9',
    },
  ],
  ARBONNE: standardImportSections,
  BEACHBODY: standardImportSections,
  BEAUTYCOUNTER: standardImportSections,
  HEMPWORX: standardImportSections,
  ISAGENIX: standardImportSections,
  IT_WORKS: standardImportSections,
  JEUNESSE: standardImportSections,
  LE_VEL: standardImportSections,
  LIMELIFE: standardImportSections,
  MELALEUCA: standardImportSections,
  MODERE: standardImportSections,
  OPTAVIA: standardImportSections,
  OTHER: standardImportSections,
  PRUVIT: standardImportSections,
  RODAN_FIELDS: standardImportSections,
  SCENTSY: standardImportSections,
  SCOUT_CELLAR: [
    {
      section: 'connect-sc',
      isOnboardingSection: true,
      isImportSection: false,
      title: 'Step 1: Connect your S&C account',
      titleLite: 'Scout & Cellar',
      spreadsheetType: 'SCOUT_CELLAR_SYNC',
      nextPathname: '/onboarding/prospects',
    },
    {
      ...facebookSection,
      title: 'Step 2: Import Facebook Friends',
      nextPathname: null,
    },
    instagramSection,
    linkedinSection,
  ],
  SENEGENCE: standardImportSections,
  SHAKLEE: standardImportSections,
  YOUNG_LIVING: [
    {
      ...facebookSection,
      nextPathname: '/onboarding/downline',
    },
    instagramSection,
    linkedinSection,
    {
      section: 'downline',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 2: Import Downline',
      titleLite: 'Downline',
      accept: '.csv',
      spreadsheetType: 'YOUNG_LIVING_DOWNLINE',
    },
  ],
  CREW: [
    {
      ...facebookSection,
      nextPathname: '/onboarding/downline',
    },
    instagramSection,
    linkedinSection,
    {
      section: 'downline',
      isOnboardingSection: true,
      isImportSection: true,
      title: 'Step 2: Import Downline',
      titleLite: 'Downline',
      accept: '.csv',
      spreadsheetType: 'YOUNG_LIVING_DOWNLINE',
    },
  ],
  YOUNIQUE: standardImportSections,
};

// For users across all companies, display the same 6 filters: All Contacts, Prospects, Customers, Consultants, Starred, Recently Added
const filters = [
  {
    key: 'all',
    label: 'All Contacts',
    filterBy: {},
  },
  {
    key: 'prospects',
    label: 'Prospects',
    filterBy: { contactType: 'PROSPECT' },
  },
  {
    key: 'customers',
    label: 'Customers',
    filterBy: { contactType: 'CUSTOMER' },
  },
  {
    key: 'consultants',
    label: 'Consultants',
    filterBy: { contactType: 'CONSULTANT' },
  },
  {
    key: 'recently_added',
    label: 'Recently Added',
    filterBy: contact =>
      moment()
        .subtract(7, 'days')
        .isBefore(contact.createdAt),
  },
];

const contactTypeOptions = [
  {
    key: 'subheader',
    label: 'Contact Type',
    subheader: true,
  },
  {
    key: 'PROSPECT',
    label: 'Prospect',
  },
  {
    key: 'CUSTOMER',
    label: 'Customer',
  },
  {
    key: 'CONSULTANT',
    label: 'Consultant',
  },
];

function getImportSections(company) {
  return filter(importSections[company], section => section.isImportSection);
}

function getOnboardingSections(company) {
  return filter(importSections[company], section => section.isOnboardingSection);
}

function getFilters(company) {
  // Replace 'Customers', 'Consultants' label with appropriate label for the user's company.
  return map(filters, filterValue => {
    if (filterValue.key === 'customers') {
      return { ...filterValue, label: `${stringsService.getCustomersLabel(company)}` };
    }
    if (filterValue.key === 'consultants') {
      return { ...filterValue, label: `${stringsService.getConsultantsLabel(company)}` };
    }
    return filterValue;
  });
}

function getContactTypeOptions(company) {
  // Replace 'Customer', 'Consultant' label with appropriate label for the user's company.
  return map(contactTypeOptions, option => {
    if (option.key === 'CUSTOMER') {
      return { ...option, label: `${stringsService.getCustomerLabel(company)}` };
    }
    if (option.key === 'CONSULTANT') {
      return { ...option, label: `${stringsService.getConsultantLabel(company)}` };
    }
    return option;
  });
}

export default {
  getImportSections,
  getOnboardingSections,
  getFilters,
  getContactTypeOptions,
};
