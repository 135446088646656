import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import cookie from 'react-cookies';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { muiThemeDough } from 'themes';

import QuickLog from 'containers/QuickLog';
import ButtonImportContacts from 'containers/ButtonImportContacts';
import CustomToolbarSelect from './CustomToolbarSelect';

class MUIDatatable extends Component {
  static propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    options: PropTypes.shape({}),
  };

  static defaultProps = {
    title: undefined,
    options: undefined,
  };

  state = {
    openQuickLog: false,
    contactsCount: 0,
    selectableRows: [],
  };

  getMuiTheme = () =>
    createMuiTheme({
      ...muiThemeDough,
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MuiToolbar: {
          regular: {
            fontFamily: 'MuseoSans',
          },
        },
        MuiTable: {
          root: {
            fontFamily: 'MuseoSans',
            '& td:first-child': {
              background: '#fff',
            },
            '& tr:hover': {
              '& td:first-child': {
                background: '#ededed',
              },
            },
          },
        },
        MuiTypography: {
          root: {
            fontFamily: 'MuseoSans !important',
          },
        },
        MuiTableSortLabel: {
          root: {
            margin: '3px 0 0 0',
          },
        },
        MuiTableRow: {
          root: {
            '&$selected': {
              '& td:first-child': {
                background: '#f5f5f5',
              },
            },
          },
        },
        selected: {},
        MuiTableCell: {
          root: {
            padding: '4px 10px 4px 10px',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            '@media (max-width:959.95px)': {
              // eslint-disable-line no-useless-computed-key
              width: '50% !important',
              height: '44px !important',
              // lineHeight: '36px',
              fontSize: '0.8125rem !important',
            },
          },
          paddingCheckbox: {
            '@media (max-width:959.95px)': {
              // eslint-disable-line no-useless-computed-key
              width: '40px !important',
            },
          },
        },
        MUIDataTableFilter: {
          root: {
            backgroundColor: '#fff',
          },
          title: {
            fontWeight: '700',
          },
          checkboxFormControlLabel: {
            margin: '0 15px 0 0 !important',
          },
        },
        MUIDataTableToolbarSelect: {
          root: {
            backgroundColor: '#fff',
          },
        },
        MuiGridListTile: {
          root: {
            width: '100% !important',
          },
        },
        MuiMenuItem: {
          root: {
            fontFamily: 'MuseoSans',
          },
        },
        MuiChip: {
          root: {
            fontFamily: 'MuseoSans',
            '&:hover': {
              boxShadow: 'none !important',
            },
            '&:focus': {
              boxShadow: 'none !important',
            },
            '&:active': {
              boxShadow: 'none !important',
            },
          },
        },
        MuiButton: {
          root: {
            textTransform: 'inherit',
            fontFamily: 'MuseoSans',
            borderRadius: '100px',
          },
          contained: {
            boxShadow: 'none',
          },
          containedPrimary: {
            color: '#fff',
          },
        },
      },
    });

  openQuickLog = selectedRows => {
    const { data } = this.props;
    let contactsList = [];
    if (selectedRows) {
      selectedRows.data.map(item => contactsList.push(JSON.parse(data[item.dataIndex][0])));
      contactsList = contactsList.map(contact => ({
        ...contact,
        value: `${contact.id}`,
        label: contact.name,
      }));
    }
    this.setState({
      contactsList,
      openQuickLog: true,
    });
  };

  handleFocusOut = () => {
    this.setState({
      openQuickLog: false,
    });
  };

  updateContactsData = data => {
    const { contactsCount } = this.state;

    let visibleData = [];
    if (data.page > 0) {
      const fromIndex = data.page * data.rowsPerPage - 1;
      const tillIndex = (data.page + 1) * data.rowsPerPage;
      visibleData = data.displayData.filter((item, index) => index > fromIndex && index < tillIndex);
    } else {
      visibleData = data.displayData.filter((item, index) => index < data.rowsPerPage);
    }

    if (contactsCount !== data.displayData.length) {
      this.setState({
        contactsCount: data.displayData.length,
        selectableRows: visibleData,
      });
    } else {
      this.setState({
        selectableRows: visibleData,
      });
    }
  };

  render() {
    const { title, columns, data, options } = this.props;
    const { openQuickLog, contactsList, contactsCount } = this.state;

    const tableOptions = {
      ...options,
      customToolbarSelect: selectedRows => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={data}
          openQuickLog={() => this.openQuickLog(selectedRows)}
        />
      ),
      onTableChange: (action, tableState) => {
        this.updateContactsData(tableState);
        if (action === 'columnViewChange') {
          const colsVisibility = {};
          tableState.columns.map(col => {
            colsVisibility[col.name] = col.display === 'true';
            return null;
          });
          cookie.save('colsVisibility', colsVisibility, { path: '/' });
        }
      },
      isRowSelectable: dataIndex => {
        const { selectableRows } = this.state;
        let show = false;
        selectableRows.map(item => {
          if (Number(item.dataIndex) === Number(dataIndex)) {
            show = true;
          }
          return null;
        });
        return show;
      },
    };

    return (
      <React.Fragment>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          {openQuickLog ? <QuickLog contactsList={contactsList} handleFocusOut={this.handleFocusOut} /> : null}
          <ButtonImportContacts openQuickLog={this.openQuickLog} />
          <MUIDataTable title={`${title} (${contactsCount})`} data={data} columns={columns} options={tableOptions} />
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default MUIDatatable;
