import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, reset, Field, change, getFormValues } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';
import Dropzone from 'react-dropzone';
import ReactPlayer from 'react-player';
import Papa from 'papaparse';
import { find, head, isEqual, map } from 'lodash';
import moment from 'moment';

import FormTextField from 'components/FormTextField';
import FormTextFieldSensitive from 'components/FormTextFieldSensitive';
import history from 'config/history';
import { selectLoading, selectCurrentUser } from 'reducers/selectors';
import {
  DISPATCH_IMPORT_CONTACTS,
  DISPATCH_FINISH_ONBOARDING,
  DISPATCH_FETCH_CONTACT_IMPORT_STATS,
} from 'actions/actionTypes';
import deviceService from 'services/deviceService';
import validation from 'services/validationService';
import cookie from 'react-cookies';

import imgSrcMonatDownload from './monat-download.png';

const isMobileDevice = deviceService.isMobile();
const desktopAspectRatio = 16 / 9;
const mobileAspectRatio = 1 / 1;
const videoRatio = isMobileDevice ? mobileAspectRatio : desktopAspectRatio;

const styles = theme => ({
  dropzone: {
    outline: 'none',
  },
  wrapper: {
    margin: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 3.5}px`,
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  wrapperLite: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
    },
  },
  buttonUpload: {
    marginRight: theme.spacing.unit,
    minWidth: 177,
    '& svg': {
      marginRight: theme.spacing.unit,
      fontSize: 20,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  buttonSubmit: {
    marginLeft: theme.spacing.unit,
  },
  instructionsParagraph: {
    marginTop: theme.spacing.unit * 2.5,
  },
  instructionsList: {
    lineHeight: 2,
    paddingLeft: 0,
    marginLeft: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2.5,
  },
  playerWrapper: {
    marginTop: theme.spacing.unit * 2,
    position: 'relative',
    paddingTop: `${100 / videoRatio}%`,
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  spreadsheetMatchingWrapper: {
    marginTop: theme.spacing.unit * 4,
  },
  listItemWrapper: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginLeft: theme.spacing.unit * 2,
    width: '50%',
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  mainDivider: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    marginBottom: theme.spacing.unit * 4,
  },
  exampleText: {
    color: 'rgba(0, 0, 0, 0.35)',
  },
  checkmarkIcon: {
    color: green[500],
    fontSize: 16,
    marginLeft: theme.spacing.unit * 1.5,
  },
  rootInsta: {
    fontFamily: 'MuseoSans',
    maxWidth: '295px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '40px',
    margin: '0',
    padding: '5px 5px 3px 32px',
    fontSize: '14px',
    '& div': {
      background: 'transparent !important',
    },
  },
});

const instaSelectOptions = [
  { key: 'FOLLOWING', value: 'Import Following (recommended)' },
  { key: 'FOLLOWERS', value: 'Import Followers' },
];

class ImportContacts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    section: PropTypes.shape({}).isRequired,
    lite: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    onFileChanged: PropTypes.func,
    onInstagramImportTypeChanged: PropTypes.func,
    dispatchImportContacts: PropTypes.func.isRequired,
    dispatchFinishOnboarding: PropTypes.func.isRequired,
    dispathFetchContactImportStats: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    formValues: PropTypes.shape({}),
  };

  static defaultProps = {
    lite: false,
    onFileChanged: undefined,
    onInstagramImportTypeChanged: undefined,
    formValues: {},
  };

  state = {
    file: undefined,
    playing: false,
    advancedFieldsExpanded: false,
    spreadsheetHeaders: [],
    spreadsheetFirstRowData: {},
    instagramImportType: 'FOLLOWING', // Select 'FOLLOWING' instagramImportType by default
  };

  componentDidUpdate(prevProps) {
    const { section, dispathFetchContactImportStats } = this.props;
    if (!isEqual(section, prevProps.section)) {
      this.handleFileChanged();
      dispathFetchContactImportStats();
    }

    if (section.section) {
      this.changeOnboardingStep(section.section);
    }
  }

  changeOnboardingStep = step => {
    cookie.save('onboardingStep', `/onboarding/${step}`, { path: '/' });
  };

  handleFiles = files => {
    this.handleFileChanged(head(files));
  };

  handleFileChanged = file => {
    const {
      onFileChanged,
      section: { spreadsheetType },
      dispatch,
    } = this.props;
    this.setState({ file });
    this.resetFormState();
    if (onFileChanged) {
      onFileChanged(file);
    }

    // If we're importing a Standard spreadsheet, open the CSV file, and fetch the header to let the user match columns.
    // Else, do nothing (just let the user hit "Next" to trigger the import).
    const isStandardImport = spreadsheetType === 'STANDARD_CUSTOMERS' || spreadsheetType === 'STANDARD_CONSULTANTS';
    if (isStandardImport && file) {
      // Parse CSV file to fetch spreadsheet header and first row data (to populate SpreadsheetMatching form options).
      // eslint-disable-next-line no-console
      console.log('ImportContacts.handleFileChanged: Standard Import file selected - parsing csv...');
      Papa.parse(file, {
        header: true,
        download: true,
        preview: 1,
        beforeFirstChunk(chunk) {
          if (chunk.startsWith('Filter: None')) {
            // For Beachbody, remove the first 4 invalid rows.
            const rows = chunk.split(/\r\n|\r|\n/);
            const newRows = rows.slice(4);
            const newChunk = newRows.join('\n');
            return newChunk;
          }
          // Do nothing - just return the original chunk.
          return chunk;
        },
        complete: results => {
          const spreadsheetHeaders = results.meta.fields;
          const spreadsheetFirstRowData = results.data[0];
          this.setState({ spreadsheetHeaders, spreadsheetFirstRowData });
          // eslint-disable-next-line no-console
          console.log(`ImportContacts.handleFileChanged spreadsheetHeaders: ${JSON.stringify(spreadsheetHeaders)}`);
          // eslint-disable-next-line no-console
          console.log(
            `ImportContacts.handleFileChanged spreadsheetFirstRowData: ${JSON.stringify(spreadsheetFirstRowData)}`,
          );

          // Try automatically matching spreadsheet headers.
          const matchingFirstNameHeader = find(spreadsheetHeaders, h => /\s*(first|full)(\s|_)*name\s*/i.test(h));
          if (matchingFirstNameHeader) {
            dispatch(change('SpreadsheetMatching', 'first_name', matchingFirstNameHeader));
          }
          const matchingLastNameHeader = find(spreadsheetHeaders, h => /\s*last(\s|_)*name\s*/i.test(h));
          if (matchingLastNameHeader) {
            dispatch(change('SpreadsheetMatching', 'last_name', matchingLastNameHeader));
          }
          const matchingPhoneHeader = find(spreadsheetHeaders, h =>
            /\s*(tele)*(phone|mobile)(\s|_)*(number)*\s*/i.test(h));
          if (matchingPhoneHeader) {
            dispatch(change('SpreadsheetMatching', 'phone', matchingPhoneHeader));
          }
          const matchingEmailHeader = find(spreadsheetHeaders, h => /\s*e-*mail(\s|_)*(address)*\s*/i.test(h));
          if (matchingEmailHeader) {
            dispatch(change('SpreadsheetMatching', 'email', matchingEmailHeader));
          }
          const matchingAddressHeader = find(spreadsheetHeaders, h =>
            /(\s*address(\s|_)*(line)*(\s|_)*1\s*)|(\s*street(\s|_)*[1]{0,1}\s*)|(^\s*address\s*$)/i.test(h));
          if (matchingAddressHeader) {
            dispatch(change('SpreadsheetMatching', 'line1', matchingAddressHeader));
          }
          const matchingCityHeader = find(spreadsheetHeaders, h => /\s*city\s*/i.test(h));
          if (matchingCityHeader) {
            dispatch(change('SpreadsheetMatching', 'city', matchingCityHeader));
          }
          const matchingStateHeader = find(spreadsheetHeaders, h => /\s*state\s*/i.test(h));
          if (matchingStateHeader) {
            dispatch(change('SpreadsheetMatching', 'state', matchingStateHeader));
          }
          const matchingZipHeader = find(spreadsheetHeaders, h => /\s*(zip|postal)(\s|_)*(code)*\s*/i.test(h));
          if (matchingZipHeader) {
            dispatch(change('SpreadsheetMatching', 'zip_code', matchingZipHeader));
          }
          const matchingCountryHeader = find(spreadsheetHeaders, h => /\s*country\s*/i.test(h));
          if (matchingCountryHeader) {
            dispatch(change('SpreadsheetMatching', 'country', matchingCountryHeader));
          }
          const matchingWebsiteHeader = find(spreadsheetHeaders, h => /\s*website\s*/i.test(h));
          if (matchingWebsiteHeader) {
            dispatch(change('SpreadsheetMatching', 'website', matchingWebsiteHeader));
          }

          // Advanced fields
          const matchingIdHeader = find(spreadsheetHeaders, h => /\s*id\s*$/i.test(h));
          if (matchingIdHeader) {
            dispatch(change('SpreadsheetMatching', 'spreadsheet_contact_id', matchingIdHeader));
          }
          const matchingJoinDateHeader = find(spreadsheetHeaders, h =>
            /\s*(join|enrollment|entry|signup|created|activated|start)(\s|_)*date\s*/i.test(h));
          if (matchingJoinDateHeader) {
            dispatch(change('SpreadsheetMatching', 'join_date', matchingJoinDateHeader));
          }
          const matchingSponsorFirstHeader = find(spreadsheetHeaders, h =>
            /\s*sponsor(\s|_)*first(\s|_)*name\s*/i.test(h));
          if (matchingSponsorFirstHeader) {
            dispatch(change('SpreadsheetMatching', 'sponsor_first_name', matchingSponsorFirstHeader));
          }
          const matchingSponsorLastHeader = find(spreadsheetHeaders, h =>
            /\s*sponsor(\s|_)*last(\s|_)*name\s*/i.test(h));
          if (matchingSponsorLastHeader) {
            dispatch(change('SpreadsheetMatching', 'sponsor_last_name', matchingSponsorLastHeader));
          }
          const matchingLevelHeader = find(spreadsheetHeaders, h => /\s*level\s*/i.test(h));
          if (matchingLevelHeader) {
            dispatch(change('SpreadsheetMatching', 'level', matchingLevelHeader));
          }
          const matchingRankHeader = find(spreadsheetHeaders, h => /\s*rank\s*/i.test(h));
          if (matchingRankHeader) {
            dispatch(change('SpreadsheetMatching', 'rank', matchingRankHeader));
          }
          const matchingStatusHeader = find(spreadsheetHeaders, h => /\s*status\s*/i.test(h));
          if (matchingStatusHeader) {
            dispatch(change('SpreadsheetMatching', 'status', matchingStatusHeader));
          }
          const matchingPvHeader = find(spreadsheetHeaders, h => /(^\s*pv\s*$)|(\s*personal(\s|_)*volume\s*)/i.test(h));
          if (matchingPvHeader) {
            dispatch(change('SpreadsheetMatching', 'pv', matchingPvHeader));
          }
          const matchingSubscriptionHeader = find(spreadsheetHeaders, h => /\s*subscription\s*/i.test(h));
          if (matchingSubscriptionHeader) {
            dispatch(change('SpreadsheetMatching', 'subscription', matchingSubscriptionHeader));
          }
          const matchingLastActiveDateHeader = find(spreadsheetHeaders, h =>
            /\s*last(\s|_)*(active|activity|order)(\s|_)*date\s*/i.test(h));
          if (matchingLastActiveDateHeader) {
            dispatch(change('SpreadsheetMatching', 'last_active_date', matchingLastActiveDateHeader));
          }
        },
      });
    }
  };

  handleSkip = nextPathname => {
    const { dispatchFinishOnboarding } = this.props;
    if (!nextPathname) {
      dispatchFinishOnboarding();
    } else {
      history.push(nextPathname);
    }
  };

  handleImportSubmit = ({ spreadsheetType, titleLite, nextPathname }) => {
    // eslint-disable-next-line no-console
    console.log(`ImportContacts.handleImportSubmit spreadsheetType: ${spreadsheetType}`);
    const { dispatchImportContacts } = this.props;
    const { file } = this.state;

    dispatchImportContacts(file, spreadsheetType, titleLite, nextPathname);
  };

  handleStandardImportSubmit = values => {
    const {
      section: { spreadsheetType, titleLite, nextPathname },
      dispatchImportContacts,
      // onSpreadsheetMatchingFormSubmit,
    } = this.props;
    const { file } = this.state;
    const spreadsheetHeaders = values;

    // eslint-disable-next-line no-console
    console.log(
      `ImportContacts.handleStandardImportSubmit spreadsheetType: ${spreadsheetType}, spreadsheetHeaders: ${JSON.stringify(
        spreadsheetHeaders,
      )}`,
    );

    // Call dispatchImportContacts, passing all selected form values (spreadsheet column headers).
    dispatchImportContacts(file, spreadsheetType, titleLite, nextPathname, spreadsheetHeaders);
  };

  handlePlayerReady = () => {
    // TODO: Confirm if disabling auto-play for all Import screens (onboarding and Import modals).
    this.setState({ playing: false });
  };

  handlePlayerError = error => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  resetFormState = () => {
    const { dispatch } = this.props;
    dispatch(reset('SpreadsheetMatching'));
    this.setState({ advancedFieldsExpanded: false });
  };

  toggleAdvancedFields = () => {
    const { advancedFieldsExpanded } = this.state;
    this.setState({ advancedFieldsExpanded: !advancedFieldsExpanded });
  };

  handleInstaSelectChange = item => {
    const { onInstagramImportTypeChanged } = this.props;

    this.setState(
      {
        instagramImportType: item,
      },
      () => {
        onInstagramImportTypeChanged(item);
      },
    );
  };

  renderPlayer() {
    const {
      classes,
      currentUser: { email },
      section: { desktopVideoUrl, mobileVideoUrl },
    } = this.props;
    const { playing } = this.state;
    const config = { wistia: { options: { email } } };
    const url = isMobileDevice ? mobileVideoUrl : desktopVideoUrl;

    return (
      <div className={classes.playerWrapper}>
        <ReactPlayer
          ref={player => {
            this.player = player;
          }}
          className={classes.player}
          config={config}
          width="100%"
          height="100%"
          controls
          url={url}
          playing={playing}
          onReady={this.handlePlayerReady}
          onError={this.handlePlayerError}
        />
      </div>
    );
  }

  renderFacebookInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a href="https://www.facebook.com/dyi" target="_blank" rel="noopener noreferrer">
                here
              </a>
            </b>{' '}
            and scroll down to <b>Download Your Information</b>
          </li>
          <li>
            Tap <b>DESELECT ALL</b> and select only <b>Friends</b>
          </li>
          <li>
            Scroll down to change <b>HTML</b> format to <b>JSON</b>
          </li>
          <li>
            Tap <b>CREATE FILE</b> and wait 2 minutes for it to finish
          </li>
          <li>
            <b>Reload</b> that FB page and tap <b>AVAILABLE COPIES</b>
          </li>
          <li>
            Tap <b>Download</b> and <b>save</b> the file. Don{"'"}t open it!
          </li>
          <li>
            Tap <b>Choose File</b> below and find <b>facebook-[firstlast].zip</b> or <b>friends.json</b>
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a href="https://www.facebook.com/dyi" target="_blank" rel="noopener noreferrer">
              here
            </a>
          </b>{' '}
          and go to <b>Download Your Information</b>
        </li>
        <li>
          Change <b>HTML</b> format to <b>JSON</b>
        </li>
        <li>
          Click <b>Deselect All</b> and select only <b>Friends</b>
        </li>
        <li>
          Click <b>Create File</b> and wait 2 minutes for it to finish
        </li>
        <li>
          <b>Reload</b> that FB page and click <b>Available Copies</b>
        </li>
        <li>
          Click <b>Download</b> and <b>save</b> the file. Don{"'"}t open it!
        </li>
        <li>
          Click <b>Choose File</b> below and find <b>facebook-[firstlast].zip</b> or <b>friends.json</b>
        </li>
      </ol>
    );
  }

  renderInstagramInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a href="https://www.instagram.com/download/request" target="_blank" rel="noopener noreferrer">
                here
              </a>
            </b>{' '}
            to download your IG data
          </li>
          <li>
            Tap <b>Next</b> {'>'} <b>Request Download</b>
          </li>
          <li>Wait 5-10min and check email for download link</li>
          <li>
            Tap <b>Download Data</b> {'>'} <b>Download</b>
          </li>
          <li>
            Tap <b>Choose File</b> below and browse for <b>[IGhandle]_[date].zip</b> (only Part 1 is needed if you see
            multiple zip files)
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a href="https://www.instagram.com/download/request" target="_blank" rel="noopener noreferrer">
              here
            </a>
          </b>{' '}
          to download your IG data
        </li>
        <li>
          Click <b>Next</b> {'>'} <b>Request Download</b>
        </li>
        <li>Wait 5-10min and check email for download link</li>
        <li>
          Click <b>Download Data</b> and save the file. Don{"'"}t open it!
        </li>
        <li>
          Click <b>Choose File</b> below and find <b>[IGhandle]_[date].zip</b> (only Part 1 is needed if you see
          multiple zip files)
        </li>
      </ol>
    );
  }

  renderLinkedinInstructions() {
    const requestDesktopSiteInstruction = deviceService.isSafari() ? (
      <li>
        Tap top left <b>AA</b> {'>'} <b>Request Desktop Website</b>
      </li>
    ) : (
      <li>
        Tap the 3 dots in bottom right {'>'} <b>Request Desktop Site</b>
      </li>
    );

    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a href="https://www.linkedin.com/psettings/member-data" target="_blank" rel="noopener noreferrer">
                here
              </a>
            </b>{' '}
            for LinkedIn data
          </li>
          {requestDesktopSiteInstruction}
          <li>
            On LinkedIn, select <b>Want something in particular?</b> {'>'} <b>Connections</b> {'>'}{' '}
            <b>Request Archive</b>
          </li>
          <li>Check email after 15min and tap download link there</li>
          {requestDesktopSiteInstruction}
          <li>
            Tap <b>Download archive</b> {'>'} <b>Download</b>
          </li>
          <li>
            Tap <b>Choose File</b> below and browse for <b>Basic_LinkedInDataExport_[date].zip</b>
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a href="https://www.linkedin.com/psettings/member-data" target="_blank" rel="noopener noreferrer">
              here
            </a>
          </b>{' '}
          for LinkedIn data
        </li>
        <li>
          Select <b>Want something in particular?</b> {'>'} <b>Connections</b> {'>'} <b>Request Archive</b>
        </li>
        <li>Check email after 15min and click download link there</li>
        <li>
          Click <b>Download archive</b> and save the file. Don{"'"}t open it!
        </li>
        <li>
          Click <b>Choose File</b> below and find <b>Basic_LinkedInDataExport_[date].zip</b> or <b>Connections.csv</b>
        </li>
      </ol>
    );
  }

  renderPlexusCustomersInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a href="https://backoffice.plexusworldwide.com/my-business/pv" target="_blank" rel="noopener noreferrer">
                here
              </a>
            </b>{' '}
            for PV report
          </li>
          <li>
            Scroll down and tap <b>EXPORT DATA</b>
          </li>
          <li>
            Save and upload <b>report.csv</b> below
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a href="https://backoffice.plexusworldwide.com/my-business/pv" target="_blank" rel="noopener noreferrer">
              here
            </a>
          </b>{' '}
          for PV report
        </li>
        <li>
          Scroll down and click <b>EXPORT DATA</b>
        </li>
        <li>
          Save and upload <b>report.csv</b> below
        </li>
      </ol>
    );
  }

  renderPlexusConsultantsInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a
                href="https://backoffice.plexusworldwide.com/my-business/points-rank-up"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </b>{' '}
            {'for Points & Rank Up report'}
          </li>
          <li>
            Scroll down and tap <b>EXPORT DATA</b> (you may have to allow pop-ups)
          </li>
          <li>
            Save and upload <b>rankup-detail-[plexusid].csv</b> below
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a
              href="https://backoffice.plexusworldwide.com/my-business/points-rank-up"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>{' '}
          {'for Points & Rank Up report'}
        </li>
        <li>
          Scroll down and click <b>EXPORT DATA</b> (you may have to allow pop-ups)
        </li>
        <li>
          Save and upload <b>rankup-detail-[plexusid].csv</b> below
        </li>
      </ol>
    );
  }

  renderMonatCustomersInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a
                href="https://mymonat.com/reports/run_report/59959011072ddb56ab0000db/run"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </b>{' '}
            {'for Customers in back office'}
          </li>
          <li>
            Tap <b>Add/Remove Columns</b> and select all columns
          </li>
          <li>
            Tap <img alt="Download" src={imgSrcMonatDownload} height="15" width="15" /> then <b>Csv</b> to download (you
            may have to allow pop-ups)
          </li>
          <li>
            <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
          </li>
          <li>
            Tap <b>Choose File</b> below and find <b>Customer-Search_[date].csv</b>
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a
              href="https://mymonat.com/reports/run_report/59959011072ddb56ab0000db/run"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>{' '}
          {'for Customers in back office'}
        </li>
        <li>
          Click <b>Add/Remove Columns</b> and select all columns
        </li>
        <li>
          Click <img alt="Download" src={imgSrcMonatDownload} height="15" width="15" /> then <b>Csv</b> to download (you
          may have to allow pop-ups)
        </li>
        <li>
          <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
        </li>
        <li>
          Click <b>Choose File</b> below and find <b>Customer-Search_[date].csv</b>
        </li>
      </ol>
    );
  }

  renderMonatConsultantsInstructions() {
    const { classes } = this.props;
    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a
                href="https://mymonat.com/reports/run_report/59959005072ddb56ab000014/run"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </b>{' '}
            {'for Market Partners in back office'}
          </li>
          <li>
            Tap <b>Add/Remove Columns</b> and select all columns
          </li>
          <li>
            Tap <img alt="Download" src={imgSrcMonatDownload} height="15" width="15" /> then <b>Csv</b> to download (you
            may have to allow pop-ups)
          </li>
          <li>
            <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
          </li>
          <li>
            Tap <b>Choose File</b> below and find <b>Market-Partner-Search_[date].csv</b>
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a
              href="https://mymonat.com/reports/run_report/59959005072ddb56ab000014/run"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </b>{' '}
          {'for Market Partners in back office'}
        </li>
        <li>
          Click <b>Add/Remove Columns</b> and select all columns
        </li>
        <li>
          Click <img alt="Download" src={imgSrcMonatDownload} height="15" width="15" /> then <b>Csv</b> to download (you
          may have to allow pop-ups)
        </li>
        <li>
          <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
        </li>
        <li>
          Click <b>Choose File</b> below and find <b>Market-Partner-Search_[date].csv</b>
        </li>
      </ol>
    );
  }

  renderDoterraCustomersContent() {
    const {
      classes,
      section: { title },
      lite,
    } = this.props;
    if (isMobileDevice) {
      const instructions = lite
        ? 'Since doTERRA spreadsheets can only be downloaded on your computer (not your phone), please switch over to a computer to import your customers.'
        : 'Since doTERRA spreadsheets can only be downloaded on your computer (not your phone), please skip this step for now. You can import your customers later!';
      return (
        <React.Fragment>
          {!lite && (
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          )}
          {this.renderPlayer()}
          <Typography className={classes.instructionsParagraph} component="div" variant="body2" gutterBottom>
            {instructions}
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {!lite && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}
        {this.renderPlayer()}
        <Typography component="div" variant="body2" gutterBottom>
          <ol className={classes.instructionsList}>
            <li>
              Click{' '}
              <b>
                <a
                  href="https://www.mydoterra.com/index.cfm?Fuseaction=evo_Modules.DetailedGenealogy&Reset=1&MailingListDownline=1&DetailType=EnrollerMailLabel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </b>{' '}
              {'to download mailing labels'}
            </li>
            <li>
              Select Levels <b>0</b> to <b>1</b> for personal enrollments
            </li>
            <li>
              Select Type <b>Enroller Mailing Labels</b>
            </li>
            <li>
              Select Members <b>Wholesale Customers Only</b>
            </li>
            <li>
              Click <b>Update Report</b> on the right
            </li>
            <li>
              Click <b>Create Mailing Labels</b> at the bottom
            </li>
            <li>
              <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
            </li>
            <li>
              Click <b>Choose File</b> below and find latest <b>DetailedGenealogy.csv</b>
            </li>
          </ol>
        </Typography>
      </React.Fragment>
    );
  }

  renderDoterraConsultantsContent() {
    const {
      classes,
      section: { title },
      lite,
    } = this.props;
    if (isMobileDevice) {
      const instructions = lite
        ? 'Since doTERRA spreadsheets can only be downloaded on your computer (not your phone), please switch over to a computer to import your Wellness Advocates.'
        : 'Since doTERRA spreadsheets can only be downloaded on your computer (not your phone), please skip this step for now. You can import your Wellness Advocates later!';
      return (
        <React.Fragment>
          {!lite && (
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          )}
          {this.renderPlayer()}
          <Typography className={classes.instructionsParagraph} component="div" variant="body2" gutterBottom>
            {instructions}
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {!lite && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}
        {this.renderPlayer()}
        <Typography component="div" variant="body2" gutterBottom>
          <ol className={classes.instructionsList}>
            <li>
              Click{' '}
              <b>
                <a
                  href="https://www.mydoterra.com/index.cfm?Fuseaction=evo_Modules.DetailedGenealogy&Reset=1&MailingListDownline=1&DetailType=EnrollerMailLabel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </b>{' '}
              {'to download mailing labels'}
            </li>
            <li>
              Select Levels <b>0</b> to <b>1</b> for personal enrollments
            </li>
            <li>
              Select Type <b>Enroller Mailing Labels</b>
            </li>
            <li>
              Select Members <b>Wellness Advocate Only</b>
            </li>
            <li>
              Click <b>Update Report</b> on the right
            </li>
            <li>
              Click <b>Create Mailing Labels</b> at the bottom
            </li>
            <li>
              <b>Save</b> or <b>download</b> the csv file. Don{"'"}t open it!
            </li>
            <li>
              Click <b>Choose File</b> below and find latest <b>DetailedGenealogy.csv</b>
            </li>
          </ol>
        </Typography>
      </React.Fragment>
    );
  }

  renderYoungLivingDownlineInstructions() {
    const { classes } = this.props;

    // If current browser time is before 12pm, time of day for mobile CSV scheduling is Morning. Else, it is Evening.
    let timeOfDay = 'Evening';
    if (moment().isBetween(moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss'))) {
      timeOfDay = 'Morning';
    }

    if (isMobileDevice) {
      return (
        <ol className={classes.instructionsList}>
          <li>
            Tap{' '}
            <b>
              <a href="https://www.youngliving.com/vo/#/organization" target="_blank" rel="noopener noreferrer">
                here
              </a>
            </b>{' '}
            {'for organization report'}
          </li>
          <li>
            Tap <b>Advanced</b> {'>'} <b>Schedule...</b> {'>'} <b>Daily</b> {'>'} <b>{timeOfDay}</b>
          </li>
          <li>
            Tap <b>Choose...</b> columns {'>'} check off ALL boxes {'>'} <b>Save</b>
          </li>
          <li>
            Check your email for <b>Young Living Reports: All Accounts</b> and save the attached file. Don{"'"}t open
            it!
          </li>
          <li>
            Tap <b>Choose File</b> below and browse for <b>All_Accounts-[date].CSV</b>
          </li>
        </ol>
      );
    }

    return (
      <ol className={classes.instructionsList}>
        <li>
          Click{' '}
          <b>
            <a href="https://www.youngliving.com/vo/#/organization" target="_blank" rel="noopener noreferrer">
              here
            </a>
          </b>{' '}
          {'for organization report'}
        </li>
        <li>
          Click <b>Advanced</b> {'>'} <b>Download Report</b> {'>'} check off ALL boxes {'>'} <b>Download</b>
        </li>
        <li>Save the file. Don{"'"}t open it!</li>
        <li>
          Click <b>Choose File</b> below and find <b>All_Accounts-[date].CSV</b>
        </li>
      </ol>
    );
  }

  renderStandardCustomersInstructions() {
    const { classes, lite } = this.props;
    return (
      <React.Fragment>
        <Typography component="div" variant="body2" gutterBottom>
          <ol className={classes.instructionsList}>
            <li>
              Find your <b>customer spreadsheet</b> in your back office or create your own
            </li>
            <li>
              Make sure it contains <b>only your customers</b>, not your downline
            </li>
            <li>
              Make sure it contains <b>their names</b> at the very least and <b>column headers in the first row</b>
            </li>
            <li>
              Save in <b>CSV format</b> and upload below
            </li>
          </ol>
        </Typography>
        {!lite && (
          <Typography component="div" variant="body2" paragraph>
            Tip: You can always skip this step and import customers later!
          </Typography>
        )}
      </React.Fragment>
    );
  }

  renderStandardConsultantsInstructions() {
    const { classes, lite } = this.props;
    return (
      <React.Fragment>
        <Typography component="div" variant="body2" gutterBottom>
          <ol className={classes.instructionsList}>
            <li>
              Find your <b>consultant spreadsheet</b> in your back office or create your own
            </li>
            <li>
              Make sure it contains <b>only the downline you want to work with</b>
            </li>
            <li>
              Make sure it contains <b>their names</b> at the very least and <b>column headers in the first row</b>
            </li>
            <li>
              Save in <b>CSV format</b> and upload below
            </li>
          </ol>
        </Typography>
        {!lite && (
          <Typography component="div" variant="body2" paragraph>
            Tip: You can always skip this step and import consultants later!
          </Typography>
        )}
      </React.Fragment>
    );
  }

  renderStandardSpreadsheetMatchingForm() {
    const { classes, formValues } = this.props;
    const { advancedFieldsExpanded, spreadsheetHeaders, spreadsheetFirstRowData } = this.state;
    const fieldOptions = map(spreadsheetHeaders, value => (
      <MenuItem key={value} value={value}>
        <Typography noWrap>
          {value}
          <Typography className={classes.exampleText} component="span" inline>
            <i>{` (ex: ${spreadsheetFirstRowData[value]})`}</i>
          </Typography>
        </Typography>
      </MenuItem>
    ));

    return (
      <form className={classes.spreadsheetMatchingWrapper}>
        <Divider className={classes.mainDivider} />
        <Typography variant="h6" gutterBottom>
          Describe your spreadsheet
        </Typography>

        <Typography component="div" variant="body2" paragraph>
          Which column from your spreadsheet best matches each field?
        </Typography>

        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="first_name"
            label="First/Full name (required)"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.first_name && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="last_name"
            label="Last name"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.last_name && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="phone"
            label="Phone"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.phone && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="email"
            label="Email"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.email && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="line1"
            label="Address"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.line1 && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="city"
            label="City"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.city && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="state"
            label="State"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.state && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="zip_code"
            label="Zip code"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.zip_code && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="country"
            label="Country"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.country && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            component={FormTextFieldSensitive}
            variant="outlined"
            name="website"
            label="Website"
            margin="normal"
            select
            className={classes.field}
            InputLabelProps={{ shrink: true }}
          >
            {fieldOptions}
          </Field>
          {formValues.website && <CheckCircleIcon className={classes.checkmarkIcon} />}
        </div>

        <List className={classes.listWrapper}>
          <ListItem className={classes.listItemWrapper} button onClick={() => this.toggleAdvancedFields()}>
            <ListItemText>Advanced</ListItemText>
            {advancedFieldsExpanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
          </ListItem>
          <Divider className={classes.divider} />
          <Collapse in={advancedFieldsExpanded} timeout="auto" unmountOnExit>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="spreadsheet_contact_id"
                label="ID"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.spreadsheet_contact_id && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="join_date"
                label="Join date"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.join_date && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="sponsor_first_name"
                label="Sponsor first name"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.sponsor_first_name && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="sponsor_last_name"
                label="Sponsor last name"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.sponsor_last_name && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="level"
                label="Level"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.level && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="rank"
                label="Rank"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.rank && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="status"
                label="Status"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.status && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="pv"
                label="PV"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.pv && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="subscription"
                label="Subscription"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.subscription && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
            <div className={classes.fieldWrapper}>
              <Field
                component={FormTextFieldSensitive}
                variant="outlined"
                name="last_active_date"
                label="Last active"
                margin="normal"
                select
                className={classes.field}
                InputLabelProps={{ shrink: true }}
              >
                {fieldOptions}
              </Field>
              {formValues.last_active_date && <CheckCircleIcon className={classes.checkmarkIcon} />}
            </div>
          </Collapse>
        </List>
      </form>
    );
  }

  render() {
    const {
      classes,
      loading,
      section: { accept, titleLite, spreadsheetType, nextPathname, title },
      lite,
      handleSubmit,
      pristine,
      invalid,
    } = this.props;
    const { file, spreadsheetHeaders, instagramImportType } = this.state;
    const isStandardImport = spreadsheetType === 'STANDARD_CUSTOMERS' || spreadsheetType === 'STANDARD_CONSULTANTS';

    return (
      <React.Fragment>
        <div
          className={classnames(classes.wrapper, {
            [classes.wrapperLite]: lite,
          })}
        >
          {spreadsheetType === 'FACEBOOK_FRIENDS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderFacebookInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'INSTAGRAM_FOLLOWERS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title || titleLite}
                </Typography>
              )}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderInstagramInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'LINKEDIN_CONNECTIONS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title || titleLite}
                </Typography>
              )}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderLinkedinInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'PLEXUS_CLIENTS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderPlexusCustomersInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'PLEXUS_AMBASSADORS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderPlexusConsultantsInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'MONAT_CUSTOMERS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderMonatCustomersInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'MONAT_MARKET_PARTNERS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderMonatConsultantsInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'DOTERRA_CUSTOMERS' && this.renderDoterraCustomersContent()}
          {spreadsheetType === 'DOTERRA_WELLNESS_ADVOCATES' && this.renderDoterraConsultantsContent()}
          {spreadsheetType === 'YOUNG_LIVING_DOWNLINE' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderYoungLivingDownlineInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'STANDARD_CUSTOMERS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderStandardCustomersInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {spreadsheetType === 'STANDARD_CONSULTANTS' && (
            <React.Fragment>
              {!lite && (
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
              )}
              {this.renderPlayer()}
              <Typography component="div" variant="body2" gutterBottom>
                {this.renderStandardConsultantsInstructions()}
              </Typography>
            </React.Fragment>
          )}
          {// Do not display "Choose File" button for mobile doTERRA customer/consultant steps.
          !(
            isMobileDevice &&
            (spreadsheetType === 'DOTERRA_CUSTOMERS' || spreadsheetType === 'DOTERRA_WELLNESS_ADVOCATES')
          ) && (
            <div className={classes.row}>
              <Dropzone accept={accept} multiple={false} onDrop={this.handleFiles}>
                {({ getRootProps, getInputProps, open }) => (
                  <div
                    {...getRootProps({
                      onClick: evt => evt.stopPropagation(),
                      onKeyDown: evt => {
                        if (evt.keyCode === 32 || evt.keyCode === 13) {
                          evt.stopPropagation();
                        }
                      },
                    })}
                    className={classes.dropzone}
                  >
                    <input {...getInputProps()} />
                    <Button
                      className={classes.buttonUpload}
                      variant="outlined"
                      color="primary"
                      disabled={loading}
                      onClick={() => open()}
                    >
                      <CloudUploadIcon className={classes.icon} />
                      Choose File
                    </Button>
                  </div>
                )}
              </Dropzone>
              {file && (
                <Typography component="i" noWrap>
                  {file.name}
                </Typography>
              )}
            </div>
          )}

          {// For Instagram, display dropdown to select Followers/Following.
          spreadsheetType === 'INSTAGRAM_FOLLOWERS' && (
            <Field
              component={FormTextField}
              name="instaSelect"
              margin="normal"
              fullWidth
              select
              input={{
                value: instagramImportType,
                onChange: this.handleInstaSelectChange,
              }}
              InputProps={{
                classes: {
                  root: classes.rootInsta,
                },
                disableUnderline: true,
              }}
            >
              {map(instaSelectOptions, option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Field>
          )}

          {// For Standard Version/Generic CSV import, once user has selected a file, display a form to let the user match column headers.
          isStandardImport && file && spreadsheetHeaders && this.renderStandardSpreadsheetMatchingForm()}

          {!lite && (
            <div className={classes.actions}>
              <Button onClick={() => this.handleSkip(nextPathname)} disabled={loading}>
                Skip
              </Button>

              {isStandardImport ? (
                <Button
                  className={classes.buttonSubmit}
                  variant="contained"
                  color="primary"
                  disabled={!file || loading || pristine || invalid}
                  type="submit"
                  onClick={handleSubmit(values => this.handleStandardImportSubmit(values))}
                >
                  {!nextPathname ? 'Done' : 'Next'}
                </Button>
              ) : (
                <Button
                  className={classes.buttonSubmit}
                  variant="contained"
                  color="primary"
                  disabled={!file || loading}
                  onClick={() => this.handleImportSubmit({ spreadsheetType, titleLite, nextPathname })}
                >
                  {!nextPathname ? 'Done' : 'Next'}
                </Button>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const formValuesSelector = state => getFormValues('SpreadsheetMatching')(state);
const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    currentUser: selectCurrentUser,
    formValues: formValuesSelector,
  }),
  {
    dispatchImportContacts: (file, spreadsheetType, label, pathSuccess, spreadsheetHeaders) => ({
      type: DISPATCH_IMPORT_CONTACTS,
      file,
      spreadsheetType,
      label,
      pathSuccess,
      spreadsheetHeaders,
    }),
    dispatchFinishOnboarding: () => ({ type: DISPATCH_FINISH_ONBOARDING }),
    dispathFetchContactImportStats: () => ({ type: DISPATCH_FETCH_CONTACT_IMPORT_STATS }),
  },
);

const withForm = reduxForm({
  form: 'SpreadsheetMatching',
  validate: validation.createValidator({
    first_name: [validation.required, validation.unique],
    last_name: validation.unique,
    phone: validation.unique,
    email: validation.unique,
    line1: validation.unique,
    city: validation.unique,
    state: validation.unique,
    zip_code: validation.unique,
    country: validation.unique,
    website: validation.unique,
    spreadsheet_contact_id: validation.unique,
    join_date: validation.unique,
    sponsor_first_name: validation.unique,
    sponsor_last_name: validation.unique,
    level: validation.unique,
    rank: validation.unique,
    status: validation.unique,
    pv: validation.unique,
    subscription: validation.unique,
    last_active_date: validation.unique,
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(ImportContacts);
