import merge from 'lodash/merge';

import { SIGN_IN, SIGN_OUT, FETCH_ACCOUNT } from 'actions/actionTypes';

const initialState = {
  user: undefined,
};

export default function createAuthReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      // SIGN_IN
      case SIGN_IN.SUCCESS:
      case FETCH_ACCOUNT.SUCCESS:
        return merge({}, state, {
          user: action.normalized.result,
        });
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
