import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import createAppStatusReducer from 'reducers/createAppStatusReducer';
import createMessageReducer from 'reducers/createMessageReducer';
import createEntitiesReducer from 'reducers/createEntitiesReducer';
import createStatsReducer from 'reducers/createStatsReducer';
import createWorkListReducer from 'reducers/createWorkListReducer';
import createRecentActivityReducer from 'reducers/createRecentActivityReducer';
import createAuthReducer from './createAuthReducer';
import createActiveRemindersReducer from './createActiveRemindersReducer';
import createS3Reducer from './createS3Reducer';

const rootReducer = combineReducers({
  auth: createAuthReducer(),
  appStatus: createAppStatusReducer(),
  message: createMessageReducer(),
  entities: createEntitiesReducer(),
  stats: createStatsReducer(),
  workList: createWorkListReducer(),
  activeReminders: createActiveRemindersReducer(),
  recentActivity: createRecentActivityReducer(),
  S3: createS3Reducer(),
  form,
});

export default rootReducer;
