import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  buttonDelete: {
    color: theme.palette.error.main,
  },
});

class DialogConfirmSkip extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const { classes, onSubmit, onCancel, title, text, ...other } = this.props;
    return (
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onCancel}
        {...other}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} autoFocus>
            Cancel
          </Button>
          <Button className={classes.buttonDelete} onClick={onSubmit}>
            Skip
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(withStyles(styles))(DialogConfirmSkip);
