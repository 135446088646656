import moment from 'moment';

function humanizeFrequency(days) {
  if (!days) {
    return 'Never';
  }
  // TODO: customize moment relative time?
  // http://momentjs.com/docs/#/customization/relative-time/
  let label;
  if (days === 7) {
    label = 'week';
  } else {
    const weeks = Math.round(days / 7);
    if (weeks > 1 && weeks < 4) {
      label = `${weeks} weeks`;
    } else {
      label = moment.duration(days, 'days').humanize();
    }
  }
  return `Every ${label}`.replace(' a ', ' ');
}

export default {
  humanizeFrequency,
};
