import { call } from 'redux-saga/effects';

import { FETCH_WORK_LIST } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchWorkList = sagaFetch.bind(null, FETCH_WORK_LIST, contactsResource.fetchWorkList);

export default function* sagaFetchWorkList() {
  yield call(fetchWorkList);
}
