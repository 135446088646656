import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';

import { muiThemeDark } from 'themes';
import routerService from 'services/routerService';
import FooterPlayer from './FooterPlayer';
import FooterSummary from './FooterSummary';

const styles = theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit,
  },
});

class Footer extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    location: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const { classes, className, location } = this.props;
    const isOnboarding = routerService.isOnboarding(location);
    return (
      <MuiThemeProvider theme={muiThemeDark}>
        <AppBar position="fixed" color="primary" className={classnames(classes.root, className)}>
          <Grid container justify="center" className={classes.content}>
            <Grid item sm={6} xs={12}>
              <FooterPlayer location={location} />
            </Grid>
            {!isOnboarding && (
              <Grid item sm={6} xs={12}>
                <FooterSummary />
              </Grid>
            )}
          </Grid>
        </AppBar>
      </MuiThemeProvider>
    );
  }
}

export default compose(withStyles(styles))(Footer);
