import { detect } from 'detect-browser';

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i.test(
    navigator.userAgent,
  );
}

function isIos() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isIosPWA() {
  const isInStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone;
  return isIos() && isInStandaloneMode;
}

function isAndroidPWA() {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
  return isAndroid && isInStandaloneMode;
}

function isSafari() {
  const browser = detect();
  return browser.name === 'safari';
}

export default {
  isMobile,
  isIos,
  isIosPWA,
  isAndroidPWA,
  isSafari,
};
