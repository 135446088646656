import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import history from 'config/history';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import FormTextField from 'components/FormTextField';
import validation from 'services/validationService';
import { DISPATCH_SAVE_CONTACT } from 'actions/actionTypes';

const styles = theme => ({
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
      maxHeight: '80vh',
      minWidth: 600,
    },
  },
});

class DialogContactEdit extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    contact: PropTypes.shape({ id: PropTypes.number }),
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchSaveContact: PropTypes.func.isRequired,
    onSectionChange: PropTypes.func,
  };

  static defaultProps = {
    onSectionChange: () => {},
    contact: {},
  };

  componentDidMount() {
    const { initialize, contact } = this.props;
    initialize(contact);
  }

  componentDidUpdate(prevProps) {
    const { initialize, contact } = this.props;
    if (contact && contact !== prevProps.contact) {
      initialize(contact);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      dispatchSaveContact,
      handleSubmit,
      pristine,
      submitting,
      open,
      onClose,
      onSectionChange,
    } = this.props;

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle>Edit Contact</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(values => dispatchSaveContact(values))}>
            <Field
              component={FormTextField}
              name="firstName"
              label="First Name"
              margin="normal"
              variant="outlined"
              fullWidth
              autoFocus
            />
            <Field
              component={FormTextField}
              name="lastName"
              label="Last Name"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="instagramUsername"
              label="Instagram Handle"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="phoneNumber"
              label="Phone"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field component={FormTextField} name="email" label="Email" margin="normal" variant="outlined" fullWidth />
            <Field
              component={FormTextField}
              name="address.line1"
              label="Address Street 1"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="address.line2"
              label="Address Street 2"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="address.city"
              label="City"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="address.state"
              label="State"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="address.zipCode"
              label="Zip Code"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="address.country"
              label="Country"
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Field
              component={FormTextField}
              name="website"
              label="Website"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
            onClick={handleSubmit(values => {
              dispatchSaveContact(values);
              onSectionChange('info');
            })}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const withConnect = connect(
  null,
  {
    dispatchSaveContact: contact => ({ type: DISPATCH_SAVE_CONTACT, contact }),
  },
);

const withForm = reduxForm({
  form: 'DialogContactEdit',
  validate: validation.createValidator({
    firstName: validation.required,
    email: validation.email,
    website: validation.website,
  }),
});

export default compose(
  withConnect,
  withForm,
  withMobileDialog(),
  withStyles(styles),
)(DialogContactEdit);
