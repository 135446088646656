import { filter, values } from 'lodash';

const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0];

function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
  return false;
}

function unique(value, allValues) {
  const formValues = values(allValues);
  const matchingFormValues = filter(formValues, v => v === value);
  if (matchingFormValues.length > 1) {
    return `Please match "${value}" only once`;
  }
  return false;
}

function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
  return false;
}

function emailConfirmation(value, allValues) {
  if (value !== allValues.email) {
    return 'Emails do not match';
  }
  return false;
}

function password(value) {
  if (!isEmpty(value) && value.length < 8) {
    return 'Password must be at least 8 characters';
  }
  return false;
}

function passwordConfirmation(value, allValues) {
  if (value !== allValues.password) {
    return 'Passwords do not match';
  }
  return false;
}

function accessCode(value, allValues) {
  // When accessCode field is displayed, it is REQUIRED for all companies except S&C.
  if (allValues.company !== 'SCOUT_CELLAR' && isEmpty(value)) {
    return 'Required';
  }
  return false;
}

function website(value) {
  if (
    !isEmpty(value) &&
    !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/i.test(
      value,
    )
  ) {
    return 'Invalid website';
  }
  return false;
}

function createValidator(rules) {
  return value => {
    const data = value.toJS ? value.toJS() : value;
    const errors = {};
    Object.keys(rules).forEach(key => {
      const rule = join([].concat(rules[key]));
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export default {
  required,
  unique,
  email,
  emailConfirmation,
  password,
  passwordConfirmation,
  accessCode,
  website,
  createValidator,
};
