import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Confetti from 'react-dom-confetti';
import { map, isEqual } from 'lodash';
import history from 'config/history';

import stringsService from 'services/stringsService';
import { DISPATCH_FETCH_WORK_LIST, DISPATCH_INCREASE_WORK_LIST_FACTOR } from 'actions/actionTypes';
import {
  selectLoading,
  selectWorkList,
  selectWorkListSuccess,
  selectWorkListGroupedByType,
  selectCurrentUser,
} from 'reducers/selectors';
import DialogConfirm from 'components/DialogConfirm';
import imgSrcConfetti from './confetti.png';

const styles = theme => ({
  confetti: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
    '& p': {
      fontWeight: 'bold',
      marginBottom: theme.spacing.unit * 4,
      marginLeft: -1 * theme.spacing.unit * 4,
      '& img': {
        height: theme.spacing.unit * 6,
        marginBottom: -14,
        marginRight: theme.spacing.unit,
      },
    },
  },
  viewAll: {
    float: 'right',
    cursor: 'pointer',
    color: '#00bcd4',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class HomeListWork extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      coldProspects: PropTypes.arrayOf(PropTypes.shape({})),
      warmProspects: PropTypes.arrayOf(PropTypes.shape({})),
      hotProspects: PropTypes.arrayOf(PropTypes.shape({})),
      customers: PropTypes.arrayOf(PropTypes.shape({})),
      consultants: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
      workListSettings: PropTypes.shape({}).isRequired,
    }).isRequired,
    workList: PropTypes.shape({}).isRequired,
    renderListItem: PropTypes.func.isRequired,
    dispatchFetchWorkList: PropTypes.func.isRequired,
    dispatchIncreaseWorkListFactor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: undefined,
  };

  state = {
    numItemsVisible: 0,
    confettiActive: false,
    openOutOfContactsDialog: false,
    outOfContactsDialogMessage: '',
  };

  componentDidMount() {
    const { success, dispatchFetchWorkList } = this.props;
    this.handleUpdate(true);
    if (!success) {
      dispatchFetchWorkList();
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (!isEqual(data, prevProps.data)) {
      this.handleUpdate();
    }
  }

  handleUpdate = skipConfetti => {
    const {
      data: { coldProspects, warmProspects, hotProspects, customers, consultants },
    } = this.props;
    const numItemsVisible =
      coldProspects.length + warmProspects.length + hotProspects.length + customers.length + consultants.length;
    const confettiActive = !skipConfetti && numItemsVisible === 0;
    this.setState({
      numItemsVisible,
      confettiActive,
    });
  };

  handleGiveMeMore = () => {
    const {
      dispatchIncreaseWorkListFactor,
      workList,
      currentUser: { company, workListSettings },
    } = this.props;

    dispatchIncreaseWorkListFactor();

    // Display "out of contacts" message to user, if appropriate (i.e., if user is out of contacts of ANY type).
    const depletedContactTypes = [];
    if (workList.coldProspects.id.length === 0 && workListSettings.coldProspects > 0) {
      depletedContactTypes.push('Cold Prospects');
    }
    if (workList.warmProspects.id.length === 0 && workListSettings.warmProspects > 0) {
      depletedContactTypes.push('Warm Prospects');
    }
    if (workList.hotProspects.id.length === 0 && workListSettings.hotProspects > 0) {
      depletedContactTypes.push('Hot Prospects');
    }
    if (workList.customers.id.length === 0 && workListSettings.customers > 0) {
      depletedContactTypes.push(`${stringsService.getCustomersLabel(company)}`);
    }
    if (workList.consultants.id.length === 0 && workListSettings.consultants > 0) {
      depletedContactTypes.push(`${stringsService.getConsultantsLabel(company)}`);
    }

    if (depletedContactTypes.length > 0) {
      const depletedContactTypesString = [
        depletedContactTypes.slice(0, -1).join(', '),
        depletedContactTypes.slice(-1)[0],
      ].join(depletedContactTypes.length < 2 ? '' : ' and ');
      const outOfContactsMessage = `You've cycled through all your ${depletedContactTypesString}! Please add new contacts or increase existing contacts' frequencies for more IPAs.`;

      this.setState({
        openOutOfContactsDialog: true,
        outOfContactsDialogMessage: outOfContactsMessage,
      });
    }
  };

  filterBy = contactsType => {
    history.push(`/contacts?filter=${contactsType}`);
  };

  render() {
    const {
      classes,
      loading,
      data: { coldProspects, warmProspects, hotProspects, customers, consultants },
      currentUser: { company },
      renderListItem,
    } = this.props;
    const { confettiActive, numItemsVisible, openOutOfContactsDialog, outOfContactsDialogMessage } = this.state;
    return (
      <React.Fragment>
        {!loading && numItemsVisible === 0 && (
          <div className={classes.emptyState}>
            <Typography variant="h5" paragraph>
              <img alt="Confetti" src={imgSrcConfetti} />
              {"Wow, you're a rockstar!"}
            </Typography>
            <Button variant="contained" color="primary" onClick={this.handleGiveMeMore}>
              GIVE ME MORE
            </Button>
          </div>
        )}
        <Confetti className={classes.confetti} active={!loading && confettiActive} />
        {coldProspects.length > 0 && (
          <React.Fragment>
            <Divider />
            <List
              subheader={
                <ListSubheader>
                  COLD PROSPECTS ({coldProspects.length}){' '}
                  <div className={classes.viewAll} onClick={() => this.filterBy('cold_prospects')} role="presentation">
                    view all
                  </div>
                </ListSubheader>
              }
            >
              {map(coldProspects, renderListItem)}
            </List>
          </React.Fragment>
        )}
        {warmProspects.length > 0 && (
          <React.Fragment>
            <Divider />
            <List
              subheader={
                <ListSubheader>
                  WARM PROSPECTS ({warmProspects.length}){' '}
                  <div className={classes.viewAll} onClick={() => this.filterBy('warm_prospects')} role="presentation">
                    view all
                  </div>
                </ListSubheader>
              }
            >
              {map(warmProspects, renderListItem)}
            </List>
          </React.Fragment>
        )}
        {hotProspects.length > 0 && (
          <React.Fragment>
            <Divider />
            <List
              subheader={
                <ListSubheader>
                  HOT PROSPECTS ({hotProspects.length}){' '}
                  <div className={classes.viewAll} onClick={() => this.filterBy('hot_prospects')} role="presentation">
                    view all
                  </div>
                </ListSubheader>
              }
            >
              {map(hotProspects, renderListItem)}
            </List>
          </React.Fragment>
        )}
        {customers.length > 0 && (
          <React.Fragment>
            <Divider />
            <List
              subheader={
                <ListSubheader>
                  {`ALL ${stringsService.getCustomersLabel(company)}`.toUpperCase()} ({customers.length}){' '}
                  <div className={classes.viewAll} onClick={() => this.filterBy('customers')} role="presentation">
                    view all
                  </div>
                </ListSubheader>
              }
            >
              {map(customers, renderListItem)}
            </List>
          </React.Fragment>
        )}
        {consultants.length > 0 && (
          <React.Fragment>
            <Divider />
            <List
              subheader={
                <ListSubheader>
                  {`ALL ${stringsService.getConsultantsLabel(company)}`.toUpperCase()} ({consultants.length}){' '}
                  <div className={classes.viewAll} onClick={() => this.filterBy('consultants')} role="presentation">
                    view all
                  </div>
                </ListSubheader>
              }
            >
              {map(consultants, renderListItem)}
            </List>
          </React.Fragment>
        )}

        <DialogConfirm
          text={outOfContactsDialogMessage}
          open={openOutOfContactsDialog}
          onClose={() => this.setState({ openOutOfContactsDialog: false })}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    workList: selectWorkList,
    success: selectWorkListSuccess,
    data: selectWorkListGroupedByType,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchWorkList: () => ({ type: DISPATCH_FETCH_WORK_LIST }),
    dispatchIncreaseWorkListFactor: () => ({ type: DISPATCH_INCREASE_WORK_LIST_FACTOR }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(HomeListWork);
