import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { map, find } from 'lodash';

import Chip from 'components/Chip';
import Menu from 'components/Menu';
import IconFrequency from 'assets/IconFrequency';
import datesService from 'services/datesService';
import IconArrowDropDown from '../../assets/IconArrowDropDown';

const styles = theme => ({
  icon: {
    padding: 1,
    marginLeft: theme.spacing.unit,
  },
  dropDownIcon: {
    padding: 0,
    margin: '0 -10px',
    fontSize: 'inherit',
  },
});

const items = map(
  [
    {
      key: 'high',
      label: 'High Priority',
      subheader: true,
    },
    {
      days: 1,
    },
    {
      days: 2,
    },
    {
      days: 3,
    },
    {
      key: 'medium',
      label: 'Medium Priority',
      subheader: true,
    },
    {
      days: 7,
    },
    {
      days: 15,
    },
    {
      days: 30,
    },
    {
      key: 'low',
      label: 'Low Priority',
      subheader: true,
    },
    {
      days: 60,
    },
    {
      days: 90,
    },
    {
      days: 180,
    },
    {
      days: 0,
    },
  ],
  item => ({
    ...item,
    key: item.key || item.days,
    label: item.label || datesService.humanizeFrequency(item.days),
  }),
);

class ChipEditFrequency extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    short: PropTypes.bool,
    withArrow: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    short: false,
    withArrow: false,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value,
    anchorEl: null,
    isChanged: false,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.changeState();
    }
  }

  changeState = () => {
    const { value } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.value !== value) {
      this.setState({
        isChanged: true,
      });
    } else {
      this.setState({
        isChanged: false,
      });
    }
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = item => {
    const { onChange } = this.props;
    this.handleClose();
    onChange(item ? item.key : null);
  };

  render() {
    const { classes, value, short, withArrow, ...other } = this.props;
    const { anchorEl, isChanged } = this.state;

    const item = find(items, { key: value });
    const label = item ? item.label : 'Edit Frequency';
    return (
      <React.Fragment>
        <Chip
          icon={
            withArrow ? (
              <IconArrowDropDown className={classes.dropDownIcon} />
            ) : (
              <IconFrequency className={classes.icon} />
            )
          }
          label={label}
          onClick={this.handleClick}
          selected={isChanged}
          {...other}
        />
        <Menu
          anchorEl={anchorEl}
          items={items}
          itemSelected={item}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onClick={e => {
            if (short) e.stopPropagation();
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ChipEditFrequency);
