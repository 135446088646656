import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router, Switch, Route } from 'react-router-dom';
import FullStory from 'react-fullstory';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Auth from 'screens/Auth';
import ResetPassword from 'screens/ResetPassword';
import SetNewPassword from 'screens/SetNewPassword';
import App from 'screens/App';
import Snackbar from 'components/Snackbar';
import SnackbarLoading from 'components/SnackbarLoading';
import history from 'config/history';
import { selectLoading, selectLoadingMessage, selectMessage } from 'reducers/selectors';
import IconOffline from 'assets/IconOffline';
import imgLogoSrc from 'containers/Header/logo.png';
import imgLogoSmallSrc from 'containers/Header/logo-small.png';

const styles = theme => ({
  logo: {
    marginRight: theme.spacing.unit * 2,
    display: 'block',
    '& img': {
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 20,
        paddingTop: 6,
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing.unit * 4,
      },
    },
  },
  offlineModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  offlineModalContent: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  offlineIcon: {
    fontSize: 110,
    margin: theme.spacing.unit * 1,
  },
});

class AppWrapper extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string,
    message: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    loadingMessage: undefined,
  };

  render() {
    const { message, loading, loadingMessage, classes, width } = this.props;

    // Render "No Internet!" offline modal if user is not connected to internet.
    if (!window.navigator.onLine) {
      const logoSrc = /xs/.test(width) ? imgLogoSmallSrc : imgLogoSrc;
      return (
        <React.Fragment>
          <AppBar position="fixed">
            <Toolbar>
              <div className={classes.logo}>
                <img alt="Dough" src={logoSrc} />
              </div>
            </Toolbar>
          </AppBar>
          <Dialog fullScreen={false} open>
            <DialogContent className={classes.offlineModal}>
              <IconOffline className={classes.offlineIcon} />
              <Typography variant="h6">No Internet!</Typography>

              <DialogContentText className={classes.offlineModalContent}>
                Poor network connection detected. Please check your connectivity.
              </DialogContentText>

              <DialogActions>
                <Button variant="contained" color="primary" onClick={() => window.location.reload(false)}>
                  Retry
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <FullStory org="GW528" />
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route path="/login" component={Auth} />
            <Route path="/signup" component={Auth} />
            <Route path="/reset_password" component={ResetPassword} />
            <Route path="/set_new_password" component={SetNewPassword} />
            <Route path="/" component={App} />
          </Switch>
        </Router>
        <SnackbarLoading loading={loading} message={loadingMessage} />
        <Snackbar {...message} />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    loadingMessage: selectLoadingMessage,
    message: selectMessage,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
  withWidth(),
)(AppWrapper);
