import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { find, map, head } from 'lodash';

import history from 'config/history';
import { DISPATCH_SIGN_OUT, DISPATCH_SAVE_CONTACT } from 'actions/actionTypes';
import AutocompleteContacts from 'containers/AutocompleteContacts';
import { selectCurrentUser } from 'reducers/selectors';
import imgLogoSrc from './logo.png';
import imgLogoSmallSrc from './logo-small.png';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    marginRight: theme.spacing.unit * 2,
    display: 'block',
    '& img': {
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 20,
        paddingTop: 6,
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing.unit * 4,
      },
    },
  },
  search: {
    position: 'relative',
    borderRadius: 14,
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
  },
  inputInput: {
    paddingLeft: theme.spacing.unit * 6,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 250,
    },
    '& p': {
      left: 50,
    },
  },
  tabsRoot: {
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    textTransform: 'initial',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 14,
    minWidth: 'initial',
    height: 34,
    minHeight: 34,
    borderRadius: 17,
  },
  tabSelected: {
    backgroundColor: fade(theme.palette.common.black, 0.2),
  },
  tabTextColorInherit: {
    opacity: 1,
  },
  button: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  buttonLabel: {
    color: theme.palette.common.white,
    padding: '0 12px',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit / 2,
    marginRight: -1 * theme.spacing.unit,
  },
  menuButton: {
    color: theme.palette.common.white,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    fontWeight: 'bold',
    fontSize: 16,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  divider: {
    borderLeft: '1px solid white',
    height: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
});

const tabs = [
  {
    value: '/home',
    label: 'Home',
    icon: 'home',
    default: true,
  },
  {
    value: '/contacts',
    label: 'Contacts',
    icon: 'people',
  },
  {
    value: '/scripts',
    label: 'Scripts',
    icon: 'font_download',
  },
  {
    value: '/stats',
    label: 'Stats',
    icon: 'poll',
  },
];

class Header extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    currentUser: PropTypes.shape({}),
    showSearch: PropTypes.bool,
    showTabs: PropTypes.bool,
    dispatchSignOut: PropTypes.func.isRequired,
    dispatchSaveContact: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentUser: undefined,
    showSearch: false,
    showTabs: false,
  };

  state = {
    menuAnchorEl: null,
    drawerAnchorEl: null,
  };

  handleOpenMenu = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleOpenDrawer = event => {
    this.setState({ drawerAnchorEl: event.currentTarget });
  };

  handleClose = path => {
    this.setState({
      menuAnchorEl: null,
      drawerAnchorEl: null,
    });
    if (path) {
      history.push(path);
    }
  };

  handleSignOut = () => {
    const { dispatchSignOut } = this.props;
    this.handleClose();
    dispatchSignOut();
  };

  handleContactsChange = contacts => {
    const {
      location: { pathname, search },
    } = this.props;
    const contact = head(contacts);
    if (contact.id) {
      history.push(`${pathname}/${contact.id}/activities${search}`);
    } else {
      const { dispatchSaveContact } = this.props;
      dispatchSaveContact(contact);
    }
  };

  render() {
    const {
      classes,
      width,
      location: { pathname },
      currentUser,
      showSearch,
      showTabs,
    } = this.props;
    const { menuAnchorEl, drawerAnchorEl } = this.state;
    const menuOpen = Boolean(menuAnchorEl);
    const drawerOpen = Boolean(drawerAnchorEl);
    const logoSrc = /xs/.test(width) ? imgLogoSmallSrc : imgLogoSrc;
    let tabSelected = head(pathname.match(/\/[a-z]+/));
    tabSelected = tabSelected && find(tabs, { value: tabSelected }) ? tabSelected : false;
    return (
      <React.Fragment>
        <AppBar position="fixed">
          <Toolbar>
            {currentUser && currentUser.onboarded ? (
              <ButtonBase className={classes.logo} onClick={() => history.push('/home/work')}>
                <img alt="Dough" src={logoSrc} />
              </ButtonBase>
            ) : (
              <div className={classes.logo}>
                <img alt="Dough" src={logoSrc} />
              </div>
            )}
            {showSearch && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <AutocompleteContacts
                  classes={{
                    input: classes.inputInput,
                  }}
                  value={[]}
                  onSelectionChange={this.handleContactsChange}
                />
              </div>
            )}
            <div className={classes.grow} />
            {showTabs && currentUser && currentUser.onboarded && (
              <React.Fragment>
                <Tabs
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                  onChange={(event, tabValue) => history.push(tabValue)}
                  value={tabSelected}
                >
                  {map(tabs, ({ value, label }) => (
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                        textColorInherit: classes.tabTextColorInherit,
                      }}
                      key={value}
                      value={value}
                      label={label}
                      disableRipple
                    />
                  ))}
                </Tabs>
                <Typography className={classes.divider} />
              </React.Fragment>
            )}
            {currentUser && (
              <React.Fragment>
                <Button
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                  onClick={() => window.open('https://m.me/DoughCRM', '_blank')}
                  color="inherit"
                >
                  Live Chat
                </Button>
                <Button
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                  aria-owns={menuOpen ? 'menu' : null}
                  aria-haspopup="true"
                  onClick={this.handleOpenMenu}
                  color="inherit"
                >
                  {currentUser.firstName}
                  <ArrowDropDownIcon className={classes.rightIcon} />
                </Button>
                <Menu id="menu" anchorEl={menuAnchorEl} open={menuOpen} onClose={() => this.handleClose()}>
                  {currentUser.onboarded && <MenuItem onClick={() => this.handleClose('/account')}>Account</MenuItem>}
                  <MenuItem onClick={this.handleSignOut}>Log out</MenuItem>
                </Menu>
              </React.Fragment>
            )}
            {currentUser && (
              <Button
                className={classes.menuButton}
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleOpenDrawer}
              >
                Menu
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
          open={drawerOpen}
          onClose={() => this.handleClose()}
        >
          <List>
            {currentUser && currentUser.onboarded && (
              <React.Fragment>
                {map(tabs, tab => (
                  <ListItem key={tab.value} button onClick={() => this.handleClose(tab.value)}>
                    <ListItemIcon>
                      <Icon>{tab.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={tab.label} />
                  </ListItem>
                ))}
                <Divider />
              </React.Fragment>
            )}
            {currentUser && currentUser.email !== 'diane.h.wolfman@gmail.com' && (
              <React.Fragment>
                <ListItem button onClick={() => window.open('https://m.me/DoughCRM', '_blank')}>
                  <ListItemIcon>
                    <Icon>chat</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Live Chat" />
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            {currentUser && currentUser.onboarded && (
              <ListItem button onClick={() => this.handleClose('/account')}>
                <ListItemIcon>
                  <Icon>account_box</Icon>
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItem>
            )}
            <ListItem button onClick={this.handleSignOut}>
              <ListItemIcon>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
  {
    dispatchSignOut: () => ({ type: DISPATCH_SIGN_OUT }),
    dispatchSaveContact: contact => ({
      type: DISPATCH_SAVE_CONTACT,
      contact,
      pathSuccess: '/home/work/:id/activities',
    }),
  },
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withWidth(),
)(Header);
