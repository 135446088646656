import { call, select } from 'redux-saga/effects';
import { map, uniq } from 'lodash';

import { FETCH_RECENT_ACTIVITY } from 'actions/actionTypes';
import activitiesResource from 'resources/activitiesResource';
import { selectActivities } from 'reducers/selectors';
import sagaFetch from './sagaFetch';
import sagaFetchContacts from './sagaFetchContacts';

// bind resource generator
const fetchRecentActivity = sagaFetch.bind(null, FETCH_RECENT_ACTIVITY, activitiesResource.fetchRecentActivity);

export default function* sagaFetchRecentActivity() {
  yield call(fetchRecentActivity);

  // lookup for contacts
  const reminders = yield select(selectActivities);
  const ids = uniq(map(reminders, 'contactId'));
  yield call(sagaFetchContacts, { ids });
}
