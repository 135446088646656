import { schema } from 'normalizr';

import api from 'services/api';
import resource from './resource';

const url = '/api/v1/scripts';

// Interceptors
function processStrategy(data) {
  return resource.processStrategy(data);
}

// Schemas
const scriptSchema = new schema.Entity('scripts', {}, { processStrategy });

// Methods
function fetchScripts() {
  return api.get(url, {
    schema: [scriptSchema],
  });
}

export default {
  fetchScripts,
};
