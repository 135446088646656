import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import Duration from 'components/Duration';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: theme.spacing.unit * 2,
    width: 240,
  },
  rootLite: {
    marginLeft: 60,
    height: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'center',
  },
  playerControls: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: 160,
    marginTop: 23,
    marginBottom: 4,
  },
  playerControlsSecundary: {
    display: 'flex',
    '& *': {
      lineHeight: 0.8,
    },
    '& a': {
      marginLeft: theme.spacing.unit,
      paddingLeft: theme.spacing.unit,
      borderLeft: `1px solid ${fade(theme.palette.common.white, 0.4)}`,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  button: {
    marginTop: theme.spacing.unit,
  },
  buttonLabel: {
    paddingRight: theme.spacing.unit,
  },
  playPauseButton: {
    marginLeft: theme.spacing.unit * 2,
    minHeight: 0,
    width: 20,
    height: 20,
    bottom: 2,
    '& svg': {
      fontSize: 16,
      color: theme.palette.common.black,
    },
  },
  iconLeft: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
});

class FooterPlayerControls extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    isOnboarding: PropTypes.bool.isRequired,
    video: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
    started: PropTypes.bool.isRequired,
    playing: PropTypes.bool.isRequired,
    played: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    onPlayPause: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
  };

  render() {
    const { classes, width, isOnboarding, video, started, playing, played, duration, onPlayPause, onExit } = this.props;
    if (/xs/.test(width)) {
      return (
        <div className={classnames(classes.rootLite, classes.playerControlsSecundary)}>
          {started && (
            <React.Fragment>
              <Typography>
                <Duration seconds={duration * (1 - played)} /> remaining
              </Typography>
              <Typography component="a" onClick={onExit}>
                Exit
              </Typography>
            </React.Fragment>
          )}
        </div>
      );
    }
    return (
      <div className={classes.root}>
        {!started && <Typography variant="h6">{video ? video.title : ''}</Typography>}
        {started && (
          <React.Fragment>
            <div className={classes.playerControls}>
              <Typography variant="h3">
                <Duration seconds={duration * (1 - played)} />
              </Typography>
              <Fab className={classes.playPauseButton} color="primary" onClick={onPlayPause}>
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
              </Fab>
            </div>
            <div className={classes.playerControlsSecundary}>
              <Typography variant="caption">remaining</Typography>
              <Typography variant="caption" component="a" onClick={onExit}>
                Exit
              </Typography>
            </div>
          </React.Fragment>
        )}
        {!started && (
          <Button
            classes={{
              root: classes.button,
              label: classes.buttonLabel,
            }}
            variant="contained"
            color="primary"
            onClick={onPlayPause}
          >
            <PlayArrowIcon className={classes.iconLeft} />
            {isOnboarding ? 'START' : 'START SESSION'}
          </Button>
        )}
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withWidth(),
)(FooterPlayerControls);
