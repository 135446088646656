import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import red from '@material-ui/core/colors/red';

const styles = () => ({
  icon: {
    color: red[900],
  },
});

class IconArrowUp extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
  };

  render() {
    const { classes, className, ...other } = this.props;
    return <ArrowUpwardIcon className={classnames(className, classes.icon)} {...other} />;
  }
}

export default compose(withStyles(styles))(IconArrowUp);
