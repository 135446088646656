function isOnboarding(location) {
  return location && /onboarding/i.test(location.pathname);
}

function getOnboardingSection(location) {
  return isOnboarding(location) ? location.pathname.replace('/onboarding/', '') : null;
}

export default {
  isOnboarding,
  getOnboardingSection,
};
