import { call } from 'redux-saga/effects';

import { DELETE_ACTIVITY } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const deleteActivity = sagaFetch.bind(null, DELETE_ACTIVITY, contactsResource.deleteActivity);

export default function* sagaDeleteActivity({ contact, activity }) {
  yield call(deleteActivity, { contact, activity });
  yield call(sagaRefresh, contact);
}
