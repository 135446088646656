import React, { Component } from 'react';
import { Field } from 'redux-form';

class FieldTrimmed extends Component {
  render() {
    return <Field parse={value => (value ? value.trim() : value)} {...this.props} />;
  }
}

export default FieldTrimmed;
