import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import FormStripe from 'components/FormStripe';
import { selectLoading } from 'reducers/selectors';
import { DISPATCH_SAVE_PLAN } from 'actions/actionTypes';
import history from 'config/history';

const styles = theme => ({
  fineprint: {
    marginTop: theme.spacing.unit,
    color: '#888888',
  },
  url: {
    color: '#888888',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginRight: theme.spacing.unit,
  },
  price: {
    color: '#f44336',
    fontWeight: 'bold',
  },
  content: {
    paddingTop: theme.spacing.unit,
  },
});

class DialogPlanPayment extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      planKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchSavePlan: PropTypes.func.isRequired,
  };

  form = React.createRef();

  handleSubmit = ({ id: cardToken }) => {
    const {
      dispatchSavePlan,
      data: { planKey },
    } = this.props;
    dispatchSavePlan(planKey, cardToken, () => history.push('/home/work'));
  };

  render() {
    const {
      classes,
      open,
      fullScreen,
      loading,
      data: { name, price },
      onClose,
    } = this.props;
    return (
      <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={onClose}>
        <DialogTitle>
          <Typography variant="h6">You chose the {name}</Typography>
          <Typography variant="subtitle1">
            Order total:{' '}
            <Typography className={classes.price} variant="subtitle1" inline>
              US ${price}
            </Typography>
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <FormStripe formRef={this.form} onSubmit={this.handleSubmit} />
          <div className={classes.buttons}>
            <Button className={classes.cancelButton} color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              // Note 1: Event() is not supported in IE 9, 10, 11. Use CustomEvent() and its polyfill instead.
              // Note 2: When creating an event and dispatching it manually, need to set cancelable to true, in order for
              // preventDefault to work (which redux-form's handleSubmit() calls for us).
              // https://github.com/facebook/react/issues/12639#issuecomment-382519193
              onClick={() => this.form.current.dispatchEvent(new CustomEvent('submit', { cancelable: true }))}
            >
              Pay ${price}
            </Button>
          </div>
          <Typography className={classes.fineprint} variant="caption" align="right">
            {'By clicking "Pay", you accept our '}
            <a
              className={classes.url}
              href="https://www.doughcrm.com/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {' and '}
            <a
              className={classes.url}
              href="https://www.doughcrm.com/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            {'. No refunds will be given for any portion of a prepaid period.'}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
  }),
  {
    dispatchSavePlan: (planKey, cardToken, callback) => ({ type: DISPATCH_SAVE_PLAN, planKey, cardToken, callback }),
  },
);

export default compose(
  withConnect,
  withMobileDialog(),
  withStyles(styles),
)(DialogPlanPayment);
