import '@babel/polyfill';
import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';

import { muiThemeDough } from 'themes';
import AppWrapper from 'screens/AppWrapper';
import store from 'config/store';
import * as serviceWorker from 'serviceWorker';
import 'index.css';

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={muiThemeDough}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppWrapper />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
