import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import IconReachOut from 'assets/IconReachOut';

import Chip from 'components/Chip';
import ChipEditStatus from 'components/ChipEditStatus';
import ChipEditType from 'components/ChipEditType';
import ChipSetReminder from 'components/ChipSetReminder';
import ChipEditFrequency from 'components/ChipEditFrequency';
import PopoverActivity from 'components/PopoverActivity';

import moment from 'moment';

const styles = theme => ({
  icon: {
    padding: 2,
    marginLeft: theme.spacing.unit,
    marginRight: -1 * theme.spacing.unit,
  },
  logReachOutChip: {
    width: '430px',
    maxWidth: '100%',
  },
  secondaryAction: {
    width: '430px',
    maxWidth: '100%',
  },
  setActionsBtns: {
    margin: '3px 3px 0 3px',
  },
  chip: {
    margin: '3px',
  },
});

class ChipLogReachOut extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.shape({}),
    PopoverProps: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    contact: PropTypes.shape({
      frequency: PropTypes.number,
      contactType: PropTypes.string,
      contactStatus: PropTypes.string,
    }),
    hasChips: PropTypes.bool,
    isBtn: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    value: undefined,
    PopoverProps: undefined,
    contact: undefined,
    hasChips: false,
    isBtn: false,
    loading: true,
  };

  state = {
    anchorEl: undefined,
    reminderValue: undefined,
    // eslint-disable-next-line react/destructuring-assignment
    frequency: this.props.contact && (this.props.contact.frequency !== undefined) ? this.props.contact.frequency : undefined,
    // eslint-disable-next-line react/destructuring-assignment
    contactType: this.props.contact && this.props.contact.contactType ? this.props.contact.contactType : undefined,
    contactStatus:
      // eslint-disable-next-line react/destructuring-assignment
      this.props.contact && this.props.contact.contactStatus ? this.props.contact.contactStatus : undefined,
  };

  componentDidUpdate(prevProps) {
    const { contact } = this.props;
    if (contact !== prevProps.contact) {
      if (contact) {
        this.handleReset();
      }
    }
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    const { contact } = this.props;
    this.setState({
      anchorEl: undefined,
      reminderValue: undefined,
      frequency: contact && (contact.frequency !== undefined) ? contact.frequency : undefined,
      contactType: contact && contact.contactType ? contact.contactType : undefined,
      contactStatus: contact && contact.contactStatus ? contact.contactStatus : undefined,
    });
  };

  handleSubmit = value => {
    const { onChange } = this.props;
    const { contactType, frequency, reminderValue, contactStatus } = this.state;

    const data = {
      reminderValue,
      contactType: contactType || value.contactType,
      frequency: frequency !== undefined ? frequency : value.frequency,
      contactStatus: contactStatus || value.contactStatus,
      date: value && value.date ? value.date : moment(),
      notes: value && value.notes ? value.notes : '',
    };

    this.handleClose();
    onChange(data);
  };

  handleRemove = () => {
    const { onChange } = this.props;
    const data = {
      reminderValue: undefined,
      contactType: undefined,
      frequency: undefined,
      contactStatus: undefined,
      notes: '',
    };
    this.handleClose();
    onChange(data);
  };

  handleReset = () => {
    const { contact } = this.props;
    this.setState({
      frequency: contact && (contact.frequency !== undefined) ? contact.frequency : undefined,
      contactType: contact && contact.contactType ? contact.contactType : undefined,
      contactStatus: contact && contact.contactStatus ? contact.contactStatus : undefined,
    });
  };

  handleChangeSelected = (property, value) => {
    this.setState({ [property]: value });
  };

  changeChips = data => {
    // Check the chips' new data to ensure that we do NOT downgrade a contact's existing contactStatus and frequency.
    const { contact } = this.props;
    const newData = { ...data };
    if (contact) {
      if (data.frequency && (contact.frequency !== 0 && contact.frequency <= data.frequency)) {
        // Ignore new frequency if contact's existing frequency is more frequent.
        delete newData.frequency;
      }

      if (data.contactStatus) {
        if (contact.contactStatus === 'HOT') {
          // Ignore new contactStatus since HOT is the highest level possible.
          delete newData.contactStatus;
        } else if (contact.contactStatus === 'WARM' && data.contactStatus !== 'HOT') {
          // Ignore new contactStatus if contact's existing contactStatus is WARM and new contactStatus is NOT HOT.
          delete newData.contactStatus;
        }
      }
    }

    this.setState({ ...newData });
  };

  render() {
    const { classes, value, PopoverProps, contact, hasChips, isBtn, loading, ...other } = this.props;
    const { anchorEl, reminderValue, frequency, contactStatus, contactType } = this.state;
    const open = Boolean(anchorEl);
    const selected = Boolean(value && value.date);
    let label = 'Log Activity';
    if (selected) {
      label = value.date.format('MM/DD');
      if (value.notes) {
        label += `: ${value.notes}`;
      }
    }
    const frequencyMaster = contact && (contact.frequency !== undefined) ? contact.frequency.frequencyMaster : undefined;
    return (
      <React.Fragment>
        {isBtn ? (
          <Button variant="contained" color="primary" size="small" onClick={this.handleClick} disabled={loading}>
            Log
          </Button>
        ) : (
          <Chip
            icon={<IconReachOut className={classes.icon} />}
            label={label}
            onClick={this.handleClick}
            selected={selected}
            {...other}
          />
        )}
        <PopoverActivity
          open={open}
          anchorEl={anchorEl}
          value={value}
          title="Log Activity"
          onClose={this.handleClose}
          onSubmit={this.handleSubmit}
          changeChips={this.changeChips}
          handleReset={this.handleReset}
          currentContactType={contactType}
          className={classes.logReachOutChip}
          secondaryAction={
            hasChips ? (
              <div className={classes.secondaryAction}>
                <ChipSetReminder
                  className={classes.chip}
                  value={reminderValue}
                  onChange={val => this.setState({ reminderValue: val })}
                />
                <ChipEditFrequency
                  className={classes.chip}
                  value={frequency}
                  onChange={val => this.setState({ frequency: val })}
                  skipSelected={frequency === frequencyMaster}
                />
                <ChipEditStatus
                  className={classes.setActionsBtns}
                  value={contactStatus}
                  onChange={changedStatus => this.handleChangeSelected('contactStatus', changedStatus)}
                />
                <ChipEditType
                  className={classes.setActionsBtns}
                  value={contactType}
                  onChange={changedType => this.handleChangeSelected('contactType', changedType)}
                />
              </div>
            ) : (
              value && (
                <Button size="small" onClick={() => this.handleRemove()}>
                  Remove
                </Button>
              )
            )
          }
          {...PopoverProps}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ChipLogReachOut);
