import api from 'services/api';
import usersResource from './usersResource';

const url = '/auth';

function signUp({ data }) {
  return api.post(url, null, {
    params: data,
    schema: usersResource.userSchema,
  });
}

function signIn({ credentials }) {
  return api.post(`${url}/sign_in`, null, {
    params: credentials,
    schema: usersResource.userSchema,
  });
}

function signOut() {
  return api.delete(`${url}/sign_out`, null, { skipErrorMessage: true });
}

export default {
  signUp,
  signIn,
  signOut,
};
