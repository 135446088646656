import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconInstagram = props => (
  <SvgIcon width="528" height="528" viewBox="0 0 528 528" {...props}>
    <path
      fill="#304FFE"
      d="M 352 462 L 176 462 C 115.5 462 66 412.5 66 352 L 66 176 C 66 115.5 115.5 66 176 66 L 352 66 C 412.5 66 462 115.5 462 176 L 462 352 C 462 412.5 412.5 462 352 462 Z"
    />
    <path
      fill="#304FFE;fill-opacity:0.290196;"
      d="M 66 176 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 L 462 176 C 462 165 460.882813 155.117188 457.617188 145.191406 C 396 95.691406 316.808594 66 231 66 C 190.308594 66 150.691406 72.617188 113.308594 85.808594 C 84.691406 105.617188 66 138.617188 66 176 Z"
    />
    <path
      fill="#6200EA"
      d="M 231 88 C 176 88 125.382813 101.191406 79.191406 123.191406 C 70.382813 139.691406 66 157.308594 66 176 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 L 462 178.191406 C 401.5 122.117188 320.117188 88 231 88 Z"
    />
    <path
      fill="#673AB7"
      d="M 462 209 C 403.691406 148.5 322.308594 110 231 110 C 171.617188 110 115.5 126.5 68.191406 155.117188 C 67.117188 161.691406 66 168.308594 66 176 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 Z"
    />
    <path
      fill="#8E24AA"
      d="M 462 242 C 408.117188 174.882813 324.5 132 231 132 C 169.382813 132 113.308594 150.691406 66 182.617188 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 Z"
    />
    <path
      fill="#C2185B"
      d="M 462 352 L 462 279.382813 C 412.5 204.617188 327.808594 154 231 154 C 169.382813 154 112.191406 174.882813 66 209 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 Z"
    />
    <path
      fill="#D81B60"
      d="M 462 352 L 462 325.617188 C 422.382813 237.617188 334.382813 176 231 176 C 168.308594 176 110 199.117188 66 237.617188 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 412.5 462 462 412.5 462 352 Z"
    />
    <path
      fill="#F50057"
      d="M 457.617188 382.808594 C 436.691406 277.191406 343.191406 198 231 198 C 166.117188 198 107.808594 224.382813 66 267.308594 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 401.5 462 444.382813 429 457.617188 382.808594 Z"
    />
    <path
      fill="#FF1744"
      d="M 438.882813 418 C 433.382813 308 343.191406 220 231 220 C 163.882813 220 104.5 251.882813 66 301.382813 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 387.191406 462 419.117188 444.382813 438.882813 418 Z"
    />
    <path
      fill="#FF5722"
      d="M 231 242 C 159.5 242 97.882813 281.617188 66 341 L 66 352 C 66 412.5 115.5 462 176 462 L 352 462 C 376.191406 462 399.308594 454.308594 416.882813 440 C 416.882813 436.691406 418 432.308594 418 429 C 418 325.617188 334.382813 242 231 242 Z"
    />
    <path
      fill="#FF6F00"
      d="M 231 264 C 154 264 90.191406 315.691406 71.5 387.191406 C 85.808594 431.191406 127.617188 462 176 462 L 352 462 C 367.382813 462 380.617188 458.691406 393.808594 453.191406 C 394.882813 445.5 396 436.691406 396 429 C 396 337.691406 322.308594 264 231 264 Z"
    />
    <path
      fill="#FF9800"
      d="M 231 286 C 155.117188 286 93.5 344.308594 89.117188 418 C 108.882813 444.382813 140.808594 462 176 462 L 352 462 C 358.617188 462 364.117188 460.882813 370.691406 459.808594 C 372.882813 449.882813 374 440 374 429 C 374 349.808594 310.191406 286 231 286 Z"
    />
    <path
      fill="#FFC107"
      d="M 347.617188 462 C 350.882813 451 352 440 352 429 C 352 361.882813 298.117188 308 231 308 C 163.882813 308 110 361.882813 110 429 C 110 432.308594 110 436.691406 111.117188 440 C 129.808594 453.191406 151.808594 462 176 462 Z"
    />
    <path
      fill="#FFD54F"
      d="M 231 330 C 176 330 132 374 132 429 C 132 437.808594 133.117188 446.617188 135.308594 454.308594 C 147.382813 459.808594 161.691406 462 176 462 L 324.5 462 C 327.808594 452.117188 330 441.117188 330 429 C 330 374 286 330 231 330 Z"
    />
    <path
      fill="#FFE082"
      d="M 231 353.117188 C 188.117188 353.117188 154 387.191406 154 430.117188 C 154 441.117188 156.191406 451 160.617188 460.882813 C 166.117188 462 170.5 462 176 462 L 301.382813 462 C 305.808594 452.117188 308 441.117188 308 430.117188 C 308 387.191406 273.882813 353.117188 231 353.117188 Z"
    />
    <path
      fill="#FFECB3"
      d="M 231 375.117188 C 200.191406 375.117188 176 399.308594 176 430.117188 C 176 442.191406 180.382813 453.191406 187 462 L 275 462 C 281.617188 453.191406 286 442.191406 286 430.117188 C 286 399.308594 261.808594 375.117188 231 375.117188 Z"
    />
    <path
      fill="#FFFFFF"
      d="M 330 418 L 198 418 C 149.617188 418 110 378.382813 110 330 L 110 198 C 110 149.617188 149.617188 110 198 110 L 330 110 C 378.382813 110 418 149.617188 418 198 L 418 330 C 418 378.382813 378.382813 418 330 418 Z M 198 132 C 161.691406 132 132 161.691406 132 198 L 132 330 C 132 366.308594 161.691406 396 198 396 L 330 396 C 366.308594 396 396 366.308594 396 330 L 396 198 C 396 161.691406 366.308594 132 330 132 Z"
    />
    <path
      fill="#FFFFFF"
      d="M 264 341 C 221.117188 341 187 306.882813 187 264 C 187 221.117188 221.117188 187 264 187 C 306.882813 187 341 221.117188 341 264 C 341 306.882813 306.882813 341 264 341 Z M 264 209 C 233.191406 209 209 233.191406 209 264 C 209 294.808594 233.191406 319 264 319 C 294.808594 319 319 294.808594 319 264 C 319 233.191406 294.808594 209 264 209 Z"
    />
    <path
      fill="#FFFFFF"
      d="M 352 176 C 352 182.617188 347.617188 187 341 187 C 334.382813 187 330 182.617188 330 176 C 330 169.382813 334.382813 165 341 165 C 347.617188 165 352 169.382813 352 176 Z "
    />
  </SvgIcon>
);

export default IconInstagram;
