import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import cookie from 'react-cookies';

import FieldTrimmed from 'components/FieldTrimmed';
import history from 'config/history';
import FormTextField from 'components/FormTextField';
import Anchor from 'components/Anchor';
import validation from 'services/validationService';
import { DISPATCH_SIGN_IN } from 'actions/actionTypes';
import { selectLoading } from 'reducers/selectors';
// import deviceService from 'services/deviceService';

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing.unit * 4,
    width: 160,
  },
  captions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Login extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatchSignIn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    if (cookie.load('onboardingStep')) {
      cookie.remove('onboardingStep', { path: '/' });
    }
  }

  // eslint-disable-next-line arrow-body-style
  renderNoAccountString = () => {
    // if (deviceService.isIos()) {
    //   return <Typography variant="caption">An existing doughcrm.com account is required</Typography>;
    // }

    return (
      <Typography variant="caption">
        {"Don't have an account? "}
        <Anchor onClick={() => history.push('/signup')}>Sign up for free</Anchor>
      </Typography>
    );
  };

  render() {
    const { classes, handleSubmit, dispatchSignIn, pristine, submitting, loading } = this.props;
    return (
      <div className={classes.root}>
        <form onSubmit={handleSubmit(values => dispatchSignIn(values))}>
          <Typography variant="h5" gutterBottom>
            Log In
          </Typography>
          <FieldTrimmed component={FormTextField} name="email" label="Email" margin="normal" fullWidth autoFocus />
          <FieldTrimmed
            component={FormTextField}
            type="password"
            name="password"
            label="Password"
            margin="normal"
            fullWidth
          />
          <div className={classes.actions}>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={pristine || submitting || loading}
            >
              Log In
            </Button>
            <div className={classes.captions}>
              <Typography variant="caption">
                <Anchor onClick={() => history.push('/reset_password')}>Forgot password?</Anchor>
              </Typography>
              {this.renderNoAccountString()}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
  }),
  {
    dispatchSignIn: credentials => ({ type: DISPATCH_SIGN_IN, credentials }),
  },
);

const withForm = reduxForm({
  form: 'Login',
  validate: validation.createValidator({
    email: [validation.required, validation.email],
    password: validation.required,
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(Login);
