import { merge } from 'lodash';

import { SIGN_IN, SIGN_OUT, FETCH_ACTIVE_REMINDERS, SAVE_REMINDER, DELETE_REMINDER } from 'actions/actionTypes';

const initialState = {
  success: false,
  reminders: undefined,
};

export default function createActiveRemindersReducer() {
  return (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ACTIVE_REMINDERS.SUCCESS:
        // Cannot use merge() because it does not work for arrays: https://stackoverflow.com/a/33247597
        return {
          ...state,
          reminders: action.normalized.result,
          success: true,
        };
      // handle updates
      case SAVE_REMINDER.SUCCESS:
      case DELETE_REMINDER.SUCCESS:
        return merge({}, state, {
          success: false,
        });
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
