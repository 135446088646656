import { filter } from 'lodash';
import moment from 'moment';

// Release dates are all set to 12am PST (1am PDT).
// Videos are currently sorted by array order (oldest at the top), NOT by releaseDate.
const commonVideosForAllCompanies = [
  {
    key: 'DAY1_VIDEO',
    isIntroductoryVideo: true,
    url: 'https://doughcrm.wistia.com/medias/lpoy17jy0e',
    img: 'http://embed.wistia.com/deliveries/9428add1dd82e601bd0835b6c58081cb.jpg?image_crop_resized=1024x576',
    title: '5 min Overview',
    releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
  },
  {
    key: 'DAY2_15MIN_IPAS',
    isIntroductoryVideo: true,
    url: 'https://doughcrm.wistia.com/medias/bfr8f1sztc',
    img: 'http://embed.wistia.com/deliveries/8f2e32a6c3fc55bf8794e489be4371be.jpg?image_crop_resized=1024x576',
    title: 'Day 1: 15 min IPAs!',
    releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
  },
  {
    key: 'DAY3_15MIN_FOLLOWUPS',
    isIntroductoryVideo: true,
    url: 'https://doughcrm.wistia.com/medias/gcgmhulbjx',
    img: 'http://embed.wistia.com/deliveries/d94f602c9aae6b1f3240a959b600ee8f.jpg?image_crop_resized=1024x576',
    title: 'Day 2: 15 min Follow-Ups',
    releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
  },
  {
    key: '15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/q62rbifi45',
    img: 'http://embed.wistia.com/deliveries/41dbdb2c482ed7681768beb68c046c88.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-06_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/8n0ydiqd53',
    img: 'http://embed.wistia.com/deliveries/cf2ef0daf92f6d4f8b7a3660503663b4.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-05-06T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-08_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/g82c0kh6lw',
    img: 'http://embed.wistia.com/deliveries/e9c78987f1b03a262904b8ac123e53ff.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-05-08T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-10_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/fvdwzq14p3',
    img: 'http://embed.wistia.com/deliveries/e442829941f8d05d2e817b8550296c35.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-05-10T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-13_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/ij5paqopft',
    img: 'http://embed.wistia.com/deliveries/dd482ebb52496023134d0eb929508ca2.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-05-13T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-15_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/tc7w7cybet',
    img: 'http://embed.wistia.com/deliveries/a47da127f3e20902548657f6ac823166.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-05-15T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-17_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/xcw51t74lt',
    img: 'http://embed.wistia.com/deliveries/8333034d447c2e4a340106b2fe007ac9.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-05-17T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-20_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/nwlwgndcde',
    img: 'http://embed.wistia.com/deliveries/3b864d6ea20b2bbdd7309983a36be65f.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-05-20T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-22_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/di2zehiw5i',
    img: 'http://embed.wistia.com/deliveries/c7bb491452d038fe111daaaf097f7fac.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-05-22T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-24_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/7xtlyv4o90',
    img: 'http://embed.wistia.com/deliveries/0c646707ba70e454d54b76533cadec0a.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-05-24T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-27_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/1pjmqx40o1',
    img: 'http://embed.wistia.com/deliveries/a836545ceb8195027a622203ac5a6400.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-05-27T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-29_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/zdz6snyof5',
    img: 'http://embed.wistia.com/deliveries/e4370fc29a878df3bea109cd767ad037.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-05-29T00:00:00.000-08:00'),
  },
  {
    key: '2019-05-31_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/i5qtvcf2q3',
    img: 'http://embed.wistia.com/deliveries/29b20b4cc1c32d94f7fd4ce78e864233.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-05-31T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-03_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/6iiwkby1tv',
    img: 'http://embed.wistia.com/deliveries/8e3a53dffee07a9381cac427eb7b02ab.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-06-03T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-05_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/qoteqj1fxy',
    img: 'http://embed.wistia.com/deliveries/65a8f92421373c733fcd4c8bdca9e542.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-06-05T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-07_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/mext778zc9',
    img: 'http://embed.wistia.com/deliveries/f32f0499ebc4600092f5cb21536af3d3.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-06-07T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-10_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/bbbktvumni',
    img: 'http://embed.wistia.com/deliveries/ab96ef31a50ca0404df450fcbb68d8e1.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-06-10T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-12_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/1ukodb0pkp',
    img: 'http://embed.wistia.com/deliveries/5a36e7c929df296b6d930ca1caf858c5.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-06-12T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-14_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/9hsm42oca9',
    img: 'http://embed.wistia.com/deliveries/ca43cc366ba3a661e07341d78bd7cd23.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-06-14T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-17_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/5yrk2zwg5d',
    img: 'http://embed.wistia.com/deliveries/35ce04d915e86a70b1187b793799da8b.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-06-17T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-19_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/eoo1afjr8q',
    img: 'http://embed.wistia.com/deliveries/7a2567c36eb5544c71d6636f4df9701f.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-06-19T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-21_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/xaz72t7hmx',
    img: 'http://embed.wistia.com/deliveries/7751ead83a7699b8367dc288049cdeb7.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-06-21T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-24_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/ojv6q2paad',
    img: 'http://embed.wistia.com/deliveries/a183cc2e1cb70bc004d06f8a7c22cb6a.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-06-24T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-26_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/7rs2gt8m3s',
    img: 'http://embed.wistia.com/deliveries/2c4a855aec974b20329a6ac9caf84761.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-06-26T00:00:00.000-08:00'),
  },
  {
    key: '2019-06-28_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/3qet08drky',
    img: 'http://embed.wistia.com/deliveries/44db6069de0cef7011df114390102d11.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-06-28T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-01_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/xx5zll30uc',
    img: 'http://embed.wistia.com/deliveries/128b289776845ec02271e7dbc80cf2f9.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-07-01T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-08_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/6vsteszilg',
    img: 'http://embed.wistia.com/deliveries/8a964d515d127a960ce7be4d4b6dd0b7.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-07-08T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-10_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/hvvfhufk9k',
    img: 'http://embed.wistia.com/deliveries/932b16282228bf259468d5824eeab855.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-07-10T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-12_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/0o7g8xxojf',
    img: 'http://embed.wistia.com/deliveries/45407f93c22189566045803d1dc21385.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-07-12T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-15_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/5ub6ghss7a',
    img: 'http://embed.wistia.com/deliveries/78b6bc04b51c7507aa73252337bdbff1.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-07-15T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-17_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/85cxsz8a79',
    img: 'http://embed.wistia.com/deliveries/c072230e58f5391ebbc2cca344f8417e.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-07-17T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-19_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/44csr2qyc3',
    img: 'http://embed.wistia.com/deliveries/125e2790791793fcffa9c6c0d010d030.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-07-19T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-22_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/1o6lcnd7lt',
    img: 'http://embed.wistia.com/deliveries/e98000817c705fcfdb07d901153491dc.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-07-22T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-24_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/rglhqy99wz',
    img: 'http://embed.wistia.com/deliveries/a67127c23c594b1f9d593a0d0520ba61.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-07-24T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-26_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/f9jkudx5jb',
    img: 'http://embed.wistia.com/deliveries/bb8f82c582204ed0b5df82f1fe8977be.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-07-26T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-29_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/40t7pgsa1x',
    img: 'http://embed.wistia.com/deliveries/f624eb36999dfc6cf7399bab22f416d6.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-07-29T00:00:00.000-08:00'),
  },
  {
    key: '2019-07-31_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/tqusumkmvi',
    img: 'http://embed.wistia.com/deliveries/dda5698fbf96938a51b63299233d5770.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-07-31T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-02_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/qoteqj1fxy',
    img: 'http://embed.wistia.com/deliveries/65a8f92421373c733fcd4c8bdca9e542.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-08-02T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-05_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/5snl0tl9w1',
    img: 'http://embed.wistia.com/deliveries/b544416be97a988676617647fa439dfe.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-08-05T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-07_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/ciy8r1nug6',
    img: 'http://embed.wistia.com/deliveries/0851f47976bb115bd53d82a6d368abf5.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-08-07T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-09_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/0wxs87lhbs',
    img: 'http://embed.wistia.com/deliveries/4eda0aa751351631559af305eee1a39a.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-08-09T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-12_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/2xi5yc31ev',
    img: 'http://embed.wistia.com/deliveries/6d45458ca1864a481006b28bf74d3a3e.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-08-12T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-14_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/elcf4pg9z4',
    img: 'http://embed.wistia.com/deliveries/d01e81f94bafce79ba80539465c33c2f.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-08-14T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-16_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/wz9hiajxx7',
    img: 'http://embed.wistia.com/deliveries/e54fb49a0ee791fd87fe4665ec77a983.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-08-16T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-19_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/zc1ht2f9sx',
    img: 'http://embed.wistia.com/deliveries/0bc19f239534c74579e9a782ae736ec0.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-08-19T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-21_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/y6rmiluubf',
    img: 'http://embed.wistia.com/deliveries/5351124bbba827f66bf1c74899dd50d6.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-08-21T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-23_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/oog7j6u2bz',
    img: 'http://embed.wistia.com/deliveries/30ea0eeafa6e3c7e35134b6eccc13bf8.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-08-23T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-26_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/6boa6igu26',
    img: 'http://embed.wistia.com/deliveries/dab7db6b8b66a8d0fdb4164b9a70ef4f.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-08-26T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-28_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/wmo3sst81l',
    img: 'http://embed.wistia.com/deliveries/c94cfb0eb106eb4583b464b4637889d9.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-08-28T00:00:00.000-08:00'),
  },
  {
    key: '2019-08-30_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/h96vci6j8a',
    img: 'http://embed.wistia.com/deliveries/c5a869bb33296c6e851e048e94cfb8a3.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-08-30T00:00:00.000-08:00'),
  },
  // Videos below are repeats of all 49 existing May-Aug videos above
  {
    key: '2019-09-02_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/8n0ydiqd53',
    img: 'http://embed.wistia.com/deliveries/cf2ef0daf92f6d4f8b7a3660503663b4.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-09-02T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-04_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/g82c0kh6lw',
    img: 'http://embed.wistia.com/deliveries/e9c78987f1b03a262904b8ac123e53ff.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-09-04T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-06_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/fvdwzq14p3',
    img: 'http://embed.wistia.com/deliveries/e442829941f8d05d2e817b8550296c35.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-09-06T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-09_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/ij5paqopft',
    img: 'http://embed.wistia.com/deliveries/dd482ebb52496023134d0eb929508ca2.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-09-09T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-11_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/tc7w7cybet',
    img: 'http://embed.wistia.com/deliveries/a47da127f3e20902548657f6ac823166.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-09-11T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-13_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/xcw51t74lt',
    img: 'http://embed.wistia.com/deliveries/8333034d447c2e4a340106b2fe007ac9.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-09-13T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-16_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/nwlwgndcde',
    img: 'http://embed.wistia.com/deliveries/3b864d6ea20b2bbdd7309983a36be65f.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-09-16T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-18_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/di2zehiw5i',
    img: 'http://embed.wistia.com/deliveries/c7bb491452d038fe111daaaf097f7fac.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-09-18T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-20_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/7xtlyv4o90',
    img: 'http://embed.wistia.com/deliveries/0c646707ba70e454d54b76533cadec0a.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-09-20T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-23_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/1pjmqx40o1',
    img: 'http://embed.wistia.com/deliveries/a836545ceb8195027a622203ac5a6400.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-09-23T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-25_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/zdz6snyof5',
    img: 'http://embed.wistia.com/deliveries/e4370fc29a878df3bea109cd767ad037.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-09-25T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-27_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/i5qtvcf2q3',
    img: 'http://embed.wistia.com/deliveries/29b20b4cc1c32d94f7fd4ce78e864233.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-09-27T00:00:00.000-08:00'),
  },
  {
    key: '2019-09-30_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/6iiwkby1tv',
    img: 'http://embed.wistia.com/deliveries/8e3a53dffee07a9381cac427eb7b02ab.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-09-30T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-02_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/qoteqj1fxy',
    img: 'http://embed.wistia.com/deliveries/65a8f92421373c733fcd4c8bdca9e542.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-10-02T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-04_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/mext778zc9',
    img: 'http://embed.wistia.com/deliveries/f32f0499ebc4600092f5cb21536af3d3.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-10-04T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-07_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/bbbktvumni',
    img: 'http://embed.wistia.com/deliveries/ab96ef31a50ca0404df450fcbb68d8e1.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-10-07T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-09_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/1ukodb0pkp',
    img: 'http://embed.wistia.com/deliveries/5a36e7c929df296b6d930ca1caf858c5.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-10-09T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-11_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/9hsm42oca9',
    img: 'http://embed.wistia.com/deliveries/ca43cc366ba3a661e07341d78bd7cd23.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-10-11T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-14_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/5yrk2zwg5d',
    img: 'http://embed.wistia.com/deliveries/35ce04d915e86a70b1187b793799da8b.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-10-14T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-16_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/eoo1afjr8q',
    img: 'http://embed.wistia.com/deliveries/7a2567c36eb5544c71d6636f4df9701f.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-10-16T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-18_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/xaz72t7hmx',
    img: 'http://embed.wistia.com/deliveries/7751ead83a7699b8367dc288049cdeb7.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-10-18T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-21_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/ojv6q2paad',
    img: 'http://embed.wistia.com/deliveries/a183cc2e1cb70bc004d06f8a7c22cb6a.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-10-21T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-23_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/7rs2gt8m3s',
    img: 'http://embed.wistia.com/deliveries/2c4a855aec974b20329a6ac9caf84761.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-10-23T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-25_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/3qet08drky',
    img: 'http://embed.wistia.com/deliveries/44db6069de0cef7011df114390102d11.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-10-25T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-28_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/xx5zll30uc',
    img: 'http://embed.wistia.com/deliveries/128b289776845ec02271e7dbc80cf2f9.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-10-28T00:00:00.000-08:00'),
  },
  {
    key: '2019-10-30_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/6vsteszilg',
    img: 'http://embed.wistia.com/deliveries/8a964d515d127a960ce7be4d4b6dd0b7.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-10-30T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-01_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/hvvfhufk9k',
    img: 'http://embed.wistia.com/deliveries/932b16282228bf259468d5824eeab855.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-11-01T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-04_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/0o7g8xxojf',
    img: 'http://embed.wistia.com/deliveries/45407f93c22189566045803d1dc21385.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-11-04T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-06_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/5ub6ghss7a',
    img: 'http://embed.wistia.com/deliveries/78b6bc04b51c7507aa73252337bdbff1.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-11-06T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-08_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/85cxsz8a79',
    img: 'http://embed.wistia.com/deliveries/c072230e58f5391ebbc2cca344f8417e.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-11-08T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-11_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/44csr2qyc3',
    img: 'http://embed.wistia.com/deliveries/125e2790791793fcffa9c6c0d010d030.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-11-11T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-13_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/1o6lcnd7lt',
    img: 'http://embed.wistia.com/deliveries/e98000817c705fcfdb07d901153491dc.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-11-13T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-15_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/rglhqy99wz',
    img: 'http://embed.wistia.com/deliveries/a67127c23c594b1f9d593a0d0520ba61.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-11-15T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-18_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/f9jkudx5jb',
    img: 'http://embed.wistia.com/deliveries/bb8f82c582204ed0b5df82f1fe8977be.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-11-18T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-20_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/40t7pgsa1x',
    img: 'http://embed.wistia.com/deliveries/f624eb36999dfc6cf7399bab22f416d6.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-11-20T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-22_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/tqusumkmvi',
    img: 'http://embed.wistia.com/deliveries/dda5698fbf96938a51b63299233d5770.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-11-22T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-25_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/qoteqj1fxy',
    img: 'http://embed.wistia.com/deliveries/65a8f92421373c733fcd4c8bdca9e542.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-11-25T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-27_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/5snl0tl9w1',
    img: 'http://embed.wistia.com/deliveries/b544416be97a988676617647fa439dfe.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-11-27T00:00:00.000-08:00'),
  },
  {
    key: '2019-11-29_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/ciy8r1nug6',
    img: 'http://embed.wistia.com/deliveries/0851f47976bb115bd53d82a6d368abf5.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-11-29T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-02_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/0wxs87lhbs',
    img: 'http://embed.wistia.com/deliveries/4eda0aa751351631559af305eee1a39a.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-12-02T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-04_30MIN_HIIT',
    url: 'https://doughcrm.wistia.com/medias/2xi5yc31ev',
    img: 'http://embed.wistia.com/deliveries/6d45458ca1864a481006b28bf74d3a3e.jpg?image_crop_resized=1024x576',
    title: '30 min HIIT',
    releaseDate: moment('2019-12-04T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-06_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/elcf4pg9z4',
    img: 'http://embed.wistia.com/deliveries/d01e81f94bafce79ba80539465c33c2f.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-12-06T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-09_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/wz9hiajxx7',
    img: 'http://embed.wistia.com/deliveries/e54fb49a0ee791fd87fe4665ec77a983.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-12-09T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-11_30MIN_MARATHON',
    url: 'https://doughcrm.wistia.com/medias/zc1ht2f9sx',
    img: 'http://embed.wistia.com/deliveries/0bc19f239534c74579e9a782ae736ec0.jpg?image_crop_resized=1024x576',
    title: '30 min Marathon',
    releaseDate: moment('2019-12-11T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-13_2MIN_PEP_TALK',
    url: 'https://doughcrm.wistia.com/medias/y6rmiluubf',
    img: 'http://embed.wistia.com/deliveries/5351124bbba827f66bf1c74899dd50d6.jpg?image_crop_resized=1024x576',
    title: '2 min Pep Talk',
    releaseDate: moment('2019-12-13T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-16_15MIN_ZEN_IPAS',
    url: 'https://doughcrm.wistia.com/medias/oog7j6u2bz',
    img: 'http://embed.wistia.com/deliveries/30ea0eeafa6e3c7e35134b6eccc13bf8.jpg?image_crop_resized=1024x576',
    title: '15 min Zen IPAs',
    releaseDate: moment('2019-12-16T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-18_20MIN_PROSPECTS',
    url: 'https://doughcrm.wistia.com/medias/6boa6igu26',
    img: 'http://embed.wistia.com/deliveries/dab7db6b8b66a8d0fdb4164b9a70ef4f.jpg?image_crop_resized=1024x576',
    title: '20 min Prospects',
    releaseDate: moment('2019-12-18T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-20_15MIN_IPA_BLASTER',
    url: 'https://doughcrm.wistia.com/medias/wmo3sst81l',
    img: 'http://embed.wistia.com/deliveries/c94cfb0eb106eb4583b464b4637889d9.jpg?image_crop_resized=1024x576',
    title: '15 min IPA Blaster',
    releaseDate: moment('2019-12-20T00:00:00.000-08:00'),
  },
  {
    key: '2019-12-23_5MIN_EASY_PEASY',
    url: 'https://doughcrm.wistia.com/medias/h96vci6j8a',
    img: 'http://embed.wistia.com/deliveries/c5a869bb33296c6e851e048e94cfb8a3.jpg?image_crop_resized=1024x576',
    title: '5 min Easy Peasy',
    releaseDate: moment('2019-12-23T00:00:00.000-08:00'),
  },
];
const videos = {
  PLEXUS: [
    {
      onboardingSection: 'prospects',
      key: 'ONBOARDING_IMPORT_FACEBOOK',
      url: 'https://doughcrm.wistia.com/medias/3gmz5iat7i',
      img: 'http://embed.wistia.com/deliveries/6d4e2da1a638b743d6a380f117d9e615.jpg?image_crop_resized=1024x576',
      title: 'Import Facebook',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    {
      onboardingSection: 'customers',
      key: 'ONBOARDING_IMPORT_PLEXUS_CUSTOMERS',
      url: 'https://doughcrm.wistia.com/medias/vx0ocn9y0g',
      img: 'http://embed.wistia.com/deliveries/9b2aa7c50c42f9cc6c2019fb5f904c7d.jpg?image_crop_resized=1024x576',
      title: 'Import Customers',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    {
      onboardingSection: 'consultants',
      key: 'ONBOARDING_IMPORT_PLEXUS_AMBASSADORS',
      url: 'https://doughcrm.wistia.com/medias/7vgcs48tmw',
      img: 'http://embed.wistia.com/deliveries/cad8e74f808e00850c3f3f8a0732a7ff.jpg?image_crop_resized=1024x576',
      title: 'Import Ambassadors',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    ...commonVideosForAllCompanies,
  ],
  MONAT: [
    {
      onboardingSection: 'prospects',
      key: 'ONBOARDING_IMPORT_FACEBOOK',
      url: 'https://doughcrm.wistia.com/medias/3gmz5iat7i',
      img: 'http://embed.wistia.com/deliveries/6d4e2da1a638b743d6a380f117d9e615.jpg?image_crop_resized=1024x576',
      title: 'Import Facebook',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    {
      onboardingSection: 'customers',
      key: 'ONBOARDING_IMPORT_MONAT_CUSTOMERS',
      url: 'https://doughcrm.wistia.com/medias/v6eexz8e7c',
      img: 'http://embed.wistia.com/deliveries/05ef676c1dff4827889a7d2805ec19f8.jpg?image_crop_resized=1024x576',
      title: 'Import Customers',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    {
      onboardingSection: 'consultants',
      key: 'ONBOARDING_IMPORT_MONAT_MARKET_PARTNERS',
      url: 'https://doughcrm.wistia.com/medias/m17qbis8sx',
      img: 'http://embed.wistia.com/deliveries/3b9fed2d562ee2c2893054fe33a119ee.jpg?image_crop_resized=1024x576',
      title: 'Import Market Partners',
      releaseDate: moment('2019-01-01T00:00:00.000-08:00'),
    },
    ...commonVideosForAllCompanies,
  ],
  DOTERRA: commonVideosForAllCompanies,
  ARBONNE: commonVideosForAllCompanies,
  BEACHBODY: commonVideosForAllCompanies,
  BEAUTYCOUNTER: commonVideosForAllCompanies,
  HEMPWORX: commonVideosForAllCompanies,
  ISAGENIX: commonVideosForAllCompanies,
  IT_WORKS: commonVideosForAllCompanies,
  JEUNESSE: commonVideosForAllCompanies,
  LE_VEL: commonVideosForAllCompanies,
  LIMELIFE: commonVideosForAllCompanies,
  MELALEUCA: commonVideosForAllCompanies,
  MODERE: commonVideosForAllCompanies,
  OPTAVIA: commonVideosForAllCompanies,
  OTHER: commonVideosForAllCompanies,
  PRUVIT: commonVideosForAllCompanies,
  RODAN_FIELDS: commonVideosForAllCompanies,
  SCENTSY: commonVideosForAllCompanies,
  SCOUT_CELLAR: commonVideosForAllCompanies,
  SENEGENCE: commonVideosForAllCompanies,
  SHAKLEE: commonVideosForAllCompanies,
  YOUNG_LIVING: commonVideosForAllCompanies,
  CREW: commonVideosForAllCompanies,
  YOUNIQUE: commonVideosForAllCompanies,
};

const standardWelcomeVideos = {
  desktopVideoUrl: 'https://doughcrm.wistia.com/medias/w6lhls0ngw',
  mobileVideoUrl: 'https://doughcrm.wistia.com/medias/g6skmgbjf8',
};
const welcomeVideoUrls = {
  PLEXUS: {
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/e3xikdv2q6',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/qhyvjhrm6q',
  },
  MONAT: {
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/oyvtt76aib',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/80ixq28699',
  },
  DOTERRA: {
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/12f6igqtoo',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/anhx5xz702',
  },
  ARBONNE: standardWelcomeVideos,
  BEACHBODY: standardWelcomeVideos,
  BEAUTYCOUNTER: standardWelcomeVideos,
  HEMPWORX: standardWelcomeVideos,
  ISAGENIX: standardWelcomeVideos,
  IT_WORKS: standardWelcomeVideos,
  JEUNESSE: standardWelcomeVideos,
  LE_VEL: standardWelcomeVideos,
  LIMELIFE: standardWelcomeVideos,
  MELALEUCA: standardWelcomeVideos,
  MODERE: standardWelcomeVideos,
  OPTAVIA: standardWelcomeVideos,
  OTHER: standardWelcomeVideos,
  PRUVIT: standardWelcomeVideos,
  RODAN_FIELDS: standardWelcomeVideos,
  SCENTSY: standardWelcomeVideos,
  SCOUT_CELLAR: {
    desktopVideoUrl: 'https://doughcrm.wistia.com/medias/u12xt4340v',
    mobileVideoUrl: 'https://doughcrm.wistia.com/medias/tpczxx8qsd',
  },
  SENEGENCE: standardWelcomeVideos,
  SHAKLEE: standardWelcomeVideos,
  YOUNG_LIVING: standardWelcomeVideos,
  CREW: standardWelcomeVideos,
  YOUNIQUE: standardWelcomeVideos,
};

function getVideos(company) {
  // Filter for videos that have been released (i.e., releaseDate is before time now).
  return filter(videos[company], video => video.releaseDate.isBefore());
}

function getWelcomeVideoUrls(company) {
  return welcomeVideoUrls[company];
}

export default {
  getVideos,
  getWelcomeVideoUrls,
};
