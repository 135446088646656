import { call } from 'redux-saga/effects';

import { DELETE_REMINDER } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const deleteReminder = sagaFetch.bind(null, DELETE_REMINDER, contactsResource.deleteReminder);

export default function* sagaDeleteReminder({ contact, reminder }) {
  yield call(deleteReminder, { contact, reminder });
  yield call(sagaRefresh, contact);
}
