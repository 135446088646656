import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import compact from 'lodash/compact';
import find from 'lodash/find';

import { DISPATCH_POST_QUICK_LOG } from 'actions/actionTypes';
import AutocompleteContacts from 'containers/AutocompleteContacts';
import Backdrop from 'components/Backdrop';
import Sparkles from 'components/Sparkles';
import QuickLogChips from './QuickLogChips';

const styles = theme => ({
  wrapperOn: {
    zIndex: '1201 !important',
    position: 'relative',
  },
  autocompleteWrapper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1.5}px`,
  },
  chipsWrapper: {
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 3}px`,
    overflow: 'hidden',
    height: theme.spacing.unit * 6,
  },
  chipsWrapperOn: {
    height: 'initial',
  },
  submitWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  button: {
    marginBottom: theme.spacing.unit / 2,
    // color: '#fff',
    textTransform: 'capitalize',
    boxShadow: 'none',
  },
  chipMore: {
    minWidth: 'initial',
  },
});

class QuickLog extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatchPostQuickLog: PropTypes.func.isRequired,
    contactsList: PropTypes.arrayOf(PropTypes.any),
    handleFocusOut: PropTypes.func,
  };

  static defaultProps = {
    contactsList: undefined,
    handleFocusOut: undefined,
  };

  state = {
    focused: false,
    contacts: [],
    chips: {
      popToday: false,
    },
    isAnimating: false,
  };

  componentDidMount() {
    const { contactsList } = this.props;
    if (contactsList) {
      this.openedFromContacts(contactsList);
    }
  }

  openedFromContacts = contactsList => {
    this.setState({
      focused: true,
      contacts: contactsList,
    });
  };

  handleFocusIn = () => {
    this.setState({
      focused: true,
    });
  };

  handleFocusOut = () => {
    const { contactsList, handleFocusOut } = this.props;
    if (contactsList) {
      handleFocusOut();
    } else {
      this.setState({
        focused: false,
      });
    }
  };

  handleReset = () => {
    this.handleFocusOut();
    this.setState({
      contacts: [],
      chips: {},
    });
  };

  handleContactsChange = contacts => {
    this.setState({ contacts });
  };

  handleChipsChange = (chip, value) => {
    this.setState(prevState => {
      const chips = {
        ...prevState.chips,
        [chip]: value,
      };
      if (chip === 'frequency') {
        chips.isFrequencyAutoAssigned = false;
      }
      return { chips };
    });
  };

  submit = () => {
    const { dispatchPostQuickLog } = this.props;
    const { contacts, chips } = this.state;

    this.setState({
      isAnimating: true,
    });
    setTimeout(() => {
      this.setState(
        {
          isAnimating: false,
        },
        () => {
          dispatchPostQuickLog(contacts, chips);
          this.handleReset();
        },
      );
    }, 550);
  };

  render() {
    const { classes, handleSubmit } = this.props;
    const { focused, contacts, chips, isAnimating } = this.state;
    let submitDisabled = isEmpty(contacts);
    if (!submitDisabled) {
      const hasExistingContacts = find(contacts, 'id');
      const vals = values(chips);
      if (chips.frequency === 0) {
        vals.push(true);
      }
      submitDisabled = hasExistingContacts && isEmpty(compact(vals));
    }
    return (
      <React.Fragment>
        <Backdrop active={focused} handleFocusOut={this.handleFocusOut} />
        <Paper
          className={classnames({
            [classes.wrapperOn]: focused,
          })}
          onClick={this.handleFocusIn}
        >
          <form onSubmit={handleSubmit(this.submit)}>
            <div className={classes.autocompleteWrapper}>
              <AutocompleteContacts
                placeholder="Who do you want to work on?"
                value={contacts}
                onFocus={this.handleFocusIn}
                onSelectionChange={this.handleContactsChange}
              />
            </div>
            <Divider />
            <Grid
              container
              className={classnames(classes.chipsWrapper, {
                [classes.chipsWrapperOn]: focused,
              })}
            >
              <Grid item sm={9} xs={9}>
                <QuickLogChips chips={chips} onChange={this.handleChipsChange} />
              </Grid>
              <Grid item sm={3} xs={3} className={classes.submitWrapper}>
                {!focused && (
                  <Chip
                    className={classnames(classes.button, classes.chipMore)}
                    label={<MoreHorizIcon />}
                    onClick={this.handleFocusIn}
                  />
                )}
                {focused && (
                  <Button
                    className={classes.button}
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitDisabled}
                  >
                    Save
                    <Sparkles isAnimating={isAnimating} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  null,
  {
    dispatchPostQuickLog: (contacts, chips) => ({ type: DISPATCH_POST_QUICK_LOG, contacts, chips }),
  },
);

const withForm = reduxForm({
  form: 'QuickLog',
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(QuickLog);
