import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { filter, includes, isEmpty } from 'lodash';

import EmptyState from 'components/EmptyState';
import TypographyPhone from 'components/TypographyPhone';
import TypographyEmail from 'components/TypographyEmail';
import TypographyAddress from 'components/TypographyAddress';
import TypographyWebsite from 'components/TypographyWebsite';
import TypographyContactID from 'components/TypographyContactID';
import TypographySource from 'components/TypographySource';
import ContactInfoValue from './ContactInfoValue';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing.unit * 2,
  },
});

const fields = [
  'instagramUsername',
  'lastName',
  'phoneNumbers',
  'emailAddresses',
  'sponsor',
  'level',
  'rank',
  'previousRank',
  'status',
  'subscription',
  'company',
  'position',
  'address',
  'website',
  'source',
  'pv',
  'lastActiveDate',
  'totalSpend',
  'totalOrders',
  'orderHistory',
];

class ContactInfo extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    contact: PropTypes.shape({}).isRequired,
  };

  render() {
    const { classes, contact } = this.props;
    const contactFields = filter(contact, (value, key) => !isEmpty(value) && includes(fields, key));
    if (isEmpty(contactFields)) {
      return (
        <EmptyState>
          <Typography variant="inherit" color="inherit">
            {`There's no contact info for ${contact.name}.`}
          </Typography>
          <Typography variant="inherit" color="inherit">
            Click on the pencil icon above to add or edit!
          </Typography>
        </EmptyState>
      );
    }

    const {
      instagramUsername,
      phoneNumber: phone,
      email,
      spreadsheetContactId: id,
      joinDate,
      sponsor,
      level,
      rank,
      previousRank,
      status,
      subscription,
      address,
      website,
      company,
      position,
      source,
      pv,
      firstName,
      lastName,
      contactType,
      lastActiveDate,
      totalSpend,
      totalOrders,
      orderHistory,
    } = contact;

    const lastActiveDateLabel = source === 'SCOUT_CELLAR_SYNC' ? 'Last Order Date' : 'Last Active Date';

    return (
      <div className={classes.root}>
        <ContactInfoValue label="Instagram Handle" value={instagramUsername} />
        <ContactInfoValue label="Phone" value={<TypographyPhone variant="body1">{phone}</TypographyPhone>} />
        <ContactInfoValue label="Email" value={<TypographyEmail variant="body1">{email}</TypographyEmail>} />
        <ContactInfoValue label="Address" value={<TypographyAddress variant="body1">{address}</TypographyAddress>} />
        <ContactInfoValue label="Website" value={<TypographyWebsite variant="body1">{website}</TypographyWebsite>} />
        <ContactInfoValue label="Company" value={company} />
        <ContactInfoValue label="Position" value={position} />
        <ContactInfoValue
          label="Source"
          value={
            <TypographySource firstName={firstName} lastName={lastName} variant="body1">
              {source}
            </TypographySource>
          }
        />
        <ContactInfoValue
          label="ID"
          value={
            id && (
              <TypographyContactID source={source} contactType={contactType} variant="body1">
                {id}
              </TypographyContactID>
            )
          }
        />
        <ContactInfoValue label="Join Date" value={joinDate && moment(joinDate).format('LL')} />
        <ContactInfoValue label="Sponsor" value={sponsor} />
        <ContactInfoValue label="Level" value={level} />
        <ContactInfoValue label="Rank" value={rank} />
        <ContactInfoValue label="Previous Rank" value={previousRank} />
        <ContactInfoValue label="Status" value={status} />
        <ContactInfoValue label="PV" value={pv} />
        <ContactInfoValue label="Subscription" value={subscription} />
        <ContactInfoValue label="Total Spend" value={totalSpend && parseFloat(totalSpend).toFixed(2)} />
        <ContactInfoValue label="Total Orders" value={totalOrders} />
        <ContactInfoValue label={lastActiveDateLabel} value={lastActiveDate && moment(lastActiveDate).format('LL')} />
        <ContactInfoValue label="Product (Qty)" value={orderHistory} />
      </div>
    );
  }
}

export default withStyles(styles)(ContactInfo);
