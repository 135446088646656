import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

import TypographyLiquid from 'components/TypographyLiquid';
import TypographyEmail from 'components/TypographyEmail';
import { selectCurrentUser } from 'reducers/selectors';

const styles = theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
});

class Plan extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      subscriptionExpiresAt: PropTypes.string,
      planKey: PropTypes.string,
    }).isRequired,
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      expireText: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const {
      classes,
      data: { name, expireText, price },
      currentUser: { subscriptionExpiresAt, planKey, email },
    } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} paragraph>
          <Icon className={classes.icon} color="primary">
            check_circle
          </Icon>
          {name}
        </Typography>
        <TypographyLiquid
          values={{ subscriptionExpiresAt: moment(subscriptionExpiresAt).format('LL'), price: `$${price}` }}
          paragraph
        >
          {expireText}
        </TypographyLiquid>
        {planKey === 'PLAN_TRIAL' ? (
          <Typography>
            {'If you wish to continue after the trial ends, you can subscribe to a '}
            {email === 'diane.h.wolfman@gmail.com' ? (
              <Typography inline>paid plan</Typography>
            ) : (
              <a href="https://www.doughcrm.com/#pricing" target="_blank" rel="noopener noreferrer">
                paid plan
              </a>
            )}
            {' afterwards. If you wish to discontinue, no action is needed.'}
          </Typography>
        ) : (
          <Typography>
            {'To change or cancel your '}
            <a href="https://www.doughcrm.com/#pricing" target="_blank" rel="noopener noreferrer">
              plan
            </a>
            {', please email '}
            <TypographyEmail subject="Subscription change" component="span" inline>
              support@doughcrm.com
            </TypographyEmail>
            .
          </Typography>
        )}
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Plan);
