import { schema } from 'normalizr';

import resource from './resource';

// Interceptors
function processStrategy(data) {
  const res = resource.processStrategy(data);
  return {
    ...res,
    name: res.firstName + (res.lastName ? ` ${res.lastName}` : ''),
  };
}

// Schemas
const userSchema = new schema.Entity('users', {}, { processStrategy });

export default {
  processStrategy,
  userSchema,
};
