import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Chip from 'components/Chip';
import IconArrowUp from '../../assets/IconArrowUp';

const styles = theme => ({
  icon: {
    padding: 1,
    marginLeft: theme.spacing.unit,
  },
});

class ChipPopToday extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: false,
  };

  render() {
    const { classes, value, onChange, ...other } = this.props;
    return (
      <Chip
        icon={<IconArrowUp className={classes.icon} />}
        label="Pop to Today"
        onClick={() => onChange(!value)}
        selected={value}
        {...other}
      />
    );
  }
}

export default withStyles(styles)(ChipPopToday);
