import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconFrequency = props => (
  <SvgIcon width="704" height="704" viewBox="0 0 528 528" {...props}>
    <g fill="#7cb342">
      <path d="M262.883 484C141.883 484 44 385 44 264S141.883 44 262.883 44c90.234 0 172.734 57.191 204.617 141.883l-40.691 15.426C401.5 134.19 335.5 88 262.883 88 166.117 88 88 167.191 88 264s78.117 176 174.883 176c71.5 0 135.308-42.883 162.808-110l40.692 16.5C432.309 430.117 353.117 484 262.883 484z" />
      <path d="M495 121l-33 110-99-40.691z" />
    </g>
  </SvgIcon>
);

export default IconFrequency;
