import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconFace = props => (
  <SvgIcon width="704" height="704" viewBox="0 0 528 528" {...props}>
    <path
      d="M165 297c0 24.191-19.809 44-44 44s-44-19.809-44-44 19.809-44 44-44 44 19.809 44 44m286 0c0-24.191-19.809-44-44-44s-44 19.809-44 44 19.809 44 44 44 44-19.809 44-44"
      fill="#ffa726"
    />
    <path
      d="M110 209c0-139.691 308-113.309 308-22v143c0 91.309-90.191 165-154 165s-154-73.691-154-165z"
      fill="#ffb74d"
    />
    <path
      d="M88 212.309c5.5-66 18.691-130.926 99-146.309l22-33h55c101.191 0 176 82.5 176 198v37.383L407 308v-66c0-115.5-148.5 48.383-220-99-3.309 60.5-66 88-66 88v77l-33-39.617V231c0-6.617-1.117-12.117 0-18.691"
      fill="#424242"
    />
    <path
      d="M363 308c0 12.117-9.883 22-22 22s-22-9.883-22-22 9.883-22 22-22 22 9.883 22 22m-154 0c0-12.117-9.883-22-22-22s-22 9.883-22 22 9.883 22 22 22 22-9.883 22-22"
      fill="#784719"
    />
    <path d="M220 407h88v22h-88z" fill="#ff9100" />
  </SvgIcon>
);

export default IconFace;
