import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import EmptyState from 'components/EmptyState';
import PopoverReminder from 'components/PopoverReminder';
import PopoverActivity from 'components/PopoverActivity';
import IconReachOut from 'assets/IconReachOut';
import IconReminder from 'assets/IconReminder';
import { selectContactCard } from 'reducers/selectors';
import {
  DISPATCH_FETCH_CONTACT_CARD,
  DISPATCH_SAVE_REMINDER,
  DISPATCH_SAVE_ACTIVITY,
  DISPATCH_DELETE_REMINDER,
  DISPATCH_DELETE_ACTIVITY,
} from 'actions/actionTypes';

const styles = theme => ({
  listItemIcon: {
    position: 'absolute',
    top: theme.spacing.unit * 1.5,
  },
  listItemText: {
    paddingLeft: theme.spacing.unit * 5,
    whiteSpace: 'pre-line',
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
});

class ContactCardValues extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contact: PropTypes.shape({ id: PropTypes.number }).isRequired,
    cardValues: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchFetchContactCardValues: PropTypes.func.isRequired,
    dispatchSaveReminder: PropTypes.func.isRequired,
    dispatchSaveActivity: PropTypes.func.isRequired,
    dispatchDeleteReminder: PropTypes.func.isRequired,
    dispatchDeleteActivity: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: undefined,
    cardValues: undefined,
  };

  state = {
    anchorEl: undefined,
    value: undefined,
  };

  componentDidMount() {
    const { dispatchFetchContactCardValues, contact } = this.props;
    if (contact.id) {
      dispatchFetchContactCardValues(contact);
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatchFetchContactCardValues, contact } = this.props;
    if (contact.id && contact.id !== prevProps.contact.id) {
      dispatchFetchContactCardValues(contact);
    }
  }

  handleClick = (event, value) => {
    this.setState({
      anchorEl: event.currentTarget,
      value,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: undefined,
      value: undefined,
    });
    return true;
  };

  getIcon = activityType => {
    if (activityType === 'REACH_OUT') {
      return <IconReachOut />;
    }
    return <IconReminder />;
  };

  render() {
    const {
      classes,
      contact,
      cardValues,
      dispatchSaveReminder,
      dispatchSaveActivity,
      dispatchDeleteReminder,
      dispatchDeleteActivity,
    } = this.props;
    if (cardValues && isEmpty(cardValues)) {
      return (
        <EmptyState>
          {`You haven't logged any activity for ${contact.name} yet.`}
          <br />
          Click below to start!
        </EmptyState>
      );
    }

    const { anchorEl, value } = this.state;

    return (
      <React.Fragment>
        <List>
          {map(cardValues, v => (
            <ListItem key={`${v.type}_${v.id}`} button onClick={event => this.handleClick(event, v)}>
              <ListItemIcon className={classes.listItemIcon}>{this.getIcon(v.activityType)}</ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={v.notes || '-'}
                secondary={v.date.format('ddd MM/DD/YY')}
              />
            </ListItem>
          ))}
        </List>
        <PopoverReminder
          open={!!value && value.type === 'reminders'}
          anchorEl={anchorEl}
          value={value}
          title="Edit Reminder"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
          onSubmit={data => this.handleClose() && data && dispatchSaveReminder(contact, data)}
          secondaryAction={
            <Button
              className={classes.buttonDelete}
              size="small"
              onClick={() => this.handleClose() && dispatchDeleteReminder(contact, value)}
            >
              Delete
            </Button>
          }
        />
        <PopoverActivity
          open={!!value && value.type === 'activities'}
          anchorEl={anchorEl}
          value={value}
          title="Edit Activity"
          onClose={this.handleClose}
          onSubmit={data => this.handleClose() && data && dispatchSaveActivity(contact, data)}
          secondaryAction={
            <Button
              className={classes.buttonDelete}
              size="small"
              onClick={() => this.handleClose() && dispatchDeleteActivity(contact, value)}
            >
              Delete
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    cardValues: selectContactCard,
  }),
  {
    dispatchFetchContactCardValues: contact => ({ type: DISPATCH_FETCH_CONTACT_CARD, contact }),
    dispatchSaveReminder: (contact, reminder) => ({ type: DISPATCH_SAVE_REMINDER, contact, reminder }),
    dispatchSaveActivity: (contact, activity) => ({ type: DISPATCH_SAVE_ACTIVITY, contact, activity }),
    dispatchDeleteReminder: (contact, reminder) => ({ type: DISPATCH_DELETE_REMINDER, contact, reminder }),
    dispatchDeleteActivity: (contact, activity) => ({ type: DISPATCH_DELETE_ACTIVITY, contact, activity }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(ContactCardValues);
