import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import map from 'lodash/map';

import Autocomplete from 'components/Autocomplete';
import { selectContacts } from 'reducers/selectors';
import { DISPATCH_FETCH_CONTACTS } from 'actions/actionTypes';

class AutocompleteContacts extends Component {
  static propTypes = {
    contacts: PropTypes.shape({}),
    placeholder: PropTypes.string,
    dispatchFetchContacts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contacts: null,
    placeholder: '',
  };

  toOptions = contacts =>
    map(contacts, contact => ({
      ...contact,
      value: `${contact.id}`,
      label: contact.name,
    }));

  handleLoadOptions = (query, callback) => {
    const { dispatchFetchContacts } = this.props;
    dispatchFetchContacts(query, contacts => {
      const options = this.toOptions(contacts);
      callback(options);
    });
  };

  render() {
    const { contacts, placeholder, ...other } = this.props;
    const defaultOptions = this.toOptions(contacts);
    return (
      <Autocomplete
        placeholder={placeholder}
        disableUnderline
        defaultOptions={defaultOptions}
        loadOptions={this.handleLoadOptions}
        {...other}
      />
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    contacts: selectContacts,
  }),
  {
    dispatchFetchContacts: (query, callback) => ({ type: DISPATCH_FETCH_CONTACTS, query, callback }),
  },
);

export default compose(withConnect)(AutocompleteContacts);
