import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { join, compact } from 'lodash';

import IconFacebook from 'assets/IconFacebook';
import IconInstagram from 'assets/IconInstagram';
import IconLinkedin from 'assets/IconLinkedin';

const styles = theme => ({
  icon: {
    fontSize: 16,
    opacity: 1,
    marginRight: theme.spacing.unit * 0.25,
    marginBottom: -theme.spacing.unit * 0.6,
    transition: 'opacity 0.3 linear',

    '&:hover': {
      opacity: 0.6,
    },
  },
});

class SocialMediaShortcuts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    contact: PropTypes.shape({}),
  };

  static defaultProps = {
    className: null,
    contact: {},
  };

  handleSocialMediaShortcut = e => {
    e.stopPropagation();
  };

  render() {
    const {
      classes,
      className,
      contact: { instagramUsername, firstName, lastName, source },
    } = this.props;

    let instagramIcon;
    if (instagramUsername) {
      instagramIcon = (
        <a
          href={`https://www.instagram.com/${instagramUsername}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.handleSocialMediaShortcut}
        >
          <IconInstagram className={classes.icon} />
        </a>
      );
    }

    let facebookIcon;
    if (firstName && lastName) {
      facebookIcon = (
        <a
          href={`https://www.facebook.com/search/top/?q=${join(compact([firstName, lastName]), '%20')}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.handleSocialMediaShortcut}
        >
          <IconFacebook className={classes.icon} />
        </a>
      );
    }

    let linkedinIcon;
    if (source && source === 'LINKEDIN_CONNECTIONS') {
      linkedinIcon = (
        <a
          href={`https://www.linkedin.com/search/results/all/?keywords=${join(compact([firstName, lastName]), '%20')}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.handleSocialMediaShortcut}
        >
          <IconLinkedin className={classes.icon} />
        </a>
      );
    }

    return (
      <span className={className}>
        {instagramIcon}
        {facebookIcon}
        {linkedinIcon}
      </span>
    );
  }
}

export default withStyles(styles)(SocialMediaShortcuts);
