import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player';

import history from 'config/history';
import { selectLoading, selectCurrentUser } from 'reducers/selectors';
import deviceService from 'services/deviceService';
import videosService from 'services/videosService';

const isMobileDevice = deviceService.isMobile();
const desktopAspectRatio = 16 / 9;
const mobileAspectRatio = 1 / 1;
const videoRatio = isMobileDevice ? mobileAspectRatio : desktopAspectRatio;

const styles = theme => ({
  wrapper: {
    margin: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 3.5}px`,
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 3,
  },
  buttonSubmit: {
    marginLeft: theme.spacing.unit,
  },
  playerWrapper: {
    marginTop: theme.spacing.unit * 2,
    position: 'relative',
    paddingTop: `${100 / videoRatio}%`,
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

class OnboardingWelcome extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      playing: false,
    };
  }

  handleStartClick = () => {
    const {
      currentUser: { company },
    } = this.props;

    if (company === 'SCOUT_CELLAR') {
      history.push('/onboarding/connect-sc');
    } else {
      history.push('/onboarding/prospects');
    }
  };

  handlePlayerReady = () => {
    // TODO: Confirm if disabling auto-play for all Import screens (onboarding and Import modals).
    this.setState({ playing: false });
  };

  handlePlayerError = error => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  render() {
    const {
      classes,
      loading,
      currentUser: { firstName, email, company },
    } = this.props;
    const { playing } = this.state;
    const config = { wistia: { options: { email } } };

    const { desktopVideoUrl, mobileVideoUrl } = videosService.getWelcomeVideoUrls(company);
    const url = isMobileDevice ? mobileVideoUrl : desktopVideoUrl;

    return (
      <React.Fragment>
        <div className={classes.wrapper}>
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              Hi, {firstName}!
            </Typography>
          </React.Fragment>

          <div className={classes.playerWrapper}>
            <ReactPlayer
              ref={player => {
                this.player = player;
              }}
              className={classes.player}
              config={config}
              width="100%"
              height="100%"
              controls
              url={url}
              playing={playing}
              onReady={this.handlePlayerReady}
              onError={this.handlePlayerError}
            />
          </div>

          <div className={classes.actions}>
            <Button
              className={classes.buttonSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => this.handleStartClick()}
            >
              Next
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(OnboardingWelcome);
