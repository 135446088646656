import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Liquid from 'liquid-node';

const engine = new Liquid.Engine();

class TypographyLiquid extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    this.handleUpdate();
  }

  handleUpdate = async () => {
    const { children, values } = this.props;
    const text = await engine.parseAndRender(children, values);
    this.setState({ text });
  };

  render() {
    const { children, values, ...other } = this.props;
    const { text } = this.state;
    return <Typography {...other}>{text}</Typography>;
  }
}

TypographyLiquid.propTypes = {
  children: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default TypographyLiquid;
