import { fork, takeLatest } from 'redux-saga/effects';

import {
  DISPATCH_SIGN_UP,
  DISPATCH_SIGN_IN,
  DISPATCH_SIGN_OUT,
  DISPATCH_RESET_PASSWORD,
  DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN,
  DISPATCH_SET_NEW_PASSWORD,
  DISPATCH_IMPORT_CONTACTS,
  DISPATCH_FETCH_INITIAL_DATA,
  DISPATCH_FETCH_ACCOUNT,
  DISPATCH_FETCH_CONTACTS,
  DISPATCH_FINISH_ONBOARDING,
  DISPATCH_FETCH_CONTACT_CARD,
  DISPATCH_FETCH_CONTACT_IMPORT_STATS,
  DISPATCH_FETCH_WORK_LIST,
  DISPATCH_FETCH_ACTIVE_REMINDERS,
  DISPATCH_FETCH_RECENT_ACTIVITY,
  DISPATCH_FETCH_ACTIVITY_STATS,
  DISPATCH_FETCH_ALL_ACTIVITY_STATS,
  DISPATCH_FETCH_SCRIPTS,
  DISPATCH_SAVE_ACCOUNT,
  DISPATCH_SAVE_PAYMENT,
  DISPATCH_SAVE_PLAN,
  DISPATCH_SAVE_CONTACT,
  DISPATCH_SAVE_REMINDER,
  DISPATCH_SAVE_ACTIVITY,
  DISPATCH_DELETE_CONTACTS,
  DISPATCH_DELETE_REMINDER,
  DISPATCH_DELETE_ACTIVITY,
  DISPATCH_POST_QUICK_LOG,
  DISPATCH_CONNECT_TO_SC,
  DISPATCH_SYNC_WITH_SC,
} from 'actions/actionTypes';
import sagaCheckLoading from './sagaCheckLoading';
import sagaSignUp from './sagaSignUp';
import sagaSignIn from './sagaSignIn';
import sagaSignOut from './sagaSignOut';
import sagaResetPassword from './sagaResetPassword';
import sagaValidateResetPasswordToken from './sagaValidateResetPasswordToken';
import sagaSetNewPassword from './sagaSetNewPassword';
import sagaImportContacts from './sagaImportContacts';
import sagaFinishOnboarding from './sagaFinishOnboarding';
import sagaFetchInitialData from './sagaFetchInitialData';
import sagaFetchAccount from './sagaFetchAccount';
import sagaFetchContacts from './sagaFetchContacts';
import sagaFetchContactCardValues from './sagaFetchContactCardValues';
import sagaFetchContactImportStats from './sagaFetchContactImportStats';
import sagaFetchWorkList from './sagaFetchWorkList';
import sagaFetchActiveReminders from './sagaFetchActiveReminders';
import sagaFetchRecentActivity from './sagaFetchRecentActivity';
import sagaFetchActivityStats from './sagaFetchActivityStats';
import sagaFetchAllActivityStats from './sagaFetchAllActivityStats';
import sagaFetchScripts from './sagaFetchScripts';
import sagaSaveAccount from './sagaSaveAccount';
import sagaSavePayment from './sagaSavePayment';
import sagaSavePlan from './sagaSavePlan';
import sagaSaveContact from './sagaSaveContact';
import sagaSaveReminder from './sagaSaveReminder';
import sagaSaveActivity from './sagaSaveActivity';
import sagaDeleteContacts from './sagaDeleteContacts';
import sagaDeleteReminder from './sagaDeleteReminder';
import sagaDeleteActivity from './sagaDeleteActivity';
import sagaPostQuickLog from './sagaPostQuickLog';
import sagaConnectSCAccount from './sagaConnectSCAccount';
import sagaSyncSCAccount from './sagaSyncSCAccount';

export default function* sagas() {
  yield fork(sagaCheckLoading);
  yield takeLatest(DISPATCH_SIGN_UP, sagaSignUp);
  yield takeLatest(DISPATCH_SIGN_IN, sagaSignIn);
  yield takeLatest(DISPATCH_SIGN_OUT, sagaSignOut);
  yield takeLatest(DISPATCH_RESET_PASSWORD, sagaResetPassword);
  yield takeLatest(DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN, sagaValidateResetPasswordToken);
  yield takeLatest(DISPATCH_SET_NEW_PASSWORD, sagaSetNewPassword);
  yield takeLatest(DISPATCH_IMPORT_CONTACTS, sagaImportContacts);
  yield takeLatest(DISPATCH_FINISH_ONBOARDING, sagaFinishOnboarding);
  yield takeLatest(DISPATCH_FETCH_INITIAL_DATA, sagaFetchInitialData);
  yield takeLatest(DISPATCH_FETCH_ACCOUNT, sagaFetchAccount);
  yield takeLatest(DISPATCH_FETCH_CONTACTS, sagaFetchContacts);
  yield takeLatest(DISPATCH_FETCH_CONTACT_CARD, sagaFetchContactCardValues);
  yield takeLatest(DISPATCH_FETCH_CONTACT_IMPORT_STATS, sagaFetchContactImportStats);
  yield takeLatest(DISPATCH_FETCH_WORK_LIST, sagaFetchWorkList);
  yield takeLatest(DISPATCH_FETCH_ACTIVE_REMINDERS, sagaFetchActiveReminders);
  yield takeLatest(DISPATCH_FETCH_RECENT_ACTIVITY, sagaFetchRecentActivity);
  yield takeLatest(DISPATCH_FETCH_ACTIVITY_STATS, sagaFetchActivityStats);
  yield takeLatest(DISPATCH_FETCH_ALL_ACTIVITY_STATS, sagaFetchAllActivityStats);
  yield takeLatest(DISPATCH_FETCH_SCRIPTS, sagaFetchScripts);
  yield takeLatest(DISPATCH_SAVE_ACCOUNT, sagaSaveAccount);
  yield takeLatest(DISPATCH_SAVE_PAYMENT, sagaSavePayment);
  yield takeLatest(DISPATCH_SAVE_PLAN, sagaSavePlan);
  yield takeLatest(DISPATCH_SAVE_CONTACT, sagaSaveContact);
  yield takeLatest(DISPATCH_SAVE_REMINDER, sagaSaveReminder);
  yield takeLatest(DISPATCH_SAVE_ACTIVITY, sagaSaveActivity);
  yield takeLatest(DISPATCH_DELETE_CONTACTS, sagaDeleteContacts);
  yield takeLatest(DISPATCH_DELETE_REMINDER, sagaDeleteReminder);
  yield takeLatest(DISPATCH_DELETE_ACTIVITY, sagaDeleteActivity);
  yield takeLatest(DISPATCH_POST_QUICK_LOG, sagaPostQuickLog);
  yield takeLatest(DISPATCH_CONNECT_TO_SC, sagaConnectSCAccount);
  yield takeLatest(DISPATCH_SYNC_WITH_SC, sagaSyncSCAccount);
}
