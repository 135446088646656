import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import capitalize from 'capitalize';

class TypographyCapitalize extends Component {
  static propTypes = {
    children: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { children, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }
    return <Typography {...other}>{capitalize.words(children.replace(/_/g, ' '))}</Typography>;
  }
}

export default TypographyCapitalize;
