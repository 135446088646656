import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = () => ({
  icon: {
    color: 'inherit',
  },
});

class IconArrowDropDown extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
  };

  render() {
    const { classes, className, ...other } = this.props;
    return <ArrowDropDownIcon className={classnames(className, classes.icon)} {...other} />;
  }
}

export default compose(withStyles(styles))(IconArrowDropDown);
