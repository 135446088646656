import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SnackbarMaterial from '@material-ui/core/Snackbar';
import { isObject, forEach } from 'lodash';

import SnackbarContentWrapper from './SnackbarContentWrapper';

class Snackbar extends Component {
  static propTypes = {
    skipSnackbar: PropTypes.bool,
    timestamp: PropTypes.number,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.arrayOf(PropTypes.string)]),
  };

  static defaultProps = {
    skipSnackbar: false,
    variant: 'success',
    content: undefined,
    timestamp: undefined,
  };

  state = {
    open: false,
    messageInfo: {},
  };

  queue = [];

  componentDidUpdate(prevProps) {
    const { skipSnackbar, content, timestamp } = this.props;
    if (!skipSnackbar && (content && timestamp !== prevProps.timestamp)) {
      if (isObject(content)) {
        forEach(content, this.add);
      } else {
        this.add(content);
      }
    }
  }

  add = message => {
    this.queue.push({
      message,
      key: new Date().getTime(),
    });

    const { open } = this.state;

    if (open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.setState({ open: false });
    } else {
      this.processQueue();
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleExited = () => {
    this.processQueue();
  };

  processQueue = () => {
    if (this.queue.length > 0) {
      this.setState({
        messageInfo: this.queue.shift(),
        open: true,
      });
    }
  };

  render() {
    const { variant } = this.props;
    const {
      messageInfo: { message, key },
      open,
    } = this.state;
    return (
      <SnackbarMaterial
        key={key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
      >
        <SnackbarContentWrapper onClose={this.handleClose} variant={variant || 'info'} message={message} />
      </SnackbarMaterial>
    );
  }
}

export default Snackbar;
