import { call } from 'redux-saga/effects';

import { SYNC_WITH_SC } from 'actions/actionTypes';
import syncAccountResource from 'resources/syncAccountResource';
import history from 'config/history';
import sagaFetch from './sagaFetch';
import sagaFetchAttempt from './sagaFetchAttempt';

// bind resource generator
const syncSCAccount = sagaFetch.bind(null, SYNC_WITH_SC, syncAccountResource.syncSCAccount);

export default function* sagaSyncSCAccount() {
  const syncRes = yield call(syncSCAccount);
  if (!syncRes.error) {
    const attemptRes = yield call(sagaFetchAttempt, { id: syncRes.data.id, label: 'Customers and Consultants' });
    if (!attemptRes.error) {
      history.push('/onboarding/prospects');
    }
  }
}
