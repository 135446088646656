import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { isEqual, find, filter, map } from 'lodash';
import queryString from 'query-string';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import SelectWithCounters from 'components/SelectWithCounters';
import history from 'config/history';
import scriptsService from 'services/scriptsService';
import { selectScripts, selectCurrentUser } from 'reducers/selectors';
import { DISPATCH_FETCH_SCRIPTS } from 'actions/actionTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    margin: `${theme.spacing.unit * 2}px 12%`,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  tableWrapper: {
    width: '100%',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterSecondaryText: {
    color: 'gray',
  },
  listItem: {
    paddingRight: 124,
    margin: theme.spacing.unit * 2,
  },
  action: {
    top: 'initial',
    right: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 1,
    bottom: 0,
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
  },
  body: {
    whiteSpace: 'pre-wrap',
    fontSize: 16,
    lineHeight: 1.5,
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

class Scripts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    scripts: PropTypes.shape({}),
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    dispatchFetchScripts: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scripts: undefined,
  };

  timer = null;

  state = {
    filters: [],
    scriptsCopied: {},
  };

  componentDidMount() {
    const {
      dispatchFetchScripts,
      currentUser: { company },
    } = this.props;

    this.setState({ filters: scriptsService.getFilters(company) });

    dispatchFetchScripts();
    this.handleScriptsUpdate();
  }

  componentDidUpdate(prevProps) {
    const { scripts } = this.props;
    if (!isEqual(scripts, prevProps.scripts)) {
      this.handleScriptsUpdate();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleScriptsUpdate = () => {
    const { scripts } = this.props;
    this.setState(prevState => ({
      filters: map(prevState.filters, f => ({
        ...f,
        items: filter(scripts, f.filterBy),
      })),
    }));
  };

  getCurrentFilter = () => {
    const {
      location: { search },
    } = this.props;
    const { filters } = this.state;
    return find(filters, { key: queryString.parse(search).filter }) || filters[0];
  };

  handleFilterChange = key => {
    history.push(key ? `/scripts?filter=${key}` : '/scripts');
  };

  handleScriptCopied = id => {
    this.setState(prevState => ({
      scriptsCopied: {
        ...prevState.scriptsCopied,
        [id]: true,
      },
    }));
    this.timer = setTimeout(() => {
      this.setState(prevState => ({
        scriptsCopied: {
          ...prevState.scriptsCopied,
          [id]: false,
        },
      }));
    }, 2000);
  };

  render() {
    const { classes, width } = this.props;
    const { filters, scriptsCopied } = this.state;
    const currentFilter = this.getCurrentFilter();

    if (!currentFilter) {
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={/xs/.test(width) ? 0 : 16}>
            {/lg|xl/.test(width) && (
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <Paper className={classes.paper}>
                  <MenuList>
                    {map(filters, ({ key, label, items }) => (
                      <MenuItem
                        key={key}
                        className={classes.filter}
                        value={key}
                        selected={currentFilter.key === key}
                        onClick={() => this.handleFilterChange(key)}
                      >
                        <span>{label}</span>
                        {items && <span className={classes.filterSecondaryText}>{`${items.length}`}</span>}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Grid>
            )}
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Paper className={classes.tableWrapper}>
                {!/lg|xl/.test(width) && (
                  <Toolbar>
                    <SelectWithCounters items={filters} value={currentFilter.key} onChange={this.handleFilterChange} />
                  </Toolbar>
                )}
                <List>
                  {map(currentFilter.items, (item, index) => (
                    <React.Fragment key={item.id}>
                      {index > 0 && <Divider />}
                      <ListItem
                        classes={{
                          secondaryAction: classes.listItem,
                        }}
                      >
                        <ListItemText
                          classes={{
                            primary: classes.title,
                            secondary: classes.body,
                          }}
                          primary={item.title}
                          secondary={item.body}
                        />
                        <ListItemSecondaryAction className={classes.action}>
                          <CopyToClipboard text={item.body} onCopy={() => this.handleScriptCopied(item.id)}>
                            <Button variant="contained" color="primary" size="small">
                              {scriptsCopied[item.id] ? 'Copied!' : 'Copy'}
                            </Button>
                          </CopyToClipboard>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    scripts: selectScripts,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchScripts: () => ({ type: DISPATCH_FETCH_SCRIPTS }),
  },
);

export default compose(
  withConnect,
  withWidth(),
  withStyles(styles),
)(Scripts);
