import { createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';

const createThemeDough = theme =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: 'MuseoSans',
    },
    palette: {
      primary: cyan,
      secondary: orange,
      background: {
        paper: theme.palette.common.white,
        default: '#e9ebee',
      },
    },
    overrides: {
      // MuiButton
      MuiButton: {
        root: {
          borderRadius: 100,
          textTransform: 'none',
          fontSize: 14,
          fontWeight: 'bold',
          minWidth: 80,
        },
        sizeSmall: {
          minWidth: 80,
        },
        containedPrimary: {
          color: '#fff',
          boxShadow: 'none',
        },
      },
      // MuiChip
      MuiChip: {
        root: {
          backgroundColor: '#f5f5f5',
        },
      },
      // MuiMenu
      MuiMenu: {
        paper: {
          minWidth: 220,
        },
      },
      // MuiCheckbox
      MuiCheckbox: {
        colorPrimary: {
          color: cyan[500],
        },
      },
      // MuiExpansionPanel
      MuiExpansionPanel: {
        expanded: {
          margin: 0,
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          paddingLeft: theme.spacing.unit,
          '&$expanded': {
            minHeight: theme.spacing.unit * 6,
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
        },
        expandIcon: {
          right: 0,
        },
      },
    },
  });

export default createThemeDough;
