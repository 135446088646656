import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FooterPlayer extends Component {
  static propTypes = {
    seconds: PropTypes.number.isRequired,
  };

  pad = string => `0${string}`.slice(-2);

  format = seconds => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = this.pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${this.pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  render() {
    const { seconds } = this.props;
    return <time dateTime={`P${Math.round(seconds)}S`}>{this.format(seconds)}</time>;
  }
}

export default FooterPlayer;
