import { createSelector } from 'reselect';
import {
  omitBy,
  map,
  get,
  groupBy,
  mapKeys,
  compact,
  orderBy,
  uniqWith,
  isEqual,
  take,
  filter,
  head,
  values,
} from 'lodash';
import moment from 'moment';

const selectEntities = state => state.entities;
const selectAppStatus = state => state.appStatus;
const selectAuth = state => state.auth;
const selectId = (_, props) => props.id || get(props, 'match.params.id');
const selectStats = state => state.stats;

export const selectMessage = state => state.message;

export const selectReady = createSelector(
  selectAppStatus,
  status => status.ready,
);

export const selectLoading = createSelector(
  selectAppStatus,
  status => status.loading,
);

export const selectLoadingMessage = createSelector(
  selectAppStatus,
  status => status.loadingMessage,
);

// users
export const selectUsers = createSelector(
  selectEntities,
  entities => omitBy(entities.users, 'deleted'),
);

export const selectCurrentUserId = createSelector(
  selectAuth,
  auth => auth.user,
);

export const selectCurrentUser = createSelector(
  selectUsers,
  selectCurrentUserId,
  (users, id) => users[id],
);

// reminders
export const selectReminders = createSelector(
  selectEntities,
  entities => omitBy(entities.reminders, 'deleted'),
);

// activities
export const selectActivities = createSelector(
  selectEntities,
  entities => omitBy(entities.activities, 'deleted'),
);

// contacts
export const selectContacts = createSelector(
  selectEntities,
  entities => omitBy(entities.contacts, 'deleted'),
);

export const selectContact = createSelector(
  selectContacts,
  selectId,
  (contacts, id) => contacts[id],
);

const selectContactCardValues = createSelector(
  selectContact,
  contact => get(contact, 'cardValues'),
);

export const selectContactCard = createSelector(
  selectContactCardValues,
  selectReminders,
  selectActivities,
  (cardValues, reminders, activities) => {
    const res = map(cardValues, value => (value.schema === 'reminders' ? reminders[value.id] : activities[value.id]));
    return uniqWith(compact(res), isEqual);
  },
);

// scripts
export const selectScripts = createSelector(
  selectEntities,
  entities => omitBy(entities.scripts, 'deleted'),
);

// S3
const selectS3SignatureKey = state => state.S3;

export const selectS3Signatures = createSelector(
  selectEntities,
  entities => omitBy(entities.S3, 'deleted'),
);

export const selectS3Signature = createSelector(
  selectS3Signatures,
  selectS3SignatureKey,
  (signatures, key) => signatures[key],
);

// upload attempts
export const selectUploadAttempts = createSelector(
  selectEntities,
  entities => entities.uploadAttempts,
);

export const makeSelectUploadAttempt = id =>
  createSelector(
    selectUploadAttempts,
    attempts => attempts[id],
  );

// stats
export const selectStatsActivity = createSelector(
  selectStats,
  stats => stats.activity,
);

export const selectAllStatsActivity = createSelector(
  selectStats,
  stats => stats.allStats,
);

export const selectStatsImport = createSelector(
  selectStats,
  stats => stats.import,
);

// work list
export const selectWorkList = state => state.workList;

export const selectWorkListSuccess = createSelector(
  selectWorkList,
  workList => workList.success,
);

export const selectWorkListEntities = createSelector(
  selectEntities,
  entities => entities.workListItems,
);

export const selectCurrentUserWorkListSettings = createSelector(
  selectCurrentUser,
  currentUser => get(currentUser, 'workListSettings'),
);

export const selectWorkListGroupedByType = createSelector(
  selectWorkList,
  selectWorkListEntities,
  selectContacts,
  selectReminders,
  selectActivities,
  selectStatsActivity,
  selectCurrentUserWorkListSettings,
  (workList, entities, contacts, reminders, activities, stats, workListSettings) => {
    // eslint-disable-next-line no-console
    console.log(
      `selectWorkListGroupedByType workListSettings: ${JSON.stringify(workListSettings)}, workList.factor: ${
        workList.factor
      }`,
    );
    const getWorkListItems = (type, max) => {
      const ids = get(workList, `${type}.id`, []);
      // eslint-disable-next-line no-console
      console.log(`selectWorkListGroupedByType max: ${max}, type: ${type}, ids: ${JSON.stringify(ids)}`);
      const items = map(ids, id => {
        const item = entities[id];
        if (!item) {
          return null;
        }
        const contact = contacts[item.contact];
        const data = reminders[item.oldestReminder] || activities[item.lastActivity];
        return { id, contact, data };
      });
      // All reminders that are overdue or due today need to show up in Today's IPAs
      // regardless of how many reach-outs user has already done.
      // https://trello.com/c/5lP9cyRf/59-list-view-fixes
      const numReminders = filter(items, { data: { type: 'reminders' } }).length;
      return take(compact(items), Math.max(max, numReminders));
    };

    // eslint-disable-next-line no-console
    console.log(`selectWorkListGroupedByType stats: ${JSON.stringify(stats)}`);

    return {
      coldProspects: getWorkListItems(
        'coldProspects',
        workList.factor * workListSettings.coldProspects - stats.COLD_PROSPECT,
      ),
      warmProspects: getWorkListItems(
        'warmProspects',
        workList.factor * workListSettings.warmProspects - stats.WARM_PROSPECT,
      ),
      hotProspects: getWorkListItems(
        'hotProspects',
        workList.factor * workListSettings.hotProspects - stats.HOT_PROSPECT,
      ),
      customers: getWorkListItems('customers', workList.factor * workListSettings.customers - stats.CUSTOMER),
      consultants: getWorkListItems('consultants', workList.factor * workListSettings.consultants - stats.CONSULTANT),
    };
  },
);

// active reminders
const selectActiveReminders = state => state.activeReminders;

export const selectActiveRemindersSuccess = createSelector(
  selectActiveReminders,
  activeReminders => activeReminders.success,
);

export const selectActiveRemindersIds = createSelector(
  selectActiveReminders,
  activeReminders => activeReminders.reminders,
);

export const selectActiveRemindersGroupedByType = createSelector(
  selectActiveRemindersIds,
  selectReminders,
  selectContacts,
  (ids, reminders, contacts) => {
    // eslint-disable-next-line no-console
    console.log(`selectActiveRemindersGroupedByType ids: ${JSON.stringify(ids)}`);
    let items = map(ids, id => {
      const data = reminders[id];
      const contact = data ? contacts[data.contactId] : null;
      if (!data || !contact) {
        return null;
      }
      return {
        data,
        contact,
        id: data.id,
      };
    });
    items = orderBy(compact(items), ['data.date'], ['asc']);
    return mapKeys(groupBy(items, item => item.contact.contactType), (value, key) => `${key.toLowerCase()}s`);
  },
);

// recent activity
const selectRecentActivity = state => state.recentActivity;

export const selectRecentActivitySuccess = createSelector(
  selectRecentActivity,
  recentActivity => recentActivity.success,
);

export const selectRecentActivityIds = createSelector(
  selectRecentActivity,
  recentActivity => recentActivity.activities,
);

export const selectRecentActivityGroupedByDate = createSelector(
  selectRecentActivityIds,
  selectActivities,
  selectContacts,
  (ids, activities, contacts) => {
    // eslint-disable-next-line no-console
    console.log(`selectRecentActivityGroupedByDate ids: ${JSON.stringify(ids)}`);
    const items = map(ids, id => {
      const data = activities[id];
      const contact = data ? contacts[data.contactId] : null;
      if (!data || !contact) {
        return null;
      }
      return {
        data,
        contact,
        id: data.id,
        date: moment(data.date).format('ddd MM/DD/YY'),
      };
    });
    return groupBy(compact(items), 'date');
  },
);

// access tokens
const selectAccessTokens = createSelector(
  selectEntities,
  entities => entities.accessTokens,
);

export const selectAccessToken = createSelector(
  selectAccessTokens,
  tokens => head(values(tokens)),
);
