import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ChipSetReminder from 'components/ChipSetReminder';
import ChipLogReachOut from 'components/ChipLogReachOut';
import ChipEditFrequency from 'components/ChipEditFrequency';
import ChipEditType from 'components/ChipEditType';
import ChipEditStatus from '../../components/ChipEditStatus/ChipEditStatus';
import ChipPopToday from '../../components/ChipPopToday/ChipPopToday';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: (-1 * theme.spacing.unit) / 2,
  },
  chip: {
    justifyContent: 'left',
    margin: theme.spacing.unit / 2,
    backgroundColor: '#f5f5f5',
  },
});

class QuickLogChips extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    chips: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChange = value => {
    const { onChange } = this.props;
    const { frequency, contactType, contactStatus } = value;
    onChange('reachOut', value);
    onChange('frequency', frequency);
    onChange('contactType', contactType);
    onChange('contactStatus', contactStatus);
  };

  render() {
    const { classes, chips, onChange } = this.props;
    return (
      <div className={classes.root}>
        <ChipSetReminder
          className={classes.chip}
          value={chips.reminder}
          onChange={value => onChange('reminder', value)}
        />
        <ChipLogReachOut className={classes.chip} value={chips.reachOut} onChange={value => this.onChange(value)} />
        <ChipPopToday className={classes.chip} value={chips.popToday} onChange={value => onChange('popToday', value)} />
        <ChipEditFrequency
          className={classes.chip}
          value={chips.frequency}
          onChange={value => onChange('frequency', value)}
        />
        <ChipEditStatus
          className={classes.chip}
          value={chips.contactStatus}
          onChange={value => onChange('contactStatus', value)}
        />
        <ChipEditType
          className={classes.chip}
          value={chips.contactType}
          onChange={value => onChange('contactType', value)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(QuickLogChips);
