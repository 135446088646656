// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    padding: '18.5px 14px',
    cursor: 'text',
  },
});

class StripeInput extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
    component: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    placeholder: undefined,
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
  };

  render() {
    const { component: Component, classes, theme, placeholder, onFocus, onBlur, onChange } = this.props;
    return (
      <Component
        className={classes.root}
        style={{
          base: {
            fontSize: '16px',
            fontFamily: `${theme.typography.fontFamily}, sans-serif`,
          },
        }}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(StripeInput);
