import { get } from 'lodash';

import actionsService from 'services/actionsService';

const initialState = {
  timestamp: undefined,
  content: undefined,
  variant: undefined,
  skipSnackbar: false,
};

export default function createMessageReducer() {
  return (state = initialState, action) => {
    let variant = 'error';
    let content = actionsService.getMessage(action);
    if (content) {
      variant = 'success';
    } else {
      const error = actionsService.getApiError(action, true);
      if (error) {
        content =
          get(error, 'response.data.errors') ||
          get(error, 'response.statusText') ||
          error.message ||
          error.data ||
          error;
      }
    }
    return content
      ? {
          timestamp: new Date().getTime(),
          content,
          variant,
          skipSnackbar: variant === 'success' && actionsService.skipSnackbar(action),
        }
      : state;
  };
}
