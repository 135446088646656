import { all, call } from 'redux-saga/effects';
import map from 'lodash/map';

import history from 'config/history';
import sagaFetchContactCardValues from './sagaFetchContactCardValues';
import sagaFetchActivityStats from './sagaFetchActivityStats';
import sagaFetchWorkList from './sagaFetchWorkList';
import sagaFetchActiveReminders from './sagaFetchActiveReminders';
import sagaFetchRecentActivity from './sagaFetchRecentActivity';

let {
  location: { pathname },
} = history;
history.listen(location => {
  // eslint-disable-next-line prefer-destructuring
  pathname = location.pathname;
});

export default function* sagaRefresh(contact) {
  const calls = [];
  if (/activities/.test(pathname) && contact) {
    calls.push(call(sagaFetchContactCardValues, { contact }));
  }
  if (/home/.test(pathname) || /contacts/.test(pathname)) {
    calls.push(call(sagaFetchActivityStats));
  }
  if (/work/.test(pathname)) {
    calls.push(call(sagaFetchWorkList));
  } else if (/active-reminders/.test(pathname)) {
    calls.push(call(sagaFetchActiveReminders));
  } else if (/recent-activity/.test(pathname)) {
    calls.push(call(sagaFetchRecentActivity));
  }
  // eslint-disable-next-line no-console
  console.log(`Refresh: ${map(calls, 'CALL.fn.name').join(', ')}`);
  yield all(calls);
}
