import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconLinkedin = props => (
  <SvgIcon width="528" height="528" viewBox="0 0 528 528" {...props}>
    <path
      fill="#0288D1"
      d="M 462 407 C 462 437.378906 437.378906 462 407 462 L 121 462 C 90.621094 462 66 437.378906 66 407 L 66 121 C 66 90.621094 90.621094 66 121 66 L 407 66 C 437.378906 66 462 90.621094 462 121 Z "
    />
    <path fill="#FFFFFF" d="M 132 209 L 187 209 L 187 396 L 132 396 Z " />
    <path
      fill="#FFFFFF"
      d="M 159.328125 187 L 159.027344 187 C 142.613281 187 132 174.753906 132 159.5 C 132 143.859375 142.957031 132 159.671875 132 C 176.386719 132 186.699219 143.859375 187 159.5 C 187 174.753906 176.386719 187 159.328125 187 Z "
    />
    <path
      fill="#FFFFFF"
      d="M 396 396 L 341 396 L 341 295.925781 C 341 271.734375 327.507813 255.234375 305.894531 255.234375 C 289.394531 255.234375 280.457031 266.363281 276.117188 277.105469 C 274.527344 280.972656 275 291.628906 275 297 L 275 396 L 220 396 L 220 209 L 275 209 L 275 237.789063 C 282.949219 225.5 295.367188 209 327.121094 209 C 366.480469 209 396 233.75 396 289.007813 Z "
    />
  </SvgIcon>
);

export default IconLinkedin;
