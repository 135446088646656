import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Checkbox, Typography, withWidth } from '@material-ui/core';
import { isEqual, isEmpty } from 'lodash';
import capitalize from 'capitalize';
import history from 'config/history';
import cookie from 'react-cookies';
import moment from 'moment';

import { DISPATCH_SAVE_CONTACT, DISPATCH_FETCH_CONTACTS } from 'actions/actionTypes';
import { selectContacts, selectContact, selectCurrentUser, selectContactCard } from 'reducers/selectors';
import stringsService from 'services/stringsService';
import DialogContact from 'containers/DialogContact';
import DialogContactEdit from 'containers/DialogContactEdit';
import ChipEditFrequency from 'components/ChipEditFrequency';
import ChipEditType from 'components/ChipEditType';
import MUIDataTable from 'components/MUIDataTable';
import ChipEditStatus from 'components/ChipEditStatus';
import SocialMediaShortcuts from 'components/SocialMediaShortcuts';

const styles = theme => ({
  root: {
    margin: `${theme.spacing.unit * 2}px 12%`,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  tableWrapper: {
    width: '100%',
  },
  circle: {
    marginLeft: 5,
    '@media (max-width:959.95px)': {
      // eslint-disable-line no-useless-computed-key
      marginLeft: 0,
      marginTop: 4,
    },
  },
  socialMedia: {
    whiteSpace: 'nowrap',
  },
  textColumn: {
    maxWidth: '100px',
    fontSize: 'inherit',
  },
  chipLite: {
    backgroundColor: 'transparent !important',
    '& span': {
      width: 'initial',
    },
  },
  orangeText: {
    color: '#f5af47',
  },
  checkboxListTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    textAlign: 'left',
    marginLeft: '7px',
    fontWeight: '500',
  },
  checkboxRoot: {
    margin: '0 0 0 15px',
    padding: '5px',
    '&$checkboxChecked': {
      color: '#00bcd4',
    },
  },
  checkboxChecked: {},
  filterCount: {
    color: 'rgba(0, 0, 0, 0.44)',
  },
});

class Contacts extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        section: PropTypes.string,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    contacts: PropTypes.shape({}),
    contact: PropTypes.shape({}),
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    cardValues: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchFetchContacts: PropTypes.func.isRequired,
    dispatchSaveContact: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contacts: undefined,
    contact: undefined,
    cardValues: undefined,
  };

  state = {
    filters: [],
    invertedSocialMedia: false,
  };

  componentDidMount() {
    const { dispatchFetchContacts } = this.props;
    dispatchFetchContacts();
    this.handleContactsUpdate();
  }

  componentDidUpdate(prevProps) {
    const { cardValues, dispatchFetchContacts } = this.props;

    if (!isEqual(prevProps.cardValues, cardValues)) {
      dispatchFetchContacts();
    }
  }

  handleContactsUpdate = () => {
    const {
      location: { search },
      currentUser: { company },
      currentUser,
    } = this.props;
    const { filters } = this.state;
    const currentFilter = queryString.parse(search).filter;
    const updatedFilters = filters;
    let invertedSocialMedia = false;
    if (currentFilter) {
      if (currentFilter === 'cold_prospects') {
        if (currentUser && currentUser.workListProspectFilter) {
          if (currentUser.workListProspectFilter === 'ALL') {
            updatedFilters[1] = ['Cold'];
            updatedFilters[8] = ['Prospect'];
          } else if (currentUser.workListProspectFilter === 'EXCLUDE_INSTAGRAM') {
            updatedFilters[1] = ['Cold'];
            updatedFilters[8] = ['Prospect'];
            updatedFilters[4] = ['Facebook'];
            invertedSocialMedia = true;
          } else if (currentUser.workListProspectFilter === 'ONLY_INSTAGRAM') {
            updatedFilters[1] = ['Cold'];
            updatedFilters[8] = ['Prospect'];
            updatedFilters[4] = ['Instagram'];
          }
        }
      } else if (currentFilter === 'warm_prospects') {
        updatedFilters[1] = ['Warm'];
        updatedFilters[8] = ['Prospect'];
      } else if (currentFilter === 'hot_prospects') {
        updatedFilters[1] = ['Hot'];
        updatedFilters[8] = ['Prospect'];
      } else if (currentFilter === 'customers') {
        updatedFilters[8] = [stringsService.getCustomerLabel(company)];
      } else if (currentFilter === 'consultants') {
        updatedFilters[8] = [stringsService.getConsultantLabel(company)];
      }
    }

    this.setState({
      filters: updatedFilters,
      invertedSocialMedia,
    });
  };

  updateFilters = filters => {
    this.setState({
      filters,
      invertedSocialMedia: false,
    });
  };

  renderCheckbox = (filterName, paramKey, paramValue, params) => {
    const { classes } = this.props;
    const { filterList, index, column, onChange, contacts, cssStyle } = params;

    return (
      <FormControlLabel
        control={
          <Checkbox
            id={`${paramValue}`}
            checked={filterList[index].indexOf(filterName) >= 0}
            onChange={() => {
              filterList[index] = this.handleClickCheckbox(filterList[index], filterName);
              onChange(filterList[index], index, column);
            }}
            classes={{
              root: classes.checkboxRoot,
              checked: classes.checkboxChecked,
            }}
          />
        }
        label={
          <label htmlFor={`${paramValue}`} style={cssStyle ? { color: cssStyle.color } : null}>
            {filterName}{' '}
            <span className={classes.filterCount}>
              ({Object.values(contacts).filter(item => item[paramKey] === paramValue).length || 0})
            </span>
          </label>
        }
        key={paramValue}
      />
    );
  };

  handleClickCheckbox = (array, el) => {
    let updatedArray = array;
    if (updatedArray.indexOf(el) >= 0) {
      updatedArray = array.filter(item => item !== el);
    } else {
      updatedArray.push(el);
    }
    return updatedArray;
  };

  render() {
    const {
      classes,
      contact,
      contacts,
      match: {
        params: { section },
      },
      currentUser: { company },
      location: { search },
      dispatchSaveContact,
    } = this.props;
    const { filters, invertedSocialMedia } = this.state;
    const colsVisibility = cookie.load('colsVisibility') || {
      rowData: false,
      contactStatus: true,
      firstName: true,
      lastName: true,
      socialMedia: true,
      latestActivity: true,
      latestDate: true,
      frequency: true,
      type: true,
      joinDate: true,
      notes: true,
      level: false,
      rank: false,
      subscription: false,
      totalSpend: false,
      totalOrders: false,
      lastActiveDate: false,
      orderHistory: false,
      email: false,
      phoneNumber: false,
    };

    const columns = [
      {
        name: 'rowData',
        label: '',
        options: {
          display: colsVisibility.rowData,
          filter: false,
          sort: false,
          download: false,
          viewColumns: false,
        },
      },
      {
        name: 'contactStatus',
        label: 'Interest',
        options: {
          display: colsVisibility.contactStatus,
          filter: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (!isEmpty(v) && typeof v === 'string') {
              return v;
            }
            return false;
          },
          filterList: filters[1],
          filterOptions: {
            names: [],
            logic(status, filterVals) {
              const statusName = {
                COLD: 'Cold',
                WARM: 'Warm',
                HOT: 'Hot',
              };
              if (!isEmpty(filterVals)) {
                return filterVals.indexOf(statusName[status]) === -1;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => {
              const checkBoxes = [
                {
                  filterName: 'Cold',
                  paramValue: 'COLD',
                  cssStyle: {
                    color: '#1565C0',
                  },
                },
                {
                  filterName: 'Warm',
                  paramValue: 'WARM',
                  cssStyle: {
                    color: '#FF9000',
                  },
                },
                {
                  filterName: 'Hot',
                  paramValue: 'HOT',
                  cssStyle: {
                    color: '#F13A00',
                  },
                },
              ];
              return (
                <div>
                  <Typography className={classes.checkboxListTitle}>Interest</Typography>
                  {checkBoxes.map(item =>
                    this.renderCheckbox(item.filterName, 'contactStatus', item.paramValue, {
                      filterList,
                      onChange,
                      index,
                      column,
                      contacts,
                      cssStyle: item.cssStyle,
                    }))}
                </div>
              );
            },
          },
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return (
              <ChipEditStatus
                short
                value={parcedContact.contactStatus}
                onChange={contactStatus => {
                  dispatchSaveContact({ ...parcedContact, contactStatus });
                }}
                className={classes.circle}
              />
            );
          },
        },
      },
      {
        name: 'firstName',
        label: 'First Name',
        options: {
          display: colsVisibility.firstName,
          filter: false,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return <strong>{parcedContact.firstName}</strong>;
          },
        },
      },
      {
        name: 'lastName',
        label: 'Last Name',
        options: {
          display: colsVisibility.lastName,
          filter: false,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return <strong>{parcedContact.lastName}</strong>;
          },
        },
      },
      {
        name: 'socialMedia',
        label: 'Social Media',
        options: {
          display: colsVisibility.socialMedia,
          filter: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (!isEmpty(v) && typeof v === 'string') {
              return v;
            }
            return false;
          },
          filterList: filters[4],
          filterOptions: {
            logic(socialMedia, filterVals) {
              if (!isEmpty(filterVals)) {
                let toShow = false;
                if (invertedSocialMedia) {
                  toShow = isEqual(socialMedia, ['Facebook']);
                } else {
                  socialMedia.map(item => {
                    if (filterVals.indexOf(item) >= 0) {
                      toShow = true;
                    }
                    return null;
                  });
                }
                return !toShow;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <Typography className={classes.checkboxListTitle}>Social Media</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="Instagram"
                      checked={filterList[index].indexOf('Instagram') >= 0}
                      onChange={() => {
                        const newFilterList = filterList;
                        newFilterList[index] = this.handleClickCheckbox(filterList[index], 'Instagram');
                        onChange(newFilterList[index], index, column);
                      }}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked,
                      }}
                    />
                  }
                  label={
                    <label htmlFor="Instagram">
                      Instagram{' '}
                      <span className={classes.filterCount}>
                        ({Object.values(contacts).filter(item => item.instagramUsername).length || 0})
                      </span>
                    </label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="Facebook"
                      checked={filterList[index].indexOf('Facebook') >= 0}
                      onChange={() => {
                        const newFilterList = filterList;
                        newFilterList[index] = this.handleClickCheckbox(filterList[index], 'Facebook');
                        onChange(newFilterList[index], index, column);
                      }}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked,
                      }}
                    />
                  }
                  label={
                    <label htmlFor="Facebook">
                      Facebook{' '}
                      <span className={classes.filterCount}>
                        ({Object.values(contacts).filter(item => item.firstName && item.lastName).length || 0})
                      </span>
                    </label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="LinkedIn"
                      checked={filterList[index].indexOf('LinkedIn') >= 0}
                      onChange={() => {
                        const newFilterList = filterList;
                        newFilterList[index] = this.handleClickCheckbox(filterList[index], 'LinkedIn');
                        onChange(newFilterList[index], index, column);
                      }}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked,
                      }}
                    />
                  }
                  label={
                    <label htmlFor="LinkedIn">
                      LinkedIn{' '}
                      <span className={classes.filterCount}>
                        (
                        {Object.values(contacts).filter(item => item.source && item.source === 'LINKEDIN_CONNECTIONS')
                          .length || 0}
                        )
                      </span>
                    </label>
                  }
                />
              </div>
            ),
          },
          sort: false,
          download: false,
          customBodyRender: (value, rowData) => {
            const parsedContact = JSON.parse(rowData.rowData[0]);
            return <SocialMediaShortcuts className={classes.socialMedia} contact={parsedContact} />;
          },
        },
      },
      {
        name: 'latestActivity',
        label: 'Latest Activity',
        options: {
          display: colsVisibility.latestActivity,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { oldestReminder, lastActivity } = JSON.parse(rowData.rowData[0]);
            let notesText;
            if (oldestReminder) {
              notesText = oldestReminder.notes;
            } else if (lastActivity) {
              notesText = lastActivity.notes;
            }
            return (
              <Typography className={classes.textColumn} noWrap>
                {notesText}
              </Typography>
            );
          },
        },
      },
      {
        name: 'latestDate',
        label: 'Latest Date',
        options: {
          display: colsVisibility.latestDate,
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            let lastActiveData;
            let lastActivity;
            if (parcedContact.oldestReminder) {
              lastActiveData = parcedContact.oldestReminder;
            } else if (parcedContact.lastActivity) {
              lastActiveData = parcedContact.lastActivity;
            }
            if (lastActiveData) {
              const date = moment(lastActiveData.datetime || lastActiveData.date);
              if (date.isSame(moment(), 'day')) {
                lastActivity = lastActiveData.type === 'reminders' ? 'today' : 'Today';
              } else if (date.isSame(moment().add(1, 'day'), 'day')) {
                lastActivity = 'in 1 day';
              } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
                lastActivity = '1 day ago';
              } else {
                lastActivity = date.fromNow();
              }
            }

            if (parcedContact.oldestReminder) {
              lastActivity = <span className={classes.orangeText}>{lastActivity}</span>;
            }
            return lastActivity;
          },
        },
      },
      {
        name: 'frequency',
        label: 'Frequency',
        options: {
          display: colsVisibility.frequency,
          filter: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (!isEmpty(v) && typeof v === 'string') {
              return v;
            }
            return false;
          },
          filterList: filters[7],
          filterOptions: {
            logic(frequency, filterVals) {
              const frequencyName = {
                1: 'Every day',
                2: 'Every 2 days',
                3: 'Every 3 days',
                7: 'Every week',
                15: 'Every 2 weeks',
                30: 'Every month',
                60: 'Every 2 months',
                90: 'Every 3 months',
                180: 'Every 6 months',
                Infinity: 'Never',
              };
              if (!isEmpty(filterVals)) {
                return filterVals.indexOf(frequencyName[frequency]) === -1;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => {
              const checkBoxes = [
                {
                  filterName: 'Every day',
                  paramValue: 1,
                },
                {
                  filterName: 'Every 2 days',
                  paramValue: 2,
                },
                {
                  filterName: 'Every 3 days',
                  paramValue: 3,
                },
                {
                  filterName: 'Every week',
                  paramValue: 7,
                },
                {
                  filterName: 'Every 2 weeks',
                  paramValue: 15,
                },
                {
                  filterName: 'Every month',
                  paramValue: 30,
                },
                {
                  filterName: 'Every 2 months',
                  paramValue: 60,
                },
                {
                  filterName: 'Every 3 months',
                  paramValue: 90,
                },
                {
                  filterName: 'Every 6 months',
                  paramValue: 180,
                },
                {
                  filterName: 'Never',
                  paramValue: 0,
                },
              ];
              return (
                <div>
                  <Typography className={classes.checkboxListTitle}>Frequency</Typography>
                  {checkBoxes.map(item =>
                    this.renderCheckbox(item.filterName, 'frequency', item.paramValue, {
                      filterList,
                      onChange,
                      index,
                      column,
                      contacts,
                    }))}
                </div>
              );
            },
          },
          sort: true,
          download: false,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return (
              <ChipEditFrequency
                short
                withArrow
                className={classes.chipLite}
                value={parcedContact.frequency}
                onChange={frequency =>
                  dispatchSaveContact({ ...parcedContact, frequency, isFrequencyAutoAssigned: false })
                }
              />
            );
          },
        },
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          display: colsVisibility.type,
          filter: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (!isEmpty(v) && typeof v === 'string') {
              return v;
            }
            return false;
          },
          filterList: filters[8],
          filterOptions: {
            names: ['Prospect', stringsService.getCustomerLabel(company), stringsService.getConsultantLabel(company)],
            logic(type, filterVals) {
              const typeName = {
                PROSPECT: 'Prospect',
                CUSTOMER: stringsService.getCustomerLabel(company),
                CONSULTANT: stringsService.getConsultantLabel(company),
              };
              if (!isEmpty(filterVals)) {
                return filterVals.indexOf(typeName[type]) === -1;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => {
              const checkBoxes = [
                {
                  filterName: 'Prospect',
                  paramValue: 'PROSPECT',
                },
                {
                  filterName: stringsService.getCustomerLabel(company),
                  paramValue: 'CUSTOMER',
                },
                {
                  filterName: stringsService.getConsultantLabel(company),
                  paramValue: 'CONSULTANT',
                },
              ];
              return (
                <div>
                  <Typography className={classes.checkboxListTitle}>Type</Typography>
                  {checkBoxes.map(item =>
                    this.renderCheckbox(item.filterName, 'contactType', item.paramValue, {
                      filterList,
                      onChange,
                      index,
                      column,
                      contacts,
                    }))}
                </div>
              );
            },
          },
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return (
              <ChipEditType
                short
                withArrow
                className={classes.chipLite}
                value={parcedContact.contactType}
                onChange={contactType => dispatchSaveContact({ ...parcedContact, contactType })}
              />
            );
          },
        },
      },
      {
        name: 'joinDate',
        label: 'Join Date',
        options: {
          display: colsVisibility.joinDate,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const parcedContact = JSON.parse(rowData.rowData[0]);
            return moment(new Date(parcedContact.joinDate)).format('MM/DD/YYYY');
          },
        },
      },
      {
        name: 'notes',
        label: 'Notes',
        options: {
          display: colsVisibility.notes,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { notes } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {notes}
              </Typography>
            );
          },
        },
      },
      {
        name: 'level',
        label: 'Level',
        options: {
          display: colsVisibility.level,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { level } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {level}
              </Typography>
            );
          },
        },
      },
      {
        name: 'rank',
        label: 'Rank',
        options: {
          display: colsVisibility.rank,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { rank } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {rank}
              </Typography>
            );
          },
        },
      },
      {
        name: 'subscription',
        label: 'Subscription',
        options: {
          display: colsVisibility.subscription,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { subscription } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {subscription}
              </Typography>
            );
          },
        },
      },
      {
        name: 'totalSpend',
        label: 'Total Spend',
        options: {
          display: colsVisibility.totalSpend,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { totalSpend } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {totalSpend && parseFloat(totalSpend).toFixed(2)}
              </Typography>
            );
          },
        },
      },
      {
        name: 'totalOrders',
        label: 'Total Orders',
        options: {
          display: colsVisibility.totalOrders,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { totalOrders } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {totalOrders}
              </Typography>
            );
          },
        },
      },
      {
        name: 'lastActiveDate',
        label: 'Last Order Date',
        options: {
          display: colsVisibility.totalSpend,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { lastActiveDate } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {lastActiveDate && moment(lastActiveDate).format('MM/DD/YYYY')}
              </Typography>
            );
          },
        },
      },
      {
        name: 'orderHistory',
        label: 'Product (Qty)',
        options: {
          display: colsVisibility.orderHistory,
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, rowData) => {
            const { orderHistory } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                {orderHistory}
              </Typography>
            );
          },
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          display: colsVisibility.email,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { email } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  {email}
                </a>
              </Typography>
            );
          },
        },
      },
      {
        name: 'phoneNumber',
        label: 'Phone',
        options: {
          display: colsVisibility.phoneNumber,
          filter: false,
          sort: true,
          download: true,
          customBodyRender: (value, rowData) => {
            const { phoneNumber } = JSON.parse(rowData.rowData[0]);
            return (
              <Typography className={classes.textColumn} noWrap>
                <a
                  href={`sms:${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  {phoneNumber}
                </a>
              </Typography>
            );
          },
        },
      },
    ];

    const data =
      Object.keys(contacts).length !== 0
        ? Object.values(contacts).map(item => {
            // Contact Status for Sorting and Download
            let contactStatus;
            if (item.contactStatus === 'HOT') {
              contactStatus = '1_Hot';
            } else if (item.contactStatus === 'WARM') {
              contactStatus = '2_Warm';
            } else if (item.contactStatus === 'COLD') {
              contactStatus = '3_Cold';
            }

            // Social Media Data for Filter
            const socialMediaSort = [];
            if (item.instagramUsername) {
              socialMediaSort.push('Instagram');
            }
            if (item.firstName && item.lastName) {
              socialMediaSort.push('Facebook');
            }
            if (item.source && item.source === 'LINKEDIN_CONNECTIONS') {
              socialMediaSort.push('LinkedIn');
            }

            // Notes for Sorting and Download
            let notesText;
            if (item.oldestReminder) {
              notesText = item.oldestReminder.notes;
            } else if (item.lastActivity) {
              notesText = item.lastActivity.notes;
            }

            // Latest Activity Data for Sorting
            let lastActiveData;
            let lastActivity;
            if (item.oldestReminder) {
              lastActiveData = item.oldestReminder;
            } else if (item.lastActivity) {
              lastActiveData = item.lastActivity;
            }
            if (lastActiveData) {
              lastActivity = lastActiveData.datetime || lastActiveData.date;
            }

            // Subscription data for Sorting and Download
            let subscription;
            if (item.subscription) {
              if (item.subscription.startsWith('Currently subscribed')) {
                subscription = `1_${item.subscription}`;
              } else if (item.subscription.startsWith('Previously subscribed')) {
                subscription = `2_${item.subscription}`;
              } else if (item.subscription.startsWith('Never subscribed')) {
                subscription = `3_${item.subscription}`;
              }
            }

            // Total Spend data for Sorting
            let totalSpend;
            if (item.totalSpend) {
              totalSpend = parseFloat(item.totalSpend);
            }

            return [
              JSON.stringify(item),
              contactStatus,
              item.firstName,
              item.lastName,
              socialMediaSort,
              notesText,
              lastActivity,
              item.frequency !== 0 ? item.frequency : Infinity,
              capitalize.words(item.contactType),
              item.joinDate,
              item.notes,
              item.level,
              item.rank,
              subscription,
              totalSpend,
              item.totalOrders,
              item.lastActiveDate,
              item.orderHistory,
              item.email,
              item.phoneNumber,
            ];
          })
        : undefined;
    const options = {
      filterType: 'checkbox',
      print: false,
      responsive: 'scrollMaxHeight',
      sortFilterList: false,
      onRowClick: (params, index) => {
        const contactId = Object.values(contacts)[index.dataIndex].id;
        history.push(`/contacts/${contactId}/activities${search}`);
      },
      onFilterChange: (changedColumn, filterList) => {
        this.updateFilters(filterList);
      },
    };

    return (
      <React.Fragment>
        <div className={classes.root}>
          <MUIDataTable title="Contacts" data={data || []} columns={columns || []} options={options} />
        </div>
        <DialogContact
          section={section}
          open={section !== 'edit' && !!contact}
          contact={contact}
          onClose={() => history.push(`/contacts${search}`)}
          onSectionChange={newValue => history.push(`/contacts/${contact.id}/${newValue}${search}`)}
        />
        <DialogContactEdit
          open={section === 'edit'}
          contact={contact}
          onClose={() => history.push(`/contacts${search}`)}
          onSectionChange={newValue => history.push(`/contacts/${contact.id}/${newValue}${search}`)}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    contacts: selectContacts,
    contact: selectContact,
    currentUser: selectCurrentUser,
    cardValues: selectContactCard,
  }),
  {
    dispatchFetchContacts: () => ({ type: DISPATCH_FETCH_CONTACTS }),
    dispatchSaveContact: contact => ({ type: DISPATCH_SAVE_CONTACT, contact }),
  },
);

export default compose(
  withConnect,
  withWidth(),
  withStyles(styles),
)(Contacts);
