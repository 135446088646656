import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Chip from 'components/Chip';
import Menu from 'components/Menu';
import PopoverReminder from 'components/PopoverReminder';
import IconReminder from 'assets/IconReminder';
import moment from 'moment';

const styles = theme => ({
  icon: {
    padding: 2,
    marginLeft: theme.spacing.unit,
    marginRight: -1 * theme.spacing.unit,
  },
});

const items = [
  {
    key: 0,
    label: 'Follow up tomorrow',
    value: moment().add(1, 'days'),
  },
  {
    key: 1,
    label: 'Follow up in 2 days',
    value: moment().add(2, 'days'),
  },
  {
    key: 2,
    label: 'Follow up in 1 week',
    value: moment().add(7, 'days'),
  },
  {
    key: 3,
    label: 'Set custom reminder...',
  },
];

class ChipSetReminder extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: undefined,
  };

  state = {
    anchorEl: undefined,
    isCustom: false,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: undefined,
    });
  };

  handleCloseReminder = () => {
    this.setState(
      {
        isCustom: false,
      },
      () => this.handleClose(),
    );
  };

  handleChange = item => {
    const { onChange } = this.props;

    if (item.key === 3) {
      this.setState({
        isCustom: true,
      });
    } else {
      onChange(item ? { date: item.value, notes: 'Follow up' } : null);
      this.handleClose();
    }
  };

  handleSubmit = value => {
    const { onChange } = this.props;
    this.handleCloseReminder();
    onChange(value);
  };

  render() {
    const { classes, value, ...other } = this.props;
    const { anchorEl, isCustom } = this.state;
    const selected = Boolean(value);
    let label = 'Set Reminder';
    if (selected) {
      label = value.date.format('MM/DD');
      if (value.notes) {
        label += `: ${value.notes}`;
      }
    }

    return (
      <React.Fragment>
        <Chip
          icon={<IconReminder className={classes.icon} />}
          label={label}
          onClick={this.handleClick}
          selected={selected}
          {...other}
        />

        <Menu
          anchorEl={anchorEl}
          items={items}
          itemSelected={items[0]}
          onChange={this.handleChange}
          onClose={this.handleClose}
        />

        <PopoverReminder
          open={isCustom}
          anchorEl={anchorEl}
          value={value}
          onClose={this.handleCloseReminder}
          onSubmit={this.handleSubmit}
          secondaryAction={
            value && (
              <Button size="small" onClick={() => this.handleSubmit()}>
                Remove
              </Button>
            )
          }
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ChipSetReminder);
