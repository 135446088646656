import api from 'services/api';

const urlSC = 'api/v1/contacts/sync_scout_cellar';

// Methods
const syncSCAccount = () => api.post(urlSC);

export default {
  syncSCAccount,
};
