import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isObject } from 'lodash';

const styles = theme => ({
  row: {
    display: 'flex',
    marginTop: theme.spacing.unit,
    whiteSpace: 'pre-wrap',
  },
  label: {
    minWidth: 150,
    paddingRight: theme.spacing.unit * 2,
  },
});

class ContactInfoValue extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  };

  static defaultProps = {
    value: undefined,
  };

  render() {
    const { classes, label, value } = this.props;
    const isNode = isObject(value);
    return (
      <div className={classes.row}>
        <Typography variant="body1" className={classes.label} align="right">
          {label}
        </Typography>
        {isNode && value}
        {!isNode && <Typography variant="body1">{value || '-'}</Typography>}
      </div>
    );
  }
}

export default compose(withStyles(styles))(ContactInfoValue);
