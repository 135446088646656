import api from 'services/api';
import accessTokensResource from './accessTokensResource';
import usersResource from './usersResource';

const url = '/auth/password';

// Methods
function resetPassword({ email, ...other }) {
  return api.post(url, null, {
    params: { email },
    ...other,
  });
}

function validateResetPasswordToken({ params }) {
  return api.get(`${url}/edit`, {
    params,
    schema: accessTokensResource.accessTokenSchema,
  });
}

function setNewPassword({ params }) {
  // TODO update auth user
  return api.put(url, null, {
    params,
    schema: usersResource.userSchema,
  });
}

export default {
  resetPassword,
  validateResetPasswordToken,
  setNewPassword,
};
