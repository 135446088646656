import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import find from 'lodash/find';

import contactsService from 'services/contactsService';
import Chip from 'components/Chip';
import Menu from 'components/Menu';
import IconFace from 'assets/IconFace';
import { selectCurrentUser } from 'reducers/selectors';
import IconArrowDropDown from '../../assets/IconArrowDropDown';

const styles = theme => ({
  icon: {
    padding: 1,
    marginLeft: theme.spacing.unit,
  },
  dropDownIcon: {
    padding: 0,
    margin: '0 -10px',
    fontSize: 'inherit',
  },
});

class ChipEditType extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    short: PropTypes.bool,
    withArrow: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    short: false,
    withArrow: false,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value,
    anchorEl: null,
    isChanged: false,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.changeState();
    }
  }

  changeState = () => {
    const { value } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.value !== value) {
      this.setState({
        isChanged: true,
      });
    } else {
      this.setState({
        isChanged: false,
      });
    }
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = item => {
    const { onChange } = this.props;
    this.handleClose();
    onChange(item ? item.key : null);
  };

  render() {
    const {
      classes,
      value,
      currentUser: { company },
      dispatch,
      short,
      withArrow,
      ...other
    } = this.props;
    const { anchorEl, isChanged } = this.state;
    const items = contactsService.getContactTypeOptions(company);
    const item = find(items, { key: value });
    const label = item ? item.label : 'Edit Type';

    return (
      <React.Fragment>
        <Chip
          icon={
            withArrow ? <IconArrowDropDown className={classes.dropDownIcon} /> : <IconFace className={classes.icon} />
          }
          label={label}
          onClick={this.handleClick}
          selected={isChanged}
          {...other}
        />
        <Menu
          anchorEl={anchorEl}
          items={items}
          itemSelected={item}
          onClose={this.handleClose}
          onChange={this.handleChange}
          onClick={e => {
            if (short) e.stopPropagation();
          }}
        />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(ChipEditType);
