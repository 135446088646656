import { call } from 'redux-saga/effects';

import { RESET_PASSWORD } from 'actions/actionTypes';
import passwordsResource from 'resources/passwordsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const resetPassword = sagaFetch.bind(null, RESET_PASSWORD, passwordsResource.resetPassword);

export default function* sagaResetPassword({ email }) {
  yield call(resetPassword, { email, skipSnackbar: true });
}
