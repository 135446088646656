import { fork, take, put, race, delay } from 'redux-saga/effects';
import { get } from 'lodash';

import {
  regexAction,
  regexRequest,
  regexSuccess,
  regexError,
  DISPATCH_LOADING_START,
  DISPATCH_LOADING_STOP,
} from 'actions/actionTypes';

function* sagaStopLoading() {
  // hide loading after a delay to handle multiple calls in parallel
  const { delaySuccess } = yield race({
    delaySuccess: delay(300),
    cancel: take(action => action.type === DISPATCH_LOADING_START),
  });
  if (delaySuccess) {
    yield put({ type: DISPATCH_LOADING_STOP });
  }
}

export default function* sagaCheckLoading() {
  while (true) {
    const { type, loadingMessage, skipLoading, meta } = yield take(action => regexAction.test(action.type));

    if (regexRequest.test(type) && !skipLoading) {
      yield put({ type: DISPATCH_LOADING_START, loadingMessage });
    } else if (regexError.test(type) || (regexSuccess.test(type) && !get(meta, 'requestAction.skipLoading'))) {
      yield fork(sagaStopLoading);
    }
  }
}
