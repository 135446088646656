import { schema } from 'normalizr';
import { get, isUndefined, values } from 'lodash';

import api from 'services/api';
import resource from './resource';
import activitiesResource from './activitiesResource';
import remindersResource from './remindersResource';
import usersResource from './usersResource';
import attemptsResource from './attemptsResource';

const url = '/api/v1/contacts';
const newUrl = '/api/v1/contacts/all';

// Interceptors
function requestInterceptor(data) {
  const isNew = !data.id;
  const names = isNew ? data.label.split(' ') : [data.firstName, data.lastName];
  return {
    ...data,
    firstName: names.shift(),
    lastName: names.length ? names.join(' ') : '',
    emailAddresses: resource.fieldValueToArray(data.email),
    addresses: resource.fieldValueToArray(data.address),
    phoneNumbers: resource.fieldValueToArray(data.phoneNumber),
    contactType: data.contactType || 'PROSPECT',
    contactStatus: data.contactStatus || 'COLD',
    frequency: isUndefined(data.frequency) ? 180 : data.frequency,
    isFrequencyAutoAssigned: isNew ? isUndefined(data.frequency) : data.isFrequencyAutoAssigned,
  };
}

function processStrategy(data) {
  const res = usersResource.processStrategy(data);
  return {
    ...res,
    sponsor:
      (res.sponsorFirstName ? res.sponsorFirstName : '') + (res.sponsorLastName ? ` ${res.sponsorLastName}` : ''),
    email: get(res.emailAddresses, '[0].value'),
    address: get(res.addresses, '[0].value', {}),
    phoneNumber: get(res.phoneNumbers, '[0].value'),
  };
}

// Schemas
const contactSchema = new schema.Entity('contacts', {}, { processStrategy });
const contactsSchema = [contactSchema];
const contactCardValuesSchema = new schema.Values(
  {
    reminders: remindersResource.reminderSchema,
    activities: activitiesResource.activitySchema,
  },
  input => input.type,
);

// new Contacts Schema
const newContactSchema = new schema.Entity(
  'contacts',
  {},
  {
    idAttribute: value => value.contact.id,
    // eslint-disable-line arrow-body-style
    processStrategy: entity => ({
      ...entity.contact,
      lastActivity: entity.lastActivity,
      oldestReminder: entity.oldestReminder ? entity.oldestReminder : null,
      name: `${entity.contact.firstName} ${entity.contact.lastName}`,
      sponsor:
        (entity.contact.sponsorFirstName ? entity.contact.sponsorFirstName : '') +
        (entity.contact.sponsorLastName ? ` ${entity.contact.sponsorLastName}` : ''),
      email: get(entity.contact.emailAddresses, '[0].value'),
      address: get(entity.contact.addresses, '[0].value', {}),
      phoneNumber: get(entity.contact.phoneNumbers, '[0].value'),
    }),
  },
);
const newContactsSchema = [newContactSchema];

const workListItemsItemSchema = new schema.Entity(
  'workListItems',
  {
    contact: contactSchema,
    oldestReminder: remindersResource.reminderSchema,
    lastActivity: activitiesResource.activitySchema,
  },
  {
    idAttribute: value => value.contact.id,
  },
);
const workListItemsSchema = new schema.Values(
  {
    consultants: [workListItemsItemSchema],
    customers: [workListItemsItemSchema],
    hotProspects: [workListItemsItemSchema],
    warmProspects: [workListItemsItemSchema],
    coldProspects: [workListItemsItemSchema],
  },
  (input, parent, key) => key,
);

// Methods
function importContacts({ s3Key, spreadsheetType, columnHeaders, instagramImportType }) {
  if (columnHeaders) {
    return api.post(
      `${url}/import_v2`,
      {
        columnHeaders,
      },
      {
        params: { s3Key, spreadsheetType },
        schema: attemptsResource.attepmtSchema,
      },
    );
  }
  return api.post(`${url}/import_v2`, null, {
    params: { s3Key, spreadsheetType, instagramImportType },
    schema: attemptsResource.attepmtSchema,
  });
}

function fetchContactImportStats() {
  return api.get(`${url}/import_stats`);
}

async function fetchContacts({ query, callback }) {
  let res;
  if (query) {
    res = await api.get(`${url}/search`, {
      params: { query },
      schema: contactsSchema,
    });
  } else {
    res = await api.get(newUrl, {
      params: { query },
      schema: newContactsSchema,
    });
  }
  if (callback) {
    const contacts = values(res.normalized.entities.contacts);
    callback(contacts);
  }
  return res;
}

async function fetchContact({ id }) {
  return api.get(`${url}/${id}`, {
    schema: contactSchema,
  });
}

async function fetchContactCard({ contact }) {
  const res = await api.get(`${url}/${contact.id}/card`, {
    schema: contactCardValuesSchema,
  });
  return {
    ...res,
    normalized: {
      entities: {
        ...res.normalized.entities,
        contacts: {
          [contact.id]: {
            cardValues: res.normalized.result,
          },
        },
      },
    },
  };
}

function fetchWorkList() {
  return api.get(`${url}/work_list_v2`, {
    schema: workListItemsSchema,
  });
}

// save
function saveContact({ contact }) {
  return api({
    method: contact.id ? 'put' : 'post',
    url: contact.id ? `${url}/${contact.id}` : url,
    data: { contact: requestInterceptor(contact) },
    schema: contactSchema,
  });
}

function saveReminder({ contact, reminder }) {
  const base = `${url}/${contact.id}/reminders`;
  return api({
    method: reminder.id ? 'put' : 'post',
    url: reminder.id ? `${base}/${reminder.id}` : base,
    data: { reminder: remindersResource.requestInterceptor(reminder) },
    schema: remindersResource.reminderSchema,
  });
}

function saveActivity({ contact, activity }) {
  const base = `${url}/${contact.id}/activities`;
  return api({
    method: activity.id ? 'put' : 'post',
    url: activity.id ? `${base}/${activity.id}` : base,
    data: { activity: activitiesResource.requestInterceptor(activity) },
    schema: activitiesResource.activitySchema,
  });
}

function assignFrequencies({ params }) {
  return api.post(`${url}/assign_frequencies`, null, {
    params,
  });
}

// delete
async function deleteContact({ id }) {
  const res = await api.delete(`${url}/${id}`);
  return {
    ...res,
    normalized: {
      entities: {
        contacts: {
          [id]: {
            deleted: true,
          },
        },
      },
    },
  };
}

async function deleteReminder({ contact, reminder }) {
  const res = await api.delete(`${url}/${contact.id}/reminders/${reminder.id}`);
  return {
    ...res,
    normalized: {
      entities: {
        reminders: {
          [reminder.id]: {
            deleted: true,
          },
        },
      },
    },
  };
}

async function deleteActivity({ contact, activity }) {
  const res = await api.delete(`${url}/${contact.id}/activities/${activity.id}`);
  return {
    ...res,
    normalized: {
      entities: {
        activities: {
          [activity.id]: {
            deleted: true,
          },
        },
      },
    },
  };
}

export default {
  processStrategy,
  contactSchema,
  contactsSchema,
  importContacts,
  fetchContactImportStats,
  fetchContacts,
  fetchContact,
  fetchContactCard,
  fetchWorkList,
  saveContact,
  saveReminder,
  saveActivity,
  assignFrequencies,
  deleteContact,
  deleteReminder,
  deleteActivity,
};
