import { call, select, all } from 'redux-saga/effects';
import { keys, differenceBy, map } from 'lodash';

import { FETCH_CONTACTS } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import { selectContacts } from 'reducers/selectors';
import sagaFetch from './sagaFetch';
import sagaFetchContact from './sagaFetchContact';

// bind resource generator
const fetchContacts = sagaFetch.bind(null, FETCH_CONTACTS, contactsResource.fetchContacts);

export default function* sagaFetchContacts({ ids, query, callback }) {
  if (!ids) {
    yield call(fetchContacts, { query, callback });
  } else {
    // fetch contacts in bulk
    const contactsLoaded = yield select(selectContacts);
    const contactsToLoadIds = differenceBy(ids, keys(contactsLoaded), parseInt);
    yield all(map(contactsToLoadIds, id => call(sagaFetchContact, { id })));
  }
}
