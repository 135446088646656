import { call, all } from 'redux-saga/effects';
import { map } from 'lodash';

import { DELETE_CONTACT } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const deleteContact = sagaFetch.bind(null, DELETE_CONTACT, contactsResource.deleteContact);

export default function* sagaDeleteContact({ ids, callback }) {
  yield all(map(ids, id => call(deleteContact, { id })));
  if (callback) {
    callback();
  }
  yield call(sagaRefresh);
}
