import { call } from 'redux-saga/effects';

import { SAVE_CONTACT } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import history from 'config/history';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const saveContact = sagaFetch.bind(null, SAVE_CONTACT, contactsResource.saveContact);

export default function* sagaSaveContact({ contact, pathSuccess, skipRefresh }) {
  const res = yield call(saveContact, { contact });
  if (res.error) {
    return res;
  }
  if (pathSuccess) {
    history.push(pathSuccess.replace(':id', res.data.id));
  }
  if (!skipRefresh) {
    yield call(sagaRefresh);
  }
  return res;
}
