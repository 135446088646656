import { FETCH_S3_SIGNATURE } from 'actions/actionTypes';

export default function createS3Reducer() {
  return (state = {}, action) => {
    switch (action.type) {
      case FETCH_S3_SIGNATURE.SUCCESS:
        return action.normalized.result;
      default:
        return state;
    }
  };
}
