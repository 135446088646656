import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compact, join } from 'lodash';

const sources = {
  FACEBOOK_FRIENDS: 'Facebook',
  INSTAGRAM_FOLLOWERS: 'Instagram',
  LINKEDIN_CONNECTIONS: 'LinkedIn',
  PLEXUS_CLIENTS: 'Plexus back office',
  PLEXUS_AMBASSADORS: 'Plexus back office',
  MONAT_CUSTOMERS: 'Monat back office',
  MONAT_MARKET_PARTNERS: 'Monat back office',
  DOTERRA_CUSTOMERS: 'doTERRA back office',
  DOTERRA_WELLNESS_ADVOCATES: 'doTERRA back office',
  STANDARD_CUSTOMERS: 'Customer spreadsheet',
  STANDARD_CONSULTANTS: 'Consultant spreadsheet',
  SCOUT_CELLAR_SYNC: 'S&C back office',
  YOUNG_LIVING_DOWNLINE: 'Young Living virtual office',
};

class TypographySource extends Component {
  static propTypes = {
    children: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    firstName: undefined,
    lastName: undefined,
  };

  render() {
    const { children, firstName, lastName, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }

    // For contacts with the source 'FACEBOOK_FRIENDS', render a link to Facebook searching for their first and last name.
    if (children === 'FACEBOOK_FRIENDS') {
      const url = `https://www.facebook.com/search/top/?q=${join(compact([firstName, lastName]), '%20')}`;
      return (
        <Typography {...other}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {sources[children]}
          </a>
        </Typography>
      );
    }

    return <Typography {...other}>{sources[children]}</Typography>;
  }
}

export default TypographySource;
