import { call, put, select } from 'redux-saga/effects';
import { FullStoryAPI } from 'react-fullstory';

import history from 'config/history';
import { DISPATCH_APP_READY, FETCH_ACCOUNT } from 'actions/actionTypes';
import accountResource from 'resources/accountResource';
import storageService from 'services/storageService';
import { selectCurrentUser } from 'reducers/selectors';
import sagaFetch from './sagaFetch';
import sagaFetchActivityStats from './sagaFetchActivityStats';

// bind resource generator
const fetchAccount = sagaFetch.bind(null, FETCH_ACCOUNT, accountResource.fetchAccount);

export default function* sagaFetchInitialData() {
  const res = yield call(fetchAccount);
  if (res.error) {
    storageService.removeAuth();
    history.push('/login');
  } else {
    // fetch stats
    yield call(sagaFetchActivityStats);

    // run FullStory
    // https://github.com/cereallarceny/react-fullstory
    const { id, email, firstName, lastName, company, careerTitle, onboarded } = yield select(selectCurrentUser);
    if (process.env.NODE_ENV === 'production') {
      FullStoryAPI('identify', id, { email, firstName, lastName, company, careerTitle, onboarded });
    }
  }
  yield put({ type: DISPATCH_APP_READY });
}
