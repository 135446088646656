import { call } from 'redux-saga/effects';

import { FETCH_CONTACT } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchContact = sagaFetch.bind(null, FETCH_CONTACT, contactsResource.fetchContact);

export default function* sagaFetchContact({ id }) {
  yield call(fetchContact, { id });
}
