import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

class DialogConfirm extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { onClose, text, ...other } = this.props;
    return (
      <Dialog aria-describedby="alert-dialog-description" onClose={onClose} {...other}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogConfirm;
