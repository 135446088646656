import { isObject } from 'lodash';

function processStrategy(data, idAttribute = 'id') {
  const { attributes = {}, ...other } = data;
  return {
    ...other,
    ...attributes,
    id: isObject(data) ? parseInt(data[idAttribute], 10) || undefined : undefined,
  };
}

function fieldValueToArray(value) {
  return value
    ? [
        {
          label: '',
          value,
        },
      ]
    : undefined;
}

export default {
  processStrategy,
  fieldValueToArray,
};
