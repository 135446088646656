import { call, select } from 'redux-saga/effects';
import { map, uniq } from 'lodash';

import { FETCH_ACTIVE_REMINDERS } from 'actions/actionTypes';
import remindersResource from 'resources/remindersResource';
import { selectReminders } from 'reducers/selectors';
import sagaFetch from './sagaFetch';
import sagaFetchContacts from './sagaFetchContacts';

// bind resource generator
const fetchActiveReminders = sagaFetch.bind(null, FETCH_ACTIVE_REMINDERS, remindersResource.fetchActiveReminders);

export default function* sagaFetchActiveReminders() {
  yield call(fetchActiveReminders);

  // lookup for contacts
  const reminders = yield select(selectReminders);
  const ids = uniq(map(reminders, 'contactId'));
  yield call(sagaFetchContacts, { ids });
}
