import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => ({
  snackbarContent: {
    minWidth: 140,
  },
  snackbarProgress: {
    color: theme.palette.common.white,
    animationDuration: '550ms',
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

class SnackbarLoading extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { classes, loading, message, ...other } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={loading}
        {...other}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <span className={classes.message}>
              <CircularProgress
                className={classes.snackbarProgress}
                variant="indeterminate"
                disableShrink
                size={24}
                thickness={4}
              />
              {message}
            </span>
          }
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(SnackbarLoading);
