import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { map, find } from 'lodash';

const styles = () => ({
  root: {
    fontSize: 22,
  },
  select: {
    minHeight: 'initial',
    '&:focus': {
      background: 'initial',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemSecondaryText: {
    color: 'gray',
  },
});

class SelectWithCounters extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  renderValue = key => {
    const { classes, items } = this.props;
    const filter = find(items, { key });
    return (
      <div className={classes.item}>
        {filter.label} {filter.items ? `(${filter.items.length})` : ''}
      </div>
    );
  };

  render() {
    const { classes, items, value, onChange } = this.props;
    return (
      <FormControl>
        <Select
          classes={{
            root: classes.root,
            select: classes.select,
          }}
          value={value}
          renderValue={this.renderValue}
          onChange={event => onChange(event.target.value)}
          disableUnderline
        >
          {map(items, item => (
            <MenuItem key={item.key} className={classes.item} value={item.key}>
              <span>{item.label}</span>
              {item.items && <span className={classes.itemSecondaryText}>{item.items.length}</span>}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default compose(withStyles(styles))(SelectWithCounters);
