import { createMuiTheme } from '@material-ui/core/styles';

import createThemeDough from './createThemeDough';
import createThemeDark from './createThemeDark';

const muiThemeDefault = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});
const muiThemeDough = createThemeDough(muiThemeDefault);
const muiThemeDark = createThemeDark(muiThemeDough);

export { muiThemeDefault, muiThemeDough, muiThemeDark };
