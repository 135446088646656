import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import history from 'config/history';
import QuickLog from 'containers/QuickLog';
import DialogContact from 'containers/DialogContact';
import DialogContactEdit from 'containers/DialogContactEdit';
import DialogSettingsIPAs from 'containers/DialogSettingsIPAs';
import { selectContacts, selectContact } from 'reducers/selectors';
import HomeListWork from './HomeListWork';
import HomeNotes from './HomeNotes';
import HomeListSelector from './HomeListSelector';
import HomeListItem from './HomeListItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    margin: `${theme.spacing.unit * 2}px 12%`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    },
  },
  paper: {
    padding: theme.spacing.unit * 3,
  },
});

class Home extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({}).isRequired,
    }).isRequired,
    contacts: PropTypes.shape({}),
    contact: PropTypes.shape({}),
  };

  static defaultProps = {
    contacts: undefined,
    contact: undefined,
  };

  renderListItem = item => {
    const {
      match: {
        params: { value },
      },
    } = this.props;

    // WORKAROUND some contacts are undefined
    // API returning deleted refs?
    if (!item.contact) {
      return <React.Fragment key={item.id} />;
    }
    let type = 'contacts';
    if (/activity/.test(value)) {
      type = 'activities';
    } else if (/reminder/.test(value)) {
      type = 'reminders';
    }
    return (
      <HomeListItem
        key={item.id}
        item={item}
        type={type}
        onClick={e => {
          e.stopPropagation();
          history.push(`/home/${value}/${item.contact.id}/activities`);
        }}
      />
    );
  };

  render() {
    const {
      classes,
      contact,
      match: {
        params: { value, section },
      },
    } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <QuickLog />
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <HomeListSelector value={value} onChange={newValue => history.push(`/home/${newValue}`)} />
                    {value === 'work' && <HomeListWork renderListItem={this.renderListItem} />}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <HomeNotes />
            </Grid>
          </Grid>
        </div>
        <DialogContact
          section={section}
          open={section !== 'edit' && !!contact}
          contact={contact}
          onClose={() => history.push(`/home/${value}`)}
          onSectionChange={newValue => history.push(`/home/${value}/${contact.id}/${newValue}`)}
        />
        <DialogContactEdit
          open={section === 'edit'}
          contact={contact}
          onClose={() => history.push(`/home/${value}`)}
          onSectionChange={newValue => history.push(`/home/${value}/${contact.id}/${newValue}`)}
        />
        <DialogSettingsIPAs open={section === 'settings'} onClose={() => history.push(`/home/${value}`)} />
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    contacts: selectContacts,
    contact: selectContact,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(Home);
