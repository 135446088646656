import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

import { SIGN_IN, SIGN_OUT } from 'actions/actionTypes';

const initialState = {
  users: {},
  contacts: {},
  reminders: {},
  activities: {},
  workListItems: {},
  uploadAttempts: {},
  accessTokens: {},
};

// Updates an entity cache in response to any action with response.entities.
export default function createEntitiesReducer() {
  return (state = initialState, action) => {
    const entities = get(action, 'normalized.entities');
    if (entities) {
      return mergeWith({}, state, entities, (dest, src) => (isArray(dest) && isArray(src) ? src : undefined));
    }
    switch (action.type) {
      // reset state
      case SIGN_IN.REQUEST:
      case SIGN_OUT.SUCCESS:
      case SIGN_OUT.ERROR:
        return initialState;
      default:
        return state;
    }
  };
}
