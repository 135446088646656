import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import cookie from 'react-cookies';

import Account from 'screens/Account';
import Paywall from 'screens/Paywall';
import Onboarding from 'screens/Onboarding';
import Home from 'screens/Home';
import Contacts from 'screens/Contacts';
import Scripts from 'screens/Scripts';
import Stats from 'screens/Stats';
import Header from 'containers/Header';
import Footer from 'containers/Footer';
import { muiThemeDark } from 'themes';
import { DISPATCH_FETCH_INITIAL_DATA } from 'actions/actionTypes';
import { selectReady, selectCurrentUser } from 'reducers/selectors';
import routerService from 'services/routerService';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  contentWithFooter: {
    paddingBottom: 112,
  },
  footer: {
    height: 112,
  },
});

class App extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    currentUser: PropTypes.shape({
      onboarded: PropTypes.bool.isRequired,
    }),
    ready: PropTypes.bool.isRequired,
    dispatchFetchInitialData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentUser: undefined,
  };

  componentDidMount() {
    const { dispatchFetchInitialData } = this.props;
    dispatchFetchInitialData();
  }

  render() {
    // Do not render the app until the app status is ready.
    const { ready } = this.props;
    if (!ready) {
      return <React.Fragment />;
    }

    const {
      classes,
      location,
      currentUser: { onboarded, subscriptionExpiresAt },
    } = this.props;
    const isPaywall = /paywall/.test(location.pathname);
    const isValidSubscription = subscriptionExpiresAt && moment(subscriptionExpiresAt).isAfter();
    if (!isPaywall && !isValidSubscription) {
      return <Redirect to="/paywall" />;
    }
    const isOnboarding = routerService.isOnboarding(location);
    if (!isOnboarding && !onboarded && isValidSubscription) {
      if (cookie.load('onboardingStep') && cookie.load('onboardingStep') !== location.pathname) {
        return <Redirect to={cookie.load('onboardingStep')} />;
      }
      return <Redirect to="/onboarding/welcome" />;
    }

    // Display black bottom bar only if current route is NOT the Paywall or Onboarding screens.
    const footerVisible = !isPaywall && !isOnboarding;

    return (
      <React.Fragment>
        <Header showSearch={!isOnboarding && !isPaywall} showTabs={!isPaywall} />
        <div className={classes.toolbar} />
        <div
          className={classnames({
            [classes.contentWithFooter]: footerVisible,
          })}
        >
          <Switch>
            <Route path="/account" component={Account} />
            <Route path="/paywall" component={Paywall} />
            <Route path="/onboarding/:section" component={Onboarding} />
            <Route path="/home/:value" component={Home} exact />
            <Route path="/home/:value/:section" component={Home} exact />
            <Route path="/home/:value/:id/:section" component={Home} />
            <Route path="/contacts" component={Contacts} exact />
            <Route path="/contacts/:id/:section" component={Contacts} />
            <Route path="/scripts" component={Scripts} />
            <Route path="/stats" component={Stats} />
            <Redirect to="/home/work" />
          </Switch>
        </div>
        {footerVisible && (
          <MuiThemeProvider theme={muiThemeDark}>
            <Footer className={classes.footer} location={location} />
          </MuiThemeProvider>
        )}
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    ready: selectReady,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchInitialData: () => ({ type: DISPATCH_FETCH_INITIAL_DATA }),
  },
);

export default compose(
  withConnect,
  withStyles(styles),
)(App);
