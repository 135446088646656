import { call } from 'redux-saga/effects';

import { SIGN_OUT } from 'actions/actionTypes';
import authResource from 'resources/authResource';
import storageService from 'services/storageService';
import history from 'config/history';
import sagaFetch from './sagaFetch';

// bind resource generator
const signOut = sagaFetch.bind(null, SIGN_OUT, authResource.signOut);

export default function* sagaSignOut() {
  yield call(signOut);
  storageService.removeAuth();
  history.push('/login');
}
