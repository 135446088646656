import { call, select, put, delay } from 'redux-saga/effects';

import { FETCH_ATTEMPT, DISPATCH_LOADING_START, DISPATCH_ERROR } from 'actions/actionTypes';
import { makeSelectUploadAttempt } from 'reducers/selectors';
import attemptsResource from 'resources/attemptsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchAttempt = sagaFetch.bind(null, FETCH_ATTEMPT, attemptsResource.fetchAttempt);

export default function* sagaFetchAttempt({ id, label }) {
  let res;
  let attempt;
  // Every 15 seconds, call ViewSpreadsheetUploadAttempt API to determine if the upload is done
  while (!attempt || !attempt.isSuccessful) {
    if (attempt) {
      const text = attempt.totalSpreadsheetContacts ? `${attempt.totalSpreadsheetContacts} ${label}` : label;
      yield put({ type: DISPATCH_LOADING_START, loadingMessage: `Importing ${text}...` });
      yield delay(15000);
    }
    res = yield call(fetchAttempt, { id });
    if (res.error) {
      return res;
    }
    attempt = yield select(makeSelectUploadAttempt(res.normalized.result));
    if (attempt.error) {
      yield put({ type: DISPATCH_ERROR, error: attempt.error });
      return attempt;
    }
  }
  return res;
}
