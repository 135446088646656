import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';

import FieldTrimmed from 'components/FieldTrimmed';
import Header from 'containers/Header';
import FormTextField from 'components/FormTextField';
import Anchor from 'components/Anchor';
import history from 'config/history';
import validation from 'services/validationService';
import { DISPATCH_RESET_PASSWORD } from 'actions/actionTypes';
import { selectLoading, selectMessage } from 'reducers/selectors';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  root: {
    background: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.unit * 3,
  },
  wrapper: {
    width: 330,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 4,
  },
  button: {
    marginTop: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    width: 160,
  },
  message: {
    color: green[500],
  },
});

class ResetPassword extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool,
    message: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatchResetPassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { classes, message, handleSubmit, dispatchResetPassword, pristine, submitting, loading } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        <form className={classes.wrapper} onSubmit={handleSubmit(values => dispatchResetPassword(values.email))}>
          <div className={classes.toolbar} />
          <Typography variant="h5" gutterBottom>
            Reset your password
          </Typography>
          <FieldTrimmed component={FormTextField} name="email" label="Email" margin="normal" fullWidth autoFocus />
          <div className={classes.actions}>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={pristine || submitting || loading}
            >
              Get Reset Link
            </Button>
            <Typography variant="caption">
              <Anchor onClick={() => history.goBack()}>Cancel</Anchor>
            </Typography>
          </div>
        </form>
        {message.content && !loading && <Typography className={classes.message}>{message.content}</Typography>}
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    message: selectMessage,
  }),
  {
    dispatchResetPassword: email => ({ type: DISPATCH_RESET_PASSWORD, email }),
  },
);

const withForm = reduxForm({
  form: 'ResetPassword',
  validate: validation.createValidator({
    email: [validation.required, validation.email],
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(ResetPassword);
