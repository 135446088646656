import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { get } from 'lodash';

class FormTextFieldSensitive extends React.Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    helperText: PropTypes.string,
  };

  static defaultProps = {
    helperText: null,
  };

  onChange = event => {
    const { input } = this.props;
    const {
      target: { value },
    } = event;
    input.onChange(value);
  };

  render() {
    const {
      helperText,
      input: { value, onBlur },
      meta: { error },
      ...other
    } = this.props;
    const readOnly = get(other, 'InputProps.readOnly', false);

    // Display errors as soon as they exist (don't wait for the field to be touched).
    const hasError = !readOnly && !!error;
    const errorText = hasError ? error : null;

    return (
      <TextField
        value={value}
        onChange={this.onChange}
        onBlur={onBlur}
        helperText={errorText || helperText}
        error={hasError}
        {...other}
      />
    );
  }
}

export default FormTextFieldSensitive;
