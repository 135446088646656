import { call, put } from 'redux-saga/effects';

import { CONNECT_TO_SC, DISPATCH_LOADING_START } from 'actions/actionTypes';
import connectAccountResource from 'resources/connectAccountResource';
import sagaFetch from './sagaFetch';
import sagaSaveAccount from './sagaSaveAccount';
import sagaSyncSCAccount from './sagaSyncSCAccount';

// bind resource generator
const connectSCAccount = sagaFetch.bind(null, CONNECT_TO_SC, connectAccountResource.connectSCAccount);

export default function* sagaConnectSCAccount({ data }) {
  yield put({ type: DISPATCH_LOADING_START, loadingMessage: 'Connecting...' });
  const connectingRes = yield call(connectSCAccount, { data });
  if (!connectingRes.error) {
    const {
      RankType,
      Status,
      Type,
      SiteURL,
      SponsorName,
      SponsorDisplayID,
      DisplayID,
      UserID,
      Username,
      LoginGuid,
      DateCreated,
      PersonTypeID,
      LanguageCode,
      CountryCode,
    } = connectingRes.data.data.user;

    const user = {};
    user.integrationScoutCellar = {
      rank_type: RankType,
      status: Status,
      type: Type,
      site_url: SiteURL,
      sponsor_name: SponsorName,
      sponsor_display_id: SponsorDisplayID,
      display_id: DisplayID,
      user_id: UserID,
      username: Username,
      login_guid: LoginGuid,
      date_created: DateCreated,
      person_type_id: PersonTypeID,
      language_code: LanguageCode,
      country_code: CountryCode,
    };
    yield call(sagaSaveAccount, { account: user });
    yield call(sagaSyncSCAccount);
  }
}
