import localStorage from 'local-storage';
import cookies from 'react-cookies';

import configService from './configService';

const PREFIX = `${configService.getAppPrefix()}:`;
const KEY_AUTH = `${PREFIX}auth`;
export const KEY_DIALOG_WELCOME_DONE = `${PREFIX}dialog-welcome-done`; // TODO: Remove later - this is no longer used.
export const KEY_TOUR_DONE = `${PREFIX}tour-done`;

function isLocalStorageAvailable() {
  // TODO use cookies as default instead of localstorage?
  try {
    const x = '__storage_test__';
    const isAvailable = localStorage.set(x, x);
    localStorage.remove(x);
    return isAvailable;
  } catch (e) {
    return false;
  }
}

const useLocalStorage = isLocalStorageAvailable();

function get(key) {
  return useLocalStorage ? localStorage.get(key) : cookies.load(key);
}

function set(key, value) {
  if (useLocalStorage) {
    return localStorage.set(key, value);
  }
  return cookies.save(key, value);
}

function remove(key) {
  if (useLocalStorage) {
    return localStorage.remove(key);
  }
  return cookies.remove(key);
}

function getAuth() {
  return get(KEY_AUTH);
}

function setAuth(value) {
  return set(KEY_AUTH, value);
}

function removeAuth() {
  return remove(KEY_AUTH);
}

export default {
  isLocalStorageAvailable,
  get,
  set,
  remove,
  getAuth,
  setAuth,
  removeAuth,
};
