import { call } from 'redux-saga/effects';

import { FETCH_CONTACT_IMPORT_STATS } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchContactImportStats = sagaFetch.bind(
  null,
  FETCH_CONTACT_IMPORT_STATS,
  contactsResource.fetchContactImportStats,
);

export default function* sagaFetchContactImportStats() {
  yield call(fetchContactImportStats);
}
