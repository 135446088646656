import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class TypographyContactID extends Component {
  static propTypes = {
    children: PropTypes.string,
    source: PropTypes.string,
    contactType: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    source: undefined,
    contactType: undefined,
  };

  render() {
    const { children, source, contactType, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }

    // For contacts from PLEXUS_AMBASSADORS spreadsheets, render a clickable link to their Back Office profile page.
    if (source === 'PLEXUS_AMBASSADORS') {
      const url = `https://backoffice.plexusworldwide.com/my-business/view-profile/${children}`;
      return (
        <Typography {...other}>
          {`${children} (`}
          <a href={url} target="_blank" rel="noopener noreferrer">
            link
          </a>
          {')'}
        </Typography>
      );
    }

    // For contacts from DOTERRA_CUSTOMERS or DOTERRA_WELLNESS_ADVOCATES spreadsheets, render a clickable link to their Back Office profile page.
    if (source === 'DOTERRA_CUSTOMERS' || source === 'DOTERRA_WELLNESS_ADVOCATES') {
      const url = `https://www.mydoterra.com/index.cfm?Fuseaction=NetTrax_PerInfo.Layout&DISTID2=${children}`;
      return (
        <Typography {...other}>
          {`${children} (`}
          <a href={url} target="_blank" rel="noopener noreferrer">
            link
          </a>
          {')'}
        </Typography>
      );
    }

    // For contacts from SCOUT_CELLAR_SYNC source, render a clickable link to their Back Office profile page.
    if (source === 'SCOUT_CELLAR_SYNC') {
      let url = '';
      if (contactType === 'CUSTOMER') {
        url = `https://team.scoutandcellar.com/Portal/Customers/Center#/CustomerCenter/customer/${children}/orders`;
      } else if (contactType === 'CONSULTANT') {
        url = `https://team.scoutandcellar.com/Portal/Team/Center#/TeamCenter/team/${children}/notes`;
      }
      return (
        <Typography {...other}>
          {`${children} (`}
          <a href={url} target="_blank" rel="noopener noreferrer">
            link
          </a>
          {')'}
        </Typography>
      );
    }

    return <Typography {...other}>{children}</Typography>;
  }
}

export default TypographyContactID;
