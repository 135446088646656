import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';

const Bar = ({
  data,
  layout,
  keys,
  indexBy,
  axisBottom,
  axisLeft,
  enableGridX,
  enableGridY,
  legends,
  margin,
  colors,
  colorBy,
  label,
  labelSkipWidth,
  labelSkipHeight,
  enableLabel,
  tooltip,
}) => (
  <ResponsiveBar
    data={data}
    layout={layout}
    keys={keys}
    indexBy={indexBy}
    margin={margin}
    padding={0.3}
    colors={colors}
    colorBy={colorBy}
    axisTop={null}
    axisBottom={axisBottom}
    axisRight={null}
    axisLeft={axisLeft}
    enableGridX={enableGridX}
    enableGridY={enableGridY}
    theme={{
      tooltip: {
        container: {
          fontFamily: 'MuseoSans',
          fontSize: '12px',
        },
      },
    }}
    label={label}
    labelSkipWidth={labelSkipWidth}
    labelSkipHeight={labelSkipHeight}
    enableLabel={enableLabel}
    tooltip={tooltip}
    legends={legends}
    motionStiffness={90}
    motionDamping={15}
  />
);

Bar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  layout: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  axisBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  axisLeft: PropTypes.string,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  legends: PropTypes.arrayOf(PropTypes.shape({})),
  margin: PropTypes.shape({}),
  colors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]),
  colorBy: PropTypes.string,
  label: PropTypes.func,
  labelSkipWidth: PropTypes.number,
  labelSkipHeight: PropTypes.number,
  enableLabel: PropTypes.bool,
  tooltip: PropTypes.func,
};

Bar.defaultProps = {
  data: undefined,
  layout: undefined,
  keys: undefined,
  indexBy: undefined,
  axisBottom: undefined,
  axisLeft: undefined,
  enableGridX: undefined,
  enableGridY: undefined,
  legends: undefined,
  margin: undefined,
  colors: undefined,
  colorBy: undefined,
  label: undefined,
  labelSkipWidth: undefined,
  labelSkipHeight: undefined,
  enableLabel: undefined,
  tooltip: undefined,
};

export default Bar;
