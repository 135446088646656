import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import FormStripe from 'components/FormStripe';
import { selectLoading } from 'reducers/selectors';
import { DISPATCH_SAVE_PAYMENT } from 'actions/actionTypes';

const styles = theme => ({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginRight: theme.spacing.unit,
  },
  content: {
    paddingTop: theme.spacing.unit,
  },
});

class DialogEditPayment extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    fullScreen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchSavePayment: PropTypes.func.isRequired,
  };

  form = React.createRef();

  handleSubmit = ({ id: cardToken }) => {
    const { dispatchSavePayment, onClose } = this.props;
    dispatchSavePayment(cardToken, onClose);
  };

  render() {
    const { classes, open, fullScreen, loading, onClose } = this.props;
    return (
      <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={onClose}>
        <DialogTitle>Update Payment Method</DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <FormStripe formRef={this.form} onSubmit={this.handleSubmit} />
          <div className={classes.buttons}>
            <Button className={classes.cancelButton} color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              // Note 1: Event() is not supported in IE 9, 10, 11. Use CustomEvent() and its polyfill instead.
              // Note 2: When creating an event and dispatching it manually, need to set cancelable to true, in order for
              // preventDefault to work (which redux-form's handleSubmit() calls for us).
              // https://github.com/facebook/react/issues/12639#issuecomment-382519193
              onClick={() => this.form.current.dispatchEvent(new CustomEvent('submit', { cancelable: true }))}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
  }),
  {
    dispatchSavePayment: (cardToken, callback) => ({ type: DISPATCH_SAVE_PAYMENT, cardToken, callback }),
  },
);

export default compose(
  withConnect,
  withMobileDialog(),
  withStyles(styles),
)(DialogEditPayment);
