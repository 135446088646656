import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth/withWidth';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ResponsiveBar from 'components/ResponsiveBar';
import ResponsiveLine from 'components/ResponsiveLine';
import ResponsiveCalendar from 'components/ResponsiveCalendar';

import { DISPATCH_FETCH_ALL_ACTIVITY_STATS } from 'actions/actionTypes';
import { selectAllStatsActivity, selectCurrentUser } from 'reducers/selectors';
import stringsService from 'services/stringsService';

const styles = theme => ({
  root: {
    margin: '30px 12%',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  statsHalfWrapper: {
    width: '100%',
    height: '300px',
    padding: '15px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  statsFullWrapper: {
    width: '100%',
    padding: '15px',
    margin: '10px 0',
  },
  tick: {
    fontFamily: 'MuseoSans',
    fontSize: '12px',
  },
  calendarWrapper: {
    height: '300px',
    fontFamily: 'MuseoSans',
    fontSize: '12px',
  },
  lineWrapper: {
    height: '300px',
    fontFamily: 'MuseoSans',
    fontSize: '12px',
    paddingRight: '15px',

    '& svg': {
      overflow: 'visible',
    },
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  lineTooltip: {
    background: 'white',
    padding: '9px 12px',
    border: '1px solid #ccc',
  },
  tableCell: {
    '& div': {
      cursor: 'pointer',
    },
  },
  activeCell: {
    color: '#00bcd4',
  },
  greenColor: {
    color: '#2bb371',
  },
  orangeColor: {
    color: '#f66d1c',
  },
  actionTypeTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

class Stats extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    allStats: PropTypes.shape({}),
    dispatchFetchAllStats: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    allStats: undefined,
  };

  constructor(props) {
    super(props);

    const {
      currentUser: { company },
    } = props;

    this.state = {
      usersTypes: {
        coldProspect: 'Cold Prospects',
        warmProspect: 'Warm Prospects',
        hotProspect: 'Hot Prospects',
        customer: `All ${stringsService.getCustomersLabel(company)}`,
        consultant: `All ${stringsService.getConsultantsLabel(company)}`,
      },
      actionTypes: stringsService.getFunnelInsightsColumns(company),

      numberOfProspectsData: [],
      numberOfActivitiesPerDayData: [],
      numberOfConversationsStartedData: [],
      tableRows: [],
      funnelDataPerDayData: [],
      activeActionType: {
        title: '',
        average: -1,
      },
    };
  }

  componentDidMount() {
    const { dispatchFetchAllStats } = this.props;

    dispatchFetchAllStats();
  }

  componentDidUpdate(prevProps) {
    const { allStats } = this.props;
    if (prevProps.allStats !== allStats) {
      this.updateNumberOfProspectsData(allStats.currentNumberOfProspects);
      this.updateNumberOfActivitiesPerDayData(allStats.numberOfActivitiesPerDay);
      this.updateNumberOfConversationsStartedData(allStats.funnelDataPerDay['01StartedConversation']);
      this.getTableRows(allStats.funnelDataPerDay);
    }
  }

  updateNumberOfProspectsData = currentNumberOfProspects => {
    const numberOfProspectsData = Object.keys(currentNumberOfProspects)
      .map(item => {
        let id;
        if (item === 'coldWithoutActivity') {
          id = 'cold, new';
        } else if (item === 'coldWithActivity') {
          id = 'cold, conversation started';
        } else if (item === 'warm') {
          id = 'warm';
        } else if (item === 'hot') {
          id = 'hot';
        }
        return { id, value: currentNumberOfProspects[item] };
      })
      .reverse();

    this.setState({
      numberOfProspectsData,
    });
  };

  updateNumberOfActivitiesPerDayData = numberOfActivitiesPerDay => {
    let numberOfActivitiesPerDayData = Object.keys(numberOfActivitiesPerDay).map(item => ({
      ...numberOfActivitiesPerDay[item],
      date: item,
    }));
    if (numberOfActivitiesPerDayData.length > 0) {
      numberOfActivitiesPerDayData = numberOfActivitiesPerDayData.slice(numberOfActivitiesPerDayData.length - 7);
    }
    this.setState({
      numberOfActivitiesPerDayData,
    });
  };

  updateNumberOfConversationsStartedData = numberOfConversationsStarted => {
    const numberOfConversationsStartedData = Object.keys(numberOfConversationsStarted).map(date => {
      const day = moment(date)
        .format('YYYY-MM-DD')
        .toString();
      return { day, value: numberOfConversationsStarted[date] };
    });
    this.setState({
      numberOfConversationsStartedData,
    });
  };

  getTableRows = funnelDataPerDay => {
    const tableRows = [];

    const reGroupedData = [];
    Object.keys(funnelDataPerDay).map(item => {
      const groupedByAction = funnelDataPerDay[item];
      const groupedByMonth = {};
      Object.keys(groupedByAction).map(dataItem => {
        const monthName = moment(dataItem)
          .format('MMM YYYY')
          .toString();
        if (!groupedByMonth[monthName]) {
          groupedByMonth[monthName] = groupedByAction[dataItem];
        } else {
          groupedByMonth[monthName] += groupedByAction[dataItem];
        }
        return null;
      });
      reGroupedData.push({ ...groupedByMonth, action: item.slice(2) });
      return null;
    });

    const sortedByMonth = {};
    reGroupedData.map(item => {
      Object.keys(item).map(dataItem => {
        const el = item[dataItem];
        if (dataItem !== 'action') {
          const sortedByMonthItem = {};
          sortedByMonthItem[item.action] = el;
          if (!sortedByMonth[dataItem]) {
            sortedByMonth[dataItem] = sortedByMonthItem;
          } else {
            sortedByMonth[dataItem] = { ...sortedByMonth[dataItem], ...sortedByMonthItem };
          }
        }
        return null;
      });
      return null;
    });

    Object.keys(sortedByMonth).map(item => tableRows.push({ item, ...sortedByMonth[item] }));

    this.setState(
      {
        tableRows: tableRows.reverse(),
      },
      () => {
        this.updateFunnelDataPerDayData(tableRows);
      },
    );
  };

  updateFunnelDataPerDayData = (tableRows, actionType) => {
    const { actionTypes } = this.state;

    const funnelDataPerDayData = [];
    const funnelData = [];
    tableRows.map(item => {
      funnelData.push({
        x: item.item,
        y: this.getPercent(item.StartedConversation, actionType ? item[actionType] : item.BecameWarm),
      });
      return null;
    });
    funnelDataPerDayData.push({
      id: 0,
      data: funnelData.reverse(),
    });
    this.setState({
      funnelDataPerDayData,
      activeActionType: actionTypes[actionType] || actionTypes.BecameWarm,
    });
  };

  renderTableHead = tableRows => {
    const { classes } = this.props;
    const { activeActionType, actionTypes } = this.state;

    return (
      <TableRow>
        <TableCell />
        {// eslint-disable-line arrow-body-style
        Object.keys(actionTypes).map(actionName => (
          <TableCell
            key={actionName}
            className={actionTypes[actionName].average ? classes.tableCell : null}
            onClick={
              actionTypes[actionName].average
                ? () => {
                    this.updateFunnelDataPerDayData(tableRows, actionName);
                  }
                : null
            }
          >
            <div className={activeActionType.title === actionTypes[actionName].title ? classes.activeCell : null}>
              {actionTypes[actionName].title}
            </div>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  getPercent = (total, current) => {
    const result = ((Number(current) * 100) / Number(total)).toFixed(0);
    return result !== 'NaN' ? result : 0;
  };

  render() {
    const { classes, width } = this.props;
    const {
      numberOfProspectsData,
      numberOfActivitiesPerDayData,
      numberOfConversationsStartedData,
      tableRows,
      funnelDataPerDayData,
      activeActionType,
      usersTypes,
      actionTypes,
    } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={/xs/.test(width) ? 0 : 16}>
            <Grid item md={6} xs={12}>
              <Paper className={classes.statsHalfWrapper}>
                <Typography variant="h6">Number of Prospects</Typography>
                <ResponsiveBar
                  data={numberOfProspectsData}
                  layout="horizontal"
                  indexBy="id"
                  keys={['value']}
                  axisLeft={null}
                  axisBottom={null}
                  enableGridY={false}
                  enableGridX={false}
                  margin={{ top: 20, right: 0, bottom: 50, left: 0 }}
                  colors={['#f99eb8', '#fffe99', '#c8e6ff', '#5099cc']}
                  colorBy="indexValue"
                  label={info => `${info.value} ${info.indexValue}`}
                  labelSkipWidth={60}
                  labelSkipHeight={12}
                  tooltip={value => `${value.indexValue}: ${value.value}`}
                />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.statsHalfWrapper}>
                <Typography variant="h6">Daily Activity</Typography>
                <ResponsiveBar
                  data={numberOfActivitiesPerDayData}
                  keys={['coldProspect', 'warmProspect', 'hotProspect', 'customer', 'consultant']}
                  indexBy="date"
                  margin={{ top: 20, right: 0, bottom: 80, left: 30 }}
                  colors={['#c8e6ff', '#fffe99', '#f99eb8', '#d5d5d5', '#a5a5a5']}
                  enableLabel={false}
                  tooltip={value =>
                    `${moment(value.indexValue)
                      .format('ddd, MM/DD')
                      .toString()} - ${usersTypes[value.id]}: ${value.value}`
                  }
                  axisBottom={{
                    renderTick: tick => (
                      <g className={classes.tick}>
                        <g transform={`translate(${tick.x - 20},${tick.y + 22})`}>
                          <text>
                            {moment(tick.value)
                              .format('ddd,')
                              .toString()}
                          </text>
                        </g>
                        <g transform={`translate(${tick.x - 20},${tick.y + 44})`}>
                          <text>
                            {moment(tick.value)
                              .format('MM/DD')
                              .toString()}
                          </text>
                        </g>
                      </g>
                    ),
                  }}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={/xs/.test(width) ? 0 : 16}>
            <Grid item xs={12}>
              <Paper className={classes.statsFullWrapper}>
                <Typography variant="h6">Conversations Started</Typography>
                <div className={classes.calendarWrapper}>
                  <ResponsiveCalendar
                    data={numberOfConversationsStartedData}
                    from={moment()
                      .subtract('months', 11)
                      .startOf('month')
                      .format('YYYY-MM-DD')
                      .toString()}
                    to={moment()
                      .endOf('month')
                      .format('YYYY-MM-DD')
                      .toString()}
                    margin={{ top: 20, right: 0, bottom: 40, left: 20 }}
                    colors={['#ddefff', '#c4dded', '#8cb3cd', '#99feff']}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={/xs/.test(width) ? 0 : 16}>
            <Grid item xs={12}>
              <Paper className={classes.statsFullWrapper}>
                <Typography variant="h6">Funnel Insights</Typography>

                <Typography className={classes.actionTypeTitle}>{activeActionType.title}</Typography>
                <div className={classes.lineWrapper}>
                  <ResponsiveLine
                    data={funnelDataPerDayData}
                    margin={{ top: 20, right: 10, bottom: 80, left: 40 }}
                    enableGridX={false}
                    axisLeft={{
                      tickValues: [0, 50, 100],
                      format: value => `${Number(value)} %`,
                    }}
                    colors={['#5099cc', '#fffe99', '#f99eb8', '#d5d5d5', '#a5a5a5']}
                    tooltip={value => (
                      <div className={classes.lineTooltip}>
                        {value.point.data.x}: {value.point.data.y}%
                      </div>
                    )}
                    markers={[
                      {
                        axis: 'y',
                        value: `${activeActionType.average}`,
                        lineStyle: { stroke: '#c1c1c1', strokeWidth: 2, strokeDasharray: 8 },
                        legend: 'Company average',
                        legendOrientation: 'horizontal',
                      },
                    ]}
                  />
                </div>
                <div className={classes.tableWrapper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>{this.renderTableHead(tableRows)}</TableHead>
                    <TableBody>
                      {tableRows.map(row => {
                        const becameWarmPercents = this.getPercent(row.StartedConversation, row.BecameWarm);
                        const becameHotPercents = this.getPercent(row.BecameWarm, row.BecameHot);
                        const becameCustomerPercents = this.getPercent(row.BecameHot, row.BecameCustomer);
                        const becameConsultantPercents = this.getPercent(row.BecameCustomer, row.BecameConsultant);

                        return (
                          <TableRow key={row.item}>
                            <TableCell component="th" scope="row" className={classes.noWrap}>
                              {row.item}
                            </TableCell>
                            <TableCell>{row.StartedConversation}</TableCell>
                            <TableCell>
                              {row.BecameWarm}
                              <div
                                className={
                                  becameWarmPercents >= actionTypes.BecameWarm.average
                                    ? classes.greenColor
                                    : classes.orangeColor
                                }
                              >
                                {becameWarmPercents} %
                              </div>
                            </TableCell>
                            <TableCell>
                              {row.BecameHot}
                              <div
                                className={
                                  becameHotPercents >= actionTypes.BecameHot.average
                                    ? classes.greenColor
                                    : classes.orangeColor
                                }
                              >
                                {becameHotPercents} %
                              </div>
                            </TableCell>
                            <TableCell>
                              {row.BecameCustomer}
                              <div
                                className={
                                  becameCustomerPercents >= actionTypes.BecameCustomer.average
                                    ? classes.greenColor
                                    : classes.orangeColor
                                }
                              >
                                {becameCustomerPercents} %
                              </div>
                            </TableCell>
                            <TableCell>
                              {row.BecameConsultant}
                              <div
                                className={
                                  becameConsultantPercents >= actionTypes.BecameConsultant.average
                                    ? classes.greenColor
                                    : classes.orangeColor
                                }
                              >
                                {becameConsultantPercents} %
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    allStats: selectAllStatsActivity,
    currentUser: selectCurrentUser,
  }),
  {
    dispatchFetchAllStats: () => ({ type: DISPATCH_FETCH_ALL_ACTIVITY_STATS }),
  },
);

export default compose(
  withConnect,
  withWidth(),
  withStyles(styles),
)(Stats);
