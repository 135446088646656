import React from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import PropTypes from 'prop-types';

const Calendar = ({ data, from, to, margin, colors }) => (
  <ResponsiveCalendar
    data={data}
    from={from}
    to={to}
    emptyColor="#eeeeee"
    colors={colors}
    margin={margin}
    yearSpacing={40}
    monthBorderColor="#ffffff"
    dayBorderWidth={2}
    dayBorderColor="#ffffff"
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'row',
        translateY: 36,
        itemCount: 3,
        itemWidth: 42,
        itemHeight: 36,
        itemsSpacing: 14,
        itemDirection: 'right-to-left',
      },
    ]}
  />
);

Calendar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  from: PropTypes.string,
  to: PropTypes.string,
  margin: PropTypes.shape({}),
  colors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]),
};

Calendar.defaultProps = {
  data: undefined,
  from: undefined,
  to: undefined,
  margin: undefined,
  colors: undefined,
};

export default Calendar;
