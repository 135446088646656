import { call } from 'redux-saga/effects';

import { FETCH_SCRIPTS } from 'actions/actionTypes';
import scriptsResource from 'resources/scriptsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchScripts = sagaFetch.bind(null, FETCH_SCRIPTS, scriptsResource.fetchScripts);

export default function* sagaFetchScripts() {
  yield call(fetchScripts);
}
