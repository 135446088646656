import moment from 'moment-timezone';
import api from 'services/api';
import usersResource from './usersResource';

const url = '/api/v1/account';

// Methods
function fetchAccount() {
  const timezone = moment.tz.guess();
  return api.get(url, {
    params: { timezone },
    schema: usersResource.userSchema,
    skipErrorMessage: true,
  });
}

function saveAccount({ account }) {
  return api.put(
    url,
    { user: account },
    {
      schema: usersResource.userSchema,
    },
  );
}

function savePayment({ cardToken }) {
  return api.put(`${url}/payment`, null, {
    params: { cardToken },
    schema: usersResource.userSchema,
  });
}

function savePlan({ planKey }) {
  return api.put(`${url}/subscription`, null, {
    params: { planKey },
    schema: usersResource.userSchema,
  });
}

export default {
  fetchAccount,
  saveAccount,
  savePayment,
  savePlan,
};
