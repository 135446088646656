import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';

import FieldTrimmed from 'components/FieldTrimmed';
import Header from 'containers/Header';
import FormTextField from 'components/FormTextField';
import validation from 'services/validationService';
import { DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN, DISPATCH_SET_NEW_PASSWORD } from 'actions/actionTypes';
import { selectLoading, selectAccessToken } from 'reducers/selectors';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  root: {
    background: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: 350,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing.unit * 4,
    width: 160,
  },
});

class SetNewPassword extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool,
    accessToken: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    dispatchValidateResetPasswordToken: PropTypes.func.isRequired,
    dispatchSetNewPassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    accessToken: undefined,
  };

  componentDidMount() {
    const {
      dispatchValidateResetPasswordToken,
      location: { search },
    } = this.props;
    dispatchValidateResetPasswordToken(queryString.parse(search));
  }

  render() {
    const { classes, accessToken, handleSubmit, dispatchSetNewPassword, pristine, submitting, loading } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        <form
          className={classes.wrapper}
          onSubmit={handleSubmit(values => dispatchSetNewPassword({ ...accessToken, ...values }))}
        >
          <div className={classes.toolbar} />
          <Typography variant="h5" gutterBottom>
            Set your new password
          </Typography>
          <FieldTrimmed
            component={FormTextField}
            type="password"
            name="password"
            label="Password (at least 8 characters)"
            margin="normal"
            fullWidth
            autoFocus
          />
          <FieldTrimmed
            component={FormTextField}
            type="password"
            name="passwordConfirmation"
            label="Confirm password"
            margin="normal"
            fullWidth
          />
          <div className={classes.actions}>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={pristine || submitting || loading || !accessToken}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    loading: selectLoading,
    accessToken: selectAccessToken,
  }),
  {
    dispatchValidateResetPasswordToken: params => ({ type: DISPATCH_VALIDATE_RESET_PASSWORD_TOKEN, params }),
    dispatchSetNewPassword: params => ({ type: DISPATCH_SET_NEW_PASSWORD, params }),
  },
);

const withForm = reduxForm({
  form: 'SetNewPassword',
  validate: validation.createValidator({
    password: [validation.required, validation.password],
    passwordConfirmation: [validation.required, validation.passwordConfirmation],
  }),
});

export default compose(
  withConnect,
  withForm,
  withStyles(styles),
)(SetNewPassword);
