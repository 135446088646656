import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    margin: `${theme.spacing.unit * 8}px ${theme.spacing.unit}px`,
    color: theme.palette.grey[500],
    flex: 1,
  },
});

class EmptyState extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { classes, children, ...other } = this.props;
    return (
      <Typography className={classes.root} variant="subtitle1" align="center" {...other}>
        {children}
      </Typography>
    );
  }
}

export default compose(withStyles(styles))(EmptyState);
