import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';

const Line = ({ data, enableGridX, enableGridY, axisBottom, axisLeft, margin, colors, tooltip, markers }) => (
  <ResponsiveLine
    data={data}
    margin={margin}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 0, max: 100 }}
    enableGridX={enableGridX}
    enableGridY={enableGridY}
    axisTop={null}
    axisRight={null}
    axisLeft={axisLeft}
    axisBottom={axisBottom}
    colors={colors}
    pointSize={5}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh
    tooltip={tooltip}
    markers={markers}
  />
);

Line.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  axisBottom: PropTypes.shape({}),
  axisLeft: PropTypes.shape({}),
  margin: PropTypes.shape({}),
  colors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]),
  tooltip: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.shape({})),
};

Line.defaultProps = {
  data: undefined,
  enableGridX: undefined,
  enableGridY: undefined,
  axisBottom: undefined,
  axisLeft: undefined,
  margin: undefined,
  colors: undefined,
  tooltip: undefined,
  markers: undefined,
};

export default Line;
