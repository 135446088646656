import { call, put } from 'redux-saga/effects';

// resuable fetch subroutine
// types : action types object with REQUEST, SUCCESS, ERROR
// fn    : resource function: authResource.signIn | ...
// args  : arguments passed to the resource function
export default function* sagaFetch(types, fn, args = {}) {
  let requestAction;
  try {
    requestAction = { type: types.REQUEST, ...args };
    yield put(requestAction);
    const res = yield call(fn, args);
    yield put({ type: types.SUCCESS, ...res, meta: { requestAction } });

    // show S&C error
    if (res.data.error) {
      yield put({ type: types.ERROR, error: "The username or password you entered isn't correct. Please try again." });
      return {
        error: "The username or password you entered isn't correct. Please try again.",
        meta: { requestAction },
      };
    }

    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({ type: types.ERROR, error });
    return { error, meta: { requestAction } };
  }
}
