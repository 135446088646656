import { call } from 'redux-saga/effects';

import { FETCH_ALL_ACTIVITY_STATS } from 'actions/actionTypes';
import activitiesResource from 'resources/activitiesResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const fetchAllActivityStats = sagaFetch.bind(null, FETCH_ALL_ACTIVITY_STATS, activitiesResource.fetchAllActivityStats);

export default function* sagaFetchAllActivityStats() {
  yield call(fetchAllActivityStats);
}
