import { filter, find } from 'lodash';

const companies = [
  'ARBONNE',
  'BEACHBODY',
  'BEAUTYCOUNTER',
  'CREW',
  'DOTERRA',
  'HEMPWORX',
  'ISAGENIX',
  'IT_WORKS',
  'JEUNESSE',
  'LE_VEL',
  'LIMELIFE',
  'MELALEUCA',
  'MODERE',
  'MONAT',
  'OPTAVIA',
  'PLEXUS',
  'PRUVIT',
  'RODAN_FIELDS',
  'SCENTSY',
  'SCOUT_CELLAR',
  'SENEGENCE',
  'SHAKLEE',
  'YOUNG_LIVING',
  'YOUNIQUE',
  'OTHER', // 'Other' should be the last Company option
];
const companyLabels = {
  PLEXUS: 'Plexus',
  MONAT: 'Monat',
  DOTERRA: 'doTERRA',
  ARBONNE: 'Arbonne',
  BEACHBODY: 'Beachbody',
  BEAUTYCOUNTER: 'Beautycounter',
  CREW: 'Crew',
  HEMPWORX: 'Hempworx',
  ISAGENIX: 'Isagenix',
  IT_WORKS: 'It Works!',
  JEUNESSE: 'Jeunesse',
  LE_VEL: 'Le-Vel',
  LIMELIFE: 'LimeLife by Alcone',
  MELALEUCA: 'Melaleuca',
  MODERE: 'Modere',
  OPTAVIA: 'Optavia',
  OTHER: 'Other',
  PRUVIT: 'Pruvit',
  RODAN_FIELDS: 'Rodan + Fields',
  SCENTSY: 'Scentsy',
  SCOUT_CELLAR: 'Scout & Cellar',
  SENEGENCE: 'SeneGence',
  SHAKLEE: 'Shaklee',
  YOUNG_LIVING: 'Young Living',
  YOUNIQUE: 'Younique',
};

const careerTitles = {
  PLEXUS: [
    'AMBASSADOR',
    'SENIOR_AMBASSADOR',
    'SILVER_AMBASSADOR',
    'GOLD_AMBASSADOR',
    'SENIOR_GOLD_AMBASSADOR',
    'RUBY_AMBASSADOR',
    'SENIOR_RUBY_AMBASSADOR',
    'EMERALD_AMBASSADOR',
    'SAPPHIRE_AMBASSADOR',
    'DIAMOND_AMBASSADOR',
  ],
  MONAT: [
    'MARKET_PARTNER',
    'MANAGING_MARKET_PARTNER',
    'ASSOCIATE_MARKET_BUILDER',
    'MARKET_BUILDER',
    'MANAGING_MARKET_BUILDER',
    'ASSOCIATE_MARKET_MENTOR',
    'MARKET_MENTOR',
    'MANAGING_MARKET_MENTOR',
    'ASSOCIATE_EXECUTIVE_DIRECTOR',
    'EXECUTIVE_DIRECTOR',
    'SENIOR_EXECUTIVE_DIRECTOR',
  ],
  DOTERRA: [
    '01_WELLNESS_ADVOCATE',
    '02_MANAGER',
    '03_DIRECTOR',
    '04_EXECUTIVE',
    '05_ELITE',
    '06_PREMIER',
    '07_SILVER',
    '08_GOLD',
    '09_PLATINUM',
    '10_DIAMOND',
    '11_BLUE_DIAMOND',
    '12_PRESIDENTIAL_DIAMOND',
    '13_DOUBLE_DIAMOND',
    '14_DOUBLE_BLUE_DIAMOND',
    '15_DOUBLE_PRESIDENTIAL_DIAMOND',
    '16_TRIPLE_DIAMOND',
  ],
  ARBONNE: [
    '01_CONSULTANT',
    '02_DISTRICT_MANAGER',
    '03_AREA_MANAGER',
    '04_REGIONAL_VICE_PRESIDENT',
    '05_NATIONAL_VICE_PRESIDENT',
  ],
  BEACHBODY: [
    '01_COACH',
    '02_EMERALD',
    '03_RUBY',
    '04_DIAMOND',
    '05_1_STAR_DIAMOND',
    '06_2_STAR_DIAMOND',
    '07_3_STAR_DIAMOND',
    '08_4_STAR_DIAMOND',
    '09_5_STAR_DIAMOND',
    '10_6_STAR_DIAMOND',
    '11_7_STAR_DIAMOND',
    '12_8_STAR_DIAMOND',
    '13_9_STAR_DIAMOND',
    '14_10_STAR_DIAMOND',
    '15_11_STAR_DIAMOND',
    '16_12_STAR_DIAMOND',
    '17_13_STAR_DIAMOND',
    '18_14_STAR_DIAMOND',
    '19_15_STAR_DIAMOND',
    '20_SUPER_STAR_DIAMOND+',
  ],
  BEAUTYCOUNTER: [
    '01_CONSULTANT',
    '02_SENIOR_CONSULTANT',
    '03_MANAGER',
    '04_SENIOR_MANAGER',
    '05_DIRECTOR',
    '06_SENIOR_DIRECTOR',
    '07_EXECUTIVE_DIRECTOR',
    '08_MANAGING_DIRECTOR',
  ],
  HEMPWORX: [
    '01_AFFILIATE',
    '02_BUILDER',
    '03_DIRECTOR',
    '04_EXECUTIVE',
    '05_5K',
    '06_10K',
    '07_25K',
    '08_50K',
    '09_100K',
    '10_250K',
    '11_500K',
    '12_SUPER',
  ],
  ISAGENIX: ['01_ASSOCIATE', '02_CONSULTANT', '03_MANAGER', '04_DIRECTOR', '05_EXECUTIVE', '06_PLATINUM'],
  IT_WORKS: [
    '01_DISTRIBUTOR',
    '02_EXECUTIVE',
    '03_RUBY',
    '04_EMERALD',
    '05_DIAMOND',
    '06_DOUBLE_DIAMOND',
    '07_TRIPLE_DIAMOND',
    '08_PRESIDENTIAL_DIAMOND',
    '09_AMBASSADOR_DIAMOND',
  ],
  JEUNESSE: [
    '01_ASSOCIATE',
    '02_DISTRIBUTOR',
    '03_EXECUTIVE',
    '04_JADE_EXECUTIVE',
    '05_PEARL_EXECUTIVE',
    '06_SAPPHIRE_EXECUTIVE',
    '07_SAPPHIRE_ELITE',
    '08_RUBY_DIRECTOR',
    '09_EMERALD_DIRECTOR',
    '10_DIAMOND_DIRECTOR',
    '11_DOUBLE_DIAMOND_DIRECTOR',
    '12_TRIPLE_DIAMOND_DIRECTOR',
    '13_PRESIDENTIAL_DIAMOND_DIRECTOR',
    '14_IMPERIAL_DIAMOND_DIRECTOR',
    '15_CROWNE_DIAMOND_DIRECTOR',
  ],
  LE_VEL: ['01_PROMOTER', '02_4K_VIP', '03_12K_VIP', '04_40K_VIP', '05_80K_VIP', '06_200K_VIP'],
  LIMELIFE: [
    '01_BEAUTY_GUIDE',
    '02_LEAD_BEAUTY_GUIDE',
    '03_STAR_BEAUTY_GUIDE',
    '04_DIRECTOR',
    '05_LEAD_DIRECTOR',
    '06_STAR_DIRECTOR',
    '07_SENIOR_DIRECTOR',
    '08_LEAD_PLATINUM_DIRECTOR',
    '09_STAR_PLATINUM_DIRECTOR',
    '10_SENIOR_PLATINUM_DIRECTOR',
  ],
  MELALEUCA: [
    '01_PRODUCT_ADVOCATE',
    '02_DIRECTOR',
    '03_SENIOR_DIRECTOR',
    '04_EXECUTIVE_DIRECTOR',
    '05_NATIONAL_DIRECTOR',
    '06_CORPORATE_DIRECTOR',
    '07_PRESIDENTIAL_DIRECTOR',
  ],
  MODERE: [
    '01_CONSULTANT',
    '02_SENIOR_CONSULTANT',
    '03_TEAM_LEADER',
    '04_SENIOR_TEAM_LEADER',
    '05_DIRECTOR_1',
    '06_DIRECTOR_2',
    '07_DIRECTOR_3',
    '08_ELITE_1',
    '09_ELITE_2',
    '10_ELITE_3',
  ],
  OPTAVIA: [
    '01_COACH',
    '02_SENIOR_COACH',
    '03_MANAGER',
    '04_ASSOCIATE_DIRECTOR',
    '05_DIRECTOR',
    '06_EXECUTIVE_DIRECTOR',
    '07_REGIONAL_DIRECTOR',
    '08_INTEGRATED_REGIONAL_DIRECTOR',
    '09_NATIONAL_DIRECTOR',
    '10_INTEGRATED_NATIONAL_DIRECTOR',
    '11_GLOBAL_DIRECTOR',
    '12_INTEGRATED_GLOBAL_DIRECTOR',
    '13_PRESIDENTIAL_DIRECTOR',
    '14_INTEGRATED_PRESIDENTIAL_DIRECTOR',
  ],
  OTHER: [
    // No defined career titles. Instead, the user will be asked for a freeform company and career title during signup.
  ],
  PRUVIT: [
    '01_R1_PRUVER',
    '02_R2_PRUVER',
    '03_R3_PRUVER',
    '04_R4_PRUVER',
    '05_R5_PRUVER',
    '06_R6_CHAMPION',
    '07_R7_100K_CHAMPION',
    '08_R8_250K_CHAMPION',
    '09_R9_750K_CHAMPION',
    '10_R10_1M_CHAMPION',
    '11_LEGEND',
  ],
  RODAN_FIELDS: [
    '01_CONSULTANT',
    '02_EXECUTIVE_CONSULTANT',
    '03_LEVEL_I_EXECUTIVE',
    '04_LEVEL_II_EXECUTIVE',
    '05_LEVEL_III_EXECUTIVE',
    '06_LEVEL_IV_EXECUTIVE',
    '07_LEVEL_V_EXECUTIVE',
    '08_RFX_EXECUTIVE',
  ],
  SCENTSY: [
    '01_ESCENTIAL_CONSULTANT',
    '02_CERTIFIED_CONSULTANT',
    '03_LEAD_CONSULTANT',
    '04_STAR_CONSULTANT',
    '05_SUPERSTAR_CONSULTANT',
    '06_DIRECTOR',
    '07_STAR_DIRECTOR',
    '08_SUPERSTAR_DIRECTOR',
  ],
  SCOUT_CELLAR: [
    '01_CONSULTANT',
    '02_SENIOR_CONSULTANT',
    '03_EXECUTIVE_CONSULTANT',
    '04_ASSOCIATE_MANAGER',
    '05_SENIOR_MANAGER',
    '06_EXECUTIVE_MANAGER',
    '07_ASSOCIATE_DIRECTOR',
    '08_DIRECTOR',
    '09_SENIOR_DIRECTOR',
    '10_EXECUTIVE_DIRECTOR',
    '11_MANAGING_DIRECTOR',
  ],
  SENEGENCE: [
    '01_MAIDEN',
    '02_ROYAL_IN_WAITING',
    '03_ROYAL',
    '04_LADY_IN_WAITING',
    '05_LADY',
    '06_COUNTESS_IN_WAITING',
    '07_COUNTESS',
    '08_DUCHESS_IN_WAITING',
    '09_DUCHESS',
    '10_PRINCESS_IN_WAITING',
    '11_PRINCESS',
    '12_CROWN_PRINCESS',
    '13_RUBY_CROWN_PRINCESS',
    '14_SAPPHIRE_CROWN_PRINCESS',
    '15_EMERALD_CROWN_PRINCESS',
    '16_QUEEN',
    '17_RUBY_QUEEN',
    '18_SAPPHIRE_QUEEN',
    '19_EMERALD_QUEEN',
    '20_AMETHYST_QUEEN',
    '21_EMPRESS',
    '22_MONARCH',
    '23_RULER',
    '24_MAJESTY',
  ],
  SHAKLEE: [
    '01_DISTRIBUTOR',
    '02_ASSOCIATE',
    '03_DIRECTOR',
    '04_SENIOR_DIRECTOR',
    '05_COORDINATOR',
    '06_SENIOR_COORDINATOR',
    '07_EXECUTIVE_COORDINATOR',
    '08_SENIOR_EXECUTIVE_COORDINATOR',
    '09_KEY_COORDINATOR',
    '10_SENIOR_KEY_COORDINATOR',
    '11_MASTER_COORDINATOR',
    '12_SENIOR_MASTER_COORDINATOR',
    '13_PRESIDENTIAL_MASTER_COORDINATOR',
  ],
  YOUNG_LIVING: [
    '01_MEMBER',
    '02_STAR',
    '03_SENIOR_STAR',
    '04_EXECUTIVE',
    '05_SILVER',
    '06_GOLD',
    '07_PLATINUM',
    '08_DIAMOND',
    '09_CROWN_DIAMOND',
    '10_ROYAL_CROWN_DIAMOND',
  ],
  CREW: [
    '01_MEMBER',
    '02_STAR',
    '03_SENIOR_STAR',
    '04_EXECUTIVE',
    '05_SILVER',
    '06_GOLD',
    '07_PLATINUM',
    '08_DIAMOND',
    '09_CROWN_DIAMOND',
    '10_ROYAL_CROWN_DIAMOND',
  ],
  YOUNIQUE: ['01_WHITE', '02_YELLOW', '03_PINK', '04_BLUE', '05_GREEN', '06_ORANGE', '07_PURPLE', '08_BLACK'],
};

const plans = [
  {
    planKey: 'PLAN_TRIAL',
    name: 'Trial Plan',
    price: 0,
    expireText: 'Your trial will expire on {{subscriptionExpiresAt}}',
  },
  {
    planKey: 'PLAN_12MO',
    name: 'Annual Plan',
    undiscountedPrice: 18.99,
    price: 179.91,
    months: 12,
    description: 'Billed annually',
    expireText:
      "Your subscription will automatically renew on {{ subscriptionExpiresAt }} and you'll be charged {{ price }} + tax every 12 months",
  },
  {
    planKey: 'PLAN_6MO',
    name: 'Semiannual Plan',
    undiscountedPrice: 20.99,
    monthlyDisplayPrice: 16.67,
    price: 99.95,
    months: 6,
    description: 'Billed every six months',
    expireText:
      "Your subscription will automatically renew on {{ subscriptionExpiresAt }} and you'll be charged {{ price }} + tax every 6 months",
    mostPopular: true,
  },
  {
    planKey: 'PLAN_1MO',
    name: 'Monthly Plan',
    undiscountedPrice: 24.99,
    price: 19.99,
    months: 1,
    description: 'Billed month-to-month',
    expireText:
      "Your subscription will automatically renew on {{subscriptionExpiresAt}} and you'll be charged {{ price }} + tax every month",
  },
];

function getCompanies() {
  return companies;
}

function getCompanyLabel(company) {
  return companyLabels[company];
}

function getCareerTitles(company) {
  return careerTitles[company];
}

function getPlansAvailable() {
  return filter(plans, ({ price }) => price > 0);
}

function getPlan(planKey = 'PLAN_TRIAL') {
  return find(plans, { planKey });
}

export default {
  getCompanies,
  getCompanyLabel,
  getCareerTitles,
  getPlansAvailable,
  getPlan,
};
