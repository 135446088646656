import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from 'material-ui-pickers/DatePicker/components/Calendar';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import moment from 'moment';
import stringsService from 'services/stringsService';
import { selectCurrentUser } from 'reducers/selectors';
import Sparkles from 'components/Sparkles';

const styles = theme => ({
  header: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconClose: {
    position: 'absolute',
    right: 0,
  },
  notesWrapper: {
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
  },
  actions: {
    padding: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  action: {
    marginLeft: theme.spacing.unit / 2,
    position: 'relative',
  },
  setActionsBtns: {
    marginRight: 10,
  },
  defNotes: {
    marginBottom: 15,
    width: '430px',
    maxWidth: '100%',
  },
  notesBtn: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '3px',
    maxWidth: '100px',
    minWidth: '1px',
    minHeight: '56px',
    fontSize: '13px',
    borderRadius: '10px',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  shortNotesBtn: {
    maxWidth: '65px',
  },
  cold: {
    color: '#1565C0',
    border: '1px solid #1565C0',
  },
  warm: {
    color: '#FF9000',
    border: '1px solid #FF9000',
  },
  hot: {
    color: '#F13A00',
    border: '1px solid #F13A00',
  },
  defColor: {
    color: '#434e61',
    border: '1px solid #434e61',
  },
  actionsWrapper: {
    marginRight: '2rem',
  },
});

class PopoverActivity extends Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      date: PropTypes.shape({}),
      notes: PropTypes.string,
    }),
    title: PropTypes.string,
    secondaryAction: PropTypes.node,
    moveOnResize: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    changeChips: PropTypes.func,
    handleReset: PropTypes.func,
    dispatch: PropTypes.func,
    currentContactType: PropTypes.string,
    currentUser: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    value: undefined,
    title: 'Activity',
    secondaryAction: undefined,
    moveOnResize: false,
    changeChips: undefined,
    handleReset: undefined,
    dispatch: undefined,
    currentContactType: 'PROSPECT',
  };

  constructor(props) {
    super(props);

    const { open, currentContactType } = props;

    this.state = {
      open,
      date: moment(),
      notes: '',
      contactType: currentContactType,
      contactStatus: undefined,
      frequency: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { value, open, currentContactType } = this.props;

    if (value && !isEqual(value, prevProps.value)) {
      this.handleNewState(value);
    } else if (open && !prevProps.open && !value) {
      this.handleNewState({
        date: moment(),
        notes: '',
      });
    } else if (currentContactType && !isEqual(currentContactType, prevProps.currentContactType)) {
      this.handleNewState({ contactType: currentContactType });
    }
    if (open !== prevProps.open) {
      this.handleNewState({ open });
    }
  }

  handleNewState = state => {
    this.setState({
      ...state,
    });
  };

  handleDateChange = date => {
    this.setState({ date });
  };

  handleTextChange = event => {
    const { handleReset } = this.props;
    this.setState({
      notes: event.target.value,
    });

    if (event.target.value === '') {
      handleReset();
    }
  };

  getColorByStatus = status => {
    const { classes } = this.props;
    switch (status) {
      case 'COLD':
        return classes.cold;
      case 'WARM':
        return classes.warm;
      case 'HOT':
        return classes.hot;
      default:
        return classes.defColor;
    }
  };

  handleSizeChange = () => {
    const { moveOnResize } = this.props;
    if (!moveOnResize) {
      return;
    }
    this.setState({ open: false });
    setTimeout(() => {
      this.setState({ open: true });
    });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    this.setState({
      isAnimating: true,
    });
    setTimeout(() => {
      this.setState(
        {
          isAnimating: false,
        },
        () => {
          onSubmit(this.state);
        },
      );
    }, 750);
  };

  handleSelectedItem = event => {
    const { changeChips } = this.props;

    if (event.contactType === 'CUSTOMER') {
      this.setState({
        isAnimatingPill3: true,
      });
      setTimeout(() => {
        this.setState(
          {
            ...event,
            isAnimatingPill3: false,
          },
          () => {
            changeChips(event);
          },
        );
      }, 750);
    } else if (event.contactType === 'CONSULTANT') {
      this.setState({
        isAnimatingPill4: true,
      });
      setTimeout(() => {
        this.setState(
          {
            ...event,
            isAnimatingPill4: false,
          },
          () => {
            changeChips(event);
          },
        );
      }, 750);
    } else {
      this.setState(
        {
          ...event,
        },
        () => {
          changeChips(event);
        },
      );
    }
  };

  render() {
    const {
      classes,
      title,
      secondaryAction,
      onClose,
      moveOnResize,
      onSubmit,
      changeChips,
      handleReset,
      currentUser,
      dispatch,
      currentContactType,
      ...other
    } = this.props;
    const { date, notes, open, contactType, isAnimating, isAnimatingPill3, isAnimatingPill4 } = this.state;
    const quickNotes = stringsService.getQuickNotes(currentUser.company, contactType);
    return (
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => onClose()}
        {...other}
        open={open}
      >
        <div className={classes.header}>
          <Typography>{title}</Typography>
          <IconButton
            className={classes.iconClose}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => onClose()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <ExpansionPanel
          elevation={0}
          CollapseProps={moveOnResize ? { timeout: 0 } : undefined}
          onChange={this.handleSizeChange}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
            <Typography className={classes.heading}>
              {date.calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'MM/DD/YY',
              })}
            </Typography>
          </ExpansionPanelSummary>
          <Calendar allowKeyboardControl={false} date={date} onChange={this.handleDateChange} />
        </ExpansionPanel>
        <div className={classes.notesWrapper}>
          {notes.length === 0 && title !== 'Edit Activity' ? (
            <div className={classes.defNotes}>
              {map(quickNotes, item => (
                <Button
                  key={item.key}
                  variant="outlined"
                  className={`${classes.notesBtn} ${this.getColorByStatus(item.contactStatus)} ${
                    item.notes === 'Engaged / RB' || item.notes === 'Followed up' ? classes.shortNotesBtn : ''
                  }`}
                  onClick={() => this.handleSelectedItem(item)}
                >
                  {item.notes}
                  {item.contactType === 'CUSTOMER' ? <Sparkles isAnimating={isAnimatingPill3} /> : null}
                  {item.contactType === 'CONSULTANT' ? <Sparkles isAnimating={isAnimatingPill4} /> : null}
                </Button>
              ))}
            </div>
          ) : null}
          <TextField
            label="Notes (optional)"
            value={notes}
            onChange={this.handleTextChange}
            rowsMax="4"
            multiline
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
          />
        </div>
        <Divider />
        <div className={classes.actions}>
          {secondaryAction}

          <Button
            className={classes.action}
            variant="contained"
            color="primary"
            size="small"
            onClick={this.handleSubmit}
          >
            Save
            <Sparkles isAnimating={isAnimating} />
          </Button>
        </div>
      </Popover>
    );
  }
}

const withConnect = connect(
  createStructuredSelector({
    currentUser: selectCurrentUser,
  }),
);

export default compose(
  withConnect,
  withStyles(styles),
)(PopoverActivity);
