import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class TypographyPhone extends Component {
  static propTypes = {
    children: PropTypes.string,
    disableClick: PropTypes.bool,
  };

  static defaultProps = {
    children: undefined,
    disableClick: false,
  };

  render() {
    const { children, disableClick, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }
    const value = children.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    if (disableClick) {
      return <Typography {...other}>{value}</Typography>;
    }
    return (
      <Typography {...other}>
        {
          <span>
            <a href={`tel:${children}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
            {' ('}
            {
              <a href={`sms:${children}`} target="_blank" rel="noopener noreferrer">
                text
              </a>
            }
            {')'}
          </span>
        }
      </Typography>
    );
  }
}

export default TypographyPhone;
