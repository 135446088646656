import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { get } from 'lodash';

class FormTextField extends React.Component {
  static propTypes = {
    input: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}).isRequired,
    helperText: PropTypes.string,
  };

  static defaultProps = {
    helperText: null,
  };

  onChange = event => {
    const { input } = this.props;
    const {
      target: { value },
    } = event;
    input.onChange(value);
  };

  render() {
    const {
      helperText,
      input: { name, value, onBlur },
      meta: { error, touched },
      ...other
    } = this.props;
    const readOnly = get(other, 'InputProps.readOnly', false);
    const hasError = !readOnly && touched && !!error;

    let errorText = null;
    if (hasError) {
      // If error is on accessCode field in Signup form, display a custom message.
      // Else, simply display the original error message.
      if (name === 'accessCode') {
        errorText = (
          <span>
            {"We're in closed beta! Email "}
            <a href="mailto:support@doughcrm.com">support@doughcrm.com</a>
            {' for an access code'}
          </span>
        );
      } else {
        errorText = error;
      }
    }

    return (
      <TextField
        value={value}
        onChange={this.onChange}
        onBlur={onBlur}
        helperText={errorText || helperText}
        error={hasError}
        {...other}
      />
    );
  }
}

export default FormTextField;
