import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class TypographyEmail extends Component {
  static propTypes = {
    children: PropTypes.string,
    subject: PropTypes.string,
    disableClick: PropTypes.bool,
  };

  static defaultProps = {
    children: undefined,
    subject: undefined,
    disableClick: false,
  };

  render() {
    const { children, subject, disableClick, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }
    if (disableClick) {
      return <Typography {...other}>{children}</Typography>;
    }
    let href = `mailto:${children}`;
    if (subject) {
      href += `?subject=${subject}`;
    }
    return (
      <Typography {...other}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      </Typography>
    );
  }
}

export default TypographyEmail;
