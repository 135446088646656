import { call } from 'redux-saga/effects';

import { VALIDATE_RESET_PASSWORD_TOKEN } from 'actions/actionTypes';
import passwordsResource from 'resources/passwordsResource';
import sagaFetch from './sagaFetch';

// bind resource generator
const validateResetPasswordToken = sagaFetch.bind(
  null,
  VALIDATE_RESET_PASSWORD_TOKEN,
  passwordsResource.validateResetPasswordToken,
);

export default function* sagaValidateResetPasswordToken({ params }) {
  yield call(validateResetPasswordToken, { params });
}
