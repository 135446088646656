import { call } from 'redux-saga/effects';

import { SIGN_IN } from 'actions/actionTypes';
import authResource from 'resources/authResource';
import history from 'config/history';
import sagaFetch from './sagaFetch';

// bind resource generator
const signIn = sagaFetch.bind(null, SIGN_IN, authResource.signIn);

export default function* sagaSignIn({ credentials }) {
  const res = yield call(signIn, { credentials });
  if (!res.error) {
    history.push('/');
  }
}
