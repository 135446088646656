import { schema } from 'normalizr';

import resource from './resource';

const idAttribute = 'uid';

// Interceptors
function processStrategy(data) {
  return resource.processStrategy(data, idAttribute);
}

// Schemas
const accessTokenSchema = new schema.Entity('accessTokens', {}, { idAttribute, processStrategy });

export default {
  accessTokenSchema,
};
