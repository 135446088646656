import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compact } from 'lodash';

class TypographyAddress extends Component {
  static propTypes = {
    children: PropTypes.shape({}),
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { children, ...other } = this.props;
    if (!children) {
      return <Typography {...other}>-</Typography>;
    }

    const { line1, line2, city, state, zipCode, country } = children;
    const zipCodeString = zipCode || '';
    const stateString = state || '';
    const details = compact([city, stateString || zipCodeString ? `${stateString} ${zipCodeString}` : '']).join(', ');
    return (
      <Typography {...other}>
        {line1 && <Typography variant="inherit">{line1}</Typography>}
        {line2 && <Typography variant="inherit">{line2}</Typography>}
        {details && <Typography variant="inherit">{details}</Typography>}
        {country && <Typography variant="inherit">{country}</Typography>}
      </Typography>
    );
  }
}

export default TypographyAddress;
