import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const IconReachOut = props => (
  <SvgIcon width="704" height="704" viewBox="0 0 528 528" {...props}>
    <path
      d="M220 396h176l66 66V220c0-24.32-19.68-44-44-44H220c-24.32 0-44 19.68-44 44v132c0 24.32 19.68 44 44 44"
      fill="#1565c0"
    />
    <path
      d="M319 286H132l-66 66V110c0-24.32 19.68-44 44-44h209c24.32 0 44 19.68 44 44v132c0 24.32-19.68 44-44 44"
      fill="#2196f3"
    />
  </SvgIcon>
);

export default IconReachOut;
