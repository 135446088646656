import { call } from 'redux-saga/effects';
import moment from 'moment';

import { SAVE_REMINDER } from 'actions/actionTypes';
import contactsResource from 'resources/contactsResource';
import sagaFetch from './sagaFetch';
import sagaRefresh from './sagaRefresh';

// bind resource generator
const saveReminder = sagaFetch.bind(null, SAVE_REMINDER, contactsResource.saveReminder);

export default function* sagaSaveReminder({ contact, reminder, skipRefresh }) {
  // If reminder date is tomorrow or later, set the time to 12:00am PT. Otherwise, use the current time.
  const now = moment();
  let reminderDate;
  if (reminder.date.isAfter(now, 'day')) {
    reminderDate = moment(reminder.date)
      .utcOffset('-0800')
      .startOf('day');
  } else {
    reminderDate = moment(reminder.date)
      .hours(now.hours())
      .minutes(now.minutes());
  }

  yield call(saveReminder, {
    contact,
    reminder: {
      ...reminder,
      datetime: reminderDate.toDate(),
      isActive: true,
    },
  });
  if (!skipRefresh) {
    yield call(sagaRefresh, contact);
  }
}
